import React, { useEffect, useState } from 'react';
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardHeader, UncontrolledAccordion } from 'reactstrap';
import { TenantConfig, Tooltip } from '../../../Components/Common/Util';
import DeviceStatusMenuIcon from '../../../assets/images/common/svg/common/device-status-icon.svg';
import PolicyMenuIcon from '../../../assets/images/common/svg/common/policy-menu-icon.svg';

const WindowsDevicesFilters = (props) => {
    const tenantConfig = TenantConfig();

    const [elasticSearchObj, setElasticSearchObj] = useState(JSON.parse(JSON.stringify(props.elasticSearchObj)));

    useEffect(() => {
        setElasticSearchObj(JSON.parse(JSON.stringify(props.elasticSearchObj)));
    }, [props.elasticSearchObj]);

    const returnArrayLength = (arr) => {
        return arr.filter((ele) => ele.checked)?.length;
    };

    return (
        <React.Fragment>
            <Card>
                <CardHeader>
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                            <Button
                                color="link"
                                className="p-0"
                                // onClick={props.clearAllFilters}
                                disabled={elasticSearchObj?.selectedFiltersKeys?.length === 0}
                            >
                                Clear All
                            </Button>
                        </div>
                    </div>
                    {elasticSearchObj?.selectedFiltersKeys?.length > 0 && (
                        <div className="filter-choices-input mt-3">
                            <div className="choices" data-type="text">
                                <div className="choices__inner min-height-1">
                                    <div className="choices__list choices__list--multiple">
                                        {elasticSearchObj.selectedFiltersKeys.map((ele, ind) => {
                                            return (
                                                <div key={ind} className="choices__item choices__item--selectable">
                                                    {ele.label}
                                                    <button
                                                        type="button"
                                                        className="choices__button"
                                                        // onClick={() => props.handleRemoveFilter(ele)}
                                                    >
                                                        Remove item
                                                    </button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </CardHeader>
                <UncontrolledAccordion flush={true} defaultOpen={['1', '2', '3']} stayOpen={true}>
                    {tenantConfig.WINDOWS_FILTER_PANELS?.includes('device_status') && (
                        <AccordionItem>
                            <AccordionHeader targetId="1">
                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                    <img alt="" src={DeviceStatusMenuIcon} />
                                    <span className="ms-1 text-primary"> Device Status</span>
                                </span>
                                {returnArrayLength(elasticSearchObj.deviceStatusArr) > 0 && (
                                    <span className="badge bg-success rounded-pill align-middle ms-1">
                                        {returnArrayLength(elasticSearchObj.deviceStatusArr)}
                                    </span>
                                )}
                            </AccordionHeader>
                            <AccordionBody accordionId="1">
                                <div className="text-body pt-1">
                                    <div className="d-flex flex-column gap-1">
                                        {elasticSearchObj.deviceStatusArr?.length > 0 ? (
                                            elasticSearchObj.deviceStatusArr.map((element, ind) => {
                                                return (
                                                    <div className="d-flex align-items-center" key={ind}>
                                                        <div className="flex-grow-1">
                                                            <div className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={element.checked}
                                                                    id={`deviceStatus${ind}`}
                                                                    className="form-check-input"
                                                                    // onChange={(e) => props.handleDeviceStatusFilter(e, element)}
                                                                />
                                                                <label
                                                                    className="form-check-label fw-normal"
                                                                    htmlFor={`deviceStatus${ind}`}
                                                                >
                                                                    {element.label}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <span className="badge bg-light text-muted">{element.count}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="fs-13 d-flex align-items-center justify-content-center">
                                                {'No Records Found.'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                    )}
                    {tenantConfig?.WINDOWS_FILTER_PANELS?.includes('policies') && (
                        <AccordionItem>
                            <AccordionHeader targetId="2" className="border-top">
                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                    <img alt="" src={PolicyMenuIcon} />
                                    <span className="ms-1 text-primary"> Policies</span>
                                </span>{' '}
                                {returnArrayLength(elasticSearchObj.policies) > 0 && (
                                    <span className="badge bg-success rounded-pill align-middle ms-1">
                                        {returnArrayLength(elasticSearchObj.policies)}
                                    </span>
                                )}
                            </AccordionHeader>
                            <AccordionBody accordionId="2" className="overflow-auto">
                                <div className="d-flex flex-column gap-1 max-height-300 text-body pt-0">
                                    {elasticSearchObj?.policies?.length > 0 ? (
                                        elasticSearchObj.policies.map((ele, index) => {
                                            return (
                                                <div className="d-flex align-items-center" key={index}>
                                                    <div className="flex-grow-1 w-auto text-truncate word-ellipsis">
                                                        <div className="form-check">
                                                            <input
                                                                type="checkbox"
                                                                checked={ele.checked}
                                                                id={`policyRadio${index}`}
                                                                className="form-check-input"
                                                                // onChange={(e) => props.handlePolicyFilter(e, ele)}
                                                            />
                                                            <label
                                                                className="form-check-label fw-normal word-ellipsis"
                                                                htmlFor={`policyRadio${index}`}
                                                            >
                                                                <span className="fs-13 fw-normal word-ellipsis" id={`policy-${index}`}>
                                                                    {ele.label}
                                                                </span>
                                                                {ele.label?.length > 20 && Tooltip(`policy-${index}`, ele.label)}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0 mb-2">
                                                        <span className="badge bg-light text-muted">{ele.count}</span>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="fs-13 d-flex align-items-center justify-content-center">{'No Records Found.'}</div>
                                    )}
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                    )}
                </UncontrolledAccordion>
            </Card>
        </React.Fragment>
    );
};

export default WindowsDevicesFilters;
