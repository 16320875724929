import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import { ENROLLMENT_STEPS_DATA } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';

const EnrollmentStepsEdit = (props) => {
    const api = new APIClient();
    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});
    const history = useHistory();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash, true);
    let formType = formTypeAndId['formType'];

    useEffect(() => {
        enrollmentStepPromise();
    }, []);

    const enrollmentStepPromise = () => {
        api.get(url.ENROLLMENT_STEPS, '', domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') handleES(resp.data);
            })
            .catch((err) => setLoading(false));
    };

    const handleChange = (e, ele, key) => {
        setValues({ ...values, [ele.value]: e.target[key] });
    };
    const handleSteps = (e, ele) => {
        let vals = JSON.parse(JSON.stringify(values));
        if (!e.target.checked) vals[ele.value] = vals[ele.value]?.filter((val) => val !== ele.key);
        else {
            if (Array.isArray(vals?.[ele.value])) vals[ele.value].push(ele.key);
            else vals = { [ele.value]: [ele.key] };
        }
        setValues(vals);
    };
    const definePath = (schema, obj) => {
        schema.path.forEach((path) => (obj = obj?.[path]));
        return obj;
    };

    const defineDropDown = async (obj, key, dataObj) => {
        obj[dataObj.value] = await dataObj.options.find(
            (option) => option[dataObj.optionValue]?.toLowerCase() === key[dataObj.value]?.toLowerCase()
        );
        return obj;
    };
    const handleES = async (resp) => {
        let obj = {};
        if (resp)
            await ENROLLMENT_STEPS_DATA?.forEach(async (dataObj) => {
                if (dataObj.inputType !== 'heading') {
                    let key = definePath(dataObj, resp);
                    if (dataObj.inputType === 'input') obj[dataObj.value] = key[dataObj.value];
                    else if (dataObj.inputType === 'dropdown') obj = await defineDropDown(obj, key, dataObj);
                    else if (dataObj.inputType === 'checkbox') obj[dataObj.value] = !!key[dataObj.value];
                    else obj[dataObj.value] = key?.[dataObj.value] || [];
                }
            });
        let data = resp ? Object.assign(resp, obj) : '';
        setValues(data);
    };
    const getES = () => {
        setLoading(true);
        api.get(url.ENROLLMENT_STEPS, '', domains.IOS_V1)
            .then(async (resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') await handleES(resp.data);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const onSaveES = () => {
        let params = {
            allow_pairing: !!values?.allow_pairing,
            // anchor_certs: null,
            auto_advance_setup: !!values?.auto_advance_setup,
            await_device_configured: !!values?.await_device_configured,
            // configuration_web_url: values?.configuration_web_url,
            department: values?.department,
            devices: [],
            is_mandatory: !!values?.is_mandatory,
            is_mdm_removable: !!values?.is_mdm_removable,
            is_multi_user: !!values?.is_multi_user,
            is_supervised: !!values?.is_supervised,
            // support_phone_number: '',
            language: values?.language?.value || undefined,
            // org_magic: '',
            profile_name: values?.profile_name || undefined,
            // region: values?.region?.code || '',
            skip_setup_items: values?.skip_setup_items,
            // supervising_host_certs: null,
            support_email_address: 'emm-support@tectoro.com'
            // url: ''
        };
        api.patch(url.ENROLLMENT_STEPS + '/' + values._id, params, false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.enrollmentStepsEdited : toastMessages.enrollmentStepsCreated);
                    getES();
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };
    const handleSave = () => {
        onSaveES();
        formType === 'edit' && history.push('/iossetup');
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Apple Setup" pageTitle="Apple Setup" history={history} />
                    <Row>
                        <Card className="card-height-100">
                            <CardHeader>
                                <div className="fs-14 fw-semibold">On screen enrollment steps for Apple devices (End-user)</div>
                                <div className=" pt-2 font-size-13 fw-medium text-muted">
                                    Enrolling Apple devices in a Mobile Device Management (MDM) solution involves several steps that
                                    end-users must follow. Here’s a detailed guide for on-screen enrollment steps for Apple devices:
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    {ENROLLMENT_STEPS_DATA.map((ele, ind) => {
                                        return (
                                            <React.Fragment key={ind}>
                                                <div className="gap-2 mb-3">
                                                    {ele.inputType === 'input' ? (
                                                        <Row>
                                                            <Row>
                                                                <Col
                                                                    xs={6}
                                                                    md={6}
                                                                    sm={6}
                                                                    lg={6}
                                                                    xl={6}
                                                                    className={'d-flex align-items-center justify-content-between'}
                                                                >
                                                                    <Label
                                                                        className={`mb-1 fw-medium d-flex ${
                                                                            ele.notes ? '' : 'align-items-center'
                                                                        }`}
                                                                    >
                                                                        {ele.label}
                                                                    </Label>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                {ele?.helpText && (
                                                                    <Col
                                                                        xs={6}
                                                                        md={6}
                                                                        sm={6}
                                                                        lg={6}
                                                                        xl={6}
                                                                        className="fw-normal font-size-12 mb-1 text-start fw-medium text-muted"
                                                                    >
                                                                        {ele.helpText}
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                            <Row>
                                                                <Col xs={6} md={6} sm={6} lg={6} xl={6}>
                                                                    <div className="input-group">
                                                                        <Input
                                                                            name={ele.value}
                                                                            id={ele.value}
                                                                            className="form-control"
                                                                            placeholder={`Enter ${ele.label}`}
                                                                            type={ele.type}
                                                                            validate={{ required: { value: true } }}
                                                                            onChange={(e) => handleChange(e, ele, 'value')}
                                                                            min={ele.minValue}
                                                                            max={ele.maxValue}
                                                                            disabled={formType === 'view'}
                                                                            maxLength={ele.maxLength}
                                                                            onBlur={props.validation?.handleBlur}
                                                                            value={values[ele?.value] || ''}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Row>
                                                    ) : ele.inputType === 'dropdown' ? (
                                                        <React.Fragment key={ind}>
                                                            <Row>
                                                                <Col
                                                                    xs={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    lg={6}
                                                                    xl={6}
                                                                    className="d-flex align-items-center justify-content-between"
                                                                >
                                                                    <Label className="mb-1 fw-medium d-flex align-items-center">
                                                                        {ele.label}
                                                                    </Label>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                {ele?.helpText && (
                                                                    <Col
                                                                        xs={6}
                                                                        md={6}
                                                                        sm={6}
                                                                        lg={6}
                                                                        xl={6}
                                                                        className="fw-normal font-size-12 mb-1 text-start fw-medium text-muted"
                                                                    >
                                                                        {ele.helpText}
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                            <Row>
                                                                <Col xs={6} md={6} sm={6} lg={6} xl={6} className="mb-2">
                                                                    <Select
                                                                        getOptionValue={(option) =>
                                                                            ele.optionValue ? option[ele.optionValue] : option.value
                                                                        }
                                                                        getOptionLabel={(option) =>
                                                                            ele.optionLabel ? option[ele.optionLabel] : option.label
                                                                        }
                                                                        isMulti={ele.isMulti ? true : false}
                                                                        isClearable={ele.isMulti || ele.removable ? true : false}
                                                                        closeMenuOnSelect={ele.isMulti ? false : true}
                                                                        id={ele.label}
                                                                        name={ele.value}
                                                                        isDisabled={formType === 'view'}
                                                                        options={ele.options?.filter((option) => !option.hidden)}
                                                                        placeholder={`Select ${ele.label}`}
                                                                        onChange={(selectedOption) =>
                                                                            handleChange(
                                                                                { target: { value: selectedOption } },
                                                                                ele,
                                                                                'value'
                                                                            )
                                                                        }
                                                                        value={values[ele.value] || ''}
                                                                        isSearchable={true}
                                                                        noOptionsMessage={() => 'No data found'}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    ) : ele.inputType === 'heading' ? (
                                                        <Row>
                                                            <div className="text-success fw-semibold text-decoration-underline">
                                                                {ele.label}
                                                            </div>
                                                        </Row>
                                                    ) : ele.inputType === 'checkbox' ? (
                                                        <React.Fragment>
                                                            <Row className="mx-2">
                                                                <Col sm={'auto'} className="p-0">
                                                                    <div className="form-check form-check-inline mb-0 me-0">
                                                                        <Input
                                                                            className="form-check-input "
                                                                            type="checkbox"
                                                                            id={ele.value}
                                                                            name={ele.value}
                                                                            // onBlur={validation.handleBlur}
                                                                            disabled={formType === 'view'}
                                                                            onChange={(e) => handleChange(e, ele, 'checked')}
                                                                            checked={!!values[ele.value] || false}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="p-0">
                                                                    <div className="fw-medium d-flex">
                                                                        {ele.label}
                                                                        {ele.badges?.map((badge, index) => (
                                                                            <span
                                                                                key={index}
                                                                                className="fs-10 d-grid place-items-center rounded-2 px-1 bg-soft-info text-info"
                                                                            >
                                                                                {badge}
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mx-5">
                                                                <Col
                                                                    xs={6}
                                                                    md={8}
                                                                    sm={6}
                                                                    lg={6}
                                                                    xl={6}
                                                                    className="fw-normal font-size-12 text-start fw-medium text-muted"
                                                                >
                                                                    {ele.helpText}
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    ) : ele.value === 'skip_setup_items' ? (
                                                        <React.Fragment>
                                                            <Row className="mx-2">
                                                                <Col sm={'auto'} className="pe-0">
                                                                    <div className="form-check form-check-inline  mb-0 me-0">
                                                                        <Input
                                                                            className="form-check-input "
                                                                            type="checkbox"
                                                                            id={ele.value}
                                                                            name={ele.value}
                                                                            // onBlur={validation.handleBlur}
                                                                            disabled={formType === 'view'}
                                                                            onChange={(e) => handleSteps(e, ele)}
                                                                            checked={!!values[ele.value]?.includes(ele.key) || false}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="p-0">
                                                                    <div className="fw-medium d-flex">
                                                                        {ele.label}
                                                                        {ele.badges?.map((badge, index) => (
                                                                            <span
                                                                                key={index}
                                                                                className="fs-10 d-grid place-items-center mx-1 px-1 rounded-2 bg-soft-info text-info"
                                                                            >
                                                                                {badge}
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mx-5">
                                                                <Col
                                                                    xs={6}
                                                                    md={8}
                                                                    sm={6}
                                                                    lg={6}
                                                                    xl={6}
                                                                    className="fw-normal font-size-12 text-start fw-medium text-muted"
                                                                >
                                                                    {ele.helpText}
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </CardBody>
                            <CardFooter>
                                <div className="d-flex justify-content-end align-items-center px-3">
                                    <Button
                                        className="bg-light border-primary text-primary mx-3 fs-12 fw-semibold px-5"
                                        onClick={() => history.push('/iossetup')}
                                    >
                                        Cancel
                                    </Button>
                                    {formType !== 'view' && (
                                        <Button className="bg-primary border-primary px-6 fs-12 fw-semibold" onClick={() => handleSave()}>
                                            {formType === 'edit' ? 'Update' : 'Save'}
                                        </Button>
                                    )}
                                </div>
                            </CardFooter>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EnrollmentStepsEdit;
