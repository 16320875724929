import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import EnrollmentStepsImage from '../../../assets/images/common/png/common/enrollmentSteps.png';
import { useHistory } from 'react-router-dom';
import { IsAuthorized } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';

const EnrollmentSteps = (props) => {
    const [showSteps, setShowSteps] = useState(false);
    const [values, setValues] = useState(props.data ? props.data : '');
    const [mode, setMode] = useState('add');
    const history = useHistory();

    useEffect(() => {
        setValues(props.data ? props.data : '');
    }, [props.data]);

    const onConfigureES = (type) => {
        history.push(`/iossetup/${type}`);
        setMode(type);
        setShowSteps(true);
    };

    return (
        <Card className="card-height-100">
            <CardHeader>
                <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                    <div className="fs-14 ">On screen enrollment steps for Apple devices (End-user)</div>
                    <div className={`d-flex justify-content-center align-items-center fs-12 ${!values ? 'text-danger' : 'text-success'}`}>
                        {!values ? (
                            <i className="ri-error-warning-fill fs-16 px-1 text-danger"></i>
                        ) : (
                            <i className="ri-checkbox-circle-fill fs-16 px-1 text-success"></i>
                        )}
                        {!values ? 'Not Configured' : 'Configured'}
                    </div>
                </div>
                <div className="padding-top-10 font-size-12 fw-medium text-muted">
                    Enrolling Apple devices in a Mobile Device Management (MDM) solution involves several steps that end-users must follow.
                    Here’s a detailed guide for on-screen enrollment steps for Apple devices.
                </div>
            </CardHeader>
            {!values ? (
                <div>
                    <CardBody>
                        <div className="text-center padding-top-35">
                            <div>
                                <img height={65} src={EnrollmentStepsImage} alt="img"></img>
                            </div>
                            <div className="fw-medium fs-14 py-3">End User Enrollment Screen Steps</div>
                            <div className="font-size-12 text-start fw-medium text-muted px-2">
                                Enrolling Apple devices in a Mobile Device Management (MDM) solution involves several steps that end-users
                                must follow. Here’s a detailed guide for on-screen enrollment steps for Apple devices.
                            </div>
                            <IsAuthorized
                                privReq={priviliges.ENTERPRISE_EDITOR}
                                yes={() => (
                                    <div className="py-4">
                                        <Button
                                            className="bg-primary px-15 py-3 border-none fw-medium fs-12"
                                            onClick={() => onConfigureES()}
                                        >
                                            Configure
                                        </Button>
                                    </div>
                                )}
                            />
                        </div>
                    </CardBody>
                </div>
            ) : (
                !showSteps && (
                    <div>
                        <CardBody>
                            <div className="text-center padding-top-35">
                                <div>
                                    <img height={65} src={EnrollmentStepsImage} alt="img"></img>
                                </div>
                                <div className="fw-medium fs-14 py-3">End User Enrollment Screen Steps</div>
                                <div className="font-size-12 text-start fw-medium text-muted px-2">
                                    Please click on Configure and choose the necessary steps which will be visible while enrolling on Apple
                                    Device by an end user.
                                </div>
                                <div
                                    className="link-success d-flex align-items-center justify-content-center fw-medium mt-3 text-decoration-underline text-success cursor-pointer"
                                    onClick={() => onConfigureES('view')}
                                >
                                    View Configured Steps
                                </div>
                                <IsAuthorized
                                    privReq={priviliges.ENTERPRISE_EDITOR}
                                    yes={() => (
                                        <div
                                            className="d-flex align-items-center link-success fw-medium fs-14 mt-2 justify-content-center gap-1 cursor-pointer"
                                            onClick={() => onConfigureES('edit')}
                                        >
                                            <i className="ri-edit-box-line fs-16" />
                                            Edit
                                        </div>
                                    )}
                                />
                            </div>
                        </CardBody>
                    </div>
                )
            )}
        </Card>
    );
};

export default EnrollmentSteps;
