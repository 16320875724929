import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Card, CardBody, CardFooter, Container, Input } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import {
    DownloadBunnyFile,
    Enterprise,
    GetAuthmodes,
    TenantConfig,
    baseURL,
    getFormTypeAndRecordId
} from '../../../Components/Common/Util';
import settingFieldsArray from './SettingFields';

import axios from 'axios';
import { toast } from 'react-toastify';
import CommonModal from '../../../Components/Common/CommonModal';
import TabsComponent from '../../../Components/Common/TabsComponent';
import { defaultBranding } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { CustomLauncherThemeTemplate, SettingsTemplate } from './LauncherTabBodies';

const AddLauncherSetup = () => {
    let api = new APIClient();
    let history = useHistory();
    let accountId = localStorage.getItem('account');
    const authModeString = GetAuthmodes();
    const authModes = authModeString ? JSON.parse(authModeString) : [];

    const urlconf = useEnv();

    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];

    document.title = formType === 'view' ? 'View Launcher' : formType === 'edit' ? 'Edit Launcher' : 'Add Launcher';

    const tenant = TenantConfig();
    const [openDropDown, setOpenDropDown] = useState({});

    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState('');
    const [type, setType] = useState('');
    const [header, setHeader] = useState('');
    const [key, setKey] = useState('');
    const [showDefaultPassword, setShowDefaultPassword] = useState(false);
    const [timerModal, setTimerModal] = useState(false);
    const [timerValue, setTimerValue] = useState({ minutes: '00', seconds: '05' });
    const [applications, setApplications] = useState([]);
    const [packageName, setPackageName] = useState('');
    const [managedConfigId, setManagedConfigId] = useState('');
    const [selectedTab, setSelectedTab] = useState('branding');
    const [settings, setSettings] = useState([...settingFieldsArray]);
    const [fileParams, setFileParams] = useState({ file: '', type: '' });
    let enterprise = Enterprise();
    let enterpriseObj = enterprise ? JSON.parse(enterprise) : {};

    let valuesArr = [
        'wifi',
        'sim',
        'data_roaming',
        'bluetooth',
        'power_button',
        'restart',
        'volume',
        'soft_keys',
        'screen_orientation',
        'brightness',
        'details',
        'system_settings',
        'policy_settings',
        'mobile_data',
        'enable_sim',
        'refresh_wifi',
        'keyboard',
        'kiosk_exit'
    ];
    const [featureEnabled, setFeatureEnabled] = useState({
        general_settings: false,
        sim_settings: false,
        memory_card_settings: false,
        wifi_settings: false,
        secure_browser: false,
        call_settings: false,
        message_settings: false,
        geo_fencing: false,
        show_device_details: true,
        mobile_data: false,
        enable_sim: false,
        keyboard: false,
        brightness: false,
        show_refresh_wifi: true,
        show_kiosk_exit: false,
        req_admin_password_kiosk_exit: false
    });
    const [passwords, setPasswords] = useState({
        wifi: tenant?.DEVICE_DEFAULT_PASSWORD,
        sim: tenant?.DEVICE_DEFAULT_PASSWORD,
        data_roaming: tenant?.DEVICE_DEFAULT_PASSWORD,
        bluetooth: tenant?.DEVICE_DEFAULT_PASSWORD,
        power_button: tenant?.DEVICE_DEFAULT_PASSWORD,
        restart: tenant?.DEVICE_DEFAULT_PASSWORD,
        volume: tenant?.DEVICE_DEFAULT_PASSWORD,
        soft_keys: tenant?.DEVICE_DEFAULT_PASSWORD,
        screen_orientation: tenant?.DEVICE_DEFAULT_PASSWORD,
        details: tenant?.DEVICE_DEFAULT_PASSWORD,
        system_settings: tenant?.DEVICE_DEFAULT_PASSWORD,
        policy_settings: tenant?.DEVICE_DEFAULT_PASSWORD,
        mobile_data: tenant?.DEVICE_DEFAULT_PASSWORD,
        enable_sim: tenant?.DEVICE_DEFAULT_PASSWORD,
        keyboard: tenant?.DEVICE_DEFAULT_PASSWORD,
        brightness: tenant?.DEVICE_DEFAULT_PASSWORD,
        refresh_wifi: tenant?.DEVICE_DEFAULT_PASSWORD,
        kiosk_exit: tenant?.DEVICE_DEFAULT_PASSWORD
    });

    useEffect(() => {
        let settingsArr = JSON.parse(JSON.stringify(settings));
        settingsArr = settingsArr.filter((setting) => (setting.tenantkey ? tenant[setting.tenantkey] : true));
        setSettings(settingsArr);
        setLoading(true);
        handlePromise();
    }, []);

    const handlePromise = () => {
        const launcherPackagePromise = new Promise((resolve, reject) => {
            api.get(url.APPLICATIONS + '/LAUNCHER')
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.status === 'success') resolve(resp.data);

                    reject('Launcher Package Not Found.');
                })
                .catch((_err) => reject('Launcher Package Not Found.'));
        });

        const applicationsPromise = new Promise((resolve, reject) => {
            api.get(url.APPLICATIONS, { enterpriseId: enterpriseObj.id, page: 1, size: 2000, metrics: 'false' })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data?.length > 0) resolve(resp.data);
                    reject('Applications failed');
                })
                .catch((err) => reject('Applications failed'));
        });

        Promise.allSettled([launcherPackagePromise, applicationsPromise])
            .then(async (result) => {
                if (result[1].status === 'fulfilled') {
                    result[1].value = result[1].value?.filter((app) => app.code !== 'LAUNCHER');
                }
                if (result[0].status === 'fulfilled') {
                    setPackageName(result[0]?.value?.[0]?.packageName);
                    if (recordID) await getConfig(result[1].status === 'fulfilled' ? result[1].value : []);
                }
                if (result[1].status === 'fulfilled' && !recordID) {
                    setApplications(result[1].value);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const prepareFoldersValue = (folders, apps) => {
        let arr = [];
        folders?.forEach((folder) => {
            let appArr = apps.filter((app) => folder.apps.includes(app.packageName));
            arr.push({ name: folder.name, apps: appArr });
        });
        return arr;
    };

    const getFolderApps = (folders) => {
        let apps = [];
        folders.forEach((folder) => (apps = apps.concat(folder.apps)));
        return apps;
    };

    const getConfig = async (apps) => {
        await api
            .get(url.MANAGED_CONFIGURATIONS + '/' + recordID)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    let data = resp.data;
                    setManagedConfigId(resp.data?.managedConfigId);
                    let obj = {
                        setting_default_password: data.config?.deviceSettings?.default_password
                            ? data.config?.deviceSettings?.default_password
                            : data.config?.settings?.kioskExitPassword,
                        setting_custom_password: data.config?.deviceSettings?.custom_password
                            ? data.config?.deviceSettings?.custom_password
                            : data.config?.settings?.kioskExitPassword
                    };

                    let folderApps =
                        data?.config?.branding?.folder?.folders?.length > 0 ? getFolderApps(data?.config?.branding?.folder?.folders) : [];
                    let appArr = apps.filter((app) => !folderApps.includes(app.packageName));
                    setApplications(appArr);
                    let timer = data.config?.deviceSettings?.close_time?.split(':');
                    setTimerValue({ minutes: timer[0], seconds: timer[1] });
                    setFormValues({
                        ...obj,
                        name: resp.data.title,
                        enableHeader: !!data?.config?.branding?.topBar?.enabled,
                        headerTransparent: !!data?.config?.branding?.topBar?.transparent,
                        headerBackgroundColor: data?.config?.branding?.topBar?.bgColor,
                        headerTextColor: data?.config?.branding?.topBar?.color,
                        showSerial: data?.config?.branding?.topBar?.serial,
                        showIMEI: data?.config?.branding?.topBar?.imei,
                        enableFooter: !!data?.config?.branding?.footer?.enabled,
                        footerBackgroundColor: data.config?.branding?.footer?.bgColor,
                        footerTextColor: data.config?.branding?.footer?.color,
                        grid: data.config?.branding?.gridSize,
                        iconOrder: data.config?.branding?.iconOrder,
                        enableCompanyLogo: data.config?.branding?.companyLogo?.enabled,
                        companyLogo: data.config?.branding?.companyLogo?.logo,
                        enableLockScreenMessage: data?.config?.branding?.enabled,
                        lockScreenMessage: data.config?.branding?.lockScreenMessage?.message,
                        orientation: data.config?.branding?.orientation,
                        iconLabelTransparent: !!data.config?.branding?.appIconLabelTransparency,
                        iconTextColor: data.config?.branding?.appIconTextColor,
                        bookmarks: data.config?.branding?.bookmarks,
                        /*
                         * enableAlertSound: data.config?.branding?.alertSound?.enabled,
                         * alertSound: data.config?.branding?.alertSound?.file,
                         */
                        enableAppLabel: !data.config?.branding?.hideappLabel,
                        footerText: data.config?.branding?.footer?.text,
                        enableLabelShadow: data.config?.branding?.appLabelShadow,
                        appsPerRow: data.config?.branding?.iconsPerRow,
                        iconSize: data.config?.branding?.iconSize,
                        footerTransparent: data.config?.branding?.footer?.transparent,

                        close_time: data?.config?.deviceSettings?.close_time,
                        wifi: data.config?.deviceSettings?.wifi?.password_type,
                        sim: data.config?.deviceSettings?.sim?.password_type,
                        data_roaming: data.config?.deviceSettings?.data_roaming?.password_type,
                        bluetooth: data.config?.deviceSettings?.bluetooth?.password_type,
                        power_button: data.config?.deviceSettings?.power_button?.password_type,
                        restart: data.config?.deviceSettings?.restart?.password_type,
                        volume: data.config?.deviceSettings?.volume_controls?.password_type,
                        soft_keys: data.config?.deviceSettings?.soft_keys?.password_type,
                        screen_orientation: data.config?.deviceSettings?.screen_orientation?.password_type,
                        details: data.config?.deviceSettings?.device_details?.password_type,
                        system_settings: data.config?.deviceSettings?.system_settings?.password_type,
                        policy_settings: data.config?.deviceSettings?.policy?.password_type,
                        app_updates: data.config?.deviceSettings?.appUpdates?.password_type,
                        kiosk_exit: data.config?.deviceSettings?.kioskExit?.password_type,
                        mobile_data: data.config?.deviceSettings?.mobileData?.password_type,
                        enable_sim: data.config?.deviceSettings?.enableSIM?.password_type,
                        keyboard: data.config?.deviceSettings?.keyboard?.password_type,
                        brightness: data.config?.deviceSettings?.brightness?.password_type,
                        refresh_wifi: data?.config?.deviceSettings?.refresh_screen_wifi?.password_type,

                        req_timer_wifi: !!data.config?.deviceSettings?.wifi?.req_timer,
                        req_timer_data_roaming: !!data?.config?.deviceSettings?.data_roaming?.req_timer,
                        req_timer_bluetooth: !!data?.config?.deviceSettings?.bluetooth?.req_timer,
                        req_timer_mobile_data: !!data?.config?.deviceSettings?.mobileData?.req_timer,
                        req_timer_enable_sim: !!data?.config?.deviceSettings?.enableSIM?.req_timer,
                        req_timer_screen_orientation: !!data?.config?.deviceSettings?.screen_orientation?.req_timer,
                        req_timer_brightness: !!data?.config?.deviceSettings?.brightness?.req_timer,
                        req_timer_device_details: !!data?.config?.deviceSettings?.device_details?.req_timer,
                        req_timer_policy_settings: !!data?.config?.deviceSettings?.policy?.req_timer,
                        req_timer_system_settings: !!data?.config?.deviceSettings?.system_settings?.req_timer,
                        req_timer_keyboard: !!data?.config?.deviceSettings?.keyboard?.req_timer,
                        req_timer_refresh_wifi: !!data?.config?.deviceSettings?.refresh_screen_wifi?.req_timer,
                        req_timer_kiosk_exit: data.config?.deviceSettings?.kioskExit?.req_timer,

                        timer_wifi: data?.config?.deviceSettings?.wifi?.timer ? data?.config?.deviceSettings?.wifi?.timer : 0,
                        timer_data_roaming: data?.config?.deviceSettings?.data_roaming?.timer
                            ? data?.config?.deviceSettings?.data_roaming?.timer
                            : 0,
                        timer_bluetooth: data?.config?.deviceSettings?.bluetooth?.timer
                            ? data?.config?.deviceSettings?.bluetooth?.timer
                            : 0,
                        timer_mobile_data: data?.config?.deviceSettings?.mobileData?.timer
                            ? data?.config?.deviceSettings?.mobileData?.timer
                            : 0,
                        timer_enable_sim: data?.config?.deviceSettings?.enableSIM?.timer
                            ? data?.config?.deviceSettings?.enableSIM?.timer
                            : 0,
                        timer_screen_orientation: data?.config?.deviceSettings?.screen_orientation?.timer
                            ? data?.config?.deviceSettings?.screen_orientation?.timer
                            : 0,
                        timer_brightness: data?.config?.deviceSettings?.brightness?.timer
                            ? data?.config?.deviceSettings?.brightness?.timer
                            : 0,
                        timer_device_details: data?.config?.deviceSettings?.device_details?.timer
                            ? data?.config?.deviceSettings?.device_details?.timer
                            : 0,
                        timer_policy_settings: data?.config?.deviceSettings?.policy?.timer
                            ? data?.config?.deviceSettings?.policy?.timer
                            : 0,
                        timer_system_settings: data?.config?.deviceSettings?.system_settings?.timer
                            ? data?.config?.deviceSettings?.system_settings?.timer
                            : 0,
                        timer_keyboard: data?.config?.deviceSettings?.keyboard?.timer ? data?.config?.deviceSettings?.keyboard?.timer : 0,
                        timer_refresh_wifi: data?.config?.deviceSettings?.refresh_screen_wifi?.timer
                            ? data?.config?.deviceSettings?.refresh_screen_wifi?.timer
                            : 0,
                        timer_kiosk_exit: data?.config?.deviceSettings?.kioskExit?.timer
                            ? data?.config?.deviceSettings?.kioskExit?.timer
                            : 0,

                        enableFolder: !!data?.config?.branding?.folder?.enbaled,
                        folders:
                            data?.config?.branding?.folder?.folders?.length > 0
                                ? prepareFoldersValue(data?.config?.branding?.folder?.folders, apps)
                                : []
                    });
                    setFeatureEnabled({
                        show_wifi: !!data.config?.deviceSettings?.wifi?.show,
                        show_sim: !!data.config?.deviceSettings?.sim?.show,
                        show_data_roaming: !!data.config?.deviceSettings?.data_roaming?.show,
                        show_screen_orientation: !!data.config?.deviceSettings?.screen_orientation?.show,
                        show_bluetooth: !!data.config?.deviceSettings?.bluetooth?.show,
                        show_soft_keys: !!data.config?.deviceSettings?.soft_keys?.show,
                        show_volume_controls: !!data.config?.deviceSettings?.volume_controls?.show,
                        show_restart: !!data.config?.deviceSettings?.restart?.show,
                        show_system_settings: !!data.config?.deviceSettings?.system_settings?.show,
                        show_device_details: !!data.config?.deviceSettings?.device_details?.show,
                        show_policy: !!data.config?.deviceSettings?.policy?.show,
                        show_kiosk_exit: !!data.config?.deviceSettings?.kioskExit?.show,
                        show_app_update: true,
                        show_mobile_data: !!data.config?.deviceSettings?.mobileData?.show,
                        show_enable_sim: !!data.config?.deviceSettings?.enableSIM?.show,
                        show_keyboard: !!data.config?.deviceSettings?.keyboard?.show,
                        show_brigntness: !!data.config?.deviceSettings?.brightness?.show,
                        enable_power_button: !!data.config?.deviceSettings?.power_button?.show,
                        show_refresh_wifi: true,
                        req_admin_password_system_settings: !!data.config?.deviceSettings?.system_settings?.req_password,
                        req_admin_password_device_details: !!data.config?.deviceSettings?.device_details?.req_password,
                        req_admin_password_wifi: !!data.config?.deviceSettings?.wifi?.req_password,
                        req_admin_password_sim: !!data.config?.deviceSettings?.sim?.req_password,
                        req_admin_password_data: !!data.config?.deviceSettings?.data_roaming?.req_password,
                        req_admin_password_screen_orientation: !!data.config?.deviceSettings?.screen_orientation?.req_password,
                        req_admin_password_bluetooth: !!data.config?.deviceSettings?.bluetooth?.req_password,
                        req_admin_password_soft_keys: !!data.config?.deviceSettings?.soft_keys?.req_password,
                        req_admin_password_volume_controls: !!data.config?.deviceSettings?.volume_controls?.req_password,
                        req_admin_password_restart: !!data.config?.deviceSettings?.restart?.req_password,
                        req_admin_password_power_button: !!data.config?.deviceSettings?.power_button?.req_password,
                        req_admin_password_policy: !!data.config?.deviceSettings?.policy?.req_password,
                        req_admin_password_app_update: !!data.config?.deviceSettings?.appUpdates?.req_password,
                        req_admin_password_kiosk_exit: data.config?.deviceSettings?.kioskExit?.req_password,
                        req_admin_password_mobile_data: data.config?.deviceSettings?.mobileData?.req_password,
                        req_admin_password_enable_sim: data.config?.deviceSettings?.enableSIM?.req_password,
                        req_admin_password_keyboard: data.config?.deviceSettings?.keyboard?.req_password,
                        req_admin_password_brightness: data.config?.deviceSettings?.brightness?.req_password,
                        req_refresh_wifi: data.config?.deviceSettings?.refresh_screen_wifi?.req_password
                    });
                    setPasswords({
                        ...passwords,
                        wifi: data.config?.deviceSettings?.wifi?.password
                            ? data.config?.deviceSettings?.wifi?.password
                            : obj.setting_default_password,
                        sim: data.config?.deviceSettings?.sim?.password
                            ? data.config?.deviceSettings?.sim?.password
                            : obj.setting_default_password,
                        data_roaming: data.config?.deviceSettings?.data_roaming?.password
                            ? data.config?.deviceSettings?.data_roaming?.password
                            : obj.setting_default_password,
                        bluetooth: data.config?.deviceSettings?.bluetooth?.password
                            ? data.config?.deviceSettings?.bluetooth?.password
                            : obj.setting_default_password,
                        power_button: data.config?.deviceSettings?.power_button?.password
                            ? data.config?.deviceSettings?.power_button?.password
                            : obj.setting_default_password,
                        restart: data.config?.deviceSettings?.restart?.password
                            ? data.config?.deviceSettings?.restart?.password
                            : obj.setting_default_password,
                        volume: data.config?.deviceSettings?.volume_controls?.password
                            ? data.config?.deviceSettings?.volume_controls?.password
                            : obj.setting_default_password,
                        soft_keys: data.config?.deviceSettings?.soft_keys?.password
                            ? data.config?.deviceSettings?.soft_keys?.password
                            : obj.setting_default_password,
                        screen_orientation: data.config?.deviceSettings?.screen_orientation?.password
                            ? data.config?.deviceSettings?.screen_orientation?.password
                            : obj.setting_default_password,
                        details: data.config?.deviceSettings?.device_details?.password
                            ? data.config?.deviceSettings?.device_details?.password
                            : obj.setting_default_password,
                        system_settings: data.config?.deviceSettings?.system_settings?.password
                            ? data.config?.deviceSettings?.system_settings?.password
                            : obj.setting_default_password,
                        policy_settings: data.config?.deviceSettings?.policy?.password
                            ? data.config?.deviceSettings?.policy?.password
                            : obj.setting_default_password,
                        app_updates: data.config?.deviceSettings?.appUpdates?.password
                            ? data.config?.deviceSettings?.appUpdates?.password
                            : obj.setting_default_password,
                        kiosk_exit: data.config?.deviceSettings?.kioskExit?.password
                            ? data.config?.deviceSettings?.kioskExit?.password
                            : obj.setting_default_password,
                        mobile_data: data.config?.deviceSettings?.mobileData?.password
                            ? data.config?.deviceSettings?.mobileData?.password
                            : obj.setting_default_password,
                        enable_sim: data.config?.deviceSettings?.enableSIM?.password
                            ? data.config?.deviceSettings?.enableSIM?.password
                            : obj.setting_default_password,
                        keyboard: data.config?.deviceSettings?.keyboard?.password
                            ? data.config?.deviceSettings?.keyboard?.password
                            : obj.setting_default_password,
                        brightness: data.config?.deviceSettings?.brightness?.password
                            ? data.config?.deviceSettings?.brightness?.password
                            : obj.setting_default_password,
                        refresh_wifi: data.config?.deviceSettings?.refresh_screen_wifi?.password
                            ? data.config?.deviceSettings?.refresh_screen_wifi?.password
                            : obj.setting_default_password
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const prepareFolders = (folderArr) => {
        folderArr = folderArr?.length > 0 ? folderArr : [];
        let arr = [];
        folderArr.forEach((folder) => {
            let apps = folder.apps.map((app) => app.packageName);
            arr.push({ name: folder.name, apps: apps });
        });
        return arr;
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: formValues?.name ? formValues.name : '',
            setting_default_password: formValues?.setting_default_password
                ? formValues?.setting_default_password
                : formValues?.kioskExitPassword
                ? formValues?.kioskExitPassword
                : tenant?.DEVICE_DEFAULT_PASSWORD,
            setting_default_password_changing: formValues?.setting_default_password
                ? formValues?.setting_default_password
                : formValues?.kioskExitPassword
                ? formValues?.kioskExitPassword
                : tenant?.DEVICE_DEFAULT_PASSWORD,
            setting_custom_password: formValues?.setting_custom_password
                ? formValues?.setting_custom_password
                : formValues?.kioskExitPassword
                ? formValues?.kioskExitPassword
                : tenant?.DEVICE_DEFAULT_PASSWORD
                ? tenant?.DEVICE_DEFAULT_PASSWORD
                : '',
            setting_custom_password_changing: formValues?.setting_custom_password
                ? formValues?.setting_custom_password
                : formValues?.kioskExitPassword
                ? formValues?.kioskExitPassword
                : tenant?.DEVICE_DEFAULT_PASSWORD
                ? tenant?.DEVICE_DEFAULT_PASSWORD
                : '',
            close_time: formValues?.close_time || '00:05',

            wifi: formValues.wifi === 'false' ? 'false' : 'true',
            sim: formValues.sim === 'false' ? 'false' : 'true',
            data_roaming: formValues.data_roaming === 'false' ? 'false' : 'true',
            bluetooth: formValues.bluetooth === 'false' ? 'false' : 'true',
            power_button: formValues.power_button === 'false' ? 'false' : 'true',
            restart: formValues.restart === 'false' ? 'false' : 'true',
            volume: formValues.volume === 'false' ? 'false' : 'true',
            soft_keys: formValues.soft_keys === 'false' ? 'false' : 'true',
            screen_orientation: formValues.screen_orientation === 'false' ? 'false' : 'true',
            details: formValues.details === 'false' ? 'false' : 'true',
            system_settings: formValues.system_settings === 'false' ? 'false' : 'true',
            policy_settings: formValues.policy_settings === 'false' ? 'false' : 'true',
            app_updates: formValues.app_updates === 'false' ? 'false' : 'true',
            kiosk_exit: formValues.kiosk_exit === 'false' ? 'false' : 'true',
            mobile_data: formValues.mobile_data === 'false' ? 'false' : 'true',
            enable_sim: formValues.enable_sim === 'false' ? 'false' : 'true',
            keyboard: formValues.keyboard === 'false' ? 'false' : 'true',
            brightness: formValues.brightness === 'false' ? 'false' : 'true',
            refresh_wifi: formValues.refresh_wifi === 'false' ? 'false' : 'true',

            enableHeader: !!formValues?.enableHeader,
            showSerial: !!formValues?.showSerial,
            showIMEI: !!formValues?.showIMEI,
            headerBackgroundColor: formValues?.headerBackgroundColor || '#FFFFFF',
            headerTextColor: formValues?.headerTextColor || '#000000',
            enableFooter: !!formValues?.enableFooter,
            footerBackgroundColor: formValues?.footerBackgroundColor || '#FFFFFF',
            footerTextColor: formValues?.footerTextColor || '#000000',
            backgroundColor: formValues?.backgroundColor || '#FFFFFF',
            grid: formValues?.grid || '6x6',
            lockScreenMessage: formValues?.lockScreenMessage || '',
            showSoftKeys: formValues?.showSoftKeys || { recent: false, home: false },
            showSystemApps: !!formValues.showSystemApps,
            showPrivateApps: !!formValues.showPrivateApps,
            iconOrder: formValues?.iconOrder || 'atoz',
            orientation: formValues?.orientation || 'landscape',
            enableLockScreenMessage: !!formValues?.enableLockScreenMessage,
            iconLabelTransparent: !!formValues?.iconLabelTransparent,
            iconTextColor: formValues?.appIconTextColor || '#FFFFFF',
            enableCompanyLogo: !!formValues?.enableCompanyLogo,
            bookmarks: formValues?.bookmarks?.length > 0 ? formValues?.bookmarks : [],
            /*
             * enableAlertSound: !!formValues?.enableAlertSound,
             * alertSound: formValues?.alertSound || undefined,
             */
            enableAppLabel: !!formValues?.enableAppLabel,
            footerText: formValues?.footerText,
            enableLabelShadow: !!formValues?.enableLabelShadow,
            headerTransparent: !!formValues?.headerTransparent,
            footerTransparent: !!formValues?.footerTransparent,
            iconSize: formValues?.iconSize ? formValues.iconSize : '80',
            appsPerRow: formValues?.appsPerRow ? formValues?.appsPerRow : { label: 4, value: 4 },
            companyLogo: formValues?.companyLogo || undefined,

            req_timer_wifi: formValues?.req_timer_wifi ? formValues?.req_timer_wifi : false,
            req_timer_data_roaming: formValues?.req_timer_data_roaming ? formValues?.req_timer_data_roaming : false,
            req_timer_bluetooth: formValues?.req_timer_bluetooth ? formValues?.req_timer_bluetooth : false,
            req_timer_mobile_data: formValues?.req_timer_mobile_data ? formValues?.req_timer_mobile_data : false,
            req_timer_enable_sim: formValues?.req_timer_enable_sim ? formValues?.req_timer_enable_sim : false,
            req_timer_screen_orientation: formValues?.req_timer_screen_orientation ? formValues?.req_timer_screen_orientation : false,
            req_timer_brightness: formValues?.req_timer_brightness ? formValues?.req_timer_brightness : false,
            req_timer_device_details: formValues?.req_timer_device_details ? formValues?.req_timer_device_details : false,
            req_timer_policy_settings: formValues?.req_timer_policy_settings ? formValues?.req_timer_policy_settings : false,
            req_timer_system_settings: formValues?.req_timer_system_settings ? formValues?.req_timer_system_settings : false,
            req_timer_keyboard: formValues?.req_timer_keyboard ? formValues?.req_timer_keyboard : false,
            req_timer_refresh_wifi: formValues?.req_timer_refresh_wifi ? formValues.req_timer_refresh_wifi : false,
            req_timer_kiosk_exit: formValues?.req_timer_kiosk_exit ? formValues.req_timer_kiosk_exit : false,

            timer_wifi: formValues?.timer_wifi ? formValues?.timer_wifi : 0,
            timer_data_roaming: formValues?.timer_data_roaming ? formValues?.timer_data_roaming : 0,
            timer_bluetooth: formValues?.timer_bluetooth ? formValues?.timer_bluetooth : 0,
            timer_mobile_data: formValues?.timer_mobile_data ? formValues?.timer_mobile_data : 0,
            timer_enable_sim: formValues?.timer_enable_sim ? formValues?.timer_enable_sim : 0,
            timer_screen_orientation: formValues?.timer_screen_orientation ? formValues?.timer_screen_orientation : 0,
            timer_brightness: formValues?.timer_brightness ? formValues?.timer_brightness : 0,
            timer_device_details: formValues?.timer_device_details ? formValues?.timer_device_details : 0,
            timer_policy_settings: formValues?.timer_policy_settings ? formValues?.timer_policy_settings : 0,
            timer_system_settings: formValues?.timer_system_settings ? formValues?.timer_system_settings : 0,
            timer_keyboard: formValues?.timer_keyboard ? formValues?.timer_keyboard : 0,
            timer_refresh_wifi: formValues?.timer_refresh_wifi ? formValues.timer_refresh_wifi : 0,
            timer_kiosk_exit: formValues?.timer_kiosk_exit ? formValues?.timer_kiosk_exit : 0,

            prepareTabs: !!formValues?.name,

            enableFolder: !!formValues.enableFolder,
            folders: formValues?.folders?.length > 0 ? formValues?.folders : []
        },
        onSubmit: (values) => {
            setLoading(true);
            const folders = prepareFolders(values.folders);
            let dataObj = {
                deviceSettings: {
                    default_password: validation.values.setting_default_password,
                    custom_password: values?.setting_custom_password,
                    close_time: values?.close_time,
                    wifi: {
                        show: !!featureEnabled.show_wifi,
                        req_password: !!featureEnabled.req_admin_password_wifi,
                        password_type: validation.values.wifi,
                        password: passwords.wifi,
                        req_timer: !!values.req_timer_wifi,
                        timer: !!values.req_timer_wifi ? Number(values.timer_wifi) : 0
                    },
                    sim: {
                        show: !!featureEnabled.show_sim,
                        req_password: !!featureEnabled.req_admin_password_sim,
                        password_type: validation.values.sim,
                        password: passwords.sim,
                        req_timer: !!values.req_timer_sim,
                        timer: !!values.req_timer_sim ? Number(values.timer_sim) : 0
                    },
                    data_roaming: {
                        show: !!featureEnabled.show_data_roaming,
                        req_password: !!featureEnabled.req_admin_password_data,
                        password_type: validation.values.data_roaming,
                        password: passwords.data_roaming,
                        req_timer: !!values.req_timer_data_roaming,
                        timer: !!values.req_timer_data_roaming ? Number(values.timer_data_roaming) : 0
                    },
                    screen_orientation: {
                        show: !!featureEnabled.show_screen_orientation,
                        req_password: !!featureEnabled.req_admin_password_screen_orientation,
                        password_type: validation.values.screen_orientation,
                        password: passwords.screen_orientation,
                        req_timer: !!values.req_timer_screen_orientation,
                        timer: !!values.req_timer_screen_orientation ? Number(values.timer_screen_orientation) : 0
                    },
                    bluetooth: {
                        show: !!featureEnabled.show_bluetooth,
                        req_password: !!featureEnabled.req_admin_password_bluetooth,
                        password_type: validation.values.bluetooth,
                        password: passwords.bluetooth,
                        req_timer: !!values.req_timer_bluetooth,
                        timer: !!values.req_timer_bluetooth ? Number(values.timer_bluetooth) : 0
                    },
                    brightness: {
                        show: !!featureEnabled.show_brigntness,
                        req_password: !!featureEnabled.req_admin_password_brightness,
                        password_type: validation.values.brightness,
                        password: passwords.brightness,
                        req_timer: !!values.req_timer_brightness,
                        timer: !!values.req_timer_brightness ? Number(values.timer_brightness) : 0
                    },
                    mobileData: {
                        show: !!featureEnabled.show_mobile_data,
                        req_password: !!featureEnabled.req_admin_password_mobile_data,
                        password_type: validation.values.mobile_data,
                        password: passwords.mobile_data,
                        req_timer: !!values.req_timer_mobile_data,
                        timer: !!values.req_timer_mobile_data ? Number(values.timer_mobile_data) : 0
                    },
                    enableSIM: {
                        show: !!featureEnabled.show_enable_sim,
                        req_password: !!featureEnabled.req_admin_password_enable_sim,
                        password_type: validation.values.enable_sim,
                        password: passwords.enable_sim,
                        req_timer: !!values.req_timer_enable_sim,
                        timer: !!values.req_timer_enable_sim ? Number(values.timer_enable_sim) : 0
                    },
                    keyboard: {
                        show: !!featureEnabled.show_keyboard,
                        req_password: !!featureEnabled.req_admin_password_keyboard,
                        password_type: validation.values.keyboard,
                        password: passwords.keyboard,
                        req_timer: !!values.req_timer_keyboard,
                        timer: !!values.req_timer_keyboard ? Number(values.timer_keyboard) : 0
                    },
                    system_settings: {
                        show: !!featureEnabled.show_system_settings,
                        req_password: !!featureEnabled.req_admin_password_system_settings,
                        password_type: validation.values.system_settings,
                        password: passwords.system_settings,
                        req_timer: !!values.req_timer_system_settings,
                        timer: !!values.req_timer_system_settings ? Number(values.timer_system_settings) : 0
                    },
                    soft_keys: {
                        show: !!featureEnabled.show_soft_keys,
                        req_password: !!featureEnabled.req_admin_password_soft_keys,
                        password_type: validation.values.soft_keys,
                        password: passwords.soft_keys,
                        req_timer: !!values.req_timer_soft_keys,
                        timer: !!values.req_timer_soft_keys ? Number(values.timer_soft_keys) : 0
                    },
                    volume_controls: {
                        show: !!featureEnabled.show_volume_controls,
                        req_password: !!featureEnabled.req_admin_password_volume_controls,
                        password_type: validation.values.volume,
                        password: passwords.volume,
                        req_timer: !!values.req_timer_volume_controls,
                        timer: !!values.req_timer_volume_controls ? Number(values.timer_volume_controls) : 0
                    },
                    restart: {
                        show: !!featureEnabled.show_restart,
                        req_password: !!featureEnabled.req_admin_password_restart,
                        password_type: validation.values.restart,
                        password: passwords.restart,
                        req_timer: !!values.req_timer_restart,
                        timer: !!values.req_timer_restart ? Number(values.timer_restart) : 0
                    },
                    device_details: {
                        show: !!featureEnabled.show_device_details,
                        req_password: !!featureEnabled.req_admin_password_device_details,
                        password_type: validation.values.details,
                        password: passwords.details,
                        req_timer: !!values.req_timer_device_details,
                        timer: !!values.req_timer_device_details ? Number(values.timer_device_details) : 0
                    },
                    policy: {
                        show: !!featureEnabled.show_policy,
                        req_password: !!featureEnabled.req_admin_password_policy,
                        password_type: validation.values.policy_settings,
                        password: passwords.policy_settings,
                        req_timer: !!values.req_timer_policy_settings,
                        timer: !!values.req_timer_policy_settings ? Number(values.timer_policy_settings) : 0
                    },
                    refresh_screen_wifi: {
                        show: !!featureEnabled.show_refresh_wifi,
                        req_password: !!featureEnabled.req_refresh_wifi,
                        password_type: values.refresh_wifi,
                        password: passwords.refresh_wifi,
                        req_timer: !!values.req_timer_refresh_wifi,
                        timer: !!values.req_timer_refresh_wifi ? Number(values.timer_refresh_wifi) : 0
                    },
                    kioskExit: {
                        show: !!tenant.KIOSK_EXIT && !!featureEnabled.show_kiosk_exit,
                        req_password: !!tenant.KIOSK_EXIT ? !!featureEnabled.req_admin_password_kiosk_exit : false,
                        password_type: !!tenant.KIOSK_EXIT ? validation.values.kiosk_exit : 'true',
                        password: !!tenant.KIOSK_EXIT ? passwords.kiosk_exit : tenant.DEVICE_DEFAULT_PASSWORD,
                        req_timer: !!tenant.KIOSK_EXIT ? !!values.req_timer_kiosk_exit : false,
                        timer: !!tenant.KIOSK_EXIT ? (!!values.req_timer_kiosk_exit ? Number(values.timer_kiosk_exit) : 0) : 0
                    }
                },
                branding: tenant?.EMM_LAUNCHER_BRANDING
                    ? {
                          iconSize: values?.iconSize,
                          iconsPerRow: values?.appsPerRow,
                          iconOrder: values?.iconOrder,
                          orientation: values?.orientation,
                          hideappLabel: !values?.enableAppLabel,
                          appIconTextColor: values.iconTextColor,
                          appIconLabelTransparency: values.iconLabelTransparent,
                          appLabelShadow: !!values?.enableLabelShadow,
                          topBar: {
                              enabled: !!values?.enableHeader,
                              color: values?.headerTextColor,
                              bgColor: values?.headerBackgroundColor,
                              transparent: !!values?.headerTransparent,
                              serial: !!values?.showSerial,
                              imei: !!values?.showIMEI
                          },
                          footer: {
                              enabled: !!values?.enableFooter,
                              color: values.footerTextColor,
                              bgColor: values.footerBackgroundColor,
                              transparent: !!values?.footerTransparent,
                              text: values?.footerText
                          },
                          companyLogo: { enabled: !!values.enableCompanyLogo, logo: values?.companyLogo },
                          folder: {
                              enbaled: !!values.enableFolder,
                              folders: !!values.enableFolder ? (folders?.length > 0 ? folders : []) : undefined
                          }
                          //   alertSound: { enabled: !!values.enableAlertSound, file: values?.alertSound }
                      }
                    : defaultBranding
            };
            let dObj = { config: { ...dataObj }, packageName: packageName, title: values?.name };
            dObj.tenant = accountId;
            dObj.baseURL = baseURL();
            dObj.authMode = authModes?.map((mode) => mode.mode);
            if (formType === 'edit') dObj.managedConfigId = managedConfigId;
            createOrUpdate(dObj);
        }
    });

    useEffect(() => {
        if (Number(validation.values.appsPerRow) > 5) {
            validation.setValues({ ...validation.values, appsPerRow: 5 });
        }
    }, [validation.values.appsPerRow]);

    const createOrUpdate = (dObj) => {
        let apiService;
        if (formType === 'edit') apiService = api.update(url.MANAGED_CONFIGURATIONS + '/' + recordID, dObj);
        else apiService = api.create(url.MANAGED_CONFIGURATIONS, dObj);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.launcherConfigEdit : toastMessages.launcherConfigCreated);
                    history.push('/aelaunchersetup');
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleEnableChange = (e, keyVal, setting, isVal) => {
        let features = { ...featureEnabled, [keyVal]: e };
        if (isVal === 'value' && !e) {
            features[setting.password_value] = false;
            validation.setValues({ ...validation.values, [setting.password_type]: 'true' });
            setPasswords({ ...passwords, [setting.password]: validation.values['setting_default_password'] });
        }
        setFeatureEnabled({ ...features });
    };
    const handlePasswordTypeChange = (e, keyVal, passwordType) => {
        if (passwordType === 'default') setPasswords({ ...passwords, [keyVal]: validation.values.setting_default_password });
        else setPasswords({ ...passwords, [keyVal]: validation.values.setting_custom_password });
    };

    const handlePasswordChange = (e, keyVal) => {
        setPasswords({ ...passwords, [keyVal]: e.target.value });
    };

    const handleCloseDefaultPassword = () => {
        validation.setValues({
            ...validation.values,
            close_time_changing: validation.values.close_time,
            setting_default_password_changing: validation.values.setting_default_password
        });
        setShowDefaultPassword(false);
        setTimerModal(false);
    };

    const handleDefaultPasswordChange = (e, val) => {
        let values = { ...validation.values };
        let passwordsObj = { ...passwords };
        valuesArr.forEach((value) => {
            if (validation.values[value] === val) passwordsObj[value] = e;
        });
        if (val === 'false') values.setting_custom_password = e;
        else values.setting_default_password = e;
        setPasswords({ ...passwordsObj });
        setShowDefaultPassword(false);
        validation.setValues({ ...values });
    };

    const defaultPasswordBody = () => {
        return (
            <div>
                <div className="input-group">
                    {formType !== 'view' ? (
                        <>
                            <div className="d-flex align-items-center w-100">
                                <Input
                                    name={key}
                                    id={key}
                                    className="form-control"
                                    placeholder={type === 'default_password' ? 'Enter Default Password' : 'Enter Custom Password'}
                                    type="text"
                                    maxLength={12}
                                    validate={{ required: { value: true } }}
                                    onChange={validation.handleChange}
                                    value={validation.values?.[key] || ''}
                                    invalid={validation.touched?.[key] && validation.errors?.[key] ? true : false}
                                />
                            </div>
                            <div className="w-100">
                                <div className="d-flex align-items-center justify-content-center">
                                    <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                                        <div className="d-flex">
                                            <i className="ri-information-line link-warning me-2 d-flex align-items-center ps-1 fs-15" />{' '}
                                            This will act as {type === 'default_password' ? 'default' : 'custom'} password
                                        </div>
                                    </Alert>
                                </div>
                            </div>
                        </>
                    ) : validation?.values?.[key] ? (
                        validation?.values?.[key]
                    ) : (
                        '–'
                    )}
                    {validation.touched?.[key] && validation.errors?.[key] ? (
                        <p className="m-0 mt-2 text-danger">{validation.errors?.[key]}</p>
                    ) : null}
                </div>
            </div>
        );
    };

    const handlePassword = (passwordType, keyVal) => {
        setType(passwordType);
        setKey(keyVal);
        setHeader(passwordType === 'default_password' ? 'Default Password' : 'Custom Password');
        setShowDefaultPassword(true);
    };

    const handleIndividualTimerChange = (e, field) => {
        if (Number(e.target.value) > 600) return;
        let regex = new RegExp(/^[0-9]+$/);
        let isValid = regex.test(e.target.value);
        if (isValid || e.target.value === '') validation.setValues({ ...validation.values, [field]: e.target.value });
    };

    const handleDownload = (val) => {
        DownloadBunnyFile(val, val, setLoading);
    };

    const handleBrandingChange = (brandingKey, val) => {
        validation.setValues({ ...validation.values, [brandingKey]: val });
    };

    const handleBookmark = (e, remove) => {
        let bookmarkArr = [...validation.values.bookmarks];
        if (remove) {
            bookmarkArr = bookmarkArr.filter((bookmark) => bookmark.url !== e.url);
            validation.setValues({ ...validation.values, bookmarks: [...bookmarkArr] });
        } else {
            const bookmarkFound = bookmarkArr.find((bookmark) => bookmark.url === validation.values.bookmark);
            if (bookmarkFound) {
                toast.error(toastMessages.existingBookmark);
                return;
            }
            validation.setValues({
                ...validation.values,
                bookmark: '',
                bookmarkName: '',
                bookmarks: [...validation.values.bookmarks, { name: validation.values.bookmarkName, url: validation.values.bookmark }]
            });
        }
    };

    const handleFileUpload = async (files, fileKey) => {
        if (
            // (fileKey === 'alertSound' && files?.filename?.endsWith('.mp3')) ||
            fileKey === 'companyLogo' &&
            ['.jpg', '.jpeg', '.png'].some((extension) => files?.filename?.endsWith(extension))
        ) {
            setLoading(true);
            const file = await files?.file?.arrayBuffer();
            api.create(url.FILE_UPLOAD + files?.filename, '', false, domains.MDM_COMMONS)
                .then((resp) => {
                    // setFileParams({ file: '', type: '' });
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    axios({
                        method: 'PUT',
                        data: file,
                        'Content-Type': 'application/octet-stream',
                        headers: { Authorization: '' },
                        url: resp.data?.url
                    }).then((result) => {
                        if (result.status === 200) {
                            validation.setValues({ ...validation.values, [fileKey]: resp.data?.downloadurl });
                        }
                    });
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        } else {
            toast.error(toastMessages.invalidFileFormat);
            validation.setValues({ ...validation.values, [fileKey]: '' });
        }
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };
    const toggleDropdown = (ind) => {
        setOpenDropDown({ [ind]: !openDropDown[ind] });
    };
    const tabsBody = (tab) => {
        if (tab === 'admin_settings')
            return (
                <SettingsTemplate
                    formType={formType}
                    validation={validation}
                    handlePassword={handlePassword}
                    featureEnabled={featureEnabled}
                    handleEnableChange={handleEnableChange}
                    handleIndividualTimerChange={handleIndividualTimerChange}
                    handlePasswordTypeChange={handlePasswordTypeChange}
                    handlePasswordChange={handlePasswordChange}
                    passwords={passwords}
                    settings={settings}
                />
            );
        else
            return (
                <CustomLauncherThemeTemplate
                    validation={validation}
                    formType={formType}
                    handleKeyDown={handleKeyDown}
                    applications={applications}
                    setApplications={setApplications}
                    openDropDown={openDropDown}
                    toggleDropdown={toggleDropdown}
                    handleDownload={handleDownload}
                    handleBrandingChange={handleBrandingChange}
                    handleBookmark={handleBookmark}
                    handleFileUpload={handleFileUpload}
                    handleEditImage={(fileType) => {
                        validation.setValues({ ...validation.values, [fileType]: '' });
                    }}
                />
            );
    };

    const [tabsList, setTabsList] = useState([]);

    useEffect(() => {
        if (formType === 'add' ? true : validation.values.prepareTabs) {
            const tabs = [];
            if (tenant.EMM_LAUNCHER_BRANDING) tabs.push({ tabID: 'branding', tabName: 'Branding', tabContent: tabsBody('branding') });
            tabs.push({ tabID: 'admin_settings', tabName: 'Admin Settings', tabContent: tabsBody('admin_settings') });
            setTabsList(tabs);
            if (tabs?.length === 1) setSelectedTab(tabs?.[0]?.tabID);
        }
    }, [validation.values, featureEnabled, passwords, applications]);

    const checkSaveDisable = () => {
        let invalid = false;
        settingFieldsArray?.forEach((setting) => {
            if (
                setting.req_timer && !!validation.values[setting.req_timer]
                    ? !validation.values[setting.timer] ||
                      validation.values[setting.timer] === '' ||
                      Number(validation.values[setting.timer]) === 0
                    : false
            ) {
                invalid = true;
                return;
            }
        });
        return !validation.values.name || validation.values.name === '' || invalid;
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={formType === 'view' ? 'View Launcher' : formType === 'edit' ? 'Edit Launcher' : 'Add Launcher'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="aelaunchersetup"
                    />
                    <Card>
                        <CardBody>
                            <div className="ms-1 mb-3 d-flex align-items-center">
                                Name
                                {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                <div className={'input-group ms-2'}>
                                    {formType !== 'view' ? (
                                        <div className="w-25 ms-5">
                                            <Input
                                                name={'name'}
                                                id={'name'}
                                                className={'form-control'}
                                                placeholder={'Enter Name'}
                                                type={'text'}
                                                maxLength={'30'}
                                                validate={{ required: { value: true } }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values['name'] || ''}
                                                invalid={validation.touched['name'] && validation.errors['name'] ? true : false}
                                            />
                                        </div>
                                    ) : validation?.values['name'] ? (
                                        validation?.values['name']
                                    ) : (
                                        '–'
                                    )}
                                    {validation.touched['name'] && validation.errors['name'] ? (
                                        <p className="m-0 mt-2 text-danger">{validation.errors['name']}</p>
                                    ) : null}
                                </div>
                            </div>
                            <TabsComponent
                                paddingRequired={true}
                                paddingClass={'p-2'}
                                tabsList={tabsList}
                                defaultTabID={selectedTab}
                                toggleTab={(tab) => setSelectedTab(tab)}
                            />
                        </CardBody>
                        <CardFooter>
                            <div className="d-flex align-items-center gap-2 justify-content-end">
                                <button type="reset" className="btn btn-light" onClick={() => history.push('/aelaunchersetup')}>
                                    Cancel
                                </button>
                                {formType !== 'view' && (
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        id="add-btn"
                                        disabled={checkSaveDisable()}
                                        onClick={(e) => validation.handleSubmit()}
                                    >
                                        {formType === 'edit' ? 'Update' : 'Save'}
                                    </button>
                                )}
                            </div>
                        </CardFooter>
                    </Card>
                    <CommonModal
                        size={'md'}
                        cancelText={'Cancel'}
                        modalheader={header}
                        show={showDefaultPassword}
                        disabled={
                            type === 'default_password'
                                ? !validation.values.setting_default_password_changing ||
                                  validation.values.setting_default_password_changing === '' ||
                                  validation.values.setting_default_password_changing?.length < 4
                                : !validation.values.setting_custom_password_changing ||
                                  validation.values.setting_custom_password_changing === '' ||
                                  validation.values.setting_custom_password_changing?.length < 4
                        }
                        onCloseClick={() => handleCloseDefaultPassword()}
                        handleClick={() =>
                            handleDefaultPasswordChange(
                                type === 'default_password'
                                    ? validation.values.setting_default_password_changing
                                    : validation.values.setting_custom_password_changing,
                                type === 'default_password' ? 'true' : 'false'
                            )
                        }
                        handleModalBody={() => defaultPasswordBody()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddLauncherSetup;
