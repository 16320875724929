/* eslint-disable max-depth */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import {
    togglePassword,
    togglePasswordChange,
    togglePasswordChangeVal,
    tooglePasswordStrengthIn,
    tooglePasswordStrengthOut
} from '../../Components/Hooks/UserHooks';

// Formik Validation
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { TenantConfig, loginUtil } from '../../Components/Common/Util';
import { tenantConfig as tenant } from '../../Components/constants/TenantConfig';
import toastMessages from '../../common/messages/toastMessages';
import { useEnv } from '../../envContext';
import { APIClient, setAuthorization } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';
import { authUser } from '../../store/Commons/action';
import AuthSlider from './AuthSlider';

const TwosVerify = () => {
    document.title = 'Two Step Verification';
    const urlconf = useEnv();
    let dispatch = useDispatch();
    let api = new APIClient();
    let accountId = localStorage.getItem('account');
    let tenantData = tenant?.[urlconf.REACT_APP_COMPANY_ID];
    let tenantObj = TenantConfig();
    let history = useHistory();
    const authData = JSON.parse(localStorage.getItem('authObj'));
    const [routeParam, setRouteParam] = useState();
    const [loading, setLoading] = useState(false);
    const [authObj, setAuthObj] = useState(authData);
    const [paste, setPaste] = useState(false);
    const [sending, setSending] = useState(false);

    let fieldsArray = [
        { id: 'digit1-input', value: 'digitOne', index: 0 },
        { id: 'digit2-input', value: 'digitTwo', index: 1 },
        { id: 'digit3-input', value: 'digitThree', index: 2 },
        { id: 'digit4-input', value: 'digitFour', index: 3 },
        { id: 'digit5-input', value: 'digitFive', index: 4 },
        { id: 'digit6-input', value: 'digitSix', index: 5 }
    ];

    useEffect(() => {
        if (!accountId) history.push('/account');
        else if (!authData) history.push('/login');
        setAuthObj(authData);
        let locationArr = window.location.hash.split('/');
        let queryString = locationArr[locationArr.length - 1];
        if (queryString === 'login-otp-verification') setRouteParam('login');
        if (queryString === 'forgot-otp-verification') setRouteParam('resetpassword');
    }, []);

    const moveToNext = (e, t, field) => {
        if (e.which === 8 || e.which === 46) validation.setValues({ ...validation.values, [field.value]: '' });
        if (e.which !== 8 && e.which !== 46) 0 < e?.target?.value?.length && document.getElementById('digit' + t + '-input')?.focus();
        else document.getElementById(`digit${t - 2}-input`)?.focus();
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            digitOne: '',
            digitTwo: '',
            digitThree: '',
            digitFour: '',
            digitFive: '',
            digitSix: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema:
            routeParam === 'resetpassword'
                ? Yup.object({
                      newPassword: Yup.string().matches(/^\S*$/, 'Spaces Are Not Allowed In Password').required('Please Enter New Password')
                  })
                : null,
        onSubmit: (values) => {
            if (validation.values.confirmPassword !== validation.values.newPassword) {
                toast.error(toastMessages.passwordDidNotMatch);
                return;
            }
            setLoading(true);
            let params = {
                otp: `${values.digitOne}${values.digitTwo}${values.digitThree}${values.digitFour}${values.digitFive}${values.digitSix}`,
                username: authObj.username,
                tenant: accountId,
                app: 'portal'
            };
            if (routeParam === 'resetpassword') {
                params.password = values.newPassword;
                api.create(url.RESET_PASSWORD, params, false, domains.IDM)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.status === 'success') history.push('/successmsg');
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
            } else
                api.create(url.VALIDATE_OTP, params, false, domains.IDM)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.status?.toLowerCase() === 'success') {
                            delete resp.data.tenantConfig;
                            setAuthorization(resp?.data?.token);
                            resp.data.privileges = resp.data.privileges?.split(',');
                            localStorage.setItem('account', resp.data.tenant);
                            resp.data.username = authObj.username;
                            dispatch(authUser(JSON.stringify(resp)));
                            if (routeParam === 'login')
                                loginUtil({
                                    user: resp,
                                    ten: resp.data.tenant,
                                    api: api,
                                    history: history,
                                    dispatch: dispatch,
                                    tenantObj: tenantObj,
                                    tenantData: tenantData,
                                    urlconf: urlconf
                                });

                            routeParam === 'resetpassword' && history.push('/successmsg');
                            localStorage.removeItem('authObj');
                            setAuthObj({});
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                    });
        }
    });

    const dynamicServiceCall = (URL, params, noEnc, customizedBaseURL) => {
        api.create(URL, params, noEnc, customizedBaseURL)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.otpSent);
                }
                setLoading(false);
                setSending(false);
            })
            .catch((err) => {
                setLoading(false);
                setSending(false);
            });
    };

    const handleClickResend = () => {
        validation.setValues({
            ...validation.values,
            digitOne: '',
            digitTwo: '',
            digitThree: '',
            digitFour: '',
            digitFive: '',
            digitSix: '',
            newPassword: '',
            confirmPassword: ''
        });
        setSending(true);
        document.getElementById('digit1-input')?.focus();
        if (routeParam === 'login') {
            let params = { username: authObj.username, password: authObj.password, tenant: accountId };
            dynamicServiceCall(url.LOGIN, params, false, domains.IDM);
        } else {
            let params = { username: authObj.username, tenant: accountId };
            dynamicServiceCall(url.FORGOT_PASSWORD, params, false, domains.IDM);
        }
    };

    const handlePaste = (e) => {
        setPaste(true);
        let value = JSON.stringify(e.clipboardData.getData('text/plain'))?.split('');
        let values = value?.slice(1, value.length - 1);
        validation.setValues({
            ...validation.values,
            digitOne: values[0] ? values[0] : '',
            digitTwo: values[1] ? values[1] : '',
            digitThree: values[2] ? values[2] : '',
            digitFour: values[3] ? values[3] : '',
            digitFive: values[4] ? values[4] : '',
            digitSix: values[5] ? values[5] : ''
        });
        document.getElementById(`digit${values.length}-input`)?.focus();
    };

    const clearOTP = () => {
        validation.setValues({
            ...validation.values,
            digitOne: '',
            digitTwo: '',
            digitThree: '',
            digitFour: '',
            digitFive: '',
            digitSix: ''
        });
        document.getElementById('digit1-input')?.focus();
    };

    const handleConfirmPassword = (e) => {
        validation.setValues({ ...validation.values, confirmPassword: e.target.value });
        validation.setTouched({ ...validation.touches, confirmPassword: true });
        validation.setErrors({
            ...validation.errors,
            confirmPassword:
                e.target.value === ''
                    ? 'Please Enter Confirm Password'
                    : e.target.value !== validation.values.newPassword
                    ? 'Password did not match'
                    : ''
        });
    };

    const handleOtpChange = (e, field) => {
        let pattern = new RegExp(/^[0-9\b]+$/);
        let result = pattern.test(e.target.value);
        return result;
    };

    const handleKeyDown = (e) => {
        if (e.ctrlKey && (e.key === 'v' || e.key === 'V')) setPaste(true);
    };

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center min-vh-100 vw-100 overflow-y-auto">
                {/* <div className="bg-overlay"></div> */}
                <div className="min-vh-100 overflow-x-hidden vw-100">
                    <div className="min-vh-100 vw-100">
                        <Row className="min-vh-100 vw-100">
                            <Col lg={12} className="min-vh-100 vw-100 p-0">
                                <Card className="min-vh-100 overflow-x-hidden m-0">
                                    <Row className={'g-0 vh-100 vw-100'}>
                                        <AuthSlider />
                                        <Col lg={4} className={'d-flex align-items-center justify-content-center'}>
                                            <div className="p-lg-12 p-6 w-100">
                                                <div className="mb-8">
                                                    <div className="avatar-lg mx-auto">
                                                        <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                                                            <i className="ri-mail-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'text-muted text-center mx-lg-3'}>
                                                    <h4>Verify Your Email</h4>
                                                    <p>Please enter the 6 digit OTP sent to your Email</p>
                                                </div>

                                                <div className="mt-8">
                                                    <Form
                                                        autoComplete="off"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-center flex-row verifyOTP mb-1 text-center">
                                                            {fieldsArray.map((field, index) => {
                                                                return (
                                                                    <div className="d-flex flex-column" key={index}>
                                                                        <Input
                                                                            key={index}
                                                                            onKeyUp={(e) => moveToNext(e, index + 2, field)}
                                                                            type="text"
                                                                            className={'form-control mx-2 text-center'}
                                                                            maxLength="1"
                                                                            id={field.id}
                                                                            autoFocus={index === 0}
                                                                            name={field.value}
                                                                            onKeyDown={(e) => handleKeyDown(e)}
                                                                            onPaste={handlePaste}
                                                                            autoComplete="off"
                                                                            onChange={(e) => {
                                                                                if (paste) setPaste(false);
                                                                                else if (handleOtpChange(e, field))
                                                                                    validation.handleChange(e);
                                                                            }}
                                                                            value={validation.values[field.value] || ''}
                                                                        />
                                                                        {index === 5 && (
                                                                            <div
                                                                                className="d-flex align-items-center mt-3 cursor-pointer"
                                                                                onClick={clearOTP}
                                                                            >
                                                                                <span className="text-decoration-underline text-primary ps-2">
                                                                                    Clear
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        {routeParam === 'resetpassword' && (
                                                            <>
                                                                <div className="text-center">
                                                                    <h5 className="text-primary">Create new password</h5>
                                                                    <p className={'text-muted'}>
                                                                        Your new password must be different from previous used password.
                                                                    </p>
                                                                </div>

                                                                <div className="mb-4">
                                                                    <Label className="form-label">New Password</Label>
                                                                    <div className="position-relative auth-pass-inputgroup">
                                                                        <Input
                                                                            onFocus={() => tooglePasswordStrengthIn('password-contain')}
                                                                            onBlur={(e) => {
                                                                                validation.handleBlur(e);
                                                                                togglePasswordChangeVal(validation.values.newPassword) &&
                                                                                    tooglePasswordStrengthOut('password-contain');
                                                                            }}
                                                                            name="newPassword"
                                                                            type="password"
                                                                            autoComplete="new-password"
                                                                            className={'form-control pe-5 password-input'}
                                                                            onPaste={() => false}
                                                                            placeholder="Enter new password"
                                                                            id="password-input"
                                                                            aria-describedby="passwordInput"
                                                                            required
                                                                            onChange={(e) => {
                                                                                validation.handleChange(e);
                                                                                togglePasswordChange(e.target.value);
                                                                            }}
                                                                            value={validation.values.newPassword || ''}
                                                                            invalid={
                                                                                validation.touched.newPassword &&
                                                                                validation.errors.newPassword
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                        {validation.touched.newPassword && validation.errors.newPassword ? (
                                                                            <FormFeedback type="invalid">
                                                                                <div>{validation.errors.newPassword}</div>
                                                                            </FormFeedback>
                                                                        ) : null}
                                                                        {validation.values.newPassword &&
                                                                            validation.values.newPassword !== '' &&
                                                                            !validation.errors.newPassword && (
                                                                                <Button
                                                                                    color="link"
                                                                                    className="position-absolute end-0 top-0 text-decoration-none cursor-auto text-muted password-addon"
                                                                                    type="button"
                                                                                >
                                                                                    <i
                                                                                        id="password-icon1"
                                                                                        onClick={() =>
                                                                                            togglePassword(
                                                                                                'password-input',
                                                                                                'password-icon1'
                                                                                            )
                                                                                        }
                                                                                        className="ri-eye-off-line align-middle cursor-pointer"
                                                                                    ></i>
                                                                                </Button>
                                                                            )}
                                                                    </div>
                                                                    <div id="passwordInput" className={'form-text'}>
                                                                        Must be at least 8 characters.
                                                                    </div>
                                                                </div>
                                                                <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                                    <h5 className="fs-13">Password must contain:</h5>
                                                                    <p id="pass-length" className="invalid fs-12 mb-2">
                                                                        Minimum <b>8 characters</b>
                                                                    </p>
                                                                    <p id="pass-max-length" className="invalid fs-12 mb-2">
                                                                        Maximum <b>12 characters</b>
                                                                    </p>
                                                                    <p id="pass-lower" className="invalid fs-12 mb-2">
                                                                        At least 1 <b>lowercase</b> letter (a-z)
                                                                    </p>
                                                                    <p id="pass-upper" className="invalid fs-12 mb-2">
                                                                        At least 1 <b>uppercase</b> letter (A-Z)
                                                                    </p>
                                                                    <p id="pass-number" className="invalid fs-12 mb-2">
                                                                        At least 1 <b>number</b> (0-9)
                                                                    </p>
                                                                    <p id="pass-special" className="invalid fs-12 mb-0">
                                                                        At least 1 <b>Special</b> Symbol
                                                                    </p>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <Label className="form-label">Confirm Password</Label>
                                                                    <div className="position-relative auth-pass-inputgroup mb-4">
                                                                        <Input
                                                                            disabled={
                                                                                !togglePasswordChangeVal(validation.values.newPassword)
                                                                            }
                                                                            type="password"
                                                                            className={'form-control pe-5 password-input'}
                                                                            onPaste={() => false}
                                                                            placeholder="Confirm password"
                                                                            id="password-input1"
                                                                            required
                                                                            name="confirmPassword"
                                                                            onChange={(e) => {
                                                                                handleConfirmPassword(e);
                                                                            }}
                                                                            value={validation.values.confirmPassword || ''}
                                                                            invalid={
                                                                                validation.touched.confirmPassword &&
                                                                                (validation.errors.confirmPassword ||
                                                                                    validation.values.confirmPassword !==
                                                                                        validation.values.newPassword)
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                        {validation.touched.confirmPassword ? (
                                                                            <FormFeedback type="invalid">
                                                                                <div>
                                                                                    {validation.errors.confirmPassword
                                                                                        ? validation.errors.confirmPassword
                                                                                        : validation.values.confirmPassword !==
                                                                                          validation.values.newPassword
                                                                                        ? 'Passwords did not match.'
                                                                                        : ''}
                                                                                </div>
                                                                            </FormFeedback>
                                                                        ) : null}
                                                                        {validation.values.confirmPassword &&
                                                                            validation.values.confirmPassword !== '' &&
                                                                            !validation.errors.confirmPassword && (
                                                                                <Button
                                                                                    color="link"
                                                                                    className={`position-absolute top-0 text-decoration-none cursor-auto text-muted password-addon ${
                                                                                        validation.values.confirmPassword !==
                                                                                        validation.values.newPassword
                                                                                            ? 'margin-end-20 end-0'
                                                                                            : 'end-0'
                                                                                    }`}
                                                                                    type="button"
                                                                                >
                                                                                    <i
                                                                                        id="password-icon2"
                                                                                        onClick={() =>
                                                                                            togglePassword(
                                                                                                'password-input1',
                                                                                                'password-icon2'
                                                                                            )
                                                                                        }
                                                                                        className="ri-eye-off-line align-middle cursor-pointer"
                                                                                    ></i>
                                                                                </Button>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className="mt-3">
                                                            <button
                                                                type="submit"
                                                                className={'btn btn-success btn-load w-100'}
                                                                disabled={
                                                                    loading ||
                                                                    (routeParam === 'resetpassword' &&
                                                                        (validation.values.confirmPassword === '' ||
                                                                            validation.errors.newPassword ||
                                                                            validation.values.confirmPassword !==
                                                                                validation.values.newPassword)) ||
                                                                    validation.values.digitOne === '' ||
                                                                    validation.values.digitTwo === '' ||
                                                                    validation.values.digitThree === '' ||
                                                                    validation.values.digitFour === '' ||
                                                                    validation.values.digitFive === '' ||
                                                                    validation.values.digitSix === ''
                                                                }
                                                            >
                                                                <span className="d-flex align-items-center justify-content-center">
                                                                    Confirm
                                                                    {loading && (
                                                                        <span className="ms-2 spinner-border" role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </Form>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <div className="mb-0 d-flex align-items-center justify-content-center">
                                                        Didn't receive OTP
                                                        <div
                                                            className={`fw-bold ${
                                                                sending
                                                                    ? 'text-muted'
                                                                    : 'text-decoration-underline cursor-pointer text-primary'
                                                            } ps-1`}
                                                            onClick={sending ? undefined : handleClickResend}
                                                        >
                                                            Resend
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-4 text-center">
                                                    <div className="mb-0 d-flex align-items-center justify-content-center">
                                                        <div
                                                            className="fw-bold text-primary text-decoration-underline ps-1 cursor-pointer"
                                                            onClick={() =>
                                                                history.push(routeParam === 'resetpassword' ? '/forgot-password' : '/login')
                                                            }
                                                        >
                                                            Not my User Name.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TwosVerify;
