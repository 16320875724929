import React, { useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import Slider from 'react-rangeslider';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormFeedback,
    Input,
    Label,
    Row,
    UncontrolledDropdown,
    UncontrolledTooltip
} from 'reactstrap';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import { convertTextCase, convertTimeFormatTo12, timeWithout530, Tooltip } from '../../../Components/Common/Util';
import AndroidIcon from '../../../assets/images/android/svg/android.svg';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';

export const selectComponent = (props) => {
    const handleChange = (option, field) => {
        props.validation.setValues({
            ...props.validation.values,
            [props.selectedApp.packagename]: { ...props.validation.values[props.selectedApp.packagename], [field.value]: option }
        });
    };
    return (
        <Row className={`${props.field.custom ? '' : 'mb-2'}`}>
            <Col
                xs={6}
                md={6}
                sm={6}
                lg={props.field.custom ? 6 : 12}
                xl={props.field.custom ? 6 : 12}
                className="d-flex align-items-center"
            >
                <div>
                    <Label className="form-label fw-medium mb-0 fs-13">
                        {props.field.label}{' '}
                        {props?.field?.badges?.map((badge, index) => (
                            <span className="badge-soft-supplier-count-1 rounded-3 fs-11 px-1 mx-1" key={index}>
                                {badge}
                            </span>
                        ))}
                        {props.field.mandatory && <span className="text-danger">*</span>}
                    </Label>
                    <div>{props.field.helpText && <div className="text-muted fs-12 mb-2 word-wrap-break">{props.field.helpText}</div>}</div>
                </div>
            </Col>
            <Col xs={6} md={6} sm={6} lg={props.field.custom ? 6 : 12} xl={props.field.custom ? 6 : 12}>
                <Select
                    getOptionValue={(option) => (props.field.optionValue ? option[props.field.optionValue] : option.value)}
                    getOptionLabel={(option) => (props.field.optionLabel ? option[props.field.optionLabel] : option.label)}
                    isMulti={props.field.isMulti ? true : false}
                    isClearable={props.field.clearable ? true : false}
                    id={props.field.label}
                    name={props.field.value}
                    isDisabled={props.formType === 'view' || props?.field?.disable}
                    closeMenuOnSelect={!props.field.isMulti}
                    options={
                        props.field.value === 'wallpaper'
                            ? props?.wallpapers
                            : props.field.value === 'geofence'
                            ? props?.geofences
                            : props?.field?.value === 'launcher'
                            ? props?.launchers
                            : props?.field.isApplicationObj
                            ? props?.[props.field.optionsVal]?.filter((config) => config.packagename === props.selectedApp?.packagename)
                            : props.field?.options
                    }
                    placeholder={`Select ${props.field.label}`}
                    onBlur={() => props.validation.handleBlur({ target: { name: props.field.value } })}
                    onChange={(selectedOption) => {
                        if (props.field.isApplicationObj) handleChange(selectedOption, props.field);
                        else props.validation.handleChange({ target: { name: props.field.value, value: selectedOption } });
                    }}
                    value={
                        props.field.isApplicationObj
                            ? props.validation?.values?.[props.selectedApp?.packagename]?.[props.field.value]
                            : props.validation?.values[props.field.value] || ''
                    }
                    isSearchable={true}
                    noOptionsMessage={() => 'No data found'}
                />
            </Col>
        </Row>
    );
};

const inputComponent = (props) => {
    const handleNumberChange = (e, field) => {
        if (field.isNumber) {
            if (field.checkSum) {
                let sum = Number(e.target.value);
                field.checkSum?.forEach((check) => (sum += props.validation.values[check] ? Number(props.validation.values[check]) : 0));
                if (sum > Number(props.validation.values[field.dependent])) return;
            }
            if (field.dependent && Number(e.target.value) > Number(props.validation.values[field.dependent])) return;
            let regex = new RegExp(/^\d*$/);
            let valid = regex.test(e.target.value);
            if (field?.regex ? (valid && (field?.min ? Number(e.target.value) >= field.min : true)) || e.target.value === '' : true)
                props.validation.handleChange(e);
        } else props.validation.handleChange(e);
    };
    return (
        <Row key={props.index}>
            {props?.field?.label && (
                <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={props.field.custom ? 6 : 12}
                    xl={props.field.custom ? 6 : 12}
                    className={`${props.field.custom ? '' : 'mb-2'}`}
                >
                    <Label className="mb-1 fw-medium fs-13">
                        {props.field?.label}
                        {props.field?.mandatory && <span className="fw-14 text-danger">*</span>}
                    </Label>
                    <div>
                        {props.field.helpText && (
                            <div className={`text-muted fs-12 word-wrap-break ${props.field.noMargin ? '' : 'mb-2'}`}>
                                {props.field?.helpText}
                            </div>
                        )}
                    </div>
                </Col>
            )}
            <Col
                xs={6}
                sm={6}
                md={6}
                lg={props.field.custom ? 6 : 12}
                xl={props.field.custom ? 6 : 12}
                className={`${props.field.custom ? '' : 'mb-2'}`}
            >
                <div className="input-group" key={props.index}>
                    <Input
                        className="form-control"
                        type={props.field.type}
                        id={props.field.value}
                        min={props.field.minVal}
                        max={props.field.maxVal}
                        name={props.field.value}
                        placeholder={`Enter ${props.field.label ? props.field.label : ''}`}
                        value={props.validation.values?.[props.field.value]}
                        onChange={(e) => handleNumberChange(e, props.field)}
                        onBlur={props.validation.handleBlur}
                        disabled={props.formType === 'view'}
                    />
                </div>
            </Col>
        </Row>
    );
};

const sliderComponent = (props) => {
    const checkValue = (value) => {
        return value !== null && value !== undefined;
    };

    const formatLabel = (value, label) => {
        if (checkValue(label)) return value + ' ' + label;
        else return value;
    };
    return (
        <React.Fragment key={props.index}>
            {props.field.label && (
                <Col xs={12} md={12} sm={12} lg={12} xl={12} className="d-flex align-items-center justify-content-between">
                    {props.field?.label && (
                        <Label className="mb-0 fw-medium fs-13 d-flex align-items-center">
                            {props.field.label}
                            {props.field.mandatory && props.formType !== 'view' ? (
                                <span className="red-color ps-1">*</span>
                            ) : props.field.helpText ? (
                                <>
                                    <i
                                        className={`ri-information-line d-flex ${
                                            props.field.notes ? '' : 'align-items-center'
                                        } blue-icon ps-1 fs-15`}
                                        id={`inputComponent-${props.index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${props.index}`}>
                                        {props.field.helpText ? props.field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            ) : (
                                <span className="ps-1">:</span>
                            )}
                        </Label>
                    )}
                </Col>
            )}
            <Col xs={12} md={12} sm={12} lg={12} xl={12}>
                {props.formType !== 'view' ? (
                    <React.Fragment>
                        <div>
                            <span>
                                <div className="d-flex align-items-center justify-content-between">
                                    <span>{props.field.min}</span>
                                    <span>
                                        Value: {props.validation.values[props.field.value] ? props.validation.values[props.field.value] : 0}
                                        %
                                    </span>
                                    <span>{props.field.max}</span>
                                </div>
                                <div className="custom-range-slider-info">
                                    <Slider
                                        className="my-3"
                                        min={props.field.min}
                                        max={props.field.max}
                                        step={props.field.step}
                                        format={(value) => formatLabel(value, '%')}
                                        onBlur={() => props.validation.handleBlur({ target: { name: [props.field.value] } })}
                                        onChange={
                                            props.formType === 'view'
                                                ? null
                                                : (e) =>
                                                      props.validation.handleChange({
                                                          target: { name: [props.field.value], value: e }
                                                      })
                                        }
                                        value={props.validation.values[props.field.value]}
                                    />
                                </div>
                            </span>
                        </div>
                    </React.Fragment>
                ) : props.validation?.values?.[props.field.value]?.length > 0 ? (
                    props.validation?.values?.[props.field.value]
                        ?.map((v) => v?.label)
                        ?.toString()
                        ?.split(',')
                        ?.join(', ')
                ) : props.validation?.values?.[props.field.value]?.label || props.validation?.values?.[props.field.value]?.name ? (
                    props.validation?.values?.[props.field.value]?.label || props.validation?.values?.[props.field.value]?.name
                ) : props.validation?.values[props.field.value] ? (
                    props.validation?.values[props.field.value]
                ) : (
                    '–'
                )}
            </Col>
        </React.Fragment>
    );
};

const toggleComponent = (props) => {
    const handleToggleChange = (e, key) => {
        props.validation.setValues({ ...props.validation.values, [key]: e.target.checked });

        if (key === 'enableVolume') {
            props.validation.setValues({
                ...props.validation.values,
                [key]: Boolean(e.target.checked),
                volumeLevel: 100
            });
        } else if (key === 'enableInputMethod') {
            props.validation.setValues({
                ...props.validation.values,
                [key]: Boolean(e.target.checked),
                inputDropdown: ''
            });
        } else if (key === 'enableSchedulePowerControl') {
            props.validation.setValues({
                ...props.validation.values,
                [key]: Boolean(e.target.checked),
                frequency: [],
                powerontime: '',
                shutdowntime: ''
            });
        }
    };
    return (
        <>
            <Row key={props.index} className={'mb-3 ' + (props.field.class ? props.field.class : '')}>
                {props.field.label && (
                    <Col xs={10} sm={10} md={10} xl={10} lg={10}>
                        <Label className="fw-medium fs-13 mb-0 ">
                            {props?.field?.label}
                            {props?.field?.badges?.map((badge, index) => (
                                <span className="badge-soft-supplier-count-1 rounded-3 fs-11 px-1 mx-1" key={index}>
                                    {badge}
                                </span>
                            ))}
                        </Label>
                        <div>
                            <div className="text-muted fs-12 mb-2 word-wrap-break">{props.field.helpText && props.field.helpText}</div>
                        </div>
                    </Col>
                )}

                <Col xs={2} sm={2} md={2} xl={2} lg={2}>
                    {props.formType !== 'view' ? (
                        <div className="form-check form-switch form-switch-md d-flex justify-content-end">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name={props.field.value}
                                id={props.field.value}
                                checked={props.validation.values[props.field.value] || false}
                                onChange={(e) => handleToggleChange(e, props.field.value)}
                                onBlur={props.validation.handleBlur}
                                value={Boolean(props.validation.values[props.field.value]) || false}
                            />
                        </div>
                    ) : props.validation.values?.[props.field.value] ? (
                        'Yes'
                    ) : (
                        'No'
                    )}
                </Col>
            </Row>
        </>
    );
};

const checkboxComponent = (props) => {
    const handleCheckChange = (key, value) => {
        props.validation.setValues({ ...props.validation.values, [key]: value });
    };

    return (
        <React.Fragment key={props.index}>
            {props.field.customCheckbox ? (
                <div className="d-flex">
                    <div className="pe-3">
                        <Input
                            name={props.field.value}
                            id={props.field.value}
                            className="form-check-input"
                            placeholder={`Enter ${props.field.label}`}
                            type={'checkbox'}
                            validate={{ required: { value: true } }}
                            onChange={props.validation.handleChange}
                            onBlur={props.validation.handleBlur}
                            value={Boolean(props.validation.values[props.field.value]) || ''}
                            checked={props.validation.values?.[props.field?.value] || false}
                        />
                    </div>
                    <div>
                        <div className="fw-medium fs-13">{convertTextCase(props.field.label)}</div>
                        <div className="text-muted fs-12">{props.field.helpText}</div>
                    </div>
                </div>
            ) : (
                <>
                    <Col xs={12} md={12} sm={12} lg={12} xl={12} className="d-flex align-items-center">
                        <div>
                            <Label className="form-label fw-medium mb-0 fs-13">
                                {props.field.label}{' '}
                                {props?.field?.badges?.map((badge, index) => (
                                    <span className="badge-soft-supplier-count-1 rounded-3 fs-11 px-1 mx-1" key={index}>
                                        {badge}
                                    </span>
                                ))}
                                {props.field.mandatory && props.formType !== 'view' && <span className="text-danger">*</span>}
                            </Label>
                            <div>
                                {props.field.helpText && (
                                    <div className="text-muted fs-12 mb-2 word-wrap-break">{props.field.helpText}</div>
                                )}
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={12} sm={12} lg={12} xl={12} className="d-flex align-items-center">
                        {props.field.checkboxes?.map((checkfield, ind) => {
                            return (
                                <div className="form-check form-check-inline align-items-center" key={ind}>
                                    <>
                                        <Input
                                            name={props.field.value}
                                            id={props.field.value}
                                            className="form-check-input"
                                            placeholder={`Enter ${props.field.label}`}
                                            type={'checkbox'}
                                            disabled={props.formType === 'view'}
                                            validate={{ required: { value: true } }}
                                            onChange={() =>
                                                handleCheckChange(
                                                    props.field?.value,
                                                    props.validation.values?.[props.field.value]?.includes(checkfield)
                                                        ? props?.validation?.values?.[props?.field?.value]?.filter((d) => d !== checkfield)
                                                        : [...(props?.validation?.values?.[props?.field?.value] || []), checkfield]
                                                )
                                            }
                                            onBlur={props.validation.handleBlur}
                                            value={props.validation.values[props.field.value] || ''}
                                            checked={props.validation.values?.[props.field?.value]?.includes(checkfield)}
                                        />
                                        <Label className="fs-13">{convertTextCase(checkfield)}</Label>
                                    </>
                                </div>
                            );
                        })}

                        {props.validation.touched[props.field.value] && props.validation.errors[props.field.value] ? (
                            <FormFeedback type="invalid">{props.validation.errors[props.field.value]}</FormFeedback>
                        ) : null}
                        {props.field.notes && (
                            <Alert className={`alert-borderless p-1 alert-${props.field.class} text-center mt-1 mb-0`} role="alert">
                                {props.field.notes}
                            </Alert>
                        )}
                    </Col>
                </>
            )}
        </React.Fragment>
    );
};

const timePickerComponent = (props) => {
    let onTime;
    let offTime;
    const handleTime = (value, date) => {
        if (value === 'powerontime') {
            onTime = new Date(date);
            onTime?.setMinutes(onTime?.getMinutes() + 15);
            props.validation?.setValues({ ...props.validation.values, [value]: date?.[0], shutdowntime: onTime });
        } else {
            offTime = new Date(date);
            onTime = new Date(props.validation?.values?.powerontime);

            if (offTime?.getTime() >= onTime?.getTime() + 4 * 60 * 1000) {
                props.validation?.setValues({
                    ...props.validation.values,
                    shutdowntime: offTime
                });
            } else props.validation.setValues({ ...props.validation.values, [value]: undefined });
        }
    };

    return (
        <React.Fragment key={props.index}>
            <Col xs={12} md={12} sm={12} lg={12} xl={12} className="d-flex align-items-center">
                <div>
                    <Label className="form-label fw-medium mb-0 fs-13">
                        {props.field.label} {props.field.mandatory && props.formType !== 'view' && <span className="text-danger">*</span>}
                    </Label>
                    <div>{props.field.helpText && <div className="text-muted fs-12 mb-2 word-wrap-break">{props.field.helpText}</div>}</div>
                </div>
            </Col>

            <Col xs={12} md={12} sm={12} lg={12} xl={12} className=" mb-2">
                {props.formType !== 'view' ? (
                    <div className="form-icon flex-nowrap">
                        <Flatpickr
                            placeholder={props.field.label}
                            value={props.validation?.values?.[props.field?.value]}
                            name={props.field?.value}
                            className="form-control form-control-icon me-2"
                            options={props.field.options}
                            onChange={(evt) => {
                                handleTime(props.field?.value, evt);
                            }}
                        />
                        <i className={props.field.icon} />
                    </div>
                ) : props.validation?.values?.[props.field?.value] ? (
                    convertTimeFormatTo12(props.validation.values?.[props.field.value])
                ) : (
                    '_'
                )}
            </Col>
            {props.field.alertMsg && props.formType !== 'view' && (
                <Alert className={'border-0 p-2 alert-info fs-12 text-center mt-1 mb-0'} role="alert">
                    {props.field.alertMsg}
                </Alert>
            )}
        </React.Fragment>
    );
};

const radioButton = (props) => {
    const handleRadioChange = (e) => {
        let obj = { ...props.validation.values };
        props.field?.clearFields?.forEach((val) => (obj[val] = ''));
        props.validation.setValues(obj);
        if (e.target.value === 'complex') props.validation.setFieldValue('passwordType', { label: 'Alphanumeric', value: 'ALPHANUMERIC' });
        props.validation.handleChange(e);
    };

    return (
        <React.Fragment key={props.index}>
            <Col xs={6} md={6} sm={6} lg={6} xl={6} className="d-flex align-items-center justify-content-between mb-1">
                <Label className="mb-0 fw-medium fs-13 d-flex align-items-center">
                    {props.field.label}
                    {props.field.helpText ? (
                        <>
                            <i
                                className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                id={`radioButton-${props.index}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`radioButton-${props.index}`}>
                                {props.field.helpText ? props.field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <span className="ps-1">:</span>
                    )}
                </Label>
            </Col>
            <Col xs={6} md={6} sm={6} lg={6} xl={6} className="mb-1">
                {props.field?.radios?.map((ele, ind) => {
                    return (
                        <div className="form-check form-check-inline" key={ind}>
                            <Input
                                className="form-check-input"
                                type="radio"
                                id={props.field.value}
                                name={props.field.value}
                                value={ele.val}
                                onChange={(e) => handleRadioChange(e)}
                                onBlur={props.validation.handleBlur}
                                checked={props.validation.values[props.field.value] === ele.val}
                                disabled={props.formType === 'view' || props.field.disabled}
                            />
                            <Label className="mb-0">{ele.label}</Label>
                        </div>
                    );
                })}
            </Col>
        </React.Fragment>
    );
};

const noteComponent = (props) => {
    return (
        props.formType !== 'view' &&
        (props.field?.customNote
            ? props.validation.values?.[props.field?.radioKey] === props.field?.radioVal
            : props.validation?.values?.[props.field.val]?.value === props.field.noteValue) && (
            <Row className={`p-0 ${props.field.notes ? '' : 'align-items-center'}`} key={props.index}>
                <Col xs={6} md={6} sm={6} lg={6} xl={6} className="d-flex align-items-center justify-content-between"></Col>
                <Col xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Alert className={`alert-borderless alert-${props.field?.noteColor} text-center p-2`} role="alert">
                        {props.field?.note}
                    </Alert>
                </Col>
            </Row>
        )
    );
};

const arrayComponent = (props) => {
    const [selected, setSelected] = useState('');
    const [mode, setMode] = useState('add');
    const handleAdd = () => {
        props.setIsAddArray(true);
        setMode('add');
        clearArrayObj();
    };

    const handleEdit = (obj) => {
        let valObj = {};
        props.field.arrayFields?.forEach((field) => {
            if (field.inputType === 'select' && field.isMulti)
                valObj[field.value] = field.options?.filter((option) => obj[field.value]?.includes(option.value));
            else if (field.inputType === 'select' && !field.isMulti)
                valObj[field.value] = field.options.find((option) => option.value === obj[field.value]);
            else valObj[field.value] = obj[field.value];
        });
        props.setIsAddArray(true);
        setMode('edit');
        setSelected(obj);
        props.validation.setValues({ ...props.validation.values, ...valObj });
    };

    const handleDelete = (index) => {
        const arrayValues = [...(props.validation.values[props.field.value] || [])];
        arrayValues?.splice(index, 1);
        props.validation.setFieldValue([props.field.value], arrayValues);
    };

    const clearArrayObj = () => {
        const valObj = {};
        props.field.arrayFields?.forEach((field) => {
            if (field.inputType === 'checkbox') valObj[field.value] = false;
            else valObj[field.value] = '';
        });
        props.validation.setValues({ ...props.validation.values, ...valObj });
    };

    const handleCancel = () => {
        props.setIsAddArray(false);
        setMode('add');
        clearArrayObj();
    };

    const handleSubmit = () => {
        const valObj = {};
        const clearObj = {};
        props.field.arrayFields?.forEach((field) => {
            if (field.inputType === 'select' && !field.isMulti) valObj[field.value] = props.validation.values[field.value]?.value;
            else if (field.inputType === 'select' && field.isMulti)
                valObj[field.value] = props.validation.values[field.value]?.map((val) => val.value);
            else valObj[field.value] = props.validation.values[field.value];

            if (field.inputType === 'checkbox') clearObj[field.value] = false;
            else clearObj[field.value] = '';
        });
        const arrayVal = [...(props.validation.values[props.field.value]?.length > 0 ? props.validation.values[props.field.value] : [])];
        const index = arrayVal?.findIndex((av) => av?.[props.field.unique] === selected?.[props.field.unique]);
        if (mode === 'add') {
            if (index >= 0) {
                toast.error(props.field.toastMsg);
                return;
            }
            arrayVal.push(valObj);
        } else {
            arrayVal.splice(index, 1, valObj);
        }
        props.setIsAddArray(false);
        setMode('add');
        props.validation.setValues({ ...props.validation.values, ...clearObj, [props.field.value]: arrayVal });
    };

    const saveDisabled = () => {
        let flag = [];
        props.field.arrayFields?.forEach((field) => {
            if (field.mandatory && !props.validation.values[field.value]) flag.push(true);
        });
        return flag.some((fl) => fl);
    };

    const handleOffcanvasBody = () => {
        return (
            <React.Fragment>
                {props.field.arrayFields?.map((field, index) => {
                    return (
                        (field.hideOn
                            ? props.validation.values[field.hideOn]?.value && props.validation.values[field.hideOn]?.value !== field.hideVal
                            : true) && (
                            <DynamicComponents
                                field={field}
                                key={index}
                                index={index}
                                formType={props.formType}
                                isAddArray={props.isAddArray}
                                setIsAddArray={props.setIsAddArray}
                                validation={props.validation}
                                wallpapers={props.wallpapers}
                                geofences={props.geofences}
                                applications={props.applications}
                                handleAppSelection={props.handleAppSelection}
                                selectedApp={props.selectedApp}
                                selected={props.selected}
                                handleAppSelect={props.handleAppSelect}
                                managedConfigs={props.managedConfigs}
                            />
                        )
                    );
                })}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {props.formType !== 'view' && (
                <div className="d-flex justify-content-end text-success">
                    <Button className="bg-success border-0" onClick={handleAdd}>
                        {props.field.addButton}
                    </Button>
                </div>
            )}
            <div>
                <Row>
                    {props.validation.values[props.field.value]?.length > 0 ? (
                        props.validation.values[props.field.value]?.map((field, index) => (
                            <Col key={index} xs={6} sm={6} md={4} lg={3} xl={3} xxl={3}>
                                {props.field.value === 'wifi' ? (
                                    <Card className="card-height-100 shadow-card rounded-4 border-top p-3">
                                        <CardBody className="position-relative text-center">
                                            <UncontrolledDropdown className="position-absolute top-5 end-5">
                                                <DropdownToggle
                                                    tag="button"
                                                    type="button"
                                                    className="btn btn-link text-muted p-1 mt-n2 text-decoration-none fs-15"
                                                >
                                                    <i className="ri-more-fill fs-14" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem onClick={() => handleEdit(field)}>
                                                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => handleDelete(index)}>
                                                        <i className="ri-delete-bin-6-line align-bottom me-2 text-muted"></i> Delete
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <div className="d-flex align-items-center gap-2">
                                                <div className="bg-soft-info p-2 br-5">
                                                    <i className="ri ri-wifi-line fs-24" />
                                                </div>
                                                <span className="text-info">
                                                    <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                        {field.name}
                                                    </EllipsisToolTip>
                                                </span>
                                            </div>
                                            <Row className="mt-2 text-start">
                                                <Col sm="6">
                                                    <div className="fw-medium">SSID</div>
                                                    <div className="mt-2 text-muted">
                                                        <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                            {field.ssid}
                                                        </EllipsisToolTip>
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="fw-medium">Security</div>
                                                    <div className="mt-2 text-muted">
                                                        <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                            {field.securitytype}
                                                        </EllipsisToolTip>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                ) : (
                                    <Card className="card-height-100 shadow-card rounded-4 border-top p-3">
                                        <CardBody className="position-relative text-center">
                                            <UncontrolledDropdown className="position-absolute top-5 end-5">
                                                <DropdownToggle
                                                    tag="button"
                                                    type="button"
                                                    className="btn btn-link text-muted p-1 mt-n2 text-decoration-none fs-15"
                                                >
                                                    <i className="ri-more-fill fs-14" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem onClick={() => handleEdit(field)}>
                                                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => handleDelete(index)}>
                                                        <i className="ri-delete-bin-6-line align-bottom me-2 text-muted"></i> Delete
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <div className="">
                                                {props.field?.cardFields?.map((card, key) => (
                                                    <div className="d-flex gap-2" key={key}>
                                                        <div className="fw-medium mb-1">{card.label}</div>
                                                        <div className="fs-12 text-muted">
                                                            <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                {field[card.value]}
                                                            </EllipsisToolTip>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </CardBody>
                                    </Card>
                                )}
                            </Col>
                        ))
                    ) : (
                        <NoRecordsFound />
                    )}
                </Row>
            </div>
            <OffcanvasModal
                direction="end"
                toggle={handleCancel}
                open={props.isAddArray}
                handleCloseClick={handleCancel}
                OffcanvasModalID="filterModal"
                handleOffcanvasBody={handleOffcanvasBody}
                modalClassName="w-25"
                saveDisabled={saveDisabled()}
                saveText={mode === 'edit' ? 'Update' : 'Save'}
                offcanvasHeader={convertTextCase(mode) + ' ' + props.field.header}
                handleSaveClick={handleSubmit}
            />
        </React.Fragment>
    );
};

const appsComponent = (props) => {
    const copyToClipboard = (e, pacName, toastName) => {
        e.stopPropagation();
        navigator.clipboard.writeText(pacName).then(() => {
            toast.success(`${toastName} packagename copied successfully`);
        });
    };

    return (
        <div className=" mt-3 mb-3">
            <Row>
                {props.applications?.length > 0 ? (
                    props.selectedApp ? (
                        props.selected?.subFields?.map((field, ind) => (
                            <Col sm={8} key={ind}>
                                <DynamicComponents
                                    field={field}
                                    index={ind}
                                    formType={props.formType}
                                    isAddArray={props.isAddArray}
                                    setIsAddArray={props.setIsAddArray}
                                    validation={props.validation}
                                    wallpapers={props.wallpapers}
                                    geofences={props.geofences}
                                    applications={props.applications}
                                    handleAppSelection={props.handleAppSelection}
                                    selectedApp={props.selectedApp}
                                    handleAppSelect={props.handleAppSelect}
                                    selected={props.selected}
                                    managedConfigs={props.managedConfigs}
                                />
                            </Col>
                        ))
                    ) : (
                        props.applications.map((app, index) => {
                            return (
                                <Col key={index} xl={2} ms={4} xs={6}>
                                    <Card className={`shadow-card card-height-100 ${app.selected ? 'cursor-pointer' : ''}`}>
                                        <CardHeader className="position-relative p-0 border-bottom-0">
                                            <div className="position-absolute end-10 top-5">
                                                <input
                                                    className="form-check-input cursor-pointer"
                                                    type="checkbox"
                                                    disabled={props.formType === 'view'}
                                                    checked={app.selected ? app.selected : false}
                                                    onChange={(e) => props.handleAppSelection(e, app, index)}
                                                />
                                            </div>
                                        </CardHeader>
                                        <CardBody onClick={(e) => props.handleAppSelect(e, app)}>
                                            <div className=" d-flex align-items-center flex-column">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <img
                                                        src={
                                                            app.icondownloadurl !== '' &&
                                                            app.icondownloadurl !== null &&
                                                            app.icondownloadurl !== undefined
                                                                ? app.icondownloadurl
                                                                : AndroidIcon
                                                        }
                                                        alt={''}
                                                        className="rounded-2"
                                                        width={40}
                                                        height={40}
                                                    />
                                                </div>
                                                <div className="flex-grow-1 mt-1 w-100">
                                                    <>
                                                        <p
                                                            className="fs-14 fw-medium m-0 text-muted text-center mb-1 text-truncate"
                                                            id={`title-${index}`}
                                                        >
                                                            {app.name}
                                                        </p>
                                                        {Tooltip(`title-${index}`, app.name)}
                                                    </>
                                                    {/* <>
                                                    <p
                                                        className="fs-14 m-0 mb-1 text-muted text-center text-truncate"
                                                        id={`package-${index}`}
                                                    >
                                                        {app.packagename}
                                                    </p>
                                                    {Tooltip(`package-${index}`, app.packagename)}
                                                </> */}
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div
                                                            id={`package-${index}`}
                                                            className="fs-13 fw-medium w-auto text-muted overflow-hidden white-space-nowrap text-overflow-ellipsis"
                                                        >
                                                            {app.packagename}
                                                        </div>
                                                        {Tooltip(`package-${index}`, app.packagename)}
                                                        <div>
                                                            <i
                                                                id={`copy-${index}`}
                                                                onClick={(e) => copyToClipboard(e, app.packagename, app.name)}
                                                                className="ri-file-copy-line ms-2 fs-14 cursor-pointer link-primary"
                                                            ></i>
                                                            {Tooltip(`copy-${index}`, 'Copy')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            );
                        })
                    )
                ) : (
                    <NoRecordsFound />
                )}
            </Row>
        </div>
    );
};

const doubleDatePicker = (props) => {
    const fp1 = useRef();
    const fp2 = useRef();
    return (
        <Row className="mt-2" key={props.index}>
            <Col xs={6} md={6} sm={6} lg={3} xl={3}>
                <Label className="mb-0 fw-medium d-flex align-items-center">
                    {props.field.label}
                    {props.field.mandatory ? <span className="red-color ps-1">*</span> : ''}
                </Label>
            </Col>
            <Col xs={3} md={3} sm={3} lg={9} xl={9}>
                <div className="d-flex align-items-center gap-2">
                    <div className="input-group flex-nowrap">
                        <Flatpickr
                            ref={fp1}
                            value={props.validation.values[props.field.value1]}
                            placeholder={props.field.placeholder1}
                            className="form-control w-50 p-2"
                            options={{
                                mode: 'single',
                                dateFormat: 'd M, Y',
                                minDate: 'today',
                                maxDate: props.validation.values[props.field.value2]
                                    ? props.validation.values[props.field.value2]
                                    : undefined
                            }}
                            onChange={(e) => {
                                props.validation.setValues({ ...props.validation.values, [props.field.value1]: e[0] });
                            }}
                        />
                        <div className="input-group-text bg-primary border-primary text-white">
                            <i className="ri-calendar-2-line"></i>
                        </div>
                    </div>
                    <div className="input-group flex-nowrap">
                        <Flatpickr
                            ref={fp2}
                            value={props.validation.values[props.field.value2]}
                            placeholder={props.field.placeholder2}
                            className="form-control w-50 p-2"
                            options={{
                                mode: 'single',
                                dateFormat: 'd M, Y',
                                minDate: props.validation.values[props.field.value1] ? props.validation.values[props.field.value1] : 'today'
                            }}
                            onChange={(e) => {
                                props.validation.setValues({ ...props.validation.values, [props.field.value2]: e[0] });
                            }}
                        />
                        <div className="input-group-text bg-primary border-primary text-white">
                            <i className="ri-calendar-2-line"></i>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export const DynamicComponents = (props) => {
    return (
        <React.Fragment key={props.index}>
            {props?.field?.inputType === 'select'
                ? selectComponent({ ...props })
                : props.field?.inputType === 'input'
                ? inputComponent({ ...props })
                : props.field?.inputType === 'toggle'
                ? toggleComponent({ ...props })
                : props.field?.inputType === 'slider'
                ? sliderComponent({ ...props })
                : props.field.inputType === 'checkbox'
                ? checkboxComponent({ ...props })
                : props.field.inputType === 'timePicker'
                ? timePickerComponent({ ...props })
                : props.field.inputType === 'radio'
                ? radioButton({ ...props })
                : props.field.inputType === 'note'
                ? noteComponent({ ...props })
                : props.field.inputType === 'array'
                ? arrayComponent({ ...props })
                : props.field.inputType === 'apps'
                ? appsComponent({ ...props })
                : props.field.inputType === 'DoubleDatePicker'
                ? doubleDatePicker({ ...props })
                : ''}
        </React.Fragment>
    );
};
