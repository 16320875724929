import toastMessages from '../../common/messages/toastMessages';

export const policyTableSchema = [
    {
        Header: 'Policy Name',
        accessor: 'name',
        sortable: true,
        customCell: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        fieldType: 'clickable',
        view: true
    },
    { Header: 'Platform', accessor: 'platform', width: 50, minWidth: 50, maxWidth: 50, sortable: false },
    { Header: 'Policy Type', accessor: 'type', width: 50, maxWidth: 50, minWidth: 50, sortable: false },
    { Header: 'Policy Code', accessor: 'code', width: 50, maxWidth: 50, minWidth: 50, sortable: true },
    { Header: 'Modified By', accessor: 'modifiedby', width: 150, maxWidth: 150, minWidth: 150, sortable: true },
    { Header: 'Modified Date', accessor: 'modifiedDate', width: 150, maxWidth: 150, minWidth: 150, sortable: false },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        edit: true,
        /*
         * qrCode: true,
         * disableQr: 'disableQR',
         * disableQRTooltip: 'QR code cannot be generated for macOS',
         * link: true,
         */
        clone: true,
        deleteFadable: true,
        fadeTooltipKeyDelete: 'deleteTooltip',
        enrollmentToken: true,
        fade: 'doNotDelete',
        sync: true,
        disableSync: 'disableSync'
        /*
         * deleteFadable: true,
         * fadeTooltip: 'You cannot delete this policy, as it is already applied to a device.',
         * fade: 'doNotDelete',
         * enrollmentToken: true
         */
    }
];

export const IOSDevicesSchema = [
    {
        Header: 'checkbox',
        customHeader: true,
        headerType: 'checkbox',
        customCell: true,
        fieldType: 'checkbox',
        accessor: 'checked',
        thClass: 'width-50 max-width-50 min-width-50 d-flex align-items-center border-bottom-0',
        className: 'width-50 max-width-50',
        width: 80,
        maxWidth: 80,
        minWidth: 80
    },
    {
        Header: 'Serial Number',
        accessor: 'serial',
        sortable: true,
        customCell: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        fieldType: 'fieldClickable',
        viewRoute: '/iosdevices/view',
        fieldKey: 'serial',
        routeKey: '_id',
        view: true,
        copy: true,
        copyToastMsg: toastMessages.serialCopied
    },
    { Header: 'Platform', accessor: 'platform', sortable: false, minWidth: 100, maxWidth: 100, width: 100 },
    { Header: 'Policy', accessor: 'policy', sortable: true, minWidth: 150, maxWidth: 150, width: 150 },
    { Header: 'Group', accessor: 'group', sortable: true, width: 100, minWidth: 100, maxWidth: 100 },
    { Header: 'Model', accessor: 'model', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    /*
     * {
     *     Header: 'OS Version',
     *     accessor: 'osversion',
     *     sortable: true,
     *     width: 100,
     *     minWidth: 100,
     *     maxWidth: 100
     * },
     */
    { Header: 'Enrollment Date', accessor: 'enrolldate', sortable: true, width: 100, minWidth: 100, maxWidth: 100 },
    { Header: 'Enrollment Type', accessor: 'enrollmenttype', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    { Header: 'Supervised', accessor: 'issupervised', sortable: true, width: 100, minWidth: 100, maxWidth: 100 },
    {
        Header: 'Device Status',
        accessor: 'deviceStatus',
        sortable: true,
        customCell: true,
        fieldType: 'badge',
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        lockDevice: true,
        reboot: true,
        disableReboot: 'notsupervised',
        rebootTooltip: 'This device is not supervised',
        shutdown: true,
        disableShutdown: 'notsupervised',
        shutdownTooltip: 'This device is not supervised',
        factoryreset: true,
        factoryResetTooltip: 'Erase Device',
        deprovisionTooltip: 'Unenroll',
        deprovision: true
        // delete: true
    }
];

export const usersTableSchema = [
    {
        customCell: true,
        fieldType: 'radio',
        accessor: 'checked',
        thClass: 'width-50 max-width-50 min-width-50 d-flex align-items-center',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    },
    {
        Header: 'Name',
        accessor: 'name',
        sortable: false,
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        customCell: true,
        fieldType: 'clickable',
        view: true
    },
    {
        Header: 'User Name',
        accessor: 'username',
        sortable: false,
        width: 150,
        minWidth: 150,
        maxWidth: 150
    },
    {
        Header: 'Email ID',
        accessor: 'email',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        sortable: false
    }
];

export const iOSconfigsSchema = [
    { Header: 'Configuration Name', accessor: 'title', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Created Date', accessor: 'createdDate', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Actions', accessor: 'html', sortable: false, customCell: true, fieldType: 'actions', edit: true, delete: true }
];

export const iOSConfigurations = [
    {
        label: 'Format',
        value: 'format',
        inputType: 'select',
        options: [
            { label: 'hii', value: 'hii' },
            { label: 'hello', value: 'hello' }
        ]
    },
    {
        label: 'Key',
        value: 'key',
        inputType: 'input'
    },
    {
        label: 'Value',
        value: 'value',
        inputType: 'input'
    }
];
