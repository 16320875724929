import React, { useEffect, useState } from 'react';
import Loader from '../../../Components/Common/Loader';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { priviliges } from '../../../Components/constants/constants';
import { AuthUser, convertUTCtoIST } from '../../../Components/Common/Util';
import delLauncher from '../../../assets/images/common/png/delete/delLauncherSetting.png';
import DeleteModal from '../../../Components/Common/DeleteModal';
import { TVLauncherSetupSchema } from '../TableSchema';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';

const TvLauncherSetup = () => {
    const urlconf = useEnv();
    document.title = 'Launcher';
    const api = new APIClient();
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [configs, setConfigs] = useState([]);
    const [configsBackup, setConfigsBackup] = useState([]);
    const [showAddNew, setShowAddNew] = useState(true);
    const [tableSchema, setTableSchema] = useState([]);
    const [selectedConfig, setSelectedConfig] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.APPLICATION_EDITOR);
        let schema = [...TVLauncherSetupSchema];
        if (findPriv) schema = TVLauncherSetupSchema.filter((item, index) => index < TVLauncherSetupSchema.length - 1);
        setTableSchema([...schema]);
        getConfigurations();
    }, []);

    const getConfigurations = () => {
        setLoading(true);
        api.get(url.CONFIG, { page: 1, size: 100 }, domains.ANDROID_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success' && resp?.data?.length > 0) {
                    resp.data.forEach((data) => {
                        data.policies = data?.policies ? data.policies : '—';
                        data.modifiedBy = data.modifiedby ? data.modifiedby : '—';
                        data.modifiedDate = data.modifieddate ? convertUTCtoIST(data.modifieddate) : '—';
                    });
                    setConfigs(resp.data);
                    setConfigsBackup(resp.data);
                    setTotalRecords(resp.totalRecords);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleSearch = (val) => {
        let configArr = JSON.parse(JSON.stringify(configsBackup));
        if (val?.length > 0) configArr = configArr.filter((conf) => conf?.name?.toLowerCase()?.includes(val?.toLowerCase()));
        setConfigs(configArr);
        setTotalRecords(configArr?.length);
    };

    const handleAddNew = () => {
        history.push('/atvlaunchersetup/add');
    };

    const handleEdit = (row) => {
        history.push('/atvlaunchersetup/edit/' + row?.original?._id);
    };

    const handleDelete = (row) => {
        setSelectedConfig(row.original);
        setDeleteModal(true);
    };

    const handleClickView = (row) => {
        history.push('/atvlaunchersetup/view/' + row?.original?._id);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete Launcher Setting?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={delLauncher} alt="deletepolicy" width={60} height={60} />
                </div>
                <div>
                    Setting Name: <span className="fw-semibold">{selectedConfig?.name}</span>
                </div>
            </div>
        );
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        api.delete(url.CONFIG + '/' + selectedConfig?._id, '', domains.ANDROID_V1)
            .then((resp) => {
                if (resp.status === 'success') {
                    toast.success(toastMessages.configurationDeleted);
                    getConfigurations();
                }
                setLoading(false);
            })
            .catch(() => setLoading(false));
        setDeleteModal(false);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Launcher" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Configs'}
                        isGlobalFilter={true}
                        searchFilter2={true}
                        searchPlaceHolder={'Name'}
                        handleGlobalSearch={handleSearch}
                        totalRecords={totalRecords}
                        customPageSize={10}
                        addButton={showAddNew}
                        columns={tableSchema}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleClickView={handleClickView}
                        handleAddNew={handleAddNew}
                        reader={priviliges.APPLICATION_READER}
                        editor={priviliges.APPLICATION_EDITOR}
                        data={configs}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
            <DeleteModal
                hideIcon={true}
                show={deleteModal}
                hideDeleteMessage={true}
                message={message()}
                confirmText={'Yes, Delete it!'}
                onDeleteClick={handleDeleteConfirmation}
                onCloseClick={() => setDeleteModal(false)}
            />
        </React.Fragment>
    );
};

export default TvLauncherSetup;
