/* eslint-disable max-lines */
import React, { useRef, useState } from 'react';
import { FilePond } from 'react-filepond';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    FormFeedback,
    Input,
    Label,
    Row,
    Table,
    UncontrolledTooltip
} from 'reactstrap';
import TabsComponent from '../../../Components/Common/TabsComponent';
import AppleImage from '../../../assets/images/apple/png/common/apple.png';
import toastMessages from '../../../common/messages/toastMessages';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import { Tooltip } from '../../../Components/Common/Util';

export const toggleButton = (props) => {
    return (
        <>
            {!props.field.hideLabel && (
                <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={props.code ? 7 : 5}
                    xl={props.code ? 7 : 5}
                    className="d-flex align-items-center justify-content-between"
                >
                    <Label className="mb-0 fw-medium d-flex align-items-center">
                        {props.field.label}
                        {props.field.helpText ? (
                            <>
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={`toggleButton-${props.index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`toggleButton-${props.index}`}>
                                    {props.field.helpText ? props.field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
            )}
            <Col
                xs={props.field.hideLabel ? 12 : 6}
                md={props.field.hideLabel ? 12 : 6}
                sm={props.field.hideLabel ? 12 : 6}
                lg={props.field.hideLabel ? 12 : 5}
                xl={props.field.hideLabel ? 12 : 5}
                className="padding-left-0"
            >
                {props.formType !== 'view' && props.formType !== 'viewChecker' ? (
                    <div className="form-check form-switch form-switch-lg">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            name={props.field.value}
                            id={props.field.value}
                            checked={
                                props.isAsset
                                    ? !!props.validation?.values?.[
                                          props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url
                                      ]?.[props.field.value]
                                    : !!props.validation?.values[props.field.value] || false
                            }
                            onChange={props.handleValidationChange ? props.handleValidationChange : props.validation.handleChange}
                            onBlur={props.validation.handleBlur}
                        />
                    </div>
                ) : (
                      props.isAsset
                          ? props.validation?.values?.[props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url]?.[
                                props.field.value
                            ]
                          : props.validation?.values[props.field.value] || false
                  ) ? (
                    'Yes'
                ) : (
                    'No'
                )}
            </Col>
        </>
    );
};

const RecursiveComponent = (props) => {
    const fields = props.fields;
    return (
        <>
            {fields.map((ele, ind) => {
                return (
                    <div key={ind}>
                        <DynamicComponents
                            field={ele}
                            index={ind}
                            formType={props.formType}
                            selected={false}
                            setSelected={props.setSelected}
                            validation={props.handleValidationChange ? props.handleValidationChange : props.validation}
                            handleKeyDown={props.handleKeyDown}
                            selectedApp={props.selectedApp}
                            isAsset={props.isAsset}
                            singleApps={props.singleApps}
                            isArray={props.isArray}
                            setIsArray={props.setIsArray}
                            managedConfigs={props.managedConfigs}
                            getManagedConfigs={props.getManagedConfigs}
                        />
                        {ele.fields && (
                            <div className="px-4">
                                <RecursiveComponent
                                    formType={props.formType}
                                    validation={props.validation}
                                    handleKeyDown={props.handleKeyDown}
                                    fields={ele.fields}
                                    handleValidationChange={props.handleValidationChange}
                                    isAsset={props.isAsset}
                                    selectedApp={props.selectedApp}
                                ></RecursiveComponent>
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export const InputComponent = (props) => {
    return (
        <Row>
            {!props.field.hideLabel && (
                <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={5}
                    xl={5}
                    className={`d-flex ${props.field.notes ? '' : 'align-items-center'} justify-content-between`}
                >
                    <Label className={`mb-0 fw-medium d-flex ${props.field.notes ? '' : 'align-items-center'}`}>
                        {props.field.label}
                        {!props.field.notMandatory && props.formType !== 'view' && props.formType !== 'viewChecker' ? (
                            <span className="red-color ps-1">*</span>
                        ) : props.field.helpText ? (
                            <>
                                <i
                                    className={`ri-information-line d-flex ${
                                        props.field.notes ? '' : 'align-items-center'
                                    } blue-icon ps-1 fs-15`}
                                    id={`inputComponent-${props.index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`inputComponent-${props.index}`}>
                                    {props.field.helpText && props.field.helpText}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
            )}
            <Col
                xs={props.field.hideLabel ? 12 : 6}
                md={props.field.hideLabel ? 12 : 6}
                sm={props.field.hideLabel ? 12 : 6}
                lg={props.field.hideLabel ? 12 : 5}
                xl={props.field.hideLabel ? 12 : 5}
                className={`${props.field.paddingLeft ? props.field.paddingLeft : ''}`}
            >
                <div className="input-group">
                    <Input
                        name={props.field.value}
                        id={props.field.value}
                        className="form-control"
                        placeholder={`Enter ${props.field.label}`}
                        type={props.field.type}
                        autoComplete="new-password"
                        validate={{ required: { value: true } }}
                        onChange={props.handleValidationChange ? props.handleValidationChange : props.validation?.handleChange}
                        min={props.field.minValue}
                        max={props.field.maxValue}
                        disabled={props.formType === 'view'}
                        maxLength={props.field.maxLength}
                        onBlur={props.validation?.handleBlur}
                        onKeyDown={(e) => props.handleKeyDown(e)}
                        value={
                            props.isAsset
                                ? props.validation?.values?.[
                                      props?.selectedApp?.adamid ? props?.selectedApp?.adamid : props?.selectedApp?.url
                                  ]?.[props?.field?.value]
                                : props.validation?.values[props.field?.value] || ''
                        }
                        invalid={
                            props.validation?.touched?.[props.field?.value] && props.validation?.errors?.[props.field?.value] ? true : false
                        }
                    />
                    {props.validation.touched[props.field.value] && props.validation.errors[props.field.value] ? (
                        <FormFeedback type="invalid">{props.validation.errors[props.field.value]}</FormFeedback>
                    ) : null}
                </div>
                {props.field.notes && (
                    <Alert className={`alert-borderless p-1 alert-${props.field.class} text-center mt-1 mb-0`} role="alert">
                        {props.field.notes}
                    </Alert>
                )}
            </Col>
        </Row>
    );
};

export const PolicyInputComponent = (props) => {
    let doc = document.getElementById(props.field.value);
    const toggleAuthPassword = (e) => {
        if (doc) doc.type = doc.type === 'password' ? 'text' : 'password';
        props.validation.setValues({ ...props.validation.values, xyz: !props.validation.values.xyz });
    };

    return (
        <Row key={props.index} className={`${props.noMargin && 'pe-0'}`}>
            {!props.field.hideLabel && (
                <Row>
                    <Col
                        xs={6}
                        md={6}
                        sm={6}
                        lg={6}
                        xl={6}
                        className={`d-flex ${props.field.notes ? '' : 'align-items-center'} justify-content-between`}
                    >
                        <Label className={`mb-0 fw-medium d-flex ${props.field.notes ? '' : 'align-items-center'}`}>
                            {props.field.label}
                            {props.formType !== 'view' && !props.field.notMandatory ? (
                                <span className="red-color ps-1">*</span>
                            ) : props.field.helpText ? (
                                <>
                                    <i
                                        className={`ri-information-line d-flex ${
                                            props.field.notes ? '' : 'align-items-center'
                                        } blue-icon ps-1 fs-15`}
                                        id={`polInputComponent-${
                                            props.field?.uncontrolledId ? props.field?.uncontrolledId : props.field.value
                                        }`}
                                    />
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        target={`polInputComponent-${
                                            props.field?.uncontrolledId ? props.field?.uncontrolledId : props.field.value
                                        }`}
                                    >
                                        {props.field.helpText && props.field.helpText}
                                    </UncontrolledTooltip>
                                </>
                            ) : (
                                <span className="ps-1">:</span>
                            )}
                            {props.field.badge && (
                                <span className={` px-2 fs-10 badge-soft-${props.field.badgeClass}`}>{props.field.badgeText}</span>
                            )}
                        </Label>
                    </Col>
                    <Row>
                        <Col xs={6} md={6} sm={6} lg={6} xl={6}>
                            {!props.field.hideHelpText && (
                                <div className="text-muted font-size-12 mb-2 word-wrap-break">
                                    {props.field.helpText && props.field.helpText}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Row>
            )}
            <Row className={`${props.noMargin ? 'pe-0' : 'mb-2'}`}>
                <Col
                    xs={props.field.hideLabel ? 12 : 6}
                    md={props.field.hideLabel ? 12 : 6}
                    sm={props.field.hideLabel ? 12 : 6}
                    lg={props.field.hideLabel ? 12 : 6}
                    xl={props.field.hideLabel ? 12 : 6}
                    className={`${props.field.paddingLeft ? props.field.paddingLeft : ''}`}
                >
                    <div className={`input-group ${props.field.type === 'password' ? 'position-relative auth-pass-inputgroup' : ''}`}>
                        <Input
                            name={props.field.value}
                            id={props.field.value}
                            className="form-control"
                            placeholder={`Enter ${props.field.label}`}
                            type={props.field.type}
                            autoComplete="new-password"
                            validate={{ required: { value: true } }}
                            onChange={(e) => {
                                if (props.handleValidationChange) props.handleValidationChange(e);
                                else if (props.field.maxValue) {
                                    if (Number(e.target.value) <= props.field.maxValue) props.validation?.handleChange(e);
                                } else props.validation?.handleChange(e);
                            }}
                            min={props.field.minValue}
                            max={props.field.maxValue}
                            disabled={props.formType === 'view'}
                            maxLength={props.field.maxLength}
                            onBlur={props.validation?.handleBlur}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                            value={
                                props.isAsset
                                    ? props.validation?.values?.[
                                          props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url
                                      ]?.[props.field.value]
                                    : props.validation?.values[props.field?.value] || ''
                            }
                            invalid={
                                props.validation?.touched?.[props.field?.value] && props.validation?.errors?.[props.field?.value]
                                    ? true
                                    : false
                            }
                        />
                        {props.field.type === 'password' && props.validation.values[props.field.value] && (
                            <i
                                onClick={toggleAuthPassword}
                                id="password-icon"
                                className={`btn btn-link position-absolute end-0 top-0 text-decoration-none cursor-pointer ${
                                    doc?.type === 'password' ? 'ri-eye-line' : 'ri-eye-off-line'
                                } align-middle cursor-pointer`}
                            ></i>
                        )}

                        {props.validation.touched[props.field.value] && props.validation.errors[props.field.value] ? (
                            <FormFeedback type="invalid">{props.validation.errors[props.field.value]}</FormFeedback>
                        ) : null}
                    </div>
                    {props.field.notes && (
                        <Alert className={`alert-borderless p-1 alert-${props.field.class} text-center mt-1 mb-0`} role="alert">
                            {props.field.notes}
                        </Alert>
                    )}
                </Col>
            </Row>
        </Row>
    );
};
export const Json = (props) => {
    return (
        <Row key={(props.field.label ? props.field.label?.replaceAll(' ', '') : '') + props.index}>
            {!props.field.hideLabel && (
                <Row>
                    {props.field.label && (
                        <Col
                            xs={12}
                            md={12}
                            sm={12}
                            lg={6}
                            xl={6}
                            className={`d-flex ${props.field.notes ? '' : 'align-items-center'} justify-content-between`}
                        >
                            <Label className={`mb-0 fw-medium d-flex ${props.field.notes ? '' : 'align-items-center'}`}>
                                {props.field.label}
                                {!props.field.notMandatory && props.formType !== 'view' && props.formType !== 'viewChecker' ? (
                                    <span className="red-color ps-1">*</span>
                                ) : props.field.helpText ? (
                                    <>
                                        <i
                                            className={`ri-information-line d-flex ${
                                                props.field.notes ? '' : 'align-items-center'
                                            } blue-icon ps-1 fs-15`}
                                            id={`inputComponent-${
                                                props.field?.uncontrolledId ? props.field?.uncontrolledId : props.field?.value
                                            }`}
                                        />
                                        <UncontrolledTooltip
                                            placement="bottom"
                                            target={`inputComponent-${
                                                props.field?.uncontrolledId ? props.field?.uncontrolledId : props.field?.value
                                            }`}
                                        >
                                            {props.field.helpText && props.field.helpText}
                                        </UncontrolledTooltip>
                                    </>
                                ) : (
                                    <span className="ps-1">:</span>
                                )}
                            </Label>
                        </Col>
                    )}
                    <Row>
                        <Col xs={12} md={12} sm={12} lg={6} xl={6}>
                            {!props.field.hideHelpText && (
                                <div className="text-muted font-size-12 mb-2"> {props.field.helpText && props.field.helpText}</div>
                            )}
                        </Col>
                    </Row>
                </Row>
            )}
            <div className="ms-4">
                {props.field?.jsonFields?.map((field, index) => (
                    <DynamicComponents
                        key={index}
                        field={field}
                        setSelected={props.setSelected}
                        setIsShowCardData={props.setIsShowCardData}
                        index={index}
                        formType={props.formType}
                        selected={props.selected}
                        validation={props.validation}
                        isArray={props.isArray}
                        setIsArray={props.setIsArray}
                        handleKeyDown={props.handleKeyDown}
                        handleValidationChange={props.handleValidationChange}
                        isAsset={props.isAsset}
                        selectedApp={props.selectedApp}
                        applications={props.applications}
                        singleApps={props.singleApps}
                        getManagedConfigs={props.getManagedConfigs}
                        managedConfigs={props.managedConfigs}
                    />
                ))}
            </div>
        </Row>
    );
};
export const selectComponent = (props) => {
    const handleBadge = (conditions, option) => {
        let text = '';
        conditions.forEach((condition) => {
            if (option[props.field.badgeKey] === condition.value) {
                text = condition.label;
            }
        });
        return text;
    };
    const handleClearFields = (field, selectedOption) => {
        const fieldVal = props.validation.values[field.value]?.optionsField
            ? props.validation.values[field.value]?.optionsField
            : props.validation.values[field.value]?.value;
        const selectedVal = selectedOption.optionsField ? selectedOption.optionsField : selectedOption?.value;
        if (fieldVal === selectedVal) return;
        let obj = { [field.value]: selectedOption };
        field.clearFields?.forEach((clearField) => {
            obj[clearField.key] = clearField.value;
        });
        props.validation.setValues({ ...props.validation.values, ...obj });
        return;
    };

    return (
        <React.Fragment key={props.index}>
            {!props.field.hideLabel && (
                <Row>
                    {props.field.label && (
                        <Col xs={12} md={12} sm={12} lg={6} xl={6} className="d-flex align-items-center justify-content-between">
                            <Label
                                className={`mb-0 d-flex align-items-center ${
                                    props.field.enableOn && !props.validation.values[props.field.enableOn] ? 'text-muted ' : 'fw-medium'
                                } `}
                            >
                                {props.field.label}
                                {props.formType !== 'view' && !props.field.notMandatory ? (
                                    <span className="red-color ps-1">*</span>
                                ) : props.field.helpText ? (
                                    <>
                                        <i
                                            className={`ri-information-line d-flex ${
                                                props.field.notes ? '' : 'align-items-center'
                                            } blue-icon ps-1 fs-15`}
                                            id={`selectComponent-${props.index}`}
                                        />
                                        <UncontrolledTooltip placement="bottom" target={`selectComponent-${props.index}`}>
                                            {props.field.helpText ? props.field.helpText : 'Helper Text'}
                                        </UncontrolledTooltip>
                                    </>
                                ) : (
                                    <span className="ps-1">:</span>
                                )}
                            </Label>
                        </Col>
                    )}
                    <Row>
                        <Col xs={12} md={12} sm={12} lg={props.field.colSize ? 12 : 6} xl={props.field.colSize ? 12 : 6}>
                            {!props.field.hideHelpText && (
                                <div className="text-muted font-size-12 mb-2 word-wrap-break ">
                                    {props.field.helpText && props.field.helpText}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Row>
            )}
            <Row>
                <Col
                    xs={props.field.hideLabel ? 12 : 6}
                    md={props.field.hideLabel ? 12 : 6}
                    sm={props.field.hideLabel ? 12 : 6}
                    lg={props.field.hideLabel ? 12 : props.field.colSize ? 12 : 6}
                    xl={props.field.hideLabel ? 12 : props.field.colSize ? 12 : 6}
                    className={`${props.noMargin ? '' : 'mb-2'}`}
                >
                    <Select
                        getOptionValue={(option) =>
                            props.field.optionValue
                                ? option[props.field.optionValue]
                                : option.optionsField
                                ? option.optionsField
                                : option.value
                        }
                        getOptionLabel={(option) =>
                            props.field.optionLabel ? (
                                props.field.showBadge ? (
                                    <div className="d-flex align-items-center gap-1">
                                        {option[props.field.optionLabel] ? option[props.field.optionLabel] : option.value}
                                        <span className="fs-10 d-grid place-items-center rounded-2 px-2 bg-soft-warning">
                                            {handleBadge(props.field.badgeConditions, option)}
                                        </span>
                                    </div>
                                ) : (
                                    option[props.field.optionLabel]
                                )
                            ) : (
                                option.label
                            )
                        }
                        isMulti={props.field.isMulti ? true : false}
                        isClearable={props.field.isMulti || props.field.removable ? true : false}
                        closeMenuOnSelect={props.field.isMulti ? false : true}
                        id={props.field.label}
                        name={props.field.value}
                        options={
                            props.field.customOptions
                                ? props.field.value === 'managedconfigid'
                                    ? props[props.field.optionsKey]?.filter((option) => option.bundleid === props.selectedApp?.packagename)
                                    : props[props.field.optionsKey]
                                : props.field.options?.filter((option) => !option.hidden)
                        }
                        placeholder={props.field.placeholder ? props.field.placeholder : `Select ${props.field.label || ''}`}
                        onBlur={() => props.validation.handleBlur({ target: { name: props.field.value } })}
                        onChange={(selectedOption) => {
                            if (props.field.clearFields) handleClearFields(props.field, selectedOption);
                            else
                                props.isAsset
                                    ? props.handleValidationChange({ target: { name: props.field.value, value: selectedOption } })
                                    : props.validation.handleChange({ target: { name: props.field.value, value: selectedOption } });
                        }}
                        value={
                            props.isAsset
                                ? props.validation?.values?.[
                                      props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url
                                  ]?.[props.field.value]
                                : props.validation.values[props.field.value] || ''
                        }
                        isSearchable={true}
                        noOptionsMessage={() => 'No data found'}
                        onMenuScrollToBottom={() => {
                            if (props.field.value === 'managedconfigid') props.getManagedConfigs();
                        }}
                        isDisabled={
                            props.formType === 'view' || (props.field.enableOn ? !props.validation.values[props.field.enableOn] : false)
                        }
                    />
                </Col>
            </Row>
            {props.field?.[
                props.validation?.values[props.field?.value]?.optionsField
                    ? props.validation?.values[props.field?.value]?.optionsField
                    : props.validation?.values[props.field?.value]?.value
            ] &&
                props.field?.[
                    props.validation?.values[props.field?.value]?.optionsField
                        ? props.validation?.values[props.field?.value]?.optionsField
                        : props.validation?.values[props.field?.value]?.value
                ]?.map((field, ind) => <DynamicComponents key={ind} {...props} field={field} index={ind} />)}
        </React.Fragment>
    );
};

export const Textbox = (props) => {
    return (
        <Row key={props.index}>
            {!props.field.hideLabel && (
                <Row>
                    <Col xs={12} md={12} sm={12} lg={6} xl={6} className="d-flex align-items-center justify-content-between">
                        <Label className={`mb-0 fw-medium d-flex ${props.field.notes ? '' : 'align-items-center'}`}>
                            {props.field.label}
                            {!props.field.notMandatory && props.formType !== 'view' && props.formType !== 'viewChecker' ? (
                                <span className="red-color ps-1">*</span>
                            ) : props.field.helpText ? (
                                <>
                                    <i
                                        className={`ri-information-line d-flex ${
                                            props.field.notes ? '' : 'align-items-center'
                                        } blue-icon ps-1 fs-15`}
                                        id={`textbox-${props.index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`textbox-${props.index}`}>
                                        {props.field.helpText && props.field.helpText}
                                    </UncontrolledTooltip>
                                </>
                            ) : (
                                <span className="ps-1">:</span>
                            )}
                        </Label>
                    </Col>
                    <Row>
                        <Col xs={12} md={12} sm={12} lg={props.field.colSize ? 12 : 6} xl={props.field.colSize ? 12 : 6}>
                            {!props.field.hideHelpText && (
                                <div className="text-muted font-size-12 mb-2 word-wrap-break ">
                                    {' '}
                                    {props.field.helpText && props.field.helpText}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Row>
            )}
            <Row>
                <Col xs={12} md={12} sm={12} lg={props.field.colSize ? 12 : 6} xl={props.field.colSize ? 12 : 6} className="mb-2">
                    <div className="input-group">
                        <textarea
                            name={props.field.value}
                            id={props.field.value}
                            className="form-control"
                            placeholder={`Enter ${props.field.label}`}
                            type={props.field.type}
                            onChange={props.handleValidationChange ? props.handleValidationChange : props.validation?.handleChange}
                            min={props.field.minValue}
                            max={props.field.maxValue}
                            rows={props.field.rows}
                            maxLength={props.field.maxLength}
                            disabled={props.formType}
                            onBlur={props.validation?.handleBlur}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                            value={
                                props.isAsset
                                    ? props.validation?.values?.[
                                          props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url
                                      ]?.[props.field.value]
                                    : props.validation?.values[props.field?.value] || ''
                            }
                        />
                        {props.validation.touched[props.field.value] && props.validation.errors[props.field.value] ? (
                            <FormFeedback type="invalid">{props.validation.errors[props.field.value]}</FormFeedback>
                        ) : null}
                    </div>
                    {props.field.type === 'array' && (
                        <Alert className={'alert-borderless p-1 alert-info text-center mt-1 mb-0'} role="alert">
                            {props.field.notes}
                        </Alert>
                    )}
                </Col>
            </Row>
        </Row>
    );
};
const Restrictions = (props) => {
    const ref = useRef(null);
    const [isScroll, setIsScroll] = useState({ 0: true });
    const currentScroll = (label, ind) => {
        let scrollName = document.getElementById(label);
        window.scrollTo({ top: scrollName.offsetTop + 140, behavior: 'smooth', block: 'start', inline: 'nearest' });
        const updatedChecked = { isScroll };
        updatedChecked[ind] = !updatedChecked[ind];
        setIsScroll(updatedChecked);
    };

    return (
        <Row className="d-flex justify-content-center align-items-center">
            <Col xs={12} md={12} sm={12} lg={12} xl={12} className="position-relative">
                {props.field.fields.map((field, ind) => {
                    return (
                        <Col key={ind}>
                            <div key={ind}>
                                <div id={field.label} className="fw-semibold fs-14 padding-top-15 padding-bottom-10" ref={ref}>
                                    {field.label}
                                </div>
                                <RecursiveComponent
                                    formType={props.formType}
                                    validation={props.validation}
                                    handleKeyDown={props.handleKeyDown}
                                    fields={field.fields}
                                    index={ind}
                                ></RecursiveComponent>
                            </div>
                        </Col>
                    );
                })}
                <div className="position-absolute">
                    <Card className="bg-light custom-section-card mt-10">
                        <CardHeader className="bg-light fw-semibold fs-14">Sections</CardHeader>
                        <CardBody className="pt-2">
                            {props.field.fields.map((field, index) => {
                                return (
                                    <Col key={index}>
                                        <div
                                            key={index}
                                            className={`fw-medium py-1 cursor-pointer d-flex justify-content-start align-items-center ${
                                                isScroll[index] ? 'text-primary' : ''
                                            }`}
                                            onClick={() => currentScroll(field.label, index)}
                                        >
                                            <i className={`${isScroll[index] ? 'ri-arrow-right-s-line fs-16' : 'ps-5'}`}></i>
                                            <span className="fw-medium"> {field.label}</span>
                                        </div>
                                    </Col>
                                );
                            })}
                        </CardBody>
                    </Card>
                </div>
            </Col>
        </Row>
    );
};
const radioButton = (props) => {
    return (
        <React.Fragment key={props.index}>
            <Col
                xs={6}
                md={6}
                sm={6}
                lg={props.code ? 7 : 5}
                xl={props.code ? 7 : 5}
                className="d-flex align-items-center justify-content-between"
            >
                <Label className="mb-0 fw-medium d-flex align-items-center">
                    {props.field.label}
                    {props.field.helpText ? (
                        <>
                            <i
                                className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                id={`radioButton-${props.index}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`radioButton-${props.index}`}>
                                {props.field.helpText ? props.field.helpText : 'Helper Text'}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <span className="ps-1">:</span>
                    )}
                </Label>
            </Col>
            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                {props.formType !== 'view' && props.formType !== 'viewChecker' ? (
                    <>
                        <div className="form-check form-check-inline">
                            <Input
                                className="form-check-input"
                                type="radio"
                                id={props.field.value}
                                name={props.field.value}
                                value={true}
                                onBlur={props.validation.handleBlur}
                                onChange={props.handleValidationChange ? props.handleValidationChange : props.validation.handleChange}
                                checked={
                                    props.isAsset
                                        ? props.validation?.values?.[
                                              props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url
                                          ]?.[props.field.value]
                                        : props.validation.values[props.field.value]
                                }
                            />
                            <Label className="mb-0">{props.field.label1}</Label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Input
                                className="form-check-input"
                                type="radio"
                                id={props.field.value}
                                name={props.field.value}
                                value={false}
                                onBlur={props.validation.handleBlur}
                                onChange={props.handleValidationChange ? props.handleValidationChange : props.validation.handleChange}
                                checked={
                                    props.isAsset
                                        ? !props.validation?.values?.[
                                              props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url
                                          ]?.[props.field.value]
                                        : !props.validation.values[props.field.value]
                                }
                            />
                            <Label className="mb-0">{props.field.label2}</Label>
                        </div>
                    </>
                ) : (
                      props.isAsset
                          ? props.validation?.values?.[props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url]?.[
                                props.field.value
                            ]
                          : props.validation.values[props.field.value]
                  ) ? (
                    props.field.label1
                ) : (
                    props.field.label2
                )}
            </Col>
        </React.Fragment>
    );
};
const checkbox = (props) => {
    const handleValidation = (e, field) => {
        if (e.target.checked) props.validation.setValues({ ...props.validation.values, [field.value]: e.target.checked });
        else {
            let obj = {};
            props.field.fields.forEach((key) => {
                if (field.inputType === 'checkbox') obj[key.value] = false;
                else obj[key.value] = '';
            });
            props.validation.setValues({ ...props.validation.values, [field.value]: e.target.checked, ...obj });
        }
    };

    const handleAppChange = (e) => {
        props.validation.setValues({
            ...props.validation.values,
            [props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url]: {
                ...props.validation.values[props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url],
                [props.field.value]: e.target.checked
            }
        });
    };

    return (
        <React.Fragment key={props.index}>
            <Col xs={8} md={8} sm={8} lg={8} xl={8} className={`${props.noMargin && 'd-flex justify-content-center'}`}>
                <>
                    <div className={`${props.noMargin && 'me-0'} form-check form-check-inline align-items-center`}>
                        <Input
                            className="form-check-input "
                            type="checkbox"
                            id={props.field.value}
                            name={props.field.value}
                            // onBlur={validation.handleBlur}
                            value={Boolean(props.validation.values[props.field.value])}
                            disabled={
                                props.formType === 'view' || (props.field.enableOn ? !props.validation.values[props.field.enableOn] : false)
                            }
                            onChange={
                                props.field.clear
                                    ? (e) => handleValidation(e, props.field)
                                    : props.field.isApp
                                    ? handleAppChange
                                    : props.handleValidationChange
                                    ? props.handleValidationChange
                                    : props.validation.handleChange
                            }
                            checked={
                                props.isAsset
                                    ? !!props.validation?.values?.[
                                          props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url
                                      ]?.[props.field.value]
                                    : !!props.validation.values[props.field.value] || false
                            }
                        />
                        {!props.field.hideLabel && (
                            <Label
                                className={`mb-0 d-flex ${
                                    props.field.enableOn && !!props.validation.values[props.field.enableOn]
                                        ? props.field.fieldClass?.replaceAll('text-muted', '')
                                        : props.field.fieldClass
                                } `}
                            >
                                {props.field.label}
                                {props.field?.helpText && (
                                    <>
                                        <i
                                            className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                            id={`icon-${props.field.value}`}
                                        />
                                        <UncontrolledTooltip placement="bottom" target={`icon-${props.field.value}`}>
                                            {props.field.helpText}
                                        </UncontrolledTooltip>
                                    </>
                                )}
                                {props.field.badge && (
                                    <span className={`p-1 ms-1 fs-10 rounded badge-soft-${props.field.badgeClass}`}>
                                        {props.field.badgeText}
                                    </span>
                                )}
                            </Label>
                        )}
                    </div>
                </>
            </Col>
            {props.field?.[props.validation?.values[props.field?.optionsField ? props.field?.optionsField : props.field?.value]] &&
                props.field?.[props.validation?.values[props.field?.optionsField ? props.field?.optionsField : props.field?.value]]?.map(
                    (field, ind) => <DynamicComponents key={ind} {...props} field={field} index={ind} />
                )}
        </React.Fragment>
    );
};
const heading = (field, index) => {
    return (
        <React.Fragment key={index}>
            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                <div className={`fs-14 ${field.fieldClass}`}>{field.label}</div>
                {field.helpText && <div className="text-muted font-size-12 mb-2 word-wrap-break">{field.helpText}</div>}
            </Col>
        </React.Fragment>
    );
};

const copyToClipboard = (pacName, toastName) => {
    navigator.clipboard.writeText(pacName).then(() => {
        toast.success(`${toastName} packagename copied successfully`);
    });
};
const Application = (props) => {
    const [SelectedThreatTab, setSelectedThreatTabS] = useState('appStoreApps');

    const handleValidationChange = (e) => {
        props.validation.setValues({
            ...props.validation.values,
            [props?.selectedApp?.adamid ? props?.selectedApp?.adamid : props?.selectedApp?.url]: {
                ...(props.validation.values?.[props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url] || {}),
                [e.target.name]: e.target.value ? e.target.value : e.target.checked,
                appType: props.selectedApp?.appType
            }
        });
    };

    const fields = props.selectedApp ? props.field.fields.filter((grid) => grid.appShow?.includes(props.selectedApp?.appType)) : [];

    const handleStoreApps = () => {
        return (
            <>
                {props.isApplication && (
                    <div className="text-muted font-size-12 mt-3 word-wrap-break ">
                        <p>The apps shown here are imported from ABM. To import apps from ABM please follow the below navigation</p>
                    </div>
                )}
                <Row className="mt-4">
                    {props.isApplication ? (
                        props.applications.map((app, index) => (
                            <Col key={index} xs={12} sm={12} md={6} lg={4} xl={3} xxl={3}>
                                <Card className={'shadow-card cursor-pointer card-height-100'}>
                                    <CardHeader className="position-relative p-0 border-bottom-0">
                                        <div className="mb-4">
                                            <div className="position-absolute top-0 end-0 px-2 py-1">
                                                <Input
                                                    className="border border-primary fs-12 cursor-pointer"
                                                    type="checkbox"
                                                    id={app.name}
                                                    name={app.name}
                                                    disabled={props.formType === 'view'}
                                                    onClick={(e) => props.addApplication(app, e)}
                                                    checked={!!app.checked}
                                                />
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody
                                        className="py-2"
                                        onClick={() =>
                                            !!app.checked || props.formType === 'view'
                                                ? props.handleSelectApp(app)
                                                : toast.error(toastMessages.verifyCheckbox)
                                        }
                                    >
                                        <Row className="d-flex justify-content-center align-items-center">
                                            <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2}>
                                                <img
                                                    className="border rounded"
                                                    src={app.icon ? app.icon : AppleImage}
                                                    alt="img"
                                                    height={40}
                                                    width={40}
                                                />
                                            </Col>
                                            <Col xs={9} sm={9} md={9} lg={9} xl={10} xxl={10} className="px-3">
                                                <div className="fs-13 fw-medium overflow-hidden white-space-nowrap text-overflow-ellipsis">
                                                    {app.name}
                                                </div>
                                                <div className="fs-10 fw-normal">{app.platforms?.join(', ')}</div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2}></Col>
                                            <Col xs={9} sm={9} md={9} lg={9} xl={10} xxl={10} className="text-muted fs-10 px-3">
                                                {app.version}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="py-1 mb-1">
                                        <div className="mt-auto">
                                            <div className="d-flex mb-1">
                                                <div className="flex-grow-1">
                                                    <div className="fs-10">Licenses</div>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <div>
                                                        <i className="ri-list-check align-bottom me-1 text-muted fs-10"></i>{' '}
                                                        <span className="fs-10">
                                                            {app.assignedlicenses}/{app.totallicenses}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="progress progress-sm animated-progess">
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{
                                                        width: (Number(app.assignedlicenses) / Number(app.totallicenses)) * 100 + '%'
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <Col>
                            {fields?.length > 0 &&
                                fields.map((ele, ind) => {
                                    return (
                                        <div key={ind}>
                                            <DynamicComponents
                                                field={ele}
                                                setSelected={props.setSelected}
                                                setIsShowCardData={props.setIsShowCardData}
                                                index={ind}
                                                isAsset={true}
                                                setIsApplication={props.setIsApplication}
                                                isApplication={props.isApplication}
                                                formType={props.formType}
                                                selected={props.selected}
                                                selectedApp={props.selectedApp}
                                                handleSelectApp={props.handleSelectApp}
                                                validation={props.validation}
                                                handleKeyDown={props.handleKeyDown}
                                                handleValidationChange={handleValidationChange}
                                                addApplication={props.addApplication}
                                                getManagedConfigs={props.getManagedConfigs}
                                                singleApps={props.singleApps}
                                                managedConfigs={props.managedConfigs}
                                            />
                                        </div>
                                    );
                                })}
                        </Col>
                    )}
                </Row>
            </>
        );
    };

    const handlelSystemApps = () => {
        return (
            <>
                <div className="text-muted font-size-12 mt-3 word-wrap-break ">
                    <p>
                        Default system apps are pre-installed applications that comes with the apple operating system, providing essential
                        functionalities such as messaging, contacts, and settings. The apps selected will be blocked. The apps will be
                        unselected by default, but admin can select, if not required.
                    </p>
                </div>
                <Row className="mt-4">
                    {props.systemApps.map((app, index) => (
                        <Col key={index} xs={12} sm={12} md={6} lg={4} xl={3} xxl={3}>
                            <Card className={'shadow-card cursor-pointer py-3'}>
                                <CardHeader className="position-relative p-0 border-bottom-0">
                                    <div className="mb-4">
                                        <div className="position-absolute top-0 end-0 px-2">
                                            <Input
                                                className="border border-primary fs-12 cursor-pointer"
                                                type="checkbox"
                                                id={app.packagename}
                                                name={app.packagename}
                                                disabled={props.formType === 'view'}
                                                onClick={() => props.addSystemApplication(app)}
                                                checked={!!app.checked}
                                            />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <Row className="d-flex justify-content-center align-items-center">
                                        <Col xs={2} sm={2} md={3} lg={3} xl={2} xxl={2}>
                                            <img
                                                src={app.image ? app.image : AppleImage}
                                                alt="img"
                                                className="border border-2 rounded-2"
                                                height={30}
                                            />
                                        </Col>
                                        <Col xs={10} sm={10} md={9} lg={9} xl={10} xxl={10}>
                                            <div className="ms-1">
                                                <div className="fs-13 fw-medium  overflow-hidden white-space-nowrap text-overflow-ellipsis">
                                                    {app.name}
                                                </div>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <div className="fs-13 fw-medium w-auto text-muted overflow-hidden white-space-nowrap text-overflow-ellipsis">
                                                        {app.packagename}
                                                    </div>
                                                    <div>
                                                        <i
                                                            id={`copy-${index}`}
                                                            onClick={(e) => copyToClipboard(app.packagename, app.name)}
                                                            className="ri-file-copy-line ms-2 fs-14 cursor-pointer link-primary"
                                                        ></i>
                                                        {Tooltip(`copy-${index}`, 'Copy')}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </>
        );
    };

    const threatsList = [
        {
            tabID: 'appStoreApps',
            tabName: (
                <div className="d-flex align-items-center gap-2">
                    <i className="ri-app-store-line" />
                    Apps & Books
                </div>
            ),
            tabContent: handleStoreApps()
        },
        {
            tabID: 'defaultSystemApps',
            tabName: (
                <div className="d-flex align-items-center gap-2">
                    <i className="ri-apps-line"></i>Default System Apps
                </div>
            ),
            tabContent: handlelSystemApps()
        }
    ];

    return (
        <Row>
            <TabsComponent
                tabsList={threatsList}
                defaultTabID={'appStoreApps'}
                noPadding={true}
                toggleTab={(tab) => setSelectedThreatTabS(tab)}
            />
        </Row>
    );
};

const renderValue = (field, value) => {
    return field.inputType === 'dropdown'
        ? field.options?.find((option) => option.value === value)?.label
        : field.inputType === 'checkbox'
        ? value
            ? 'Yes'
            : 'No'
        : value;
};

const tableInput = (props) => {
    const handleAdd = () => {
        let values = [...(props.validation.values[props.field.value] || [])];
        if (props.field.max ? values?.length <= props.field.max : true) values.push(props.validation.values[props.field.val]);
        props.validation.setValues({ ...props.validation.values, [props.field.value]: values, [props.field.val]: '' });
    };
    const handleDelete = (ind) => {
        let values = [...(props.validation.values[props.field.value] || [])];
        values.splice(ind, 1);
        props.validation.setValues({ ...props.validation.values, [props.field.value]: values, [props.field.val]: '' });
    };

    return (
        <React.Fragment key={props.index}>
            <Row>
                <Col sm={6}>
                    <Label className="mb-0 fw-medium d-flex align-items-center">{props.field.label}</Label>
                    <div className="text-muted font-size-12 mb-2 word-wrap-break ">{props.field.helpText && props.field.helpText}</div>
                    <Table bordered={props.validation.values?.[props.field.value]?.length === 1}>
                        <tbody>
                            {(props.validation.values?.[props.field.value] || [])?.map((value, ind) => (
                                <tr key={ind}>
                                    <td>
                                        <div className="d-flex align-items-center justify-content-between">
                                            {renderValue(props.field, value)}
                                            {props.formType !== 'view' && (
                                                <Button color="danger" outline size="sm" onClick={() => handleDelete(ind)}>
                                                    <i className="ri-delete-bin-6-line fs-16" />
                                                </Button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {(props.field.max && props.validation.values[props.field.value]?.length
                                ? props.validation.values[props.field.value]?.length < props.field.max
                                : true) && props.formType !== 'view' ? (
                                <tr>
                                    <td className={`${props.validation.values?.[props.field.value]?.length === 1 ? '' : 'border-none'}`}>
                                        <div className="d-flex align-items-center gap-2">
                                            <Input
                                                name={props.field?.val}
                                                id={props.field?.val}
                                                className="form-control"
                                                autoComplete="new-password"
                                                placeholder={`Enter ${props.field.label}`}
                                                type={props.field.type}
                                                validate={{ required: { value: true } }}
                                                onChange={
                                                    props.handleValidationChange
                                                        ? props.handleValidationChange
                                                        : props.validation?.handleChange
                                                }
                                                min={props.field.minValue}
                                                max={props.field.maxValue}
                                                maxLength={props.field.maxLength}
                                                onBlur={props.validation?.handleBlur}
                                                onKeyDown={(e) => props.handleKeyDown(e)}
                                                value={props.validation?.values[props.field?.val] || ''}
                                                invalid={
                                                    props.validation?.touched?.[props.field?.val] &&
                                                    props.validation?.errors?.[props.field?.val]
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <Button
                                                color="success"
                                                outline
                                                size="sm"
                                                disabled={
                                                    !props.validation.values[props.field.val] ||
                                                    props.validation.values[props.field.val] === ''
                                                }
                                                onClick={handleAdd}
                                            >
                                                <i className="ri-check-line fs-16" />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ) : !props.validation.values[props.field.value]?.length ? (
                                '–'
                            ) : (
                                ''
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const tableObject = (props) => {
    const handleDisable = () => {
        let flag = false;
        flag = props.field.mandatory?.every(
            (content) => (flag = !props.validation.values[content] || props.validation.values[content] === '')
        );

        if (props.field.conditionalMandatory?.length > 0) {
            if (flag) return;
            flag = !props.field.conditionalMandatory?.some((mandatory) => (flag = !!props.validation.values[mandatory]));
        }
        return flag;
    };

    const handleAdd = () => {
        let values = [...(props.validation.values[props.field.value] || [])];
        let obj = {};
        let clearObj = {};
        props.field.tableContent.forEach((content) => {
            obj[content.saveKey ? content.saveKey : content.value] =
                content.inputType === 'dropdown'
                    ? props.validation.values[content.value]?.[content.optionValue ? content.optionValue : 'value']
                    : content.inputType === 'checkbox'
                    ? !!props.validation.values[content.value]
                    : props.validation.values[content.value]
                    ? props.validation.values[content.value]
                    : undefined;
            clearObj[content.value] = '';
        });
        if (props.field.max ? values?.length <= props.field.max : true) values.push(JSON.parse(JSON.stringify(obj)));
        props.validation.setValues({ ...props.validation.values, ...clearObj, [props.field.value]: values });
    };

    const handleDelete = (ind) => {
        let values = [...(props.validation.values[props.field.value] || [])];
        values.splice(ind, 1);
        let clearObj = {};
        props.field.tableContent.forEach((content) => (clearObj[content.saveKey ? content.saveKey : content.value] = ''));
        props.validation.setValues({ ...props.validation.values, ...clearObj, [props.field.value]: values });
    };

    return (
        <React.Fragment key={props.index}>
            <Row>
                <Col sm={props.field.colSize ? props.field.colSize : 6}>
                    <Label className="mb-0 fw-medium d-flex align-items-center">{props.field.label}</Label>
                    <div className="text-muted font-size-12 mb-2 word-wrap-break ">{props.field.helpText && props.field.helpText}</div>
                    <Table bordered>
                        {props.field.showHeader && (
                            <thead className="bg-light text-muted">
                                <tr>
                                    {props.field.tableContent?.map((content, index) => (
                                        <td key={index}>
                                            {content.label}
                                            {props.field.mandatory?.includes(content.saveKey ? content.saveKey : content.value) && (
                                                <span className="text-danger ms-1">*</span>
                                            )}
                                        </td>
                                    ))}
                                    <td>Actions</td>
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            {(props.validation.values?.[props.field.value] || [])?.map((value, ind) => (
                                <tr key={ind}>
                                    {props.field.tableContent?.map((content, index) => (
                                        <td className={content.columnClass} key={index}>
                                            {renderValue(content, value[content.saveKey ? content.saveKey : content.value])}
                                        </td>
                                    ))}
                                    {props.formType !== 'view' && (
                                        <td className="d-flex justify-content-center">
                                            <Button color="danger" outline size="sm" onClick={() => handleDelete(ind)}>
                                                <i className="ri-delete-bin-6-line fs-16" />
                                            </Button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                            {(props.field.max && props.validation.values[props.field.value]?.length
                                ? props.validation.values[props.field.value]?.length < props.field.max
                                : true) &&
                                (props.formType !== 'view' ? (
                                    <tr
                                        className={`${
                                            props.validation.values?.[props.field.value]?.length === 1 || props.field.showHeader
                                                ? ''
                                                : 'border-none'
                                        }`}
                                    >
                                        {props.field.tableContent?.map((content, index) => (
                                            <td
                                                key={index}
                                                className={`${content.columnClass ? content.columnClass : ''} ${
                                                    props.validation.values?.[props.field.value]?.length === 1 || props.field.showHeader
                                                        ? ''
                                                        : 'border-none px-0'
                                                }`}
                                            >
                                                <Row className="p-0 justify-content-center">
                                                    <DynamicComponents
                                                        noMargin={true}
                                                        field={content}
                                                        index={index}
                                                        formType={props.formType}
                                                        selected={false}
                                                        setSelected={props.setSelected}
                                                        validation={
                                                            props.handleValidationChange ? props.handleValidationChange : props.validation
                                                        }
                                                        handleKeyDown={props.handleKeyDown}
                                                        selectedApp={props.selectedApp}
                                                        isAsset={props.isAsset}
                                                        singleApps={props.singleApps}
                                                        getManagedConfigs={props.getManagedConfigs}
                                                        managedConfigs={props.managedConfigs}
                                                    />
                                                </Row>
                                            </td>
                                        ))}
                                        <td
                                            className={`${
                                                props.validation.values?.[props.field.value]?.length === 1 || props.field.showHeader
                                                    ? ''
                                                    : 'border-none'
                                            } d-flex justify-content-center`}
                                        >
                                            <Button color="success" outline size="sm" disabled={handleDisable()} onClick={handleAdd}>
                                                <i className="ri-check-line fs-16" />
                                            </Button>
                                        </td>
                                    </tr>
                                ) : !props.validation.values[props.field.value]?.length ? (
                                    '–'
                                ) : (
                                    ''
                                ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const uploadComponent = (props) => {
    const handleFileUpload = (file, field) => {
        let valid = false;
        field.accept
            ? field.accept?.forEach((accept) => {
                  if (file.filename.endsWith(accept)) valid = true;
              })
            : (valid = true);
        if (valid) {
            const reader = new FileReader();
            if (field.convert === 'base64') {
                reader.onloadend = () => {
                    let str = reader.result.split(',')[1];
                    props.validation.setValues({ ...props.validation.values, [field.value]: str });
                };
                reader.readAsDataURL(file.file);
            } else if (field.convert === 'hex') {
                reader.onloadend = () => {
                    const arrayBuffer = reader.result;
                    const bytes = new Uint8Array(arrayBuffer);

                    let hexString = '';
                    bytes.forEach((byte) => {
                        hexString += byte.toString(16).padStart(2, '0');
                    });
                    props.validation.setValues({ ...props.validation.values, [field.value]: hexString });
                };
                reader.readAsArrayBuffer(file.file);
            }
        }
    };

    const handleDelete = (key) => {
        props.validation.setValues({ ...props.validation.values, [key]: '' });
    };

    return (
        <React.Fragment>
            {!props.field.hideLabel && (
                <Row>
                    <Col
                        xs={6}
                        md={6}
                        sm={6}
                        lg={6}
                        xl={6}
                        className={`d-flex ${props.field.notes ? '' : 'align-items-center'} justify-content-between`}
                    >
                        <Label className={`mb-0 fw-medium d-flex ${props.field.notes ? '' : 'align-items-center'}`}>
                            {props.field.label}
                            {props.formType !== 'view' && !props.field.notMandatory ? (
                                <span className="red-color ps-1">*</span>
                            ) : props.field.helpText ? (
                                <>
                                    <i
                                        className={`ri-information-line d-flex ${
                                            props.field.notes ? '' : 'align-items-center'
                                        } blue-icon ps-1 fs-15`}
                                        id={`polInputComponent-${props.field.value}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`polInputComponent-${props.field.value}`}>
                                        {props.field.helpText && props.field.helpText}
                                    </UncontrolledTooltip>
                                </>
                            ) : (
                                <span className="ps-1">:</span>
                            )}
                            {props.field.badge && (
                                <span className={` px-2 fs-10 badge-soft-${props.field.badgeClass}`}>{props.field.badgeText}</span>
                            )}
                        </Label>
                    </Col>
                    <Row>
                        <Col xs={6} md={6} sm={6} lg={6} xl={6}>
                            {!props.field.hideHelpText && (
                                <div className="text-muted font-size-12 mb-2 word-wrap-break">
                                    {props.field.helpText && props.field.helpText}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Row>
            )}
            <Row className="mb-2">
                <Col sm={props.field.hideLabel ? 12 : 6}>
                    {!props.validation.values[props.field.value] ? (
                        <FilePond
                            name={props.field.value}
                            acceptedFileTypes={props.field.acceptedFileTypes ? props.field.acceptedFileTypes : null}
                            minFileSize={props.field.minFileSize || '5KB'}
                            maxFileSize={props.field.maxFileSize}
                            maxFiles={props.field.maxFiles}
                            allowMultiple={true}
                            allowFileSizeValidation={true}
                            allowImageValidateSize={true}
                            imageValidateSizeMinWidth={props.field.imageValidateSizeMinWidth ? props.field.imageValidateSizeMinWidth : null}
                            imageValidateSizeMaxWidth={props.field.imageValidateSizeMaxWidth ? props.field.imageValidateSizeMaxWidth : null}
                            imageValidateSizeMinHeight={
                                props.field.imageValidateSizeMinHeight ? props.field.imageValidateSizeMinHeight : null
                            }
                            imageValidateSizeMaxHeight={
                                props.field.imageValidateSizeMaxHeight ? props.field.imageValidateSizeMaxHeight : null
                            }
                            disabled={props?.formType === 'view'}
                            onaddfile={(_error, fileItems) => handleFileUpload(fileItems, props.field)}
                            className="filepond filepond-input-multiple"
                        />
                    ) : (
                        <div className="d-flex align-items-center gap-2">
                            <div className="p-2 rounded border border-2">
                                <img
                                    src={`data:image/png;base64,${props.validation.values[props.field.value]}`}
                                    alt={props.field.value}
                                    height={80}
                                    className="rounded border"
                                />
                            </div>
                            {props.formType !== 'view' && (
                                <i
                                    className="ri-delete-bin-5-line link-danger cursor-pointer fs-18"
                                    onClick={() => handleDelete(props.field.value)}
                                />
                            )}
                        </div>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
};

const multipleProfileComponent = (props) => {
    const handleFieldData = async (fields, obj) => {
        await fields.forEach((field) => {
            if (field.inputType === 'checkbox') obj[field.value] = false;
            else obj[field.value] = '';

            if (field.inputType === 'dropdown') {
                field.options.forEach(async (option) => {
                    if (field[option.value]?.length > 0) {
                        obj = await handleFieldData(field[option.value], obj);
                    }
                });
            }
        });
        return obj;
    };
    const getEmptyObj = async () => {
        let obj = {};
        await props.field?.arrayFields?.forEach((field) => {
            if (field.inputType === 'checkbox') obj[field.value] = false;
            else obj[field.value] = '';

            if (field.inputType === 'dropdown') {
                field.options.forEach(async (option) => {
                    if (field[option.value]?.length > 0) {
                        obj = await handleFieldData(field[option.value], obj);
                    }
                });
            }
        });
        return obj;
    };
    const handleAddClick = async () => {
        let obj = await getEmptyObj();
        props.validation.setValues({ ...props.validation.values, ...obj });
        props.setIsArray(false);
        props.setArrayMode('add');
        props.setArrayIndex(-1);
    };

    const handleCancel = async () => {
        let obj = await getEmptyObj();
        props.validation.setValues({ ...props.validation.values, ...obj });
        props.setIsArray(true);
        props.setArrayMode('add');
        props.setArrayIndex(-1);
    };

    const handleMultiInputDisable = (fields, flag) => {
        fields.forEach((input) => {
            if (!input.notMandatory && !props.validation.values[input.value]) flag = true;
        });
        return flag;
    };

    const checkDisable = () => {
        let flag = false;
        props.field?.arrayFields?.forEach((field) => {
            if (field.inputType !== 'checkbox' && !field.notMandatory && !props.validation.values[field.value]) flag = true;

            if (field.inputType === 'dropdown' && field[props.validation.values[field.value]?.value]?.length > 0) {
                field[props.validation.values[field.value]?.value]?.forEach((subField) => {
                    if (subField.inputType === 'multiInput') {
                        flag = handleMultiInputDisable(subField.inputs, flag);
                    } else if (subField.inputType !== 'checkbox' && !subField.notMandatory && !props.validation.values[subField.value])
                        flag = true;
                });
            }
        });
        return flag;
    };

    const handleSave = async () => {
        let obj = {};
        props.field?.arrayFields?.forEach((field) => {
            obj[field.value] = props.validation.values[field.value];

            if (field.inputType === 'dropdown' && field[props.validation.values?.[field.value]?.value]?.length > 0) {
                field[props.validation.values?.[field.value]?.value]?.forEach((subField) => {
                    obj[subField.value] = props.validation.values[subField.value];
                });
            }
        });
        let values = { ...props.validation.values };
        if (values[props.field.value] && values[props.field.value]?.length > 0) {
            if (props.arrayMode === 'edit') values[props.field.value]?.splice(props.arrayIndex, 1, obj);
            else values[props.field.value]?.push(obj);
        } else values[props.field.value] = [obj];
        let emptyObj = await getEmptyObj();
        props.validation.setValues({ ...values, ...emptyObj });
        props.setIsArray(true);
        props.setArrayMode('add');
        props.setArrayIndex(-1);
    };

    const handleEdit = (value, index) => {
        props.validation.setValues({ ...props.validation.values, ...value });
        props.setArrayMode('edit');
        props.setArrayIndex(index);
        props.setIsArray(false);
    };

    const handleDelete = (index) => {
        let values = JSON.parse(JSON.stringify(props.validation.values[props.field.value]));
        values.splice(index, 1);
        props.validation.setValues({ ...props.validation.values, [props.field.value]: values });
        props.setArrayMode('add');
        props.setArrayIndex(-1);
    };

    const renderCardValue = (card, value) => {
        return card.type === 'boolean'
            ? value?.[card.value]
                ? 'Yes'
                : 'No'
            : card.type === 'dropdown'
            ? value?.[card.value]?.label
                ? value?.[card.value]?.label
                : '—'
            : value?.[card.value]
            ? value?.[card.value]
            : '—';
    };

    return props.isArray ? (
        <React.Fragment key={props.index}>
            {props.formType !== 'view' && (
                <div className="d-flex align-items-center justify-content-end pe-3">
                    <Button color="primary" sime="md" onClick={handleAddClick}>
                        {props.field.addButtonText}
                    </Button>
                </div>
            )}
            <Row className="bvinwen">
                {props.validation.values?.[props.field.value]?.length > 0 ? (
                    props.validation.values?.[props.field.value]?.map((value, index) => (
                        <Col sm="auto" className="p-2" key={index}>
                            <Card className="shadow-card">
                                <CardBody className="d-flex align-items-center justify-content-center position-relative">
                                    <div className="position-absolute top-5 end-5">
                                        <div className="d-flex align-items-center gap-2">
                                            <i className="ri-edit-line link-info cursor-pointer" onClick={() => handleEdit(value, index)} />
                                            <i
                                                className="ri-delete-bin-line link-danger cursor-pointer"
                                                onClick={() => handleDelete(index)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <table>
                                            {props.field.cardFields?.map((card, ind) => (
                                                <tr key={ind}>
                                                    <td className="fw-semibold px-3 py-2">{card.label}</td>
                                                    <td className="fw-semibold px-2 py-2">:</td>
                                                    <td className="px-3 py-2 ">
                                                        <div className="width-150">
                                                            <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                {renderCardValue(card, value)}
                                                            </EllipsisToolTip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))
                ) : (
                    <NoRecordsFound />
                )}
            </Row>
        </React.Fragment>
    ) : (
        <React.Fragment key={props.index}>
            {props.field?.arrayFields?.map((field, index) => {
                return (
                    <div key={index}>
                        <DynamicComponents
                            field={field}
                            index={index}
                            formType={props.formType}
                            selected={false}
                            setSelected={props.setSelected}
                            validation={props.handleValidationChange ? props.handleValidationChange : props.validation}
                            handleKeyDown={props.handleKeyDown}
                            singleApps={props.singleApps}
                            managedConfigs={props.managedConfigs}
                            getManagedConfigs={props.getManagedConfigs}
                        />
                    </div>
                );
            })}
            <div className="d-flex align-items-center justify-content-end gap-2 pe-3">
                <Button size="md" className="btn btn-light" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button size="md" color="primary" onClick={handleSave} disabled={checkDisable()}>
                    Save
                </Button>
            </div>
        </React.Fragment>
    );
};

export const multiInputComponent = (props) => {
    return (
        <React.Fragment>
            <Row>
                <Col sm={6}>
                    {!props.field.hideLabel && (
                        <Row>
                            <Col
                                xs={6}
                                md={6}
                                sm={6}
                                lg={6}
                                xl={6}
                                className={`d-flex ${props.field.notes ? '' : 'align-items-center'} justify-content-between`}
                            >
                                <Label className={`mb-0 fw-medium d-flex ${props.field.notes ? '' : 'align-items-center'}`}>
                                    {props.field.label}
                                    {props.formType !== 'view' ? (
                                        <span className="red-color ps-1">*</span>
                                    ) : props.field.helpText ? (
                                        <>
                                            <i
                                                className={`ri-information-line d-flex ${
                                                    props.field.notes ? '' : 'align-items-center'
                                                } blue-icon ps-1 fs-15`}
                                                id={`polInputComponent-${props.field.value}`}
                                            />
                                            <UncontrolledTooltip placement="bottom" target={`polInputComponent-${props.field.value}`}>
                                                {props.field.helpText && props.field.helpText}
                                            </UncontrolledTooltip>
                                        </>
                                    ) : (
                                        <span className="ps-1">:</span>
                                    )}
                                    {props.field.badge && (
                                        <span className={` px-2 fs-10 badge-soft-${props.field.badgeClass}`}>{props.field.badgeText}</span>
                                    )}
                                </Label>
                            </Col>
                            <Row>
                                <Col xs={6} md={6} sm={6} lg={6} xl={6}>
                                    {!props.field.hideHelpText && (
                                        <div className="text-muted font-size-12 mb-2 word-wrap-break">
                                            {props.field.helpText && props.field.helpText}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Row>
                    )}
                    <Row>
                        <div className="d-flex align-items-center mb-2">
                            {props.field.inputs?.map((input, ind) => (
                                <>
                                    <div
                                        className={`input-group ${input.class} ${
                                            props.field.type === 'password' ? 'position-relative auth-pass-inputgroup' : ''
                                        }`}
                                    >
                                        <Input
                                            name={input.value}
                                            id={input.value}
                                            className={`form-control ${input.class}`}
                                            placeholder={`Enter ${input.placeHolder}`}
                                            type={input.type}
                                            autoComplete="new-password"
                                            validate={{ required: { value: true } }}
                                            onChange={
                                                props.handleValidationChange ? props.handleValidationChange : props.validation?.handleChange
                                            }
                                            min={input.minValue}
                                            max={input.maxValue}
                                            disabled={props.formType === 'view'}
                                            maxLength={input.maxLength}
                                            onBlur={props.validation?.handleBlur}
                                            onKeyDown={(e) => props.handleKeyDown(e)}
                                            value={
                                                props.isAsset
                                                    ? props.validation?.values?.[
                                                          props.selectedApp?.adamid ? props.selectedApp?.adamid : props.selectedApp?.url
                                                      ]?.[input.value]
                                                    : props.validation?.values[input?.value] || ''
                                            }
                                            invalid={
                                                props.validation?.touched?.[input?.value] && props.validation?.errors?.[input?.value]
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                    {ind < props.field.inputs?.length - 1 && <span className="mx-1">:</span>}
                                </>
                            ))}
                        </div>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const TimePicker = (props) => {
    const handleTimeChange = (e, field) => {
        props.validation.setValues({ ...props.validation.values, [field.value]: e[0] });
    };
    return (
        <Row key={props.index} className={`${props.noMargin && 'pe-0'}`}>
            <Row>
                <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`d-flex ${props.field.notes ? '' : 'align-items-center'} justify-content-between`}
                >
                    <Label className={`mb-0 fw-medium d-flex ${props.field.notes ? '' : 'align-items-center'}`}>
                        {props.field.label}
                        {props.formType !== 'view' && !props.field.notMandatory ? (
                            <span className="red-color ps-1">*</span>
                        ) : props.field.helpText ? (
                            <>
                                <i
                                    className={`ri-information-line d-flex ${
                                        props.field.notes ? '' : 'align-items-center'
                                    } blue-icon ps-1 fs-15`}
                                    id={`picker-${props.field?.uncontrolledId ? props.field?.uncontrolledId : props.field.value}`}
                                />
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target={`picker-${props.field?.uncontrolledId ? props.field?.uncontrolledId : props.field.value}`}
                                >
                                    {props.field.helpText && props.field.helpText}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                        {props.field.badge && (
                            <span className={` px-2 fs-10 badge-soft-${props.field.badgeClass}`}>{props.field.badgeText}</span>
                        )}
                    </Label>
                </Col>
                <Row>
                    <Col xs={6} md={6} sm={6} lg={6} xl={6}>
                        {!props.field.hideHelpText && (
                            <div className="text-muted font-size-12 mb-2 word-wrap-break">
                                {props.field.helpText && props.field.helpText}
                            </div>
                        )}
                    </Col>
                </Row>
            </Row>
            <Row className={`${props.noMargin ? 'pe-0' : 'mb-2'}`}>
                <Col
                    xs={props.field.hideLabel ? 12 : 6}
                    md={props.field.hideLabel ? 12 : 6}
                    sm={props.field.hideLabel ? 12 : 6}
                    lg={props.field.hideLabel ? 12 : 6}
                    xl={props.field.hideLabel ? 12 : 6}
                    className={`${props.field.paddingLeft ? props.field.paddingLeft : ''}`}
                >
                    <div className={`input-group ${props.field.type === 'password' ? 'position-relative auth-pass-inputgroup' : ''}`}>
                        <Flatpickr
                            placeholder={props.field?.placeholder}
                            value={props.validation?.values?.[props?.field?.value]}
                            name={props.field?.value}
                            disabled={props.formType === 'view'}
                            className="form-control form-control-icon min-width-160 me-2"
                            options={props.field?.options}
                            onChange={(e) => handleTimeChange(e, props.field)}
                        />
                    </div>
                </Col>
            </Row>
        </Row>
    );
};

export const DynamicComponents = (props) => {
    return (
        <React.Fragment key={props.index}>
            {props.field.inputType === 'input' ? (
                <InputComponent
                    field={props.field}
                    index={props.index}
                    validation={props.validation}
                    code={props.code}
                    isAsset={props.isAsset}
                    formType={props.formType}
                    selected={props.selected}
                    handleChange={props.handleChange}
                    handleKeyDown={props.handleKeyDown}
                    selectedAppData={props.selectedAppData}
                    handleValidationChange={props.handleValidationChange}
                    selectedApp={props.selectedApp}
                />
            ) : props.field.inputType === 'switch' ? (
                toggleButton({
                    field: props.field,
                    index: props.index,
                    validation: props.validation,
                    code: props.code,
                    formType: props.formType,
                    handleValidationChange: props.handleValidationChange,
                    isAsset: props.isAsset,
                    selectedApp: props.selectedApp
                })
            ) : props.field.inputType === 'dropdown' ? (
                selectComponent({
                    noMargin: props.noMargin,
                    field: props.field,
                    index: props.index,
                    validation: props.validation,
                    formType: props.formType,
                    selected: props.selected,
                    selectedAppData: props.selectedAppData,
                    handleValidationChange: props.handleValidationChange,
                    isAsset: props.isAsset,
                    selectedApp: props.selectedApp,
                    handleKeyDown: props.handleKeyDown,
                    applications: props.applications,
                    singleApps: props.singleApps,
                    managedConfigs: props.managedConfigs,
                    getManagedConfigs: props.getManagedConfigs
                })
            ) : props.field.inputType === 'textbox' ? (
                <Textbox
                    field={props.field}
                    index={props.index}
                    handleKeyDown={props.handleKeyDown}
                    validation={props.validation}
                    formType={props.formType}
                    handleValidationChange={props.handleValidationChange}
                    isAsset={props.isAsset}
                    selectedApp={props.selectedApp}
                />
            ) : props.field.inputType === 'restrictions' ? (
                <Restrictions
                    field={props.field}
                    index={props.index}
                    handleKeyDown={props.handleKeyDown}
                    validation={props.validation}
                    setSelected={props.setSelected}
                    setIsShowCardData={props.setIsShowCardData}
                    formType={props.formType}
                    isAsset={props.isAsset}
                    handleValidationChange={props.handleValidationChange}
                    selectedApp={props.selectedApp}
                />
            ) : props.field.inputType === 'application' ? (
                <Application
                    field={props.field}
                    index={props.index}
                    validation={props.assetsValidation}
                    isAsset={true}
                    code={props.code}
                    formType={props.formType}
                    handleChange={props.handleChange}
                    handleKeyDown={props.handleKeyDown}
                    applications={props.applications}
                    isApplication={props.isApplication}
                    selectedAppData={props.selectedAppData}
                    setIsApplication={props.setIsApplication}
                    addApplication={props.addApplication}
                    systemApps={props.systemApps}
                    addSystemApplication={props.addSystemApplication}
                    handleSelectApp={props.handleSelectApp}
                    selectedApp={props.selectedApp}
                    managedConfigs={props.managedConfigs}
                    getManagedConfigs={props.getManagedConfigs}
                />
            ) : props.field.inputType === 'policyInput' ? (
                <PolicyInputComponent
                    noMargin={props.noMargin}
                    field={props.field}
                    index={props.index}
                    validation={props.validation}
                    code={props.code}
                    formType={props.formType}
                    selected={props.selected}
                    handleChange={props.handleChange}
                    handleKeyDown={props.handleKeyDown}
                    selectedAppData={props.selectedAppData}
                    handleValidationChange={props.handleValidationChange}
                    isAsset={props.isAsset}
                    selectedApp={props.selectedApp}
                />
            ) : props.field.inputType === 'json' ? (
                <Json
                    field={props.field}
                    index={props.index}
                    validation={props.validation}
                    code={props.code}
                    formType={props.formType}
                    selected={props.selected}
                    handleChange={props.handleChange}
                    handleKeyDown={props.handleKeyDown}
                    selectedAppData={props.selectedAppData}
                    handleValidationChange={props.handleValidationChange}
                    isAsset={props.isAsset}
                    selectedApp={props.selectedApp}
                    singleApps={props.singleApps}
                    applications={props.applications}
                    getManagedConfigs={props.getManagedConfigs}
                    managedConfigs={props.managedConfigs}
                />
            ) : props.field.inputType === 'checkbox' ? (
                checkbox({
                    noMargin: props.noMargin,
                    field: props.field,
                    index: props.index,
                    code: props.code,
                    formType: props.formType,
                    validation: props.validation,
                    handleValidationChange: props.handleValidationChange,
                    isAsset: props.isAsset,
                    selectedApp: props.selectedApp,
                    handleKeyDown: props.handleKeyDown
                })
            ) : props.field.inputType === 'heading' ? (
                heading(props.field, props.fieldClass)
            ) : props.field.inputType === 'radio' ? (
                radioButton({
                    field: props.field,
                    index: props.index,
                    code: props.code,
                    formType: props.formType,
                    validation: props.validation,
                    handleValidationChange: props.handleValidationChange,
                    isAsset: props.isAsset,
                    selectedApp: props.selectedApp
                })
            ) : props.field.inputType === 'tableInput' ? (
                tableInput({
                    field: props.field,
                    index: props.index,
                    code: props.code,
                    formType: props.formType,
                    validation: props.validation,
                    handleKeyDown: props.handleKeyDown
                })
            ) : props.field.inputType === 'tableObject' ? (
                tableObject({
                    field: props.field,
                    index: props.index,
                    code: props.code,
                    formType: props.formType,
                    validation: props.validation,
                    handleKeyDown: props.handleKeyDown
                })
            ) : props.field.inputType === 'upload' ? (
                uploadComponent({
                    field: props.field,
                    index: props.index,
                    code: props.code,
                    formType: props.formType,
                    validation: props.validation
                })
            ) : props.field.inputType === 'array' ? (
                multipleProfileComponent({
                    field: props.field,
                    index: props.index,
                    formType: props.formType,
                    validation: props.validation,
                    handleKeyDown: props.handleKeyDown,
                    isArray: props.isArray,
                    setIsArray: props.setIsArray,
                    arrayMode: props.arrayMode,
                    setArrayMode: props.setArrayMode,
                    arrayIndex: props.arrayIndex,
                    setArrayIndex: props.setArrayIndex
                })
            ) : props.field.inputType === 'multiInput' ? (
                multiInputComponent({ ...props })
            ) : props.field.inputType === 'timePicker' ? (
                TimePicker({ ...props })
            ) : (
                <div className="fw-semibold text-decoration-underline text-success fs-14">{props.field.label} :</div>
            )}
        </React.Fragment>
    );
};
