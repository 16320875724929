import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { TV_SCHEMA } from './AndroidTVSchema';
import { DynamicComponents } from './TVDynamicComponents';

const AddAndroidTVPolicies = () => {
    const urlconf = useEnv();
    const api = new APIClient();
    const history = useHistory();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash, true);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    document.title = formType === 'view' ? 'View Policy' : formType === 'edit' ? 'Edit Policy' : 'Add Policy';

    const [selected, setSelected] = useState('');
    const [loading, setLoading] = useState(false);
    const [wallpapers, setWallpapers] = useState([]);
    const [applications, setApplications] = useState([]);
    const [applicationsBackup, setApplicationsBackup] = useState([]);
    const [confirmApps, setConfirmApps] = useState([]);
    const [formValues, setFormValues] = useState('');
    const [geofences, setGeoFences] = useState([]);
    const [PoliciesSchemaArr, setPoliciesSchemaArr] = useState(JSON.parse(JSON.stringify(TV_SCHEMA)));
    const [profileDetails, setProfileDetails] = useState(PoliciesSchemaArr);
    const [isConfigured, setIsConfigured] = useState({});
    const [isAddArray, setIsAddArray] = useState(false);
    const [launchers, setLaunchers] = useState([]);
    let policyType = window.location.hash?.split('/').reverse()?.[0];
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletingProfile, setDeletingProfile] = useState('');
    const [selectedApp, setSelectedApp] = useState('');
    const [managedConfigs, setManagedConfigs] = useState([]);

    useEffect(() => {
        defineSchema();
    }, []);

    const defineSchema = async () => {
        let schemaArr = JSON.parse(JSON.stringify(PoliciesSchemaArr));
        schemaArr = schemaArr.filter((schema) => schema.show?.includes(policyType));
        let schema = await schemaFilter(JSON.parse(JSON.stringify(schemaArr)));
        setPoliciesSchemaArr([...schema]);
        setProfileDetails([...schema]);
        if (formType === 'add') initValues();
        handlePromise();
    };

    const initValues = () => {
        let obj = {};
        TV_SCHEMA?.forEach((schemaObj) => {
            if (schemaObj?.fields?.length > 0) {
                schemaObj?.fields?.forEach((field) => {
                    if (field.inputType === 'toggle' || field.inputType === 'checkbox') obj[field.value] = false;
                    else if (field.inputType === 'slider') obj[field.value] = field.max;
                    else if (field.inputType === 'radio') obj[field.value] = field.radios?.[0]?.val;
                    else obj[field.value] = '';
                });
            }
        });
        setFormValues(obj);
    };

    const schemaFilter = async (schemaArr) => {
        const filteredSchemaArr = await Promise.all(
            schemaArr.map(async (schemaObj) => {
                if (schemaObj.show?.includes(policyType)) {
                    const fieldTypes = ['fields'];
                    for (const fieldType of fieldTypes) {
                        if (schemaObj[fieldType]) {
                            const filteredFields = filterSchema(schemaObj[fieldType]);
                            schemaObj[fieldType] = await schemaFilter(filteredFields);
                        }
                    }
                }
                return schemaObj;
            })
        );
        return JSON.parse(JSON.stringify(filteredSchemaArr));
    };

    const filterSchema = (arr) => {
        return arr.filter((obj) => obj.show?.includes(policyType));
    };
    const handlePromise = () => {
        setLoading(true);
        const applicationsPromise = new Promise((resolve, reject) => {
            api.get(url.TV_APPS, '', domains.ANDROID_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Application failed.');
                })
                .catch((err) => reject(err));
        });

        const assetsPromise = new Promise((resolve, reject) => {
            api.get(url.ASSETS, { page: 1, size: 2000 })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Asset failed.');
                })
                .catch((err) => reject(err));
        });

        const launcherPromise = new Promise((resolve, reject) => {
            api.get(url.CONFIG, { page: 1, size: 10 }, domains.ANDROID_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Launcher Failed.');
                })
                .catch((err) => reject('Launcher Failed.'));
        });

        const policyPromisee = new Promise((resolve, reject) => {
            if (recordID) {
                api.get(url.POLICIES + '/' + formTypeAndId.recordID, '', domains.ANDROID_V1)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);
                        else reject('Policy failed.');
                    })
                    .catch((err) => reject('Policy failed.'));
            } else {
                reject('Policy failed.');
            }
        });

        const managedConfigsPromise = new Promise((resolve, reject) => {
            api.get(url.IOS_APPCONFIG, { page: 1, size: 100 }, domains.ANDROID_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Managed Config failed.');
                })
                .catch((err) => reject(err));
        });

        Promise.allSettled([policyPromisee, applicationsPromise, assetsPromise, launcherPromise, managedConfigsPromise])
            .then(async (result) => {
                if (result[3].status === 'fulfilled') {
                    setLaunchers(result[3].value);
                }
                let apps = [];
                if (result[1].status === 'fulfilled') {
                    apps = result[1].value;
                }
                let wallpaperArr = [];
                if (result[2].status === 'fulfilled') {
                    if (result[2]?.value?.length > 0) {
                        result[2]?.value?.forEach((ele) => {
                            let obj = { label: ele.name, value: ele.serverFileName, serverFileName: ele.serverFileName };
                            if (ele.assetType === 'Wallpaper') wallpaperArr.push(obj);
                        });
                    }
                    setWallpapers(wallpaperArr);
                }
                let dataObj;
                if (result[0].status === 'fulfilled') {
                    const res = result[0].value?.policy;
                    let obj = await prepareDataObj(res, wallpaperArr, result[3].value);
                    dataObj = {
                        policyName: result[0]?.value?.name,
                        ...obj
                    };
                    dataObj.passwordType = [
                        { label: 'Numeric', value: 'NUMERIC' },
                        { label: 'Alphabet', value: 'ALPHABETIC' },
                        { label: 'Alphanumeric', value: 'ALPHANUMERIC' }
                    ]?.find((setting) => setting.value === res?.passwordsettings?.passwordtype);
                    dataObj.passwordConfiguration =
                        res?.passwordsettings?.enabled === 'Y'
                            ? res?.passwordsettings?.passwordtype === 'NUMERIC' || res?.passwordsettings?.passwordtype === 'ALPHABETIC'
                                ? 'simple'
                                : res?.passwordsettings?.passwordtype === 'ALPHANUMERIC'
                                ? 'complex'
                                : 'userChoice'
                            : 'userChoice';
                    let packageArr = result?.[0]?.value?.apps?.map((app) => app.packageName);
                    apps?.forEach((app) => {
                        if (packageArr?.includes(app.packagename)) {
                            app.selected = true;
                            let appObj = result?.[0]?.value?.apps?.find((application) => application.packageName === app.packagename);
                            dataObj = {
                                ...dataObj,
                                [app.packagename]: {
                                    managedconfigid: (result[4]?.value || [])?.find(
                                        (managedConfig) => managedConfig.managedconfigid === appObj.managedConfigId
                                    )
                                }
                            };
                        }
                    });
                    setFormValues(dataObj);
                }
                if (result[4].status === 'fulfilled') {
                    setManagedConfigs(result[4].value);
                }
                let selectedApps = apps.filter((app) => app.selected);
                let unSelectedApps = apps.filter((app) => !app.selected);
                let finalApps = selectedApps.concat(formType === 'view' ? [] : unSelectedApps);
                defineSchemaConfigured(result?.[0]?.value?.policy || '', finalApps);
                setApplications(finalApps);
                setConfirmApps(finalApps);
                setApplicationsBackup(finalApps);
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const definePath = async (polObj, pathArr) => {
        let val = JSON.parse(JSON.stringify(polObj));
        await pathArr?.forEach((path) => {
            val = val?.[path];
        });
        return val;
    };

    const setTimeVal = (val) => {
        const timeArr = val?.split(':');
        const date = new Date();
        date.setHours(timeArr?.[0]);
        date.setMinutes(timeArr?.[1]);
        return date;
    };

    const handleDoubleDatePicker = (obj, val, field) => {
        obj[field.value1] = new Date(val?.[field?.value1]);
        obj[field.value2] = new Date(val?.[field?.value2]);
        return obj;
    };

    const defineType = async (val, field, wallpaperArr, launcherArr, obj) => {
        let value =
            field.inputType === 'select'
                ? field.options?.length > 0
                    ? field.isMulti
                        ? field.options.filter((option) => val?.includes(option.value))
                        : field.options.find((option) => option.value === val)
                    : field.optionsVal === 'wallpapers'
                    ? wallpaperArr?.find((wallpaper) => wallpaper?.[field.optionValue] === val)
                    : field.optionsVal === 'launcher'
                    ? launcherArr?.find((launcher) => launcher?.[field.optionValue] === val)
                    : []
                : field.inputType === 'toggle'
                ? !!val
                : field.inputType === 'timePicker'
                ? setTimeVal(val)
                : val;
        if (field.inputType === 'DoubleDatePicker') obj = handleDoubleDatePicker(obj, val, field);
        else obj[field.value] = value;
        return obj;
    };

    const prepareDataObj = async (polObj, wallpaperArr, launcherArr) => {
        let obj = {};
        await Promise.all(
            TV_SCHEMA?.map((schema) => {
                if (schema.label !== 'Applications')
                    schema?.fields?.forEach(async (field) => {
                        if (field.path?.length > 0) {
                            let val = await definePath(polObj, field.path);
                            obj = await defineType(val, field, wallpaperArr, launcherArr, obj);
                        }
                    });
            })
        );
        return obj;
    };

    const defineSchemaConfigured = (polObj, apps) => {
        let configured = {};
        TV_SCHEMA?.forEach((configure) => {
            if (configure.key === 'apps' && apps?.filter((app) => app.selected)?.length > 0) configured[configure.label] = true;
            else if (polObj[configure.val]?.enabled === 'Y') configured[configure.label] = true;
        });
        setIsConfigured(configured);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formValues },
        validationSchema: Yup.object({
            policyName: Yup.string().required('Please enter your policy name')
        })
    });

    const handleConfigured = (values) => {
        let flag = [];
        selected.fields.forEach((field) => {
            if (field.inputType === 'input' || field.inputType === 'checkbox' || field.inputType === 'toggle')
                flag.push(!!values[field.value]);
            else if (field.inputType === 'select') {
                if (field.isMulti)
                    flag.push(
                        validation.values[field.value] ? validation.values[field.value]?.length > 0 : !!validation.values[field.value]
                    );
                else flag.push(!!validation.values[field.value]);
            } else if (field.inputType === 'array') {
                //
            }
        });
        if (flag.includes(true)) return true;
        else return false;
    };

    const confirmValidation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formValues },
        onSubmit: () => {
            confirmValidation.setValues({ ...validation.values });
            setConfirmApps(applicationsBackup);
            const configured = JSON.parse(JSON.stringify(isConfigured));
            if (selected.label === 'Wi-Fi Settings') configured['Wi-Fi Settings'] = !!validation.values?.wifi?.length > 0;
            else if (selected.label === 'Applications')
                configured['Applications'] = !!applicationsBackup.filter((app) => app.selected).length > 0;
            else configured[selected.label] = handleConfigured(validation.values);
            setIsConfigured(configured);
            if (selectedApp) setSelectedApp('');
            else setSelected('');
        }
    });

    useEffect(() => {
        confirmValidation.setValues({ ...confirmValidation.values, policyName: validation.values.policyName });
    }, [validation.values.policyName]);

    const prepareApplicationData = () => {
        let selectedApps = [];
        applicationsBackup.forEach((app) => {
            if (app.selected) {
                selectedApps.push({
                    appName: app.name,
                    packageName: app.packagename,
                    version: app.appversion,
                    apkUrl: app.appserverfilename,
                    iconUrl: app.iconserverfilename,
                    managedConfigId: validation.values[app.packagename]?.managedconfigid?.managedconfigid
                });
            }
        });
        return selectedApps;
    };

    const handleSubmit = () => {
        let ontime = '';
        let offtime = '';
        if (validation.values?.powerontime) {
            const date = new Date(validation.values?.powerontime);
            ontime = date.getHours()?.toString()?.padStart(2, '0') + ':' + date.getMinutes()?.toString()?.padStart(2, '0');
        }
        if (validation.values?.shutdowntime) {
            const date = new Date(validation.values?.shutdowntime);
            offtime = date.getHours()?.toString()?.padStart(2, '0') + ':' + date.getMinutes()?.toString()?.padStart(2, '0');
        }
        let dataObj = {
            name: validation.values?.policyName,
            policyType: formTypeAndId.queryType,
            policy: {
                restrictions: !!isConfigured['Restrictions']
                    ? {
                          enabled: !!isConfigured['Restrictions'] ? 'Y' : 'N',
                          restriction: {
                              allowVoiceAssistant: !!validation.values?.allowVoiceAssistant,
                              allowCast: !!validation.values?.allowCast,
                              allowCameraAccess: !!validation.values?.allowCameraAccess,
                              enableBluetooth: !!validation.values?.enableBluetooth,
                              allowUsb: !!validation.values?.allowUsb,
                              allowScreenCapturing: !!validation.values?.allowScreenCapturing,
                              factoryResetDisabled: !!validation.values?.factoryResetDisabled,
                              allowSettingsMenuAccess: !!validation.values?.allowSettingsMenuAccess,
                              allowNotificationBar: !!validation.values?.allowNotificationBar,
                              allowAppInstallation: !!validation.values?.allowAppInstallation,
                              allowAppUninstallation: !!validation.values?.allowAppUninstallation,
                              allowAutomaticAppUpdates: !!validation.values?.allowAutomaticAppUpdates,
                              allowAutomaticDeviceUpdates: !!validation.values?.allowAutomaticDeviceUpdates
                          }
                      }
                    : undefined,
                generalsettings: !!isConfigured['General Settings']
                    ? {
                          enabled: !!isConfigured['General Settings'] ? 'Y' : 'N',
                          wallpaper: validation.values?.wallpaper?.value,
                          enablevolumecontrol: validation.values?.enableVolume,
                          volume: validation.values?.volumeLevel
                      }
                    : undefined,
                wifisettings: !!isConfigured['Wi-Fi Settings']
                    ? {
                          enabled: !!isConfigured['Wi-Fi Settings'] ? 'Y' : 'N',
                          wificonfig: validation.values.wifi
                      }
                    : undefined,
                passwordsettings: !!isConfigured['Password Settings']
                    ? {
                          enabled: !!isConfigured['Password Settings'] ? 'Y' : 'N',
                          passwordtype:
                              validation.values?.passwordConfiguration === 'userChoice'
                                  ? 'PASSWORD_QUALITY_UNSPECIFIED'
                                  : validation.values?.passwordConfiguration === 'complex'
                                  ? 'ALPHANUMERIC'
                                  : validation.values?.passwordType?.value,
                          settings: {
                              minPasswordLength:
                                  validation.values?.passwordConfiguration === 'complex'
                                      ? validation.values?.passwordMinimumLength
                                      : undefined,
                              minLowerCase:
                                  validation.values?.passwordConfiguration === 'complex'
                                      ? validation.values?.passwordMinimumLowerCase
                                      : undefined,
                              maxPasswordHistory:
                                  validation.values?.passwordConfiguration === 'complex' ||
                                  validation.values?.passwordConfiguration === 'simple'
                                      ? validation.values?.passwordHistoryLength?.value
                                      : undefined,
                              expirationTimeOut:
                                  validation.values?.passwordConfiguration === 'complex' ||
                                  validation.values?.passwordConfiguration === 'simple'
                                      ? validation.values?.passwordExpirationTimeout?.value
                                      : undefined,
                              maxInactivityTimeOut:
                                  validation.values?.passwordConfiguration === 'complex' ||
                                  validation.values?.passwordConfiguration === 'simple'
                                      ? validation.values?.maximumInactivityTimeout?.value
                                      : undefined,
                              minSplCharacters:
                                  validation.values?.passwordConfiguration === 'complex'
                                      ? validation.values?.passwordMinimumSymbols
                                      : undefined,
                              minUpperCase:
                                  validation.values?.passwordConfiguration === 'complex'
                                      ? validation.values?.passwordMinimumUpperCase
                                      : undefined,
                              maxFailedAttempts:
                                  validation.values?.passwordConfiguration === 'complex' ||
                                  validation.values?.passwordConfiguration === 'simple'
                                      ? validation.values?.maximumFailedPasswordsForWipe?.value
                                      : undefined,
                              minNumbers:
                                  validation.values?.passwordConfiguration === 'complex'
                                      ? validation.values?.passwordMinimumNumbers
                                      : undefined
                          }
                      }
                    : undefined,
                configuration: !!isConfigured['Configurations']
                    ? {
                          enabled: !!isConfigured['Configurations'] ? 'Y' : 'N',
                          launcher: validation?.values?.launcher?._id
                      }
                    : undefined,
                osupdates: !!isConfigured['Apps & OS Upgrades']
                    ? {
                          enabled: !!isConfigured['ODM Features'] ? 'Y' : 'N',
                          appautoupdatepolicy: validation.values.appAutoUpdatePolicy?.value,
                          systemupdate: validation.values?.systemUpdate?.value,
                          startminutes: validation.values?.startMinutes ? validation.values?.startMinutes : undefined,
                          endminutes: validation.values?.endMinutes ? validation.values?.endMinutes : undefined,
                          freezeperiod: {
                              startdate: validation.values?.startdate,
                              enddate: validation.values?.enddate
                          }
                      }
                    : undefined,
                odmfeatures: !!isConfigured['ODM Features']
                    ? {
                          enabled: !!isConfigured['ODM Features'] ? 'Y' : 'N',
                          enableinputmethod: validation.values?.enableInputMethod,
                          inputmethod: validation.values?.inputMethod?.value,
                          enableSwitchWindow: !!validation.values?.enableSwitchWindow,
                          powercontrol: validation.values?.powerStatus?.value,
                          enableschedulepowercontrol: !!validation.values?.enableSchedulePowerControl,
                          scheduledday: !!validation.values?.enableSchedulePowerControl ? validation.values?.frequency : undefined,
                          ontime: !!validation.values?.enableSchedulePowerControl ? ontime : undefined,
                          offtime: !!validation.values?.enableSchedulePowerControl ? offtime : undefined
                      }
                    : undefined
            },
            apps: prepareApplicationData()
        };
        let apiService;
        if (formType === 'edit') apiService = api.patch(url.POLICIES + '/' + recordID, dataObj, true, domains.ANDROID_V1);
        else apiService = api.create(url.POLICIES, dataObj, true, domains.ANDROID_V1);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.androidTvPolicyUpdated : toastMessages.androidTvPolicyCreated);
                    history.push('/atvpolicies');
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleProfileDetail = (profileObj, index) => {
        profileObj = { ...profileObj, active: true };
        let profileDetailsArr = [];
        profileDetails.map((detail) => {
            if (profileDetails.indexOf(detail) === index) return profileDetailsArr.push(profileObj);
            else return profileDetailsArr.push({ ...detail, active: false });
        });
        setSelected(profileObj);
        setProfileDetails(profileDetailsArr);
    };

    const handleShowOn = (field) => {
        let flag = [];
        field?.showOn?.forEach((showOn) => {
            flag.push(
                showOn?.type === 'select'
                    ? validation.values[showOn.key]?.[showOn.check] === showOn.value
                    : validation.values[showOn.key] === showOn.value
            );
        });
        return field.showCondition === 'OR' ? flag.some((val) => val === true) : flag.every((val) => val === true);
    };

    const handleAppSelection = (e, app) => {
        e.stopPropagation();
        let apps = JSON.parse(JSON.stringify(applicationsBackup));
        apps.forEach((appObj) => {
            if (appObj.packagename === app.packagename) {
                appObj.selected = e.target.checked;
            } else if (policyType === 'SA') appObj.selected = false;
        });
        let selectedApps = apps.filter((appObj) => appObj.selected);
        let unselectedApps = apps.filter((appObj) => !appObj.selected);
        setApplicationsBackup(apps);
        setApplications(selectedApps.concat(unselectedApps));
    };

    const handleAppSelect = (e, app) => {
        if (!app.selected) {
            toast.error(toastMessages.selectCheckbox);
            return;
        }
        setSelectedApp(app);
    };

    const handleField = (fields) => {
        return (
            <Col sm={selected?.isArray ? 12 : selected.customCol ? 8 : 7}>
                {fields?.map(
                    (field, index) =>
                        (field.showOn ? handleShowOn(field) : true) && (
                            <Row key={index}>
                                <DynamicComponents
                                    field={field}
                                    index={index}
                                    formType={formType}
                                    isAddArray={isAddArray}
                                    setIsAddArray={setIsAddArray}
                                    validation={validation}
                                    wallpapers={wallpapers}
                                    geofences={geofences}
                                    selected={selected}
                                    applications={applications}
                                    handleAppSelection={handleAppSelection}
                                    launchers={launchers}
                                    policyType={policyType}
                                    selectedApp={selectedApp}
                                    handleAppSelect={handleAppSelect}
                                    managedConfigs={managedConfigs}
                                />
                            </Row>
                        )
                )}
            </Col>
        );
    };

    const handleBack = () => {
        if (selectedApp) {
            setSelectedApp('');
        } else setSelected('');
        setIsAddArray(false);
        validation.setValues({ ...validation.values, name: '', SSID: '', securitytype: '', hideSSID: false });
        validation.setValues({ ...confirmValidation.values });
    };

    const handleCancel = () => {
        if (selectedApp) {
            setSelectedApp('');
            validation.setValues({ ...confirmValidation.values });
        } else if (selected !== '') {
            if (selected?.key === 'apps') setApplications(confirmApps);
            setSelected('');
            validation.setValues({ ...confirmValidation.values });
        } else history.push('/atvpolicies');
    };

    const saveDisabled = () => {
        let flag = [];
        if (selected)
            selected.fields?.forEach((field) => {
                let subFlag = [];
                field.showOn?.forEach((showOn) => {
                    subFlag.push(
                        showOn.type === 'select'
                            ? validation.values[showOn.key]?.[showOn.check] === showOn.value
                            : validation.values[showOn.key] === showOn.value
                    );
                });
                flag.push(
                    (field.showCondition === 'OR' ? subFlag.some((val) => val === true) : subFlag.every((val) => val === true))
                        ? field.mandatory
                            ? field.checkLength
                                ? validation.values[field.value]?.length > 0
                                : field.inputType === 'DoubleDatePicker'
                                ? !!validation.values[field.value1] && !!validation.values[field.value2]
                                : !!validation.values[field.value]
                            : true
                        : true
                );
            });
        flag.push((validation?.values?.policyName || '')?.length < 3 ? false : true);
        return flag.includes(false);
    };

    const handleDeleteConfirm = () => {
        let obj = JSON.parse(JSON.stringify(validation.values));
        deletingProfile?.fields?.forEach((field) => {
            if (field.inputType === 'array') obj[field.value] = [];
            else if (field.inputType === 'DoubleDatePicker') {
                obj[field.value1] = '';
                obj[field.value2] = '';
            } else if (field.inputType === 'input' || field.inputType === 'select') obj[field.value] = '';
            else if (field.inputType === 'checkbox' || field.inputType === 'toggle') obj[field.value] = false;
            else if (field.inputType === 'radio') obj[field.value] = field.radios?.[0]?.val;
            else if (field.inputType === 'slider') obj[field.value] = field.max;
        });
        validation.setValues({ ...obj });
        setIsConfigured({ ...isConfigured, [deletingProfile.label]: false });
        setDeleteModal(false);
    };

    const handleDeleteConfig = (e, profile) => {
        e.stopPropagation();
        setDeletingProfile(profile);
        setDeleteModal(true);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to clear the configuration for {deletingProfile.label}?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={deletingProfile.icon} alt="deleteGroup" width={60} height={60} />
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={`${formType === 'view' ? 'View Policy' : formType === 'edit' ? 'Edit Policy' : 'Add Policy'}`}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        customBack={selected !== '' ? true : false}
                        backLink={selected === '' ? 'atvpolicies' : handleBack}
                    />
                    <Card>
                        <CardHeader>
                            {selected === '' ? (
                                <>
                                    <div className="fw-semibold fs-16 mb-1">{`Policy (${
                                        policyType === 'SA' ? 'Single App' : 'Multi App'
                                    })`}</div>
                                    <div className="fs-13">
                                        Define and apply policies to manage Android TV settings and usage restrictions. Customize these
                                        policies to align with your organization’s needs, ensuring device security, compliance and optimal
                                        performance.
                                    </div>
                                    <Row className="mt-2">
                                        <Col sm="2" className="d-flex align-items-center">
                                            Policy Name
                                            {formType !== 'view' ? (
                                                <span className="red-color ps-1">*</span>
                                            ) : (
                                                <span className="ps-1">:</span>
                                            )}
                                        </Col>
                                        <Col>
                                            {formType !== 'view' ? (
                                                <div className="w-30">
                                                    <Input
                                                        name="policyName"
                                                        id="policyName"
                                                        className="form-control"
                                                        placeholder="Enter Configuration Name"
                                                        type="text"
                                                        maxLength="30"
                                                        validate={{ required: { value: true } }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values['policyName'] || ''}
                                                        invalid={
                                                            validation.touched['policyName'] && validation.errors['policyName']
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            ) : validation?.values['policyName'] ? (
                                                <span className="ps-1">{validation?.values['policyName']}</span>
                                            ) : (
                                                '–'
                                            )}
                                            {validation.touched['policyName'] && validation.errors['policyName'] ? (
                                                <p className="m-0 mt-2 text-danger">{validation.errors['policyName']}</p>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <div className="fw-semibold fs-16 mb-1">{selected.label}</div>
                                    <div>{selected.helpText?.[policyType] ? selected?.helpText?.[policyType] : selected?.helpText}</div>
                                </>
                            )}
                        </CardHeader>
                        <CardBody className={`${(validation?.values?.policyName || '')?.length < 3 ? 'mask pe-none' : ''}`}>
                            {selected === '' && (
                                <Row>
                                    {profileDetails.map((profile, index) => (
                                        <Col xs={6} sm={3} md={4} lg={''} xl={''} className="min-width-per-20 flex-grow-0" key={index}>
                                            <Card
                                                className="shadow-card cursor-pointer card-height-100"
                                                onClick={() => handleProfileDetail(profile, index)}
                                            >
                                                <CardBody>
                                                    {isConfigured[profile.label] &&
                                                        formType !== 'view' &&
                                                        profile.label !== 'Restrictions' && (
                                                            <span
                                                                className="text-danger fs-12 text-decoration-underline curosr-pointer position-absolute end-10 top-5 fw-medium"
                                                                onClick={(e) => handleDeleteConfig(e, profile)}
                                                            >
                                                                Clear
                                                            </span>
                                                        )}
                                                    <div className="d-flex align-items-center justify-content-center flex-column gap-2">
                                                        <img src={profile.icon} alt={profile.label} height={30} />
                                                        <div className="fs-14 fw-medium text-center d-flex align-items-center gap-1">
                                                            {profile.label}
                                                        </div>
                                                        <div
                                                            className={`${
                                                                isConfigured[profile.label] ? 'text-success' : 'text-muted'
                                                            } d-flex align-items-center gap-2`}
                                                        >
                                                            <i
                                                                className={`${
                                                                    isConfigured[profile.label]
                                                                        ? 'ri-checkbox-circle-fill'
                                                                        : 'ri-information-line'
                                                                }`}
                                                            />
                                                            <span className="fs-12 text-center">
                                                                {isConfigured[profile.label] ? 'Configured' : 'Not Configured'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            )}
                            {selected !== '' && <Row className="px-0 gap-3">{handleField(selected.fields)}</Row>}
                        </CardBody>
                        <CardFooter>
                            <div className="gap-2 d-flex justify-content-end">
                                <Button type="button" className="btn btn-light" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                {formType !== 'view' && (
                                    <Button
                                        size="md"
                                        type="button"
                                        color="primary"
                                        disabled={saveDisabled()}
                                        onClick={selected !== '' ? confirmValidation.handleSubmit : () => handleSubmit()}
                                    >
                                        {formType === 'edit' && selected === '' ? 'Update' : 'Save'}
                                    </Button>
                                )}
                            </div>
                        </CardFooter>
                    </Card>
                </Container>
                <DeleteModal
                    hideIcon={true}
                    show={deleteModal}
                    hideDeleteMessage={true}
                    message={message()}
                    confirmText={'Yes, Delete it!'}
                    onDeleteClick={handleDeleteConfirm}
                    onCloseClick={() => {
                        setDeletingProfile('');
                        setDeleteModal(false);
                    }}
                    deleteMessage={'Are you sure you want to delete this configuration ?'}
                />
            </div>
        </React.Fragment>
    );
};

export default AddAndroidTVPolicies;
