import EnrollCode from '../../../assets/images/tv/svg/EnrollCode.svg';

export const expirationOptions = [
    { label: '15 Days', value: 15 },
    { label: '1 Month', value: 30 },
    { label: '3 Months', value: 90 },
    { label: '6 Months', value: 180 },
    { label: '1 Year', value: 365 }
];

export const securityOptions = [
    {
        label: 'Enrollment without Login',
        val: 'NO_AUTH',
        toolTip: 'Upon enabling this option, user can enrol the device without any authentication.',
        value: 'securityOptions'
    },
    {
        label: 'Login with Authentication Modes',
        val: 'AUTH_MODES',
        toolTip:
            'Upon enabling this option, the end user will be able to login with all the possible login options as defined in the authentication mode.',
        value: 'securityOptions'
    },
    {
        label: 'Login with Static Pin',
        val: 'STATIC_PIN',
        toolTip:
            'A static PIN is a fixed numerical code used for secure authentication. During enrollment, the same PIN is used to verify user identity during authentication.',
        value: 'securityOptions'
    }
];

export const networkOptions = [
    { label: 'Any', value: 'network', val: 'any' },
    { label: 'Specific', value: 'network', val: 'specific' }
];

export const TvEnrollSecurity = [
    { label: 'Configuration Name', inputType: 'input', value: 'name', mandatory: true },
    {
        label: 'Expiration',
        value: 'expiration',
        inputType: 'select',
        customCol: false,
        mandatory: true,
        helpText: 'Choose the duration for which the token remains constant',
        options: expirationOptions
    },
    {
        label: 'Advanced Security Options :',
        inputType: 'radio',
        value: 'securityOptions',
        radios: securityOptions
    },
    {
        inputType: 'input',
        maxLength: 4,
        showKey: 'securityOptions',
        showVal: 'STATIC_PIN',
        placeHolder: 'Static Pin',
        placeholder: 'Custom PIN',
        value: 'pin'
    },
    {
        label: 'Enrollment Conditions :',
        inputType: 'heading',
        mandatory: true
    },
    {
        label: 'Network',
        helpText:
            'Selecting "Any" allows enrollment on any connected network. Selecting "Specific" restricts enrollment to the specified IP address only.',
        value: 'network',
        inputType: 'radio',
        radios: networkOptions,
        align: 'row',
        noBorder: true,
        margintop: true
    },
    {
        inputType: 'tableInput',
        showKey: 'network',
        toastmsg: 'ip address already exists',
        showVal: 'specific',
        val: 'ipaddress',
        placeHolder: 'IP Address',
        value: 'ipAddress'
    }
];

export const enrollOptionsTab = [
    { label: 'Security', accessor: 'security' },
    { label: 'Expiry Date', accessor: 'endDate' }
];

export const EnrollmentProcess = [
    /*
     * {
     *     label: 'QR Code',
     *     helptext: 'Scan the QR code using your device`s camera to enroll Android TV using QR code',
     *     labelCopyIcon: true,
     *     image: LinkImage
     * },
     */
    {
        label: 'Enrollment Code',
        helptext: 'Enter the Enrollment Code in necessary field on the device to enroll device using Enrollment code',
        copyIcon: true,
        image: EnrollCode
    }
    /*
     * {
     *     label: 'URL',
     *     helptext: 'By accessing this link, users can initiate the setup process for enrolling',
     *     copyIcon: true,
     *     image: LinkImage
     * }
     */
];

export const cardDetails = [
    { label: 'Security', accessor: 'security' },
    { label: 'Enrollment Code', accessor: 'enrollmentcode', copyIcon: true }
    /*
     * { label: 'Created By', accessor: 'modifiedby' },
     * { label: 'Created Date', accessor: 'modifiedDate' }
     */
];
