import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { FilePond } from 'react-filepond';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, convertBytesTo, noCaseSensitiveSortBy } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { assignSchema, filesSchema } from '../TableSchema';
import axios from 'axios';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import AnimatedTreeComponent from '../../../Components/Common/AnimatedTreeComponent';
import DeleteModal from '../../../Components/Common/DeleteModal';
import polDelete from '../../../assets/images/common/png/common/policyDelete.png';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';

const Files = () => {
    const api = new APIClient();
    const [loading, setLoading] = useState(false);
    const [offCanvasLoading, setOffCanvasLoading] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [isAssign, setIsAssign] = useState(false);
    const [selectedRow, setSelectedRow] = useState('');
    const [file, setFile] = useState();
    const [mode, setMode] = useState('add');
    const [files, setFiles] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [tableSchema, setTableSchema] = useState([...filesSchema]);
    const [assignTableSchema, setAssignTableSchema] = useState([...assignSchema]);
    const [groups, setGroups] = useState([]);
    const [flatGroups, setFlatGroups] = useState([]);
    const [flatGroupsBackup, setFlatGroupsBackup] = useState([]);
    const [checkedGroups, setCheckedGroups] = useState([]);
    const [groupsBackup, setGroupsBackup] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalAssignRecords, setTotalAssignmentRecords] = useState(0);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [assignmentParams, setAssignmentParams] = useState({ page: 1, size: 10 });
    const urlconf = useEnv();
    let user = AuthUser();
    user = user ? JSON.parse(user) : '';

    useEffect(() => {
        setLoading(true);
        const findPriv = user?.data?.privileges?.find((priv) => priv === priviliges.FILE_EDITOR);
        if (!findPriv) {
            let schema = JSON.parse(JSON.stringify(tableSchema));
            let assignmentSchema = JSON.parse(JSON.stringify(assignTableSchema));
            schema = schema.filter((obj) => obj.Header !== 'Actions');
            assignmentSchema = assignmentSchema.filter((obj) => obj.Header !== 'Actions');
            setTableSchema(schema);
            setAssignTableSchema(assignmentSchema);
        }
        handlePromise(searchParams);
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: (values) => {}
    });

    const setChildGroups = (groupArr, groupsData) => {
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            groupsData.push(group);
            if (group.children) {
                let obj = setChildGroups(group.children, groupsData);
                group.children = obj.children;
                groupsData = [...obj.groupsData];
            }
        });
        return { children: groupArr, groupsData: groupsData };
    };

    const prepareGroups = (tagArr) => {
        let groupsData = [];
        tagArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            groupsData.push(group);
            if (group.children) {
                let obj = setChildGroups(group.children, groupsData);
                group.children = obj.children;
                groupsData = [...obj.groupsData];
            }
        });
        setFlatGroups(groupsData);
        setFlatGroupsBackup(groupsData);
        tagArr = noCaseSensitiveSortBy(tagArr, 'name');
        return tagArr;
    };

    const handlePromise = (params) => {
        const filesPromise = new Promise((resolve, reject) => {
            api.get(url.FILES, params, domains.FILES_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        resolve(resp);
                    } else reject('Files failed');
                })
                .catch((err) => reject(err));
        });

        const groupsPromise = new Promise((resolve, reject) => {
            api.get(url.DEVICE_GROUPS, { page: 1, size: 100 }, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') resolve(resp.data);
                    else reject('Groups failed.');
                })
                .catch((err) => reject(err));
        });

        Promise.allSettled([filesPromise, groupsPromise])
            .then(async (result) => {
                if (result[0].status === 'fulfilled') {
                    fileBinding(result[0].value);
                }
                if (result[1].status === 'fulfilled') {
                    let groupsArr = JSON.parse(JSON.stringify(result[1].value));
                    setGroups(groupsArr);
                    setGroupsBackup(JSON.parse(JSON.stringify(groupsArr)));
                    await prepareGroups(result[1].value);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const fileBinding = (resp) => {
        (resp?.data || [])?.forEach((data) => {
            data.fileSize = convertBytesTo(data.filesize);
        });
        setFiles(resp?.data || []);
        setTotalRecords(resp?.totalRecords);
    };

    const getFiles = (params) => {
        setSearchParams(params);
        api.get(url.FILES, params, domains.FILES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    fileBinding(resp);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const getAssignments = (row, params) => {
        setAssignmentParams(params);
        api.get(url.FILES + '/' + row._id + '/assign', params, domains.FILES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    setAssignments(resp.data || []);
                    setTotalAssignmentRecords(resp.totalRecords);
                }
                setOffCanvasLoading(false);
            })
            .catch((err) => setOffCanvasLoading(false));
    };

    const handleAddNew = () => {
        setMode('add');
        setShowAddModal(true);
    };

    const handleClickView = (row) => {
        setLoading(true);
        api.get(url.FILE_SIGNEDURL + '/' + row.original.serverfilename, '', domains.FILES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    var a = document.createElement('a');
                    a.href = resp.data;
                    a.download = row.original?.filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setLoading(false);
                }
            })
            .catch((_err) => setLoading(false));
    };

    const handleShare = (row) => {
        setSelectedRow(row.original);
        getAssignments(row.original, assignmentParams);
        setShowOffcanvas(true);
    };

    const handleDelete = (row) => {
        setSelectedRow(row.original);
        setDeleteModal(true);
    };

    const handleSave = () => {
        setLoading(true);
        const fileBuffer = file?.[0]?.file;
        api.create(url.FILE_SIGNEDURL + '/' + file?.[0]?.filename, '', false, domains.FILES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    axios({
                        method: 'PUT',
                        data: fileBuffer,
                        'Content-Type': 'application/octet-stream',
                        headers: { Authorization: '' },
                        url: resp.data?.url
                    })
                        .then((result) => {
                            if (result.status === 200) {
                                api.create(
                                    url.FILES,
                                    {
                                        title: validation.values.title,
                                        filename: file?.[0]?.filename,
                                        serverfilename: resp?.data?.serverfilename,
                                        filesize: file?.[0]?.fileSize
                                    },
                                    false,
                                    domains.FILES_V1
                                )
                                    .then((response) => {
                                        response = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(response) : response;
                                        if (response.status === 'success') {
                                            toast.success(toastMessages.fileUploaded);
                                            validation.resetForm();
                                            setFile('');
                                            getFiles(searchParams);
                                        } else setLoading(false);
                                    })
                                    .catch((err) => setLoading(false));
                            } else setLoading(false);
                        })
                        .catch((err) => setLoading(false));
                }
            })
            .catch((err) => setLoading(false));
        setShowAddModal(false);
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        api.delete(url.FILES + '/' + selectedRow?._id, '', domains.FILES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.fileDeleted);
                    getFiles(searchParams);
                } else setLoading(false);
                setDeleteModal(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleShareConfirm = () => {
        setShowOffcanvas(true);
        let assigned = checkedGroups.map((group) => {
            return { type: 'GROUP', ref: group };
        });
        api.create(url.FILES + '/' + selectedRow?._id + '/assign', { assign: assigned }, false, domains.FILES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.fileAssigned);
                    getAssignments(selectedRow, assignmentParams);
                    setInitStates();
                }
            })
            .catch((err) => setOffCanvasLoading(false));
    };

    const handleUnassign = (row) => {
        setOffCanvasLoading(true);
        api.create(url.FILES + '/' + selectedRow?._id + '/assign', { unassign: [row?.original?._id] }, false, domains.FILES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    getAssignments(selectedRow, assignmentParams);
                } else setOffCanvasLoading(false);
            })
            .catch((err) => setOffCanvasLoading(false));
    };

    const onPageChange = (pgNum) => {
        setLoading(true);
        getFiles({ page: pgNum, size: 10 });
    };

    const onAssignmentPageChange = (pgNum) => {
        setOffCanvasLoading(true);
        getAssignments(selectedRow, { page: pgNum, size: 10 });
    };

    const toggle = useCallback(() => {
        setShowOffcanvas(!showOffcanvas);
        setInitStates();
    }, [showOffcanvas]);

    const setInitStates = () => {
        setGroups([...groupsBackup]);
        setFlatGroups(flatGroupsBackup);
        setCheckedGroups([]);
        setIsAssign(false);
    };

    const handleDisableSave = () => {
        return checkedGroups?.length === 0;
    };

    const handleDisableAdd = () => {
        return !(validation.values.title && file?.length > 0);
    };

    const setGroupChecked = (groupsArr, group) => {
        groupsArr.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
            if (groupObj.children) groupObj.children = setGroupChecked(groupObj.children, group);
        });
        return groupsArr;
    };

    const changeGroups = (groupsArr, checked, group) => {
        groupsArr.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
            if (groupObj.children) groupObj.children = setGroupChecked(groupObj.children, group);
        });
        setCheckedGroups(checked);
        setGroups(groupsArr);
    };

    const handleModalBody = () => {
        return (
            <React.Fragment>
                <Row className="mb-2">
                    <Col xs={3} md={3} sm={3} lg={3} xl={3} className="d-flex align-items-center">
                        <Label className="mb-1 fw-medium fs-13 d-flex align-items-center">
                            Name
                            <span className="fw-14 text-danger ms-1">*</span>
                        </Label>
                    </Col>
                    <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                        <div className="input-group">
                            <Input
                                className="form-control"
                                id={'title'}
                                name={'title'}
                                maxLength={30}
                                placeholder={'Enter Name'}
                                value={validation.values?.['title']}
                                onChange={validation.handleChange}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <FilePond
                        name="files"
                        maxFiles={1}
                        allowMultiple={true}
                        files={file}
                        className="filepond filepond-input-multiple"
                        onupdatefiles={(fileItems) => setFile(fileItems)}
                    />
                </Row>
            </React.Fragment>
        );
    };

    const handleOffcanvasBody = () => {
        return (
            <React.Fragment>
                {isAssign ? (
                    <AnimatedTreeComponent groups={groups} flatGroups={flatGroups} changeGroups={changeGroups} />
                ) : (
                    <TableContainer
                        loading={offCanvasLoading}
                        tableHeader={`${selectedRow?.title} - Shared to`}
                        isGlobalFilter={true}
                        addButton={true}
                        largeSearchFilter={true}
                        addButtonText={'Assign New'}
                        SearchFilterWidth={'width-280'}
                        // searchVal={searchVal}
                        columns={assignTableSchema}
                        handleDelete={handleUnassign}
                        onPageChange={onAssignmentPageChange}
                        handleAddNew={() => setIsAssign(true)}
                        totalRecords={totalAssignRecords}
                        data={assignments}
                        editor={priviliges.FILE_EDITOR}
                        reader={priviliges.FILE_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                )}
            </React.Fragment>
        );
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete this file?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={polDelete} alt="deleteFile" width={60} height={60} />
                </div>
                <div>
                    Title: <span className="fw-semibold">{selectedRow?.title}</span>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Files" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Files'}
                        isGlobalFilter={true}
                        addButton={true}
                        largeSearchFilter={true}
                        SearchFilterWidth={'width-280'}
                        // searchVal={searchVal}
                        onPageChange={onPageChange}
                        columns={tableSchema}
                        handleDelete={handleDelete}
                        handleAddNew={handleAddNew}
                        handleClickView={handleClickView}
                        handleShare={handleShare}
                        totalRecords={totalRecords}
                        data={files}
                        editor={priviliges.FILE_EDITOR}
                        reader={priviliges.FILE_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <CommonModal
                        size={'md'}
                        show={showAddModal}
                        modalheader={'Add New File'}
                        disabled={handleDisableAdd()}
                        onCloseClick={() => {
                            setMode('add');
                            setShowAddModal(false);
                            validation.resetForm();
                            setFile('');
                        }}
                        saveText="Save"
                        cancelText="Cancel"
                        handleModalBody={() => handleModalBody()}
                        handleClick={() => handleSave()}
                    />
                    <OffcanvasModal
                        loading={offCanvasLoading}
                        direction="end"
                        toggle={toggle}
                        open={showOffcanvas}
                        hideSaveButton={!isAssign}
                        saveDisabled={handleDisableSave()}
                        handleSaveClick={handleShareConfirm}
                        handleCloseClick={toggle}
                        OffcanvasModalID="shareFile"
                        handleOffcanvasBody={handleOffcanvasBody}
                        modalClassName={'w-40'}
                        offcanvasHeader="File Sharing"
                    />
                    <DeleteModal
                        show={deleteModal}
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={message()}
                        confirmText={'Delete'}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        deleteMessage={'Are you sure you want to delete this App ?'}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Files;
