import React, { useEffect, useState } from 'react';
import Reports from '../../Reports/Reports';
import { reportSchema } from '../../Reports/ReportsSchema';
import { TenantConfig } from '../../../Components/Common/Util';

const TVReports = () => {
    const [schema, setSchema] = useState([...reportSchema]);
    const tenant = TenantConfig();

    useEffect(() => {
        let schemaArr = JSON.parse(JSON.stringify(reportSchema));
        let typeObj = schemaArr.find((obj) => obj.value === 'reportFilter');
        let typeIndex = schemaArr.findIndex((obj) => obj.value === 'reportFilter');
        if (typeObj?.options) typeObj.options = typeObj.options.filter((option) => tenant.TV_REPORTS_CARDS?.includes(option.key));
        schemaArr.splice(typeIndex, 1, typeObj);
        setSchema(schemaArr);
    }, []);

    return <Reports schema={schema} />;
};

export default TVReports;
