import { securityOptions } from '../../../Components/constants/constants';
import GENERAL from '../../../assets/images/tv/png/general.png';
import PASSWORD from '../../../assets/images/tv/png/password.png';
import RESTRICTIONS from '../../../assets/images/tv/png/restriction.png';
import APPS from '../../../assets/images/tv/png/apps.png';
import POWER from '../../../assets/images/tv/png/power.png';
import WIFI from '../../../assets/images/tv/png/wifi.png';
import CONFIGURATIONS from '../../../assets/images/tv/png/configurations.png';
import toastMessages from '../../../common/messages/toastMessages';
import odmFeatures from '../../../assets/images/tv/png/odmfeatures.png';

export const dropdownOptions = {
    maximumFailedPasswordsForWipe: [
        { label: 'Never', value: 0 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 }
    ],
    passwordHistoryLength: [
        { label: 'None', value: 0 },
        { label: 'Last 3 password', value: 3 },
        { label: 'Last 5 password', value: 5 },
        { label: 'Last 10 password', value: 10 }
    ],
    passwordExpirationTimeout: [
        { label: 'Never Expires', value: 0 },
        { label: 'One Month', value: 1 },
        { label: 'Two Months', value: 2 },
        { label: 'Three Months', value: 3 },
        { label: 'Six Months', value: 6 },
        { label: 'One year', value: 12 }
    ],
    maximumInactivityTimeout: [
        { label: 'Never', value: 0 },
        { label: '1 min', value: 1 },
        { label: '2 min', value: 2 },
        { label: '3 min', value: 3 },
        { label: '4 min', value: 4 },
        { label: '5 min', value: 5 },
        { label: '10 min', value: 10 },
        { label: '15 min', value: 15 }
    ]
};

export const TV_SCHEMA = [
    {
        label: 'General Settings',
        icon: GENERAL,
        key: 'general',
        val: 'generalsettings',
        show: ['SA', 'MA', 'WA'],
        active: true,
        helpText: 'Manage essential device configurations to customize the user experience on Android TV.',
        fields: [
            {
                label: 'Wallpaper',
                value: 'wallpaper',
                helpText: 'Set a custom wallpaper for enrolled devices',
                inputType: 'select',
                optionsVal: 'wallpapers',
                show: ['MA', 'WA'],
                clearable: true,
                optionLabel: 'label',
                optionValue: 'value',
                removable: true,
                path: ['generalsettings', 'wallpaper']
            },
            /*
             * {
             *     label: 'Branding/Launcher',
             *     value: 'launcher',
             *     helpText: 'Select the customized interface made in launcher',
             *     inputType: 'select',
             *     optionsVal: 'launcher',
             *     show: ['SA', 'MA', 'WA'],
             *     optionLabel: 'label',
             *     optionValue: 'value',
             *     removable: true
             * },
             */
            {
                label: 'Enable Volume',
                value: 'enableVolume',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Enable Volume to control audio levels for sound output on your device',
                path: ['generalsettings', 'enablevolumecontrol']
            },
            {
                value: 'volumeLevel',
                inputType: 'slider',
                show: ['SA', 'MA', 'WA'],
                helpText:
                    'Enabling this feature allows the admin to manage device volume. This would be the default volume on all devices.',
                step: 1,
                max: 100,
                min: 0,
                conditionalHide: true,
                hideOn: 'value',
                field: 'enableVolume',
                showOn: [{ key: 'enableVolume', value: true }],
                path: ['generalsettings', 'volume']
            }
        ]
    },
    {
        label: 'Restrictions',
        key: 'restrictions',
        icon: RESTRICTIONS,
        val: 'restrictions',
        show: ['SA', 'MA', 'WA'],
        active: false,
        helpText:
            'Apply and manage device usage limitations to ensure compliance with organizational policies and control access to specific functionalities on Android TV',
        fields: [
            {
                label: 'Allow Voice Assistant',
                value: 'allowVoiceAssistant',
                show: ['SA', 'MA', 'WA'],
                inputType: 'toggle',
                helpText: 'Enable access to voice assistant features for hands-free operation',
                path: ['restrictions', 'restriction', 'allowVoiceAssistant']
            },
            {
                label: 'Allow Cast/Mirroring',
                value: 'allowCast',
                show: ['SA', 'MA', 'WA'],
                inputType: 'toggle',
                helpText: 'Permit screen sharing or casting from external devices',
                path: ['restrictions', 'restriction', 'allowCast']
            },
            {
                label: 'Allow Camera Access',
                value: 'allowCameraAccess',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Allow applications to use the device’s camera for capturing images or video',
                path: ['restrictions', 'restriction', 'allowCameraAccess']
            },
            {
                label: 'Enable Bluetooth',
                value: 'enableBluetooth',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Turn on Bluetooth functionality for connecting to other devices wirelessly',
                path: ['restrictions', 'restriction', 'enableBluetooth']
            },
            {
                label: 'Allow USB',
                value: 'allowUsb',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Permit USB connections for file transfers and device management',
                path: ['restrictions', 'restriction', 'allowUsb']
            },
            {
                label: 'Allow Screen Capturing',
                value: 'allowScreenCapturing',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Enable the ability to take screenshots',
                path: ['restrictions', 'restriction', 'allowScreenCapturing']
            },
            {
                label: 'Allow Settings Menu Access',
                value: 'allowSettingsMenuAccess',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Grant users access to the device’s settings menu for configuration changes',
                path: ['restrictions', 'restriction', 'allowSettingsMenuAccess']
            },
            {
                label: 'Allow Factory Reset',
                value: 'factoryResetDisabled',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                path: ['restrictions', 'restriction', 'factoryResetDisabled'],
                helpText: 'Permit the option of factory reset on the device to restore it to its original factory settings'
            },
            {
                label: 'Enable Notification Bar',
                value: 'allowNotificationBar',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Allow the notification bar to display alerts and messages',
                path: ['restrictions', 'restriction', 'allowNotificationBar']
            },
            /*
             * {
             *     label: 'Application Switch Window',
             *     value: 'allowWindowSwitch',
             *     inputType: 'toggle',
             *     show: ['SA', 'MA', 'WA'],
             *     helpText: 'Enable users to switch between applications',
             *     path: ['restrictions', 'restriction', 'allowWindowSwitch']
             * },
             */
            {
                label: 'Allow App Installation',
                value: 'allowAppInstallation',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Permit the installation of new applications on the device',
                path: ['restrictions', 'restriction', 'allowAppInstallation']
            },
            {
                label: 'Allow App Uninstallation',
                value: 'allowAppUninstallation',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Enable users to remove applications from the device',
                path: ['restrictions', 'restriction', 'allowAppUninstallation']
            },
            {
                label: 'Allow Automatic App Updates',
                value: 'allowAutomaticAppUpdates',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Allow applications to update on the device automatically',
                path: ['restrictions', 'restriction', 'allowAutomaticAppUpdates']
            },
            {
                label: 'Allow Automatic Device Updates',
                value: 'allowAutomaticDeviceUpdates',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Allow OS to update on the device automatically',
                path: ['restrictions', 'restriction', 'allowAutomaticDeviceUpdates']
            }
        ]
    },
    {
        label: 'Password Settings',
        key: 'password',
        icon: PASSWORD,
        val: 'passwordsettings',
        show: ['SA', 'MA', 'WA'],
        active: false,
        customCol: true,
        helpText: 'Define and enforce password configurations to enhance device security and regulate access controls.',
        fields: [
            {
                label: 'Password Configuration',
                helpText: 'Configure the password settings as per organization security standards',
                inputType: 'radio',
                value: 'passwordConfiguration',
                show: ['SA', 'MA', 'WA'],
                radios: [
                    { label: 'User Choice', val: 'userChoice' },
                    { label: 'Simple', val: 'simple' },
                    { label: 'Complex', val: 'complex' }
                ],
                clearFields: [
                    'passwordMinimumLength',
                    'passwordMinimumSymbols',
                    'passwordMinimumLowerCase',
                    'passwordMinimumUpperCase',
                    'passwordType',
                    'maximumFailedPasswordsForWipe',
                    'passwordHistoryLength',
                    'passwordExpirationTimeout',
                    'maximumInactivityTimeout',
                    'unifiedLockSettings'
                ]
            },
            {
                inputType: 'note',
                note: 'Users can choose to enable a password on their device. If they choose to set a password, they can select their preferred type.',
                customNote: true,
                radioKey: 'passwordConfiguration',
                radioVal: 'userChoice',
                show: ['SA', 'MA', 'WA'],
                noteColor: 'info'
            },
            {
                label: 'Password Type',
                value: 'passwordType',
                inputType: 'select',
                showOn: [{ key: 'passwordConfiguration', value: 'simple' }],
                show: ['SA', 'MA', 'WA'],
                custom: true,
                mandatory: true,
                helpText: 'Specify the type of password that the user can set on the device',
                options: [
                    { label: 'Numeric', value: 'NUMERIC' },
                    { label: 'Alphabet', value: 'ALPHABETIC' }
                ]
            },
            {
                label: 'Password Type',
                value: 'passwordType',
                inputType: 'select',
                showOn: [{ key: 'passwordConfiguration', value: 'complex' }],
                show: ['SA', 'MA', 'WA'],
                disable: true,
                custom: true,
                mandatory: true,
                helpText: 'Specify the type of password that user can set on the device',
                notes: 'It requires a combination of letters, numbers and special characters for enhanced security',
                options: [{ label: 'Alphanumeric', value: 'ALPHANUMERIC' }]
            },
            {
                label: 'Minimum Password Length',
                value: 'passwordMinimumLength',
                inputType: 'input',
                show: ['SA', 'MA', 'WA'],
                type: 'text',
                custom: true,
                mandatory: true,
                regex: true,
                min: 1,
                isNumber: true,
                helpText: 'Specify the minimum length of password a user can set on the device',
                showOn: [{ key: 'passwordConfiguration', value: 'complex' }],
                path: ['passwordsettings', 'settings', 'minPasswordLength']
            },
            {
                label: 'Minimum number of Special Characters',
                value: 'passwordMinimumSymbols',
                inputType: 'input',
                show: ['SA', 'MA', 'WA'],
                type: 'text',
                custom: true,
                regex: true,
                isNumber: true,
                checkSum: ['passwordMinimumLowerCase', 'passwordMinimumUpperCase', 'passwordMinimumNumbers'],
                dependent: 'passwordMinimumLength',
                helpText: 'Specify the minimum number of special characters a password should contain',
                mandatory: true,
                showOn: [{ key: 'passwordConfiguration', value: 'complex' }],
                path: ['passwordsettings', 'settings', 'minSplCharacters']
            },
            {
                label: 'Minimum Number of Lower-Case Allowed',
                value: 'passwordMinimumLowerCase',
                inputType: 'input',
                type: 'text',
                show: ['SA', 'MA', 'WA'],
                custom: true,
                mandatory: true,
                regex: true,
                isNumber: true,
                dependent: 'passwordMinimumLength',
                checkSum: ['passwordMinimumSymbols', 'passwordMinimumUpperCase', 'passwordMinimumNumbers'],
                helpText: 'Specify the minimum number of lowercase letters a password set on the device must contain',
                showOn: [{ key: 'passwordConfiguration', value: 'complex' }],
                path: ['passwordsettings', 'settings', 'minLowerCase']
            },
            {
                label: 'Minimum Number of Upper-Case Allowed',
                value: 'passwordMinimumUpperCase',
                inputType: 'input',
                type: 'text',
                show: ['SA', 'MA', 'WA'],
                custom: true,
                mandatory: true,
                regex: true,
                isNumber: true,
                dependent: 'passwordMinimumLength',
                helpText: 'Specify the minimum number of uppercase letters a password set on the device must contain',
                showOn: [{ key: 'passwordConfiguration', value: 'complex' }],
                checkSum: ['passwordMinimumSymbols', 'passwordMinimumLowerCase', 'passwordMinimumNumbers'],
                path: ['passwordsettings', 'settings', 'minUpperCase']
            },
            {
                label: 'Minimum Number of Numbers Allowed',
                value: 'passwordMinimumNumbers',
                inputType: 'input',
                type: 'text',
                show: ['SA', 'MA', 'WA'],
                custom: true,
                mandatory: true,
                isNumber: true,
                regex: true,
                dependent: 'passwordMinimumLength',
                checkSum: ['passwordMinimumSymbols', 'passwordMinimumLowerCase', 'passwordMinimumUpperCase'],
                helpText: 'Specify the minimum number of numeric characters in an alphanumeric password to be set on the device',
                showOn: [{ key: 'passwordConfiguration', value: 'complex' }],
                path: ['passwordsettings', 'settings', 'minNumbers']
            },
            {
                label: 'Maximum Failed attempts for Wipe',
                value: 'maximumFailedPasswordsForWipe',
                inputType: 'select',
                mandatory: true,
                show: ['SA', 'MA', 'WA'],
                custom: true,
                showOn: [
                    { key: 'passwordConfiguration', value: 'complex' },
                    { key: 'passwordConfiguration', value: 'simple' }
                ],
                showCondition: 'OR',
                helpText:
                    'Secure the device by automatically wiping it after a specified number of unsuccessful attempts to prevent unauthorized access.',
                options: dropdownOptions.maximumFailedPasswordsForWipe,
                path: ['passwordsettings', 'settings', 'maxFailedAttempts']
            },
            {
                label: 'Maximum Password History',
                value: 'passwordHistoryLength',
                inputType: 'select',
                custom: true,
                mandatory: true,
                show: ['SA', 'MA', 'WA'],
                showOn: [
                    { key: 'passwordConfiguration', value: 'simple' },
                    { key: 'passwordConfiguration', value: 'complex' }
                ],
                showCondition: 'OR',
                helpText: 'Choose the maximum number of previous passcodes stored in history that an end user cannot reuse.',
                options: dropdownOptions.passwordHistoryLength,
                path: ['passwordsettings', 'settings', 'maxPasswordHistory']
            },
            {
                label: 'Password Expiration Timeout',
                value: 'passwordExpirationTimeout',
                inputType: 'select',
                show: ['SA', 'MA', 'WA'],
                custom: true,
                showOn: [
                    { key: 'passwordConfiguration', value: 'simple' },
                    { key: 'passwordConfiguration', value: 'complex' }
                ],
                mandatory: true,
                showCondition: 'OR',
                helpText: 'Choose the maximum duration for which the passcode remains active.',
                options: dropdownOptions.passwordExpirationTimeout,
                path: ['passwordsettings', 'settings', 'expirationTimeOut']
            },
            {
                label: 'Maxmimum Inactivity Timeout',
                value: 'maximumInactivityTimeout',
                inputType: 'select',
                custom: true,
                show: ['SA', 'MA', 'WA'],
                showOn: [
                    { key: 'passwordConfiguration', value: 'simple' },
                    { key: 'passwordConfiguration', value: 'complex' }
                ],
                mandatory: true,
                showCondition: 'OR',
                helpText: 'Choose the maximum period of inactivity before the device’s screen locks automatically.',
                options: dropdownOptions.maximumInactivityTimeout,
                path: ['passwordsettings', 'settings', 'maxInactivityTimeOut']
            }
        ]
    },
    {
        label: 'Wi-Fi Settings',
        key: 'wifi',
        show: ['SA', 'MA', 'WA'],
        val: 'wifisettings',
        icon: WIFI,
        isArray: true,
        helpText: 'Manage and configure network connectivity options to control device access and ensure secure connections on Android TV',
        fields: [
            {
                addButton: '+ Add Wi-Fi',
                inputType: 'array',
                value: 'wifi',
                unique: 'ssid',
                offCanvasAdd: true,
                header: 'Wi-Fi',
                show: ['SA', 'MA', 'WA'],
                toastMsg: toastMessages.existingSSID,
                cardFields: [
                    { label: 'Name', value: 'name' },
                    { label: 'SSID', value: 'ssid' },
                    { label: 'Security Type', value: 'securityType', type: 'object', key: 'value' }
                ],
                arrayFields: [
                    {
                        label: 'Wi-Fi Name',
                        value: 'name',
                        inputType: 'input',
                        type: 'text',
                        mandatory: true,
                        noMargin: true,
                        helpText: 'Enter the Wi-Fi Name'
                    },
                    {
                        label: 'Service Set Identifier(SSID)',
                        value: 'ssid',
                        inputType: 'input',
                        mandatory: true,
                        type: 'text',
                        noMargin: true,
                        helpText: 'Enter the SSID'
                    },
                    {
                        label: 'Security',
                        value: 'securitytype',
                        inputType: 'select',
                        mandatory: true,
                        helpText: 'Select the security type',
                        options: securityOptions
                    },
                    {
                        label: 'Password',
                        value: 'password',
                        inputType: 'input',
                        type: 'text',
                        helpText: 'Enter Password',
                        hideOn: 'securitytype',
                        hideVal: 'None'
                    },
                    {
                        label: 'Hidden SSID',
                        value: 'hiddenssid',
                        inputType: 'checkbox',
                        customCheckbox: true,
                        helpText: 'Control if SSID can be display on the device or not'
                    }
                ],
                path: ['wifisettings', 'wificonfig']
            }
        ],
        active: false
    },
    {
        label: 'Configurations',
        key: 'configurations',
        icon: CONFIGURATIONS,
        active: false,
        val: 'configuration',
        show: ['SA', 'MA', 'WA'],
        helpText:
            'Manage essential configurations required for Android TV as per organization requirements. Choose respective settings that are configured in Configurations tab.',
        fields: [
            {
                label: 'Launcher',
                value: 'launcher',
                helpText: 'Select the launcher from the dropdown',
                inputType: 'select',
                optionLabel: 'name',
                optionsVal: 'launcher',
                optionValue: '_id',
                show: ['SA', 'MA', 'WA'],
                path: ['configuration', 'launcher']
            }
        ]
    },
    {
        label: 'Apps & OS Upgrades',
        key: 'apps_os_upgrades',
        show: ['SA', 'MA', 'WA'],
        icon: odmFeatures,
        active: false,
        customCol: true,
        val: 'osupdates',
        helpText:
            'ODM (Original Device Manufacturer) Features give administrators access to special settings and functions unique to each device manufacturer. These tools help organizations boost device performance, enhance security, and improve usability by making the most of manufacturer-provided options.',
        fields: [
            {
                label: 'App Auto Update Policy',
                value: 'appAutoUpdatePolicy',
                helpText: 'Controls how and when applications on a device needs to be updated automatically.',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'APP_AUTO_UPDATE_POLICY_UNSPECIFIED' },
                    { label: 'User Choice', value: 'CHOICE_TO_THE_USER' },
                    { label: 'Never', value: 'NEVER' },
                    { label: 'Wi-Fi Only', value: 'WIFI_ONLY' },
                    { label: 'Always', value: 'ALWAYS' }
                ],
                show: ['SA', 'MA', 'WA'],
                path: ['osupdates', 'appautoupdatepolicy']
            },
            {
                label: 'System Update Type',
                value: 'systemUpdate',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'SYSTEM_UPDATE_TYPE_UNSPECIFIED' },
                    { label: 'Automatic', value: 'AUTOMATIC' },
                    { label: 'Windowed', value: 'WINDOWED' },
                    { label: 'Postpone', value: 'POSTPONE' }
                ],
                helpText: 'Controls how OS updates are handled and applied on the device.',
                show: ['SA', 'MA', 'WA'],
                path: ['osupdates', 'systemupdate']
            },
            {
                label: 'Start Minutes',
                value: 'startMinutes',
                inputType: 'input',
                mandatory: true,
                showOn: [{ key: 'systemUpdate', value: 'WINDOWED', type: 'select', check: 'value' }],
                defaultVal: '1',
                type: 'number',
                minVal: 1,
                maxVal: 60,
                helpText:
                    'Start Time refers to the designated time at which the OS is permitted to install update, measured as the number of minutes after midnight in the devices’ local time. The value must be in between 0 and 1439.',
                show: ['SA', 'MA', 'WA'],
                path: ['osupdates', 'startminutes']
            },
            {
                label: 'End Minutes',
                value: 'endMinutes',
                inputType: 'input',
                defaultVal: '60',
                type: 'number',
                minVal: 1,
                maxVal: 60,
                helpText:
                    'End Time specifies the cut-off point for when updates can be applied. The value must be in between 0 and 1439. Ensure that the value in End Minutes is higher than the value in Start Minutes.',
                mandatory: true,
                showOn: [{ key: 'systemUpdate', value: 'WINDOWED', type: 'select', check: 'value' }],
                show: ['SA', 'MA', 'WA'],
                path: ['osupdates', 'endminutes']
            },
            {
                label: 'Freeze Period',
                value1: 'startdate',
                value2: 'enddate',
                mandatory: true,
                inputType: 'DoubleDatePicker',
                placeholder1: 'Select Start Date',
                placeholder2: 'Select End Date',
                helpText: 'Refers to a specified dates during which no new updates, patches, or modifications are applied to the system.',
                showOn: [{ key: 'systemUpdate', value: 'WINDOWED', type: 'select', check: 'value' }],
                custom: true,
                show: ['SA', 'MA', 'WA'],
                path: ['osupdates', 'freezeperiod']
            }
        ]
    },
    {
        label: 'Applications',
        key: 'apps',
        isArray: true,
        show: ['SA', 'MA', 'WA'],
        icon: APPS,
        active: false,
        helpText: {
            MA: 'Use this section to easily install and uninstall applications on Android TV devices remotely. It allows admins to manage app availability, ensuring users have access to the necessary applications while maintaining control over the device environment.',
            SA: 'Use this section to remotely select and configure a Single App Mode on Android TV devices. It allows admin to lock the device to a specific application, ensuring user access only to a designated app, while maintaining full control over the device environment.'
        },
        fields: [{ inputType: 'apps', show: ['SA', 'MA', 'WA'] }],
        subFields: [
            {
                label: 'Managed Configuration',
                value: 'managedconfigid',
                isApplicationObj: true,
                inputType: 'select',
                helpText: 'Select',
                optionsVal: 'managedConfigs',
                optionLabel: 'name',
                optionValue: 'managedconfigid',
                show: ['SA', 'MA', 'WA']
            }
        ]
    },
    {
        label: 'ODM Features',
        key: 'odmfeatures',
        show: ['SA', 'MA', 'WA'],
        icon: odmFeatures,
        active: false,
        customCol: true,
        val: 'odmfeatures',
        helpText:
            'ODM (Original Device Manufacturer) Features give administrators access to special settings and functions unique to each device manufacturer. These tools help organizations boost device performance, enhance security, and improve usability by making the most of manufacturer-provided options.',
        fields: [
            {
                label: 'Enable Input Source',
                value: 'enableInputMethod',
                show: ['SA', 'MA', 'WA'],
                inputType: 'toggle',
                helpText: 'Enable control over device input sources',
                path: ['odmfeatures', 'enableinputmethod']
            },
            {
                label: 'Input Source',
                value: 'inputMethod',
                inputType: 'select',
                show: ['SA', 'MA', 'WA'],
                mandatory: true,
                helpText: 'Choose the default input source for the device',
                options: [
                    { label: 'HDMI1', value: 'HDMI1' },
                    { label: 'HDMI2', value: 'HDMI2' },
                    { label: 'HDMI3', value: 'HDMI3' },
                    { label: 'HDMI4', value: 'HDMI4' }
                ],
                conditionalHide: true,
                placeholder: 'Select Input Source',
                removable: true,
                showOn: [{ key: 'enableInputMethod', value: true }],
                path: ['odmfeatures', 'inputmethod']
            },
            {
                label: 'Enable Switch Windows',
                value: 'enableSwitchWindow',
                inputType: 'toggle',
                show: ['MA'],
                helpText: 'Enable users to switch between applications',
                path: ['odmfeatures', 'enableSwitchWindow']
            },
            {
                label: 'Power Status',
                value: 'powerStatus',
                inputType: 'select',
                show: ['SA', 'MA', 'WA'],
                options: [
                    { label: 'ON', value: 'ON' },
                    { label: 'OFF', value: 'OFF' }
                ],
                helpText: 'This feature enables the admin to power on or off all the devices linked to the policy',
                path: ['odmfeatures', 'powercontrol']
            },
            {
                label: 'Enable Schedule Power Controls',
                value: 'enableSchedulePowerControl',
                inputType: 'toggle',
                show: ['SA', 'MA', 'WA'],
                helpText: 'This feature enables admin to schedule power controls to automatically manage the IFP’s power ON and OFF times',
                path: ['odmfeatures', 'enableschedulepowercontrol']
            },
            {
                label: 'Frequency',
                value: 'frequency',
                show: ['SA', 'MA', 'WA'],
                inputType: 'checkbox',
                helpText: 'Choose the frequency for scheduling power controls ',
                mandatory: true,
                checkLength: true,
                showOn: [{ key: 'enableSchedulePowerControl', value: true }],
                checkboxes: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
                path: ['odmfeatures', 'scheduledday']
            },
            {
                label: 'Power on Time',
                value: 'powerontime',
                type: 'time',
                icon: 'ri-time-line',
                show: ['SA', 'MA', 'WA'],
                inputType: 'timePicker',
                mandatory: true,
                conditionalHide: true,
                helpText: 'Specify the time for device to be turned ON automatically',
                hideOn: 'showon',
                showOn: [{ key: 'enableSchedulePowerControl', value: true }],
                options: {
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h:i K'
                },
                path: ['odmfeatures', 'ontime']
            },
            {
                label: 'Shutdown Time',
                value: 'shutdowntime',
                type: 'time',
                icon: 'ri-time-line',
                inputType: 'timePicker',
                show: ['SA', 'MA', 'WA'],
                helpText: 'Specify the time for device to be turned OFF automatically',
                mandatory: true,
                conditionalHide: true,
                hideOn: 'showon',
                showOn: [{ key: 'enableSchedulePowerControl', value: true }],
                alertMsg: 'Shutdown time should be greater than power on time',
                options: {
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h:i K',
                    minuteIncrement: 5
                },
                path: ['odmfeatures', 'offtime']
            }
        ]
    }
];

export const WIFI_FIELDS_SCHEMA = [
    {
        label: 'Wi-Fi Name',
        value: 'name',
        inputType: 'input',
        type: 'text',
        noMargin: true,
        helpText: 'Enter the Wi-Fi Name'
    },
    {
        label: 'Service Set Identifier(SSID)',
        value: 'SSID',
        inputType: 'input',
        type: 'text',
        noMargin: true,
        helpText: 'Enter the SSID'
    },
    {
        label: 'Security',
        value: 'security',
        inputType: 'select',
        helpText: 'Select the security type',
        options: securityOptions
    },
    {
        label: 'Hidden SSID',
        value: 'hideSSID',
        inputType: 'checkbox',
        customCheckbox: true,
        helpText: 'Control if SSID can be display on the device or not'
    }
];
