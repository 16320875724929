import React from 'react';
import info from '../../assets/images/common/svg/common/information.svg';
import { UncontrolledTooltip } from 'reactstrap';
import { Store } from '../../store/store';
import EllipsisToolTip from './Tooltip/Tooltip';
import hexToRgba from 'hex-to-rgba';
import CryptoJS from 'crypto-js';
import { CONSTANTS, days, daysNumeric, priviliges, timeFormat } from '../constants/constants';
import { tenantConfig } from '../../store/TenantConfig/action';
import { enterpriseAccount } from '../../store/Commons/action';
import * as urlHelper from '../../helpers/url_helper';
import * as domainHelper from '../../helpers/domain_helper';
import { APIClient } from '../../helpers/api_helper';
import { useEnv } from '../../envContext';

export const getDate = (date, dateWithoutTime, includeSeconds) => {
    const sDate = new Date();
    if (!date) {
        date = new Date(sDate);
    }
    date = new Date(date);
    let DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    let DATE_OPTIONS_NO_TIME = { year: 'numeric', month: 'short', day: 'numeric' };
    let DATE_OPTIONS_INCLUDE_SECONDS = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    return date.toLocaleDateString(
        'en-US',
        dateWithoutTime ? DATE_OPTIONS_NO_TIME : includeSeconds ? DATE_OPTIONS_INCLUDE_SECONDS : DATE_OPTIONS
    );
};

export const getTime = (d) => {
    let date = new Date(d);
    return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
};

export const convertBytesTo = (bytes) => {
    let decimals = 0;
    if (!+bytes) return '0 Bytes';

    const k = 1023;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const convertDataToBytes = (size) => {
    let unit = size.substring(size.length - 2, size.length);
    let amount = parseInt(size.substring(0, size.length - 2));
    let valueInBytes = 0;
    switch (unit?.toUpperCase()) {
        case 'KB':
            valueInBytes = amount * 1024;
            break;
        case 'MB':
            valueInBytes = amount * 1024 * 1024;
            break;
        case 'GB':
            valueInBytes = amount * 1024 * 1024 * 1024;
            break;
        case 'TB':
            valueInBytes = amount * 1024 * 1024 * 1024 * 1024;
            break;
        default:
            break;
    }
    return valueInBytes;
};

export const convertSecondsTo = (time, secs) => {
    time = secs ? time : time / 1000;
    const SECONDS_IN_A_MINUTE = 60;
    const SECONDS_IN_AN_HOUR = 3600;
    const SECONDS_IN_A_DAY = 86400;

    // Calculate days
    const noofdays = Math.floor(time / SECONDS_IN_A_DAY);
    let remainingSeconds = time % SECONDS_IN_A_DAY;

    // Calculate hours
    const hours = Math.floor(remainingSeconds / SECONDS_IN_AN_HOUR);
    remainingSeconds %= SECONDS_IN_AN_HOUR;

    // Calculate minutes
    const minutes = Math.floor(remainingSeconds / SECONDS_IN_A_MINUTE);
    remainingSeconds %= SECONDS_IN_A_MINUTE;

    return {
        days: noofdays,
        hours: hours,
        minutes: minutes,
        seconds: remainingSeconds
    };
};

export const formatDate = (noofdays, hours, minutes, seconds, className) => {
    let formattedResult = [];
    if (noofdays > 0 || formattedResult?.length > 0) {
        // Include days if years or months are included
        formattedResult.push(
            <div key="days">
                <span>{String(noofdays).padStart(2, '0')}</span>{' '}
                <span className={`${className ? className : 'text-muted'} ms-1`}>{noofdays > 1 ? 'days' : 'day'}</span>
            </div>
        );
    }
    if (hours > 0 || formattedResult?.length > 0) {
        // Include hours if years, months, or days are included
        formattedResult.push(
            <div className="ms-1" key={'hours'}>
                <span>{String(hours).padStart(2, '0')}</span>
                <span className={`${className ? className : 'text-muted'} ms-1`}> {hours > 1 ? 'hrs' : 'hr'}</span>
            </div>
        );
    }
    if (minutes > 0 || formattedResult?.length > 0) {
        // Include minutes if years, months, days, or hours are included
        formattedResult.push(
            <div className="ms-1" key={'minutes'}>
                <span>{String(minutes).padStart(2, '0')}</span>{' '}
                <span className={`${className ? className : 'text-muted'} ms-1`}>{minutes > 1 ? 'mins' : 'min'}</span>
            </div>
        );
    }
    if (noofdays === 0 && hours === 0)
        formattedResult.push(
            <div className="ms-1" key={'secs'}>
                {minutes > 1 ? ':' : ''} <span>{String(seconds).padStart(2, '0')} </span>
                <span className={`${className ? className : 'text-muted'} ms-1`}>{seconds > 1 ? 'secs' : 'sec'}</span>
            </div>
        );
    return <div className="d-flex align-items-center">{formattedResult}</div>;
};

export const getDateOnly = (date) => {
    return getDate(date, true);
};

export const getDateUTCFormat = (param) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateObj = new Date(param);
    const month = monthNames[dateObj.getMonth()];
    const day = String(dateObj.getDate() - 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return month + '\n' + day + ', ' + year;
};

export const getUTCTime = (date) => {
    return date === '' || date === null ? date : new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const uniqBy = (arr, predicate) => {
    const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];
    return [
        ...arr
            .reduce((map, item) => {
                const key = item === null || item === undefined ? item : cb(item);
                map.has(key) || map.set(key, item);
                return map;
            }, new Map())
            .values()
    ];
};

export const uniqueID = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const getMonth = (val) => {
    return val > 9 ? '-' + val : '-0' + val;
};

export const getDateVal = (val) => {
    return val > 9 ? val : '0' + val;
};

export const convertTextCase = (name, param) => {
    let result;
    if (param) result = name?.charAt(0)?.toUpperCase() + name?.substr(1)?.toLowerCase();
    else result = name?.charAt(0)?.toUpperCase() + name?.substr(1);
    return result;
};

export const splitAndConvertPascalCase = (fullString, splitKey) => {
    let output = fullString
        ?.split(splitKey)
        ?.map((word) => convertTextCase(word, true))
        ?.join(' ');
    return output;
};

export const convertHMSTOMS = (time) => {
    let hms = time.split(':');
    return hms[0] * 60 * 60 * 1000 + hms[1] * 60 * 1000 + hms[2] * 1000;
};

export const getUTCGteLteFromDate = (dateVal) => {
    const time = new Date(dateVal);
    const previousDay = new Date(dateVal - 1);
    const gte =
        previousDay.getFullYear() + getMonth(previousDay.getMonth() + 1) + '-' + getDateVal(previousDay.getDate()) + 'T18:31:00.000Z';
    const lte = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T18:29:00.000Z';
    return { gte: gte, lte: lte };
};

export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export const profileTypeLabel = (policyType) => {
    let label;
    if (policyType === 'FM') label = 'Fully Managed Device';
    else if (policyType === 'WP') label = 'Work Profile';
    else if (policyType === 'DD') label = 'Dedicated Device';
    else if (policyType === 'SA') label = 'Single App';
    else if (policyType === 'MA') label = 'Multi App';
    else if (policyType === 'KL') label = 'Kiosk Launcher';
    else if (policyType === 'TL') label = 'Tectoro Launcher';
    return label;
};

export const InfoMessage = (message) => {
    return (
        <div className="d-flex justify-content-end">
            <div className="d-flex mb-3">
                <span className="info-message p-2">
                    <img className="me-1" src={info} alt="info Icon" />
                    {message}
                </span>
            </div>
        </div>
    );
};

export const convertUTCtoIST = (date, dateOnly) => {
    if (date !== null && date !== '' && date !== undefined) {
        var dateUTC = new Date(date);
        var dateUTCTime = dateUTC.getTime();
        var dateIST = new Date(dateUTCTime);
        /*
         * dateIST.setHours(dateIST.getHours() + 5); // date shifting for IST timezone (+5 hours and 30 minutes)
         * dateIST.setMinutes(dateIST.getMinutes() + 30);
         */
        return dateOnly ? getDateOnly(dateIST) : getDate(dateIST);
    } else {
        return dateOnly ? getDateOnly(new Date()) : getDate(new Date());
    }
};
export const timeWithout530 = (time, showDate) => {
    let formattedDate = '—';
    if (time) {
        let date = new Date(time);
        date.setHours(date.getHours());
        date.setMinutes(date.getMinutes());

        formattedDate = date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        });
    }
    return formattedDate;
};

export const add530ToTime = (time) => {
    let formattedDate = '—';
    if (time) {
        let date = new Date(time);
        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);
        formattedDate = date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        });
    }
    return formattedDate;
};

export const sortStringNumerics = (arr, key) => {
    return arr.sort((a, b) => {
        const versionA = a[key].split('.').map(Number);
        const versionB = b[key].split('.').map(Number);

        for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
            const numA = versionA[i] || 0;
            const numB = versionB[i] || 0;

            if (numA !== numB) {
                return numB - numA;
            }
        }

        return 0;
    });
};

export const convertDate = (str, key) => {
    var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join(key);
};

export const compareDates = (lrt, datePc2) => {
    let comparision = true;
    var d1 = new Date(lrt);
    var d2 = new Date(datePc2);
    if (!lrt && datePc2 && d2.getFullYear() > 2021) {
        comparision = true;
    } else if (lrt && (!datePc2 || d2.getFullYear() < 2021)) {
        comparision = false;
    } else {
        comparision = d1 < d2;
    }
    return comparision;
};

export const getFormTypeAndRecordIDForTickets = (path) => {
    let formType;
    let recordID = '';
    let queryType;
    let pathArr = path.split('/');
    const actionExist = path.search('/takeAction') !== -1;
    const viewExist = path.search('/viewAction') !== -1;
    recordID = pathArr[pathArr.length - 1];
    if (actionExist) {
        formType = 'edit';
    } else if (viewExist) {
        formType = 'view';
    }
    return { formType: formType, recordID: recordID };
};

export const getFormTypeAndRecordId = (path, extraParam) => {
    let formType;
    let recordID = '';
    let queryType;
    const createExist = path.search('/add') !== -1;
    const viewExist = path.search('/view') !== -1;
    const editExist = path.search('/edit') !== -1;
    const allExist = path.search('/all') !== -1;
    const takeActionExist = path.search('/takeAction') !== -1;
    const enrollmentTokenExist = path.search('/enrollmenttoken') !== -1;
    const checkerExist = path.search('/checker') !== '-1;';
    if (editExist) {
        formType = 'edit';
        recordID = path.split('/').reverse()[extraParam ? 1 : 0];
        if (extraParam) queryType = path.split('/').reverse()[0];
    } else if (viewExist) {
        formType = 'view';
        recordID = path.split('/').reverse()[extraParam ? 1 : 0];
        if (extraParam) queryType = path.split('/').reverse()[0];
    } else if (createExist) {
        formType = 'add';
        if (extraParam) queryType = path.split('/').reverse()[0];
    } else if (allExist) {
        formType = 'all';
    } else if (takeActionExist) {
        formType = 'takeAction';
        recordID = path.split('/').reverse()[extraParam ? 1 : 0];
        if (extraParam) queryType = path.split('/').reverse()[0];
    } else if (enrollmentTokenExist) {
        recordID = path.split('/').reverse()[extraParam ? 1 : 0];
        if (extraParam) queryType = path.split('/').reverse()[0];
    } else if (checkerExist) {
        recordID = path.split('/').reverse()[extraParam ? 1 : 0];
        if (extraParam) queryType = path.split('/').reverse()[0];
        formType = 'viewChecker';
    }
    return { formType: formType, recordID: recordID, queryType: queryType };
};

export const baseURL = () => {
    return window.location.origin?.split(':')?.includes('//localhost') ? 'https://portal.mdmdev.tectoro.com' : window.location.origin;
};

export const TenantConfig = () => {
    return Store.getState()?.TenantConfig?.tenantConfig;
};

export const GetAuthmodes = () => {
    return Store.getState()?.AuthModes?.authModes;
};

export const getEnvVals = () => {
    return Store.getState().Commons?.envVals;
};

export const AuthUser = () => {
    return Store.getState()?.Commons?.authUser;
};

export const Enterprise = () => {
    return Store.getState()?.Commons?.enterpriseAccount;
};

export const LogoutTime = () => {
    return Store.getState().Commons?.logoutTime;
};

export const UploadFile = (file, setLoading) => {
    const api = new APIClient();
    const urlconf = useEnv();
    setLoading(true);
    let formData = new FormData();
    formData.append('file', file);
    api.get('uurl', '', domainHelper.MDM_COMMONS)
        .then((urlFile) => {
            urlFile = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(urlFile) : urlFile;
            api.create(urlFile, formData, false, domainHelper.MDM_COMMONS).then((resp) => {
                if (resp?.status === 'success') {
                    setLoading(false);
                }
            });
        })
        .catch((err) => setLoading(false));
};

export const DownloadBunnyFile = (url, fileName, setLoading) => {
    setLoading(true);
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            const blobURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobURL;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobURL);
            setLoading(false);
        })
        .catch((err) => setLoading(false))
        .catch((err) => setLoading(false));
};

export function DownloadFile(Url, fileNameToDownload) {
    fetch(baseURL() + '/api/' + Url).then((response) => {
        response.blob().then((blob) => {
            var fileURL = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = fileURL;
            a.download = fileNameToDownload;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    });
}

export const customCss = (text, id) => {
    function styleAlphabets(input) {
        return input.replace(/([a-zA-Z]+)/g, '<span style="text-muted">$1</span>');
    }

    const inputString = text;
    const styledString = styleAlphabets(inputString);
    return styledString;
};

export const Tooltip = (id, label) => {
    return (
        <UncontrolledTooltip placement="bottom" target={id}>
            {label}
        </UncontrolledTooltip>
    );
};

export const ellipsisToolTip = (label) => {
    return <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>{label}</EllipsisToolTip>;
};

export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? ' hr, ' : ' hrs, ') : '';
    var mDisplay = m > 0 ? m + (m === 1 ? ' min, ' : ' min, ') : '';
    var sDisplay = s > 0 ? s + (s === 1 ? ' sec' : ' sec') : '';
    return hDisplay + mDisplay + sDisplay;
}

export const changeClasses = (configData) => {
    if (configData) {
        if (configData.COMAPNY_PRIMARY_COLOR)
            configData.COMAPNY_PRIMARY_COLOR = configData.COMAPNY_PRIMARY_COLOR?.startsWith('rgba')
                ? configData.COMAPNY_PRIMARY_COLOR
                : hexToRgba(configData.COMAPNY_PRIMARY_COLOR);
        if (configData.COMAPNY_SECONDARY_COLOR)
            configData.COMAPNY_SECONDARY_COLOR = configData.COMAPNY_SECONDARY_COLOR?.startsWith('rgba')
                ? configData.COMAPNY_SECONDARY_COLOR
                : hexToRgba(configData.COMAPNY_SECONDARY_COLOR);
        if (configData.COMAPNY_SUCCESS_COLOR)
            configData.COMAPNY_SUCCESS_COLOR = configData.COMAPNY_SUCCESS_COLOR?.startsWith('rgba')
                ? configData.COMAPNY_SUCCESS_COLOR
                : hexToRgba(configData.COMAPNY_SUCCESS_COLOR);
    }
    var style = document.createElement('style');
    var style1 = document.createElement('style');
    var style2 = document.createElement('style');
    var style3 = document.createElement('style');
    var style4 = document.createElement('style');
    var style5 = document.createElement('style');
    var style6 = document.createElement('style');
    var style7 = document.createElement('style');
    var style8 = document.createElement('style');
    var style9 = document.createElement('style');
    var style10 = document.createElement('style');
    var style11 = document.createElement('style');
    var style12 = document.createElement('style');

    style.type = 'text/css';
    style1.type = 'text/css';
    style2.type = 'text/css';
    style3.type = 'text/css';
    style4.type = 'text/css';
    style5.type = 'text/css';
    style6.type = 'text/css';
    style7.type = 'text/css';
    style8.type = 'text/css';
    style9.type = 'text/css';
    style10.type = 'text/css';
    style11.type = 'text/css';
    style12.type = 'text/css';

    style.innerHTML = `.link-primary { color: ${
        configData?.COMAPNY_PRIMARY_COLOR ? configData?.COMAPNY_PRIMARY_COLOR : 'rgba(64, 81, 137, 1)'
    } !important; }`;
    style1.innerHTML = `.text-primary {color: ${
        configData?.COMAPNY_PRIMARY_COLOR ? configData?.COMAPNY_PRIMARY_COLOR : 'rgba(64, 81, 137, 1)'
    } !important; }`;
    style2.innerHTML = `.bg-primary {background-color: ${
        configData?.COMAPNY_PRIMARY_COLOR ? configData?.COMAPNY_PRIMARY_COLOR : 'rgba(64, 81, 137, 1)'
    } !important; }`;
    style3.innerHTML = `.link-secondary: {color: ${
        configData?.COMAPNY_SECONDARY_COLOR ? configData?.COMAPNY_SECONDARY_COLOR : 'rgba(53, 119, 241, 1)'
    } !important; }`;
    style4.innerHTML = `.text-secondary: {color: ${
        configData?.COMAPNY_SECONDARY_COLOR ? configData?.COMAPNY_SECONDARY_COLOR : 'rgba(53, 119, 241, 1)'
    } !important; }`;
    style5.innerHTML = `.bg-secondary: {background-color:${
        configData?.COMAPNY_SECONDARY_COLOR ? configData?.COMAPNY_SECONDARY_COLOR : 'rgba(53, 119, 241, 1)'
    } !important;}`;
    style6.innerHTML = `.link-success: {color: ${
        configData?.COMAPNY_SUCCESS_COLOR ? configData?.COMAPNY_SUCCESS_COLOR : 'rgba(10, 179, 156, 1)'
    } !important; }`;
    style7.innerHTML = `.text-success: {color: ${
        configData?.COMAPNY_SUCCESS_COLOR ? configData?.COMAPNY_SUCCESS_COLOR : 'rgba(10, 179, 156, 1)'
    } !important; }`;
    style8.innerHTML = `.bg-success: {background-color:${
        configData?.COMAPNY_SUCCESS_COLOR ? configData?.COMAPNY_SUCCESS_COLOR : 'rgba(10, 179, 156, 1)'
    } !important;}`;
    style9.innerHTML = `.badge-soft-success: {color: ${
        configData?.COMAPNY_SUCCESS_COLOR ? configData?.COMAPNY_SUCCESS_COLOR : 'rgba(10, 179, 156, 1)'
    } !important; background-color: rgba(${
        configData?.COMAPNY_SUCCESS_COLOR ? configData?.COMAPNY_SUCCESS_COLOR : 'rgba(10, 179, 156, 1)'
    }, 0.1) !important; }`;
    style10.innerHTML = `.badge-soft-secondary: {color: ${
        configData?.COMAPNY_SECONDARY_COLOR ? configData?.COMAPNY_SECONDARY_COLOR : 'rgba(53, 119, 241, 1)'
    } !important; background-color: rgba(${configData?.COMAPNY_SECONDARY_COLOR}, 0.1) !important; }`;
    style11.innerHTML = `.badge-soft-primary: {color: ${
        configData?.COMAPNY_PRIMARY_COLOR ? configData?.COMAPNY_PRIMARY_COLOR : 'rgba(64, 81, 137, 1)'
    } !important; background-color: rgba(${
        configData?.COMAPNY_PRIMARY_COLOR ? configData?.COMAPNY_PRIMARY_COLOR : 'rgba(64, 81, 137, 1)'
    }, 0.1) !important; }`;
    style12.innerHTML = `#page-topbar: {background-color: ${
        configData?.COMAPNY_PRIMARY_COLOR ? configData?.COMAPNY_PRIMARY_COLOR : 'rgba(64, 81, 137, 1)'
    } !important; border-color: ${
        configData?.COMAPNY_PRIMARY_COLOR ? configData?.COMAPNY_PRIMARY_COLOR : 'rgba(64, 81, 137, 1)'
    } !important; }`;

    document.getElementsByTagName('head')[0].appendChild(style);
    document.getElementsByTagName('head')[0].appendChild(style1);
    document.getElementsByTagName('head')[0].appendChild(style2);
    document.getElementsByTagName('head')[0].appendChild(style3);
    document.getElementsByTagName('head')[0].appendChild(style4);
    document.getElementsByTagName('head')[0].appendChild(style5);
    document.getElementsByTagName('head')[0].appendChild(style6);
    document.getElementsByTagName('head')[0].appendChild(style7);
    document.getElementsByTagName('head')[0].appendChild(style8);
    document.getElementsByTagName('head')[0].appendChild(style9);
    document.getElementsByTagName('head')[0].appendChild(style10);
    document.getElementsByTagName('head')[0].appendChild(style11);
    document.getElementsByTagName('head')[0].appendChild(style12);
};

export function diffTwoDatesHrsMintsSeconds(date, extra) {
    let date1 = new Date(date);
    let date2 = extra ? new Date(extra) : new Date();
    const timeDiffInMsNoAbs = date1 - date2;
    let timeDiffInMs = Math.abs(date2 - date1);
    let seconds = Math.floor((timeDiffInMs / 1000) % 60);
    let minutes = Math.floor((timeDiffInMs / 1000 / 60) % 60);
    let hours = Math.floor((timeDiffInMs / 1000 / 60 / 60) % 24);
    let daysVal = Math.floor((timeDiffInMs / 1000 / 60 / 60 / 24) % 7);
    let weeks = Math.floor(timeDiffInMs / 1000 / 60 / 60 / 24 / 7);
    let totalHours = Math.floor(timeDiffInMs / 1000 / 60 / 60);
    let totalMinutes = Math.floor(timeDiffInMs / 1000 / 60);
    let totalSeconds = Math.floor(timeDiffInMs / 1000);
    let totalDays = Math.floor(timeDiffInMs / 1000 / 60 / 60 / 24);
    let totalWeeks = Math.floor(timeDiffInMs / 1000 / 60 / 60 / 24 / 7);
    const totalDaysNoAbs = Math.floor(timeDiffInMsNoAbs / 1000 / 60 / 60 / 24);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        days: daysVal,
        weeks: weeks,
        totalWeeks: totalWeeks,
        totalDays: totalDays,
        totalHours: totalHours,
        totalMinutes: totalMinutes,
        totalSeconds: totalSeconds,
        totalDaysNoAbs: totalDaysNoAbs
    };
}

export const IsAuthorized = (
    props = {
        yes: () => null,
        no: () => null
    }
) => {
    let userString = AuthUser();
    let user = JSON.parse(userString);
    if (props.privRequired) {
        let findPriv = user?.data?.privileges?.includes(props.privRequired);

        if (findPriv) return props.yes();
        else return props.no ? props.no() : null;
    } else return props.yes();
};

export const IsAuthorizedCondition = (
    props = {
        yes: () => null,
        no: () => null
    }
) => {
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    if (user?.data?.[props.key] === props.value || user?.data?.[props.key]?.includes(props.value)) {
        return props.yes();
    }
    return props.no();
};

export const PrivCheck = (props) => {
    let userString = AuthUser();
    let user = JSON.parse(userString);
    if (props.reader || props.editor) {
        let findPriv = user?.data?.privileges?.includes(props.editor) || user?.data?.privileges?.includes(props.reader);

        if (findPriv) return props.yes();
        else return props.no();
    } else return props.yes();
};

PrivCheck.defaultProps = {
    yes: () => null,
    no: () => null
};

export const sortBy = (arr, key) => {
    return arr.sort((a, b) => (a[key] < b[key] ? -1 : b[key] < a[key] ? 1 : 0));
};

export const sortByAsc = (arr, key) => {
    return arr.sort((a, b) => (a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0));
};

export const noCaseSensitiveSortBy = (arr, key) => {
    return arr.sort((a, b) => (a[key]?.toLowerCase() < b[key]?.toLowerCase() ? -1 : b[key]?.toLowerCase() < a[key]?.toLowerCase() ? 1 : 0));
};

export const sortStringsNoKey = (arr) => {
    return arr.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
};

export const guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const getUniqueValues = (array) => {
    let arr = [];
    array.forEach((obj) => {
        let contains = arr.includes(obj);
        if (!contains) arr.push(obj);
    });
    return arr;
};

const hexCharacters = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F'];
function getCharacter(index) {
    return hexCharacters[index];
}
export function getRandomColor() {
    let hexColorRep = '#';
    for (let index = 0; index < 6; index++) {
        const randomPosition = Math.floor(Math.random() * hexCharacters.length);
        hexColorRep += getCharacter(randomPosition);
    }
    return hexColorRep;
}

export const viewChange = (displayType, handleDisaplyChange) => {
    return (
        <>
            <button
                type="button"
                onClick={() => handleDisaplyChange('grid')}
                className={`me-2 ps-2 pe-2 pt-1 pb-1 btn ${
                    displayType === 'grid' ? 'btn-primary' : 'btn-soft-primary waves-effect waves-light'
                }`}
            >
                <span className="d-flex align-items-center fs-18">
                    <i className="ri-grid-fill"></i>
                </span>
            </button>
            <button
                type="button"
                onClick={() => handleDisaplyChange('table')}
                className={`me-2 ps-2 pe-2 pt-1 pb-1 btn ${
                    displayType === 'table' ? 'btn-primary' : 'btn-soft-primary waves-effect waves-light'
                }`}
            >
                <span className="d-flex align-items-center fs-18">
                    <i className="ri-menu-fill"></i>
                </span>
            </button>
        </>
    );
};

export const getRandomKey = () => {
    return CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
};

export const generateKey = (salt, passPhrase, aesKeySize, aesIterationCount) => {
    let keySize = aesKeySize / 32;
    let iterationCount = aesIterationCount;
    var key = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
        keySize: keySize,
        hasher: CryptoJS.algo.SHA256.create(),
        iterations: iterationCount
    });
    return key;
};

export const encrypt = (plainText) => {
    let iv = getRandomKey();
    let salt = getRandomKey();
    var key = generateKey(salt, CONSTANTS?.PASSPHRASE, CONSTANTS?.KEYSIZE, CONSTANTS?.ITERATION_COUNT);
    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(plainText), key, { iv: CryptoJS.enc.Hex.parse(iv) });
    var encodedCiphertext = encrypted.ciphertext.toString();
    return `${iv}::${salt}::${encodedCiphertext}`;
};

export const decrypt = (encryptedData) => {
    const [iv, salt, encodedCiphertext] = encryptedData.split('::');
    const key = generateKey(salt, CONSTANTS?.PASSPHRASE, CONSTANTS?.KEYSIZE, CONSTANTS?.ITERATION_COUNT);
    const ciphertext = CryptoJS.enc.Hex.parse(encodedCiphertext);
    const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, { iv: CryptoJS.enc.Hex.parse(iv) });
    return decrypted.toString(CryptoJS.enc.Utf8);
};

// Upload File Validation
export const fileValidation = (type, extension, validateFile) => {
    type = type?.toUpperCase();
    let field = '';
    switch (type) {
        case 'IMAGE':
            field = 'wallpaper';
            break;
        case 'VIDEO':
            field = 'video';
            break;
        case 'AUDIO':
            field = 'audio';
            break;
        case 'DOCUMENT':
            field = 'documents';
            break;
        case 'BULK_ACTION':
            field = 'bulkAction';
            break;
        case 'APPS':
            field = 'apps';
            break;
        default:
            field = 'all';
    }
    return {
        valid: validateFile[field]?.includes(extension?.toLowerCase()),
        message: validateFile[field]?.length > 1 ? validateFile[field]?.join(' / ') : validateFile[field]?.[0]
    };
};

export const getWeeks = (startDate, endDate, returnNumeric) => {
    let weekArr = [];
    let startWeek = startDate.toString();
    let endWeek = endDate.toString();
    let startWeekNum = daysNumeric[startWeek.substr(0, 3)];
    let endWeekNum = daysNumeric[endWeek.substr(0, 3)];
    let index = startWeekNum;
    while (index !== endWeekNum) {
        weekArr.push(index);
        index = (index + 1) % 7;
    }
    weekArr.push(endWeekNum);
    if (returnNumeric) return weekArr;
    else {
        let daysArr = [];
        weekArr.forEach((week) => {
            daysArr.push(days[week]);
        });
        return daysArr;
    }
};

export const prepareTenantData = (config, tenantObj, tenantData) => {
    let filtersObj = {
        deviceStatusArr: false,
        policiesArr: false,
        selectedFiltersKeys: false,
        tagListArr: false,
        tagListBackup: false,
        violationStatusArr: false,
        onlineStatusArr: false,
        appStatusArr: false
    };
    if (config?.ANDROID?.DEVICES?.FILTER_PANELS?.length > 0) {
        filtersObj = {
            deviceStatusArr: config?.ANDROID?.DEVICES?.FILTER_PANELS?.includes('device_status'),
            policiesArr: config?.ANDROID?.DEVICES?.FILTER_PANELS?.includes('policies'),
            tagListArr: config?.ANDROID?.DEVICES?.FILTER_PANELS?.includes('groups'),
            violationStatusArr: config?.ANDROID?.DEVICES?.FILTER_PANELS?.includes('violations'),
            onlineStatusArr: config?.ANDROID?.DEVICES?.FILTER_PANELS?.includes('device_status'),
            appStatusArr: config?.ANDROID?.DEVICES?.FILTER_PANELS?.includes('appStatusArr')
        };
    }

    let configData = {
        name: tenantObj?.name,
        COMPANY_LOGO: tenantObj?.COMPANY_LOGO ? tenantObj?.COMPANY_LOGO : tenantData.COMPANY_LOGO,
        PRIMARY_HEADER: tenantObj?.PRIMARY_HEADER ? tenantObj?.PRIMARY_HEADER : tenantData?.PRIMARY_HEADER,
        SECONDARY_HEADER: tenantObj?.SECONDARY_HEADER ? tenantObj?.SECONDARY_HEADER : tenantData?.SECONDARY_HEADER,
        COMPANY_DEVELOPED_BY: 'Enterprise Mobility Solution',
        FAV_ICON: tenantObj?.FAV_ICON,
        COMPANY_FAVICON: tenantData.COMPANY_FAVICON,
        COMAPNY_PRIMARY_COLOR: tenantObj?.COMPANY_PRIMARY_COLOR,
        COMAPNY_SECONDARY_COLOR: tenantObj?.COMPANY_SECONDARY_COLOR,
        COMAPNY_SUCCESS_COLOR: tenantObj?.COMPANY_SUCCESS_COLOR,
        COMPANY_FOOTER: tenantObj?.COMPANY_FOOTER,
        COMPANY_SLIDE_BG1: tenantData?.COMPANY_SLIDE_BG1,
        DASHBOARD: ['ANDROID', 'TV', 'WINDOWS', 'IOS']?.some((platform) => config?.[platform]?.DASHBOARD?.featureEnabled),
        DEVICES: ['ANDROID', 'TV', 'WINDOWS', 'IOS']?.some((platform) => config?.[platform]?.DEVICES?.featureEnabled),
        DEVICE_POLICY: ['ANDROID', 'TV', 'WINDOWS', 'IOS']?.some((platform) => config?.[platform]?.POLICIES?.featureEnabled),
        DEVICE_GROUPS: config?.DEVICE_GROUPS?.featureEnabled,
        // DEVICE_AUTOMATION: config?.DEVICE_AUTOMATION?.featureEnabled,
        SETUP: ['ANDROID', 'TV', 'WINDOWS', 'IOS']?.some((platform) => config?.[platform]?.SETUP?.featureEnabled),
        // DEVICE_SETTINGS: config?.DEVICE_SETTINGS?.featureEnabled,
        DEVICE_BULK_REQUEST: ['ANDROID', 'TV', 'WINDOWS', 'IOS']?.some((platform) => !!config?.[platform]?.BULK_REQUEST?.featureEnabled),
        APPS: ['ANDROID', 'TV', 'WINDOWS', 'IOS']?.some((platform) => config?.[platform]?.APPS?.featureEnabled),
        BROWSER: config?.BROWSER?.featureEnabled,
        APP_PROTECTION_POLICY: config?.APP_PROTECTION?.featureEnabled,
        FILES: config?.FILES?.featureEnabled,
        LAUNCHER_SETUP: config?.ANDROID?.LAUNCHER?.featureEnabled,
        MESSAGES: config?.MESSAGES?.featureEnabled,
        REPORTS: ['ANDROID', 'TV', 'WINDOWS', 'IOS']?.some((platform) => config?.[platform]?.REPORTS?.featureEnabled),
        FIREWALL: config?.FIREWALL?.featureEnabled,
        GEOFENCE: config?.GEOFENCE?.featureEnabled,
        TIMEFENCE: config?.TIMEFENCE?.featureEnabled,
        WIFIFENCE: config?.WIFIFENCE?.featureEnabled,
        SIM_SETTINGS: config?.SIM_SETTINGS?.featureEnabled,
        CONTACT_LIST: config?.CONTACT_LIST?.featureEnabled,
        CALL_CONFIG: config?.CALL_CONFIG?.featureEnabled,
        PACKAGES: config?.PACKAGES?.featureEnabled,
        ASSETS: config?.ASSETS?.featureEnabled,
        PASSWORD: config?.PASSWORD?.featureEnabled,
        // ZERO_TOUCH: config?.ZERO_TOUCH?.featureEnabled,
        CERTIFICATE_MANAGEMENT: config?.CERTIFICATE_MANAGEMENT?.featureEnabled,
        ODM_FEATURES: config?.ODM_FEATURES?.featureEnabled,
        CONDITIONAL_ACCESS_MAM: config?.CONDITIONAL_ACCESS_MAM?.featureEnabled,
        SHOW_SIM_INFO: config?.ANDROID?.DEVICES?.SIM_INFO,
        HOURLY_APP_USAGE: config?.ANDROID?.DEVICES?.HOURLY_APP_USAGE,
        CONTACT_MANAGEMENT: config?.CONTACT_MANAGEMENT?.featureEnabled,
        LICENSE: config?.LICENSE?.featureEnabled,
        AUTH_MODES: config?.AUTH_MODES?.featureEnabled,
        ENROLLMENT_SETTNGS: config?.ENROLLMENT_SETTNGS?.featureEnabled,

        DEVICE_ACTIONS: config?.ANDROID?.DEVICES?.ACTIONS?.length > 0 ? config?.ANDROID?.DEVICES?.ACTIONS?.split(',') : [],
        TV_DEVICE_ACTIONS: config?.TV?.DEVICES?.ACTIONS?.length > 0 ? config?.TV?.DEVICES?.ACTIONS?.split(',') : [],
        IOS_DEVICE_ACTIONS_MONITORING: config?.IOS?.DEVICES?.ACTIONS?.length > 0 ? config?.IOS?.DEVICES?.ACTIONS?.split(',') : [],
        WINDOWS_DEVICE_ACTIONS_MONITORING:
            config?.WINDOWS?.DEVICES?.ACTIONS?.length > 0 ? config?.WINDOWS?.DEVICES?.ACTIONS?.split(',') : [],
        TV_HOURLY_APP_USAGE: config?.TV_DEVICES?.settings?.HOURLY_APP_USAGE,

        DEVICE_DEFAULT_PASSWORD: config?.GLOBAL?.DEFAULT_PASSWORD,
        TABLE_COLUMNS: config?.ANDROID?.DEVICES?.TABLE_COLUMNS?.length > 0 ? config?.ANDROID?.DEVICES?.TABLE_COLUMNS?.split(',') : [],
        COMPANY_POLICY_ACCESS:
            config?.ANDROID?.POLICIES?.POLICY_TYPES?.length > 0 ? config?.ANDROID?.POLICIES?.POLICY_TYPES?.split(',') : [],
        EMM_DD_POLICY_TYPES:
            config?.ANDROID?.POLICIES?.SUB_POLICY_TYPES?.length > 0 ? config?.ANDROID?.POLICIES?.SUB_POLICY_TYPES?.split(',') : [],
        IOS_POLICY_TYPES: config?.IOS?.POLICIES?.POLICY_TYPES?.length > 0 ? config?.IOS?.POLICIES?.POLICY_TYPES?.split(',') : [],
        IOS_DD_POLICY_TYPES: config?.IOS?.POLICIES?.SUB_POLICY_TYPES?.length > 0 ? config?.IOS?.POLICIES?.SUB_POLICY_TYPES?.split(',') : [],
        WINDOWS_POLICY_TYPES:
            config?.WINDOWS?.POLICIES?.POLICY_TYPES?.length > 0 ? config?.WINDOWS?.POLICIES?.POLICY_TYPES?.split(',') : [],
        /*
         * LANGUAGES: config?.ANNOUNCEMENTS?.settings?.LANGUAGES,
         * ASSET_TYPES: config?.ASSETS?.settings?.ASSET_TYPES,
         * DEFAULT_PATH: config?.CONTENT_MANAGEMENT?.settings?.DEFAULT_PATH,
         * CONTENT_TYPES_ALLOWED: config?.CONTENT_MANAGEMENT?.settings?.CONTENT_TYPES_ALLOWED,
         * CHROME_ENABLED: config?.EMM?.settings?.CHROME_ENABLED,
         * ANDROID_LEGACY_ENABLED: config?.EMM?.settings?.ANDROID_LEGACY_ENABLED,
         * ENABLE_SIGN_IN: config?.EMM?.settings?.ENABLE_SIGN_IN,
         * AUTOMATION_FENCES: config?.DEVICE_AUTOMATION?.settings?.features,
         * MAX_DATA_USAGE: config?.ANDROID?.DEVICES?.MAX_DATA_USAGE,
         * MAX_DATA_USAGE_UNIT: config?.ANDROID?.DEVICES?.MAX_DATA_USAGE_UNIT,
         * AUTO_REFRESH: config?.DEVICES?.settings?.AUTO_REFRESH,
         */
        FILTER_PANELS: config?.ANDROID?.DEVICES?.FILTER_PANELS?.length > 0 ? config?.ANDROID?.DEVICES?.FILTER_PANELS?.split(',') : [],
        SHOW_FILTERS: filtersObj,
        ENABLE_WORKFLOW: config?.ANDROID?.POLICIES?.WORKFLOW,
        ANDROID_REPORTS_CARDS: config?.ANDROID?.REPORTS?.REPORTS?.length > 0 ? config?.ANDROID?.REPORTS?.REPORTS?.split(',') : [],
        TV_REPORTS_CARDS: config?.TV?.REPORTS?.REPORTS?.length > 0 ? config?.TV?.REPORTS?.REPORTS?.split(',') : [],
        DASHBOARD_WIDGETS: config?.ANDROID?.DASHBOARD?.WIDGETS?.length > 0 ? config?.ANDROID?.DASHBOARD?.WIDGETS?.split(',') : [],
        TV_WIDGETS: config?.TV?.DASHBOARD?.WIDGETS?.length > 0 ? config?.TV?.DASHBOARD?.WIDGETS?.split(',') : [],
        IOS_WIDGETS: config?.IOS?.DASHBOARD?.WIDGETS?.length > 0 ? config?.IOS?.DASHBOARD?.WIDGETS?.split(',') : [],
        WINDOWS_WIDGETS: config?.WINDOWS?.DASHBOARD?.WIDGETS?.length > 0 ? config?.WINDOWS?.DASHBOARD?.WIDGETS?.split(',') : [],
        ANDROID_TV_ENABLED: config?.TV?.featureEnabled,
        IOS_ENABLED: config?.IOS?.featureEnabled,
        WINDOWS_ENABLED: config?.WINDOWS?.featureEnabled,
        ANDROID_ENTERPRISE_ENABLED: config?.ANDROID?.featureEnabled,
        QR_AUTHENTICATON_ENABLED: config?.GLOBAL?.AUTHENTICATOR,

        enable_call_logs_config: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_call_logs_config'),
        enable_app_usage_report_config: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_app_usage_report_config'),
        enable_violations_cofig: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_violations_cofig'),
        enable_location_history_config: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_location_history_config'),
        enable_sim_operators_config: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_sim_operators_config'),
        enable_battery_config: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_battery_config'),
        enable_last_modified_date_config: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_last_modified_date_config'),
        enable_personal_account_config: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_personal_account_config'),
        enable_wallpaper_config: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_wallpaper_config'),
        enable_lock_screen_wallpaper: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_lock_screen_wallpaper'),
        enable_boot_animation_config: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_boot_animation_config'),
        enable_shutdown_logo: config?.ANDROID?.POLICIES?.CONFIGURATIONS?.includes('enable_shutdown_logo'),

        ENABLE_SCHEDULE_MESSAGE: config?.MESSAGES?.SCHEDULE_MESSAGE,
        ENABLE_RECALL: config?.MESSAGES?.settings?.RECALL,

        ENABLE_DATA_TRACKING: config?.ANDROID?.DEVICES?.DATA_TRACKING,
        MAX_ANDROID_DEVICE: Number(config?.ANDROID?.DEVICES?.MAX_DEVICES),
        MAX_IOS_DEVICE: Number(config?.IOS?.DEVICES?.MAX_DEVICES),
        MAX_WINDOWS_DEVICE: Number(config?.WINDOWS?.DEVICES?.MAX_DEVICES),

        ENABLE_TV_GEOFENCE: config?.TV?.POLICIES?.GEOFENCE,
        EMM_LAUNCHER_BRANDING: config?.ANDROID?.POLICIES?.LAUNCHER_BRANDING,
        AE_ENABLE_DISABLE_PACKAGE: config?.ANDROID?.POLICIES?.PACKAGE_ENABLED,
        ADMIN_REPORTS: config?.ADMIN?.REPORTS?.length > 0,
        ADMIN_REPORT_CARDS: config?.ADMIN?.REPORTS?.length > 0 ? config?.ADMIN?.REPORTS?.split(',') : [],
        IOS_FILTER_PANELS: config?.IOS?.DEVICES?.FILTER_PANELS?.length > 0 ? config?.IOS?.DEVICES?.FILTER_PANELS?.split(',') : [],
        WINDOWS_FILTER_PANELS:
            config?.WINDOWS?.DEVICES?.FILTER_PANELS?.length > 0 ? config?.WINDOWS?.DEVICES?.FILTER_PANELS?.split(',') : [],
        CUSTOM_DASHBOARD_EMAIL: config?.ANDROID?.DASHBOARD?.CUSTOM_DASHBOARD,
        ANDROID_BULK_REQUESTS: config?.ANDROID?.BULK_REQUEST?.REQUESTS,
        TV_BULK_REQUESTS: config?.TV?.BULK_REQUEST?.REQUESTS
    };
    return configData;
};

export const loginUtil = (props) => {
    const tenantPromise = new Promise((resolve, reject) => {
        props.api
            .get(urlHelper.TENANT_BY_CODE + props.ten, '', domainHelper.IDM)
            .then((resp) => {
                resp = props.urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') resolve(resp.data);
                else reject('tenant failed.');
            })
            .catch((err) => reject('tenant failed.'));
    });
    const enterprisePromise = new Promise((resolve, reject) => {
        if (
            props.user.data.privileges?.includes(priviliges.ENTERPRISE_READER) ||
            props.user.data.privileges?.includes(priviliges.ENTERPRISE_EDITOR)
        ) {
            let params = { page: 1, size: 2000 };
            props.api
                .get(urlHelper.ENTERPRISES, params)
                .then((resp) => {
                    resp = props.urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp) resolve(resp);
                })
                .catch((err) => {
                    reject('go to dashboard');
                });
        } else {
            reject('go to dashboard');
        }
    });

    Promise.allSettled([tenantPromise, enterprisePromise]).then((result) => {
        if (result[0].status === 'fulfilled') {
            let tenantVal = JSON.parse(JSON.stringify(result[0]?.value?.Config));
            let preparedTenant = prepareTenantData(tenantVal, props.tenantObj, props.tenantData);
            props.dispatch(tenantConfig(preparedTenant));
        }
        if (result[1].status === 'fulfilled') {
            props.dispatch(enterpriseAccount(JSON.stringify(result[1]?.value?.data?.[0])));
            props.history.push(props.route ? props.route : '/getstarted');
        } else if (result[1].status === 'rejected') {
            if (result[1].reason === 'go to dashboard') {
                props.dispatch(enterpriseAccount(JSON.stringify({})));
                props.history.push(props.route ? props.route : '/getstarted');
            }
        }
    });
};
export const getChartsData = (props) => {
    return {
        labels: props?.labels ? props?.labels : [],
        chart: {
            type: 'donut',
            height: 250
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: '76%',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '18px',
                            offsetY: -5
                        },
                        value: {
                            show: true,
                            fontSize: '20px',
                            color: '#343a40',
                            fontWeight: 500,
                            offsetY: 5,
                            formatter: function (val) {
                                return val;
                            }
                        },
                        total: {
                            show: true,
                            fontSize: '13px',
                            label: props?.label ? props?.label : 'Total Devices',
                            color: '#9599ad',
                            fontWeight: 500,
                            formatter: function (w) {
                                return props?.showTotal
                                    ? props?.totalDevices
                                    : w.globals.seriesTotals.reduce(function (a, b) {
                                          return a + b;
                                      }, 0);
                            }
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 15,
                height: 10,
                radius: 2
            },
            itemMargin: {
                horizontal: 12,
                vertical: 0
            }
        },
        colors: props?.colors ? props?.colors : []
    };
};

export const getAppProtectionPathParams = (path) => {
    const params = path.split('/');
    let properties = { formType: '', recordID: '', platform: '', domain: '' };
    if (params.includes('add')) {
        properties = {
            formType: 'add',
            recordID: undefined,
            platform: params?.includes('android') ? 'android' : 'ios',
            domain: params?.includes('microsoft') ? 'microsoft' : 'tectoro'
        };
    } else {
        properties = {
            formType: params?.[params?.length - 2],
            recordID: params?.[params?.length - 1],
            platform: params?.includes('android') ? 'android' : 'ios',
            domain: params?.includes('microsoft') ? 'microsoft' : 'tectoro'
        };
    }
    return properties;
};

export const timeSort = (data, key) => {
    data.sort((a, b) => {
        const dateA = new Date(a[key]).getTime();
        const dateB = new Date(b[key]).getTime();
        return dateB - dateA;
    });
    return data;
};

export const handleAPPTime = (time) => {
    let noOfDays = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    // Regex to match the ISO 8601 duration format
    const regex = /P(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?/;
    const matches = time.match(regex);

    if (matches) {
        if (matches[1]) noOfDays = parseInt(matches[1]);
        if (matches[2]) hours = parseInt(matches[2]);
        if (matches[3]) minutes = parseInt(matches[3]);
        if (matches[4]) seconds = parseInt(matches[4]);

        // Convert everything to minutes
        const totalMinutes = noOfDays * 24 * 60 + hours * 60 + minutes + seconds / 60;

        return totalMinutes;
    } else {
        throw new Error('Invalid ISO 8601 duration format');
    }
};

export const convertBytesToWithDecimals = (bytes, decimals) => {
    if (!+bytes) return '0 Bytes';

    const k = 1023;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const windowsConvertBytesTo = (bytes) => {
    let decimals = 0;
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const convertTimeFormatTo12 = (time) => {
    time = new Date(time);
    const timeArr = [time?.getHours(), time?.getMinutes()];
    const hours = timeFormat[timeArr[0]];
    return hours[0]?.padStart(2, '0') + ':' + timeArr[1]?.toString()?.padStart(2, '0') + ' ' + hours[1];
};
