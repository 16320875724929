import { Circle, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import Slider from 'react-rangeslider';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container, FormFeedback, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CustomStepperComponent from '../../../Components/Common/CustomStepperComponent';
import Loader from '../../../Components/Common/Loader';
import { getFormTypeAndRecordId, noCaseSensitiveSortBy } from '../../../Components/Common/Util';
import { GEOFENCE_ACTIONS } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { DynamicComponents } from '../Actions';
import Assigning from '../Assigning';

const AddGeoFence = () => {
    const urlconf = useEnv();
    Geocode.setApiKey(urlconf.REACT_APP_MAP_TOKEN);
    Geocode.setLanguage('en');
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: urlconf.REACT_APP_MAP_TOKEN
    });
    let history = useHistory();
    let api = new APIClient();

    const [loading, setLoading] = useState(false);

    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];

    document.title = formType === 'view' ? 'View Geo Fence' : formType === 'edit' ? 'Edit Geo Fence' : 'Add Geo Fence';
    const [center, setCenter] = useState({ lat: 17.4327609, lng: 78.3845325 });
    const [address, setAddress] = useState('');
    const [showMarker, setShowMarker] = useState(true);
    const [formValues, setFormValues] = useState('');
    const [groups, setGroups] = useState([]);
    const [flatGroups, setFlatGroups] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const violationOptions = [
        { label: 'Low', value: 'LOW' },
        { label: 'Medium', value: 'MEDIUM' },
        { label: 'High', value: 'HIGH' }
    ];

    const actions = [
        { label: 'No Action', value: 'NO_ACTION' },
        { label: 'Disable Device', value: 'DISABLED' }
    ];

    const mapStyles = { width: '100%', height: '90%' };

    const setGroupsData = async (deviceGroups, dataObj, groupsData, deviceGroupCodes) => {
        await deviceGroups.forEach(async (data) => {
            data.label = data.name;
            data.key = data.code;
            data.checked = deviceGroupCodes.includes(data.code);
            groupsData.push(data);
            if (data.children) {
                let obj = await setGroupsData(data.children, data, groupsData, deviceGroupCodes);
                data.children = obj.deviceGroups;
                groupsData = [...obj.groupsData];
            }
        });
        return { deviceGroups: deviceGroups, groupsData: groupsData };
    };

    useEffect(() => {
        handlePromise();
    }, []);

    const handlePromise = () => {
        setLoading(true);
        const groupsPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100 };
            api.get(url.DEVICE_GROUPS, params, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Groups failed.');
                })
                .catch((err) => reject('Groups failed.'));
        });

        const policyPromise = new Promise((resolve, reject) => {
            api.get(url.POLICIES, { page: 1, size: 1000 })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data?.length > 0) resolve(resp.data);
                    else reject('Policies failed.');
                })
                .catch((err) => reject('Policies failed.'));
        });

        const fencePromise = new Promise((resolve, reject) => {
            if (recordID)
                api.get(url.DEVICE_AUTOMATION + '/' + recordID)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.data) resolve(resp.data);
                        else reject('Fence failed');
                    })
                    .catch((err) => reject('Fence failed'));
            else reject('Fence failed');
        });

        Promise.allSettled([policyPromise, groupsPromise, fencePromise])
            .then(async (result) => {
                let deviceGroups = [];
                if (result[0].status === 'fulfilled') {
                    setPolicies(result[0].value);
                }
                if (result[2].status === 'fulfilled') {
                    const resp = result[2].value.config;
                    deviceGroups = result[2]?.value?.assignTo === 'GROUP' ? result[2]?.value?.assign?.split(',') || [] : [];
                    const dataObj = {
                        geofenceName: result[2]?.value?.name,
                        geoRadius: resp?.radius,
                        geoDescription: resp?.description,
                        latitude: resp.latitude,
                        longitude: resp.longitude,
                        geoFenceIn: {
                            action: handleAction(resp?.inAction),
                            policy: handlePolicy(result[0].value, resp?.inAction)
                        },
                        geoFenceOut: {
                            action: handleAction(resp?.outAction),
                            policy: handlePolicy(result[0].value, resp?.outAction)
                        }
                    };
                    handleMapClick({ lat: resp.latitude, lng: resp.longitude });
                    setFormValues({ ...dataObj });
                }
                if (result[1]?.status === 'fulfilled') {
                    let superParents = [];
                    let flatGroupArr = [];
                    if (result[1]?.value) {
                        let groupsData = [];
                        await result[1]?.value.forEach(async (data) => {
                            data.label = data.name;
                            data.key = data.code;
                            data.checked = deviceGroups.includes(data.code);
                            groupsData.push(data);
                            if (data.children) {
                                let obj = await setGroupsData(data.children, data, groupsData, deviceGroups);
                                data.children = obj.deviceGroups;
                                groupsData = [...obj.groupsData];
                            }
                        });
                        flatGroupArr = groupsData;
                    }
                    setFlatGroups(flatGroupArr);
                    setSelectedGroups(flatGroupArr.filter((group) => group.checked)?.map((group) => group.code));
                    superParents = noCaseSensitiveSortBy(result[1]?.value, 'label');
                    setGroups(superParents);
                }
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
    };
    const handleAction = (slot) => {
        return [
            { label: 'No Action', value: 'NO_ACTION' },
            { label: 'Choose Policy', value: 'PolicySwitch' },
            { label: 'Disable Device', value: 'DISABLED' }
        ]?.find((action) => action.value === slot.action);
    };

    const handlePolicy = (policiesArr, slot) => {
        return slot.policy ? policiesArr.find((policy) => policy.code === slot.policy) : undefined;
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            geoRadius: formValues?.geoRadius || formValues?.geoRadius === 0 ? formValues?.geoRadius : 1000,
            geofenceName: formValues?.geofenceName ? formValues?.geofenceName : '',
            geoDescription: formValues?.geoDescription ? formValues?.geoDescription : '',
            latitude: formValues.latitude ? formValues.latitude : center.lat,
            longitude: formValues.longitude ? formValues.longitude : center?.lng,
            geoFenceIn: formValues.geoFenceIn ? formValues.geoFenceIn : '',
            geoFenceOut: formValues.geoFenceOut ? formValues.geoFenceOut : ''
        },
        onSubmit: () => {}
    });

    const handleSearchAddress = () => {
        Geocode.fromAddress(address)
            .then((response) => {
                const latLng = response.results[0].geometry.location;
                setCenter(latLng);
                validation?.setValues({ ...validation?.values, latitude: latLng.lat, longitude: latLng.lng });
            })
            .catch((_err) => {
                toast.error(toastMessages.locationNotFound);
            });
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleMapClick = (clickEvent, doNotSet) => {
        let latLng = {
            lat: clickEvent.latLng?.lat() ? clickEvent.latLng?.lat() : clickEvent.lat,
            lng: clickEvent.latLng?.lng() ? clickEvent.latLng?.lng() : clickEvent.lng
        };
        setCenter(latLng);
        if (!doNotSet) validation?.setValues({ ...validation?.values, latitude: latLng.lat, longitude: latLng.lng });
        setFormValues({ ...validation?.values });
        let addressString = '';
        Geocode.fromLatLng(latLng.lat, latLng.lng)
            .then((resp) => {
                if (resp.results) {
                    resp.results[0].address_components.forEach((addressObj, ind) => {
                        addressString = addressString + (ind !== 0 ? ', ' : '') + addressObj.long_name?.toString();
                    });
                    setAddress(addressString);
                    setShowMarker(true);
                }
            })
            .catch((_err) => {
                if (!doNotSet) toast.error(toastMessages.addressNotFound);
            });
    };

    const setCurrentLocation = () => {
        if (!recordID)
            navigator.geolocation.getCurrentPosition(function (position) {
                let latLng = { lat: position.coords.latitude, lng: position.coords.longitude };
                handleMapClick(latLng);
            });
    };

    const checkValue = (value) => {
        return value !== null && value !== undefined;
    };

    const formatLabel = (value, label) => {
        if (checkValue(label)) return value + ' ' + label;
        else return value;
    };

    const handleOnBlur = () => {
        if (!!validation.values.latitude && !!validation.values.longitude)
            handleMapClick({ lat: Number(validation.values.latitude), lng: Number(validation.values.longitude) }, true);
    };

    const stepperData = [
        {
            title: 'Configuration',
            icon: 1,
            disabled: false,
            renderTab: (
                <React.Fragment>
                    <Row>
                        <Col sm={9}>
                            <div className="d-flex gap-3 mb-3">
                                <Input
                                    type="text"
                                    value={address}
                                    onChange={handleAddressChange}
                                    className="w-60"
                                    placeholder="Search the place here"
                                />
                                {formType !== 'view' && (
                                    <Button size="sm" type="button" color={'primary'} onClick={handleSearchAddress}>
                                        <span className="d-flex align-items-center text-truncate">
                                            <i className="ri-search-line me-1 fs-16"></i> Search
                                        </span>
                                    </Button>
                                )}
                            </div>
                            {isLoaded && (
                                <GoogleMap
                                    mapContainerStyle={mapStyles}
                                    center={center}
                                    zoom={14}
                                    onLoad={setCurrentLocation}
                                    onClick={formType === 'view' ? null : handleMapClick}
                                >
                                    {showMarker && <Marker position={center} />}
                                    {showMarker && (
                                        <Circle
                                            center={center}
                                            radius={validation.values.geoRadius}
                                            options={{
                                                fillColor: '#59AF5067',
                                                color: '#0D807B',
                                                strokeColor: '#0D807B',
                                                strokeOpacity: 0.8,
                                                strokeWeight: 2,
                                                fillOpacity: 0.35
                                            }}
                                        />
                                    )}
                                </GoogleMap>
                            )}
                            {/* <Map
                                        zoom={14}
                                        style={mapStyles}
                                        google={props.google}
                                        onClick={formType === 'view' ? null : handleMapClick}
                                        onTilesloaded={setCurrentLocation}
                                        initialCenter={center}
                                        center={center}
                                        keyboardShortcuts={false}
                                        streetViewControl={false}
                                    >
                                        {showMarker && <Marker position={center} />}
                                        {showMarker && (
                                            <Circle
                                                radius={validation.values.geoRadius}
                                                center={center}
                                                fillColor="#65b59c"
                                                color="#65b59c"
                                            />
                                        )}
                                    </Map> */}
                        </Col>
                        <Col sm={3} className="min-height-400">
                            <Row className="gap-3">
                                <Col sm={12}>
                                    <Label className="form-label d-flex align-items-center fw-medium mb-1">
                                        Latitude
                                        {formType !== 'view' ? (
                                            <span className="red-color ps-1"> *</span>
                                        ) : (
                                            <span className="ps-1"> :</span>
                                        )}
                                    </Label>
                                    <div className="text-muted wrap-text-word mb-1 fs-10">
                                        Please provide coordinates if available, or else they'll auto-fetch when selecting a point on the
                                        map.
                                    </div>
                                    <div className="input-group">
                                        <Input
                                            name="latitude"
                                            id={'Latitude'}
                                            className="form-control"
                                            placeholder="Enter The Latitude"
                                            disabled={formType === 'view'}
                                            validate={{ required: { value: true } }}
                                            onChange={validation.handleChange}
                                            onBlur={(e) => {
                                                validation.handleBlur(e);
                                                handleOnBlur();
                                            }}
                                            value={validation?.values['latitude'] || ''}
                                            invalid={validation?.touched['latitude'] && validation?.errors['latitude'] ? true : false}
                                        />
                                        {validation?.touched['latitude'] && validation?.errors['latitude'] ? (
                                            <FormFeedback type="invalid">{validation?.errors['latitude']}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    <Label className="form-label d-flex align-items-center fw-medium mb-1">
                                        Longitude
                                        {formType !== 'view' ? (
                                            <span className="red-color ps-1"> *</span>
                                        ) : (
                                            <span className="ps-1"> :</span>
                                        )}
                                    </Label>
                                    <div className="text-muted wrap-text-word mb-1 fs-10">
                                        Please provide coordinates if available, or else they'll auto-fetch when selecting a point on the
                                        map.
                                    </div>
                                    <div className="input-group">
                                        <Input
                                            name="longitude"
                                            id={'Longitude'}
                                            className="form-control"
                                            placeholder="Enter The Longitude"
                                            disabled={formType === 'view'}
                                            validate={{ required: { value: true } }}
                                            onChange={validation.handleChange}
                                            onBlur={(e) => {
                                                validation.handleBlur(e);
                                                handleOnBlur();
                                            }}
                                            value={validation?.values['longitude'] || ''}
                                            invalid={validation?.touched['longitude'] && validation?.errors['longitude'] ? true : false}
                                        />
                                        {validation?.touched['longitude'] && validation?.errors['longitude'] ? (
                                            <FormFeedback type="invalid">{validation?.errors['longitude']}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    <div className="d-flex align-items-center text-truncate fs-13 fw-medium">Set Radius :</div>
                                    <div className="text-muted wrap-text-word mb-1 fs-10">Please specify the desired radius in meters</div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>{0}</span>
                                        <span>Value: {validation.values.geoRadius ? validation.values.geoRadius : 0} m</span>
                                        <span>{1000}</span>
                                    </div>
                                    <div className="custom-range-slider-info">
                                        <Slider
                                            className="my-3"
                                            min={0}
                                            max={1000}
                                            step={100}
                                            format={(value) => formatLabel(value, 'm')}
                                            onBlur={() => validation.handleBlur({ target: { name: 'geoRadius' } })}
                                            onChange={
                                                formType === 'view'
                                                    ? null
                                                    : (e) => validation.handleChange({ target: { name: 'geoRadius', value: e } })
                                            }
                                            value={validation.values.geoRadius}
                                        />
                                    </div>
                                </Col>

                                <Col sm={12}>
                                    <Label className="form-label d-flex align-items-center fw-medium">Description</Label>

                                    <div className="input-group">
                                        <Input
                                            name="geoDescription"
                                            id={'geoDescription'}
                                            className="form-control"
                                            type="textarea"
                                            rows={7}
                                            disabled={formType === 'view'}
                                            placeholder="Enter Description"
                                            validate={{ required: { value: true } }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation?.values['geoDescription'] || ''}
                                            invalid={
                                                validation?.touched['geoDescription'] && validation?.errors['geoDescription'] ? true : false
                                            }
                                        />
                                        {validation?.touched['geoDescription'] && validation?.errors['geoDescription'] ? (
                                            <FormFeedback type="invalid">{validation?.errors['geoDescription']}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </React.Fragment>
            )
        },
        {
            title: 'Actions',
            icon: 2,
            disabled: false,
            renderTab: (
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                        {GEOFENCE_ACTIONS?.map((field, index) => (
                            <DynamicComponents
                                key={index}
                                field={field}
                                index={index}
                                formType={formType}
                                validation={validation}
                                policies={policies}
                            />
                        ))}
                    </Col>
                </Row>
            )
        },
        {
            title: 'Assignment',
            icon: 3,
            disabled: false,
            renderTab: (
                <Row>
                    <Col md={6}>
                        <div className="fw-semibold mt-3">
                            Assign Group<span className="text-danger px-1">*</span>
                        </div>
                        <div className="text-muted mb-3 fs-11">Please specify the group to which the configurations should apply </div>
                        <Assigning
                            groups={groups}
                            flatGroups={flatGroups}
                            selectedGroups={selectedGroups}
                            setGroups={setGroups}
                            setSelectedGroups={setSelectedGroups}
                            setFlatGroups={setFlatGroups}
                            mode={formType}
                        />
                    </Col>
                </Row>
            )
        }
    ];

    const handleSave = () => {
        let dataObj = {
            name: validation.values?.geofenceName,
            type: 'GEOFENCE',
            config: {
                description: validation.values.geoDescription,
                radius: validation.values.geoRadius,
                latitude: validation.values.latitude,
                longitude: validation.values.longitude,
                inAction: { action: validation.values.geoFenceIn?.action?.value, policy: validation.values?.geoFenceIn?.policy?.code },
                outAction: { action: validation.values.geoFenceOut?.action?.value, policy: validation.values?.geoFenceOut?.policy?.code }
            },
            assign: selectedGroups?.join(','),
            assignTo: 'GROUP',
            status: 'ACTIVE'
        };

        let apiService;
        if (recordID) {
            setLoading(true);
            apiService = api.update(url.DEVICE_AUTOMATION + '/geofence/' + recordID, dataObj);
        } else {
            setLoading(true);
            apiService = api.create(url.DEVICE_AUTOMATION + '/geofence', dataObj);
        }
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') toast.success(recordID ? toastMessages.geofenceUpdated : toastMessages.geofenceCreated);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
                history.push('/geofence');
            });
    };

    const handleDisableSave = () => {
        let flag = false;
        return (
            !validation.values.geofenceName ||
            !validation.values.geoRadius ||
            !validation.values.latitude ||
            !validation.values.longitude ||
            selectedGroups?.length === 0 ||
            !validation.values?.geoFenceIn?.action?.value ||
            (validation.values?.geoFenceIn?.action?.value === 'PolicySwitch' ? !validation.values?.geoFenceIn?.policy?.code : false) ||
            !validation.values?.geoFenceOut?.action?.value ||
            (validation.values?.geoFenceOut?.action?.value === 'PolicySwitch' ? !validation.values?.geoFenceOut?.policy?.code : false) ||
            flag
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={formType === 'view' ? 'View Geo-Fence' : formType === 'edit' ? 'Edit Geo-Fence' : 'Add Geo-Fence'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="geofence"
                    />
                    <Card className="card-height-100 min-height-610">
                        <CardHeader>
                            <div className="fw-semibold fs-16 mb-1">Geo-Fence</div>
                            <div className="fs-13">
                                A Geo-Fence is a virtual perimeter set up using GPS or RFID technology to create a defined geographic
                                boundary. This technology enables location-based monitoring and management by triggering alerts or actions
                                when a device enters or exits the specified area. Geo-Fencing is commonly used in various applications, such
                                as asset tracking, fleet management, and security systems, to enhance operational efficiency and ensure
                                safety.
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                Configuration Name
                                {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                {formType !== 'view' ? (
                                    <div className="w-30 ms-5">
                                        <Input
                                            name="geofenceName"
                                            id="geofenceName"
                                            className="form-control"
                                            placeholder="Enter Configuration Name"
                                            type="text"
                                            maxLength="30"
                                            validate={{ required: { value: true } }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values['geofenceName'] || ''}
                                            invalid={validation.touched['geofenceName'] && validation.errors['geofenceName'] ? true : false}
                                        />
                                    </div>
                                ) : validation?.values['geofenceName'] ? (
                                    <span className="ps-1">{validation?.values['geofenceName']}</span>
                                ) : (
                                    '–'
                                )}
                                {validation.touched['geofenceName'] && validation.errors['geofenceName'] ? (
                                    <p className="m-0 mt-2 text-danger">{validation.errors['geofenceName']}</p>
                                ) : null}
                            </div>
                        </CardHeader>
                        <CardBody className="p-0">
                            <CustomStepperComponent
                                stepperData={stepperData}
                                enableSaveBtn={true}
                                handleSave={handleSave}
                                handleDisableSave={handleDisableSave()}
                                cancelBtn={true}
                                routeText={'geofence'}
                                formType={formType}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddGeoFence;
