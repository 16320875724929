/* eslint-disable no-shadow */
/* eslint-disable max-lines */
/* eslint-disable max-nested-callbacks */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Collapse, Container, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { tvDeviceActions } from '../../../Components/Common/DeviceUtils';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import {
    IsAuthorized,
    TenantConfig,
    Tooltip,
    add530ToTime,
    convertBytesTo,
    convertDate,
    convertSecondsTo,
    convertUTCtoIST,
    formatDate,
    getDate,
    getDateOnly,
    getFormTypeAndRecordId,
    sortByAsc
} from '../../../Components/Common/Util';
import { APP_USAGE_INTERVALS, priviliges } from '../../../Components/constants/constants';

import hardwareIcon from '../../../assets//images/common/png/devicesViewIcons/hardware.png';
import applicationsIcon from '../../../assets/images/common/svg/devicesViewIcons/applications.svg';
import factoryReset from '../../../assets/images/common/png/common/factoryReset.png';
import hpIcon from '../../../assets/images/common/png/devicesViewIcons/hp.png';
import lockDevice from '../../../assets/images/common/png/devicesViewIcons/lockDevice.png';
import nokiaIcon from '../../../assets/images/common/png/devicesViewIcons/nokiaIcon.png';
import rebootDevice from '../../../assets/images/common/png/devicesViewIcons/rebootDevice.png';
import samsungIcon from '../../../assets/images/common/png/devicesViewIcons/samsungIcon.png';
import softwareIcon from '../../../assets/images/common/svg/devicesViewIcons/software.svg';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { androidTVAppSchema } from '../TableSchema';
// import lenovoIcon from '../../../../assets/images/lenovo.png';
import Flatpickr from 'react-flatpickr';
import Slider from 'react-rangeslider';
import Select from 'react-select';
import { toast } from 'react-toastify';
import DeleteModal from '../../../Components/Common/DeleteModal';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import Pagination from '../../../Components/Common/Pagination/Pagination';
import acerIcon from '../../../assets/images/common/png/devicesViewIcons/acerIcon.png';
import celkonIcon from '../../../assets/images/common/png/devicesViewIcons/celkonIcon.png';
import vu from '../../../assets/images/common/png/devicesViewIcons/vu.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { handleGeoViolationHistoryFunc } from '../../AndroidEnterprise/Devices/AndroidEnterpriseViewDevicesMetrics';
import { moveGroupsSchema } from '../../AndroidEnterprise/Devices/TableSchema';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import classNames from 'classnames';

const AndroidTVViewDevices = () => {
    document.title = 'View Android TV';
    let history = useHistory();
    const urlconf = useEnv();
    let api = new APIClient();
    const [loading, setLoading] = useState();
    const [applications, setApplications] = useState([]);

    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let recordID = formTypeAndId['recordID'];
    let fp1 = useRef(null);

    const tenantConfig = TenantConfig();

    const [tvDetails, setTVDetails] = useState('');
    const [refreshTime, setRefreshTime] = useState(0);
    const [showPolicyDropdown, setShowPolicyDropdown] = useState(false);
    const [showDeviceStateDropdown, setShowDeviceStateDropdown] = useState(false);
    const [showPhoneInput, setShowPhoneInput] = useState(false);
    const [policyOptions, setPolicyOptions] = useState([]);
    const [policy, setPolicy] = useState('');
    const [deviceStatus, setDeviceStatus] = useState('');
    const [policyBackup, setPolicyBackup] = useState('');
    const [deviceStatusBackup, setDeviceStatusBackup] = useState('');
    const [locationsHistoryTotalRecords, setLocationHistoryTotalRecords] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showDeviceGroup, setShowDeviceGroup] = useState(false);
    const [defaultGroup, setDefaultGroup] = useState('');
    const [locationsHistory, setLocationsHistory] = useState([]);
    const [selectedDeviceGroup, setSelectedDeviceGroup] = useState('');
    const [subBreadcrumbItems, setSubBreadcrumbItems] = useState([{ name: 'Main Group' }]);
    const [deviceGroupOptions, setDeviceGroupsOptions] = useState([]);
    const [deviceGroupOptionsBackup, setDeviceGroupsOptionsBackup] = useState([]);
    const [offcanvasModal, setOffcanvasModal] = useState(false);
    const [appUsageType, setAppUsageType] = useState({ label: 'Custom Apps', value: 'custom' });
    const [totalAppDetails, setTotalAppDetails] = useState('');
    const [appUsage, setAppUsage] = useState([]);
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [sidePanelLoader, setSidePanelLoader] = useState(false);
    const [usageTime, setUsageTime] = useState({ label: 'Daily', value: 'daily' });
    const [type, setType] = useState('');
    const [actionText, setActionText] = useState('');
    const [actionModal, setActionModal] = useState(false);
    const [geoViolationData, setGeoViolationData] = useState({ inCount: 0, outCount: 0, records: [] });
    const [geoViolationPage, setGeoViolationPage] = useState(1);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 20, id: tvDetails?.id });
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [geoTotalRecords, setGeoTotalRecords] = useState(0);
    const [col, setCol] = useState({});
    let fp = useRef();
    const [dateVal, setDateVal] = useState([]);
    const [selectedMonitor, setSelectedMonitor] = useState('');
    const [volume, setVolume] = useState(0);
    const [dateChange, setDateChange] = useState({});
    const [powerStatus, setPowerStatus] = useState('ON');
    const [inputMethod, setInputMethod] = useState();
    const [BackinputMethod, setBackInputMethod] = useState();
    const [selectedAction, setSelectedAction] = useState('');
    const [message, setMessage] = useState('');

    const toastMessage = {
        WIPE: toastMessages.factoryReset,
        LOCK: toastMessages.lockDevice,
        REBOOT: toastMessages.rebootDevice,
        CAMERA_ENABLE: toastMessages.cameraEnable,
        CAMERA_DISABLE: toastMessages.cameraDisable,
        SHUTDOWN: toastMessages.shutdownDevice
    };

    const inputOptions = [
        { label: 'HDMI1', value: 'HDMI1' },
        { label: 'HDMI2', value: 'HDMI2' },
        { label: 'HDMI3', value: 'HDMI3' },
        { label: 'HDMI4', value: 'HDMI4' }
    ];

    const deviceStateOptions = [
        { name: 'ACTIVE', code: 'ACTIVE' },
        { name: 'DISABLED', code: 'DISABLED' }
    ];

    useEffect(() => {
        setLoading(true);
        handlePromise();
    }, [recordID]);

    const handlePromise = () => {
        const deviceGetByID = new Promise((resolve, reject) => {
            api.get(url.DEVICES + '/' + recordID, '', domains.ANDROID_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Android TV failed.');
                })
                .catch((_err) => reject('Android TV failed.'));
        });

        const policyPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 1000 };
            api.get(url.POLICIES, params, domains.ANDROID_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Policy failed.');
                })
                .catch((err) => reject('Policy failed.'));
        });

        const getDeviceGroups = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100 };
            api.get(url.DEVICE_GROUPS, params, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Group failedd.');
                })
                .catch((err) => reject('Group failed.'));
        });

        Promise.allSettled([deviceGetByID, policyPromise, getDeviceGroups]).then((result) => {
            let polOptions = [];
            if (result?.[1]?.status === 'fulfilled') {
                // eslint-disable-next-line no-unsafe-optional-chaining
                polOptions = [...result?.[1]?.value];
                setPolicyOptions(result?.[1]?.value);
            }
            let arr = [];
            if (result[2].status === 'fulfilled') {
                if (result[2].value?.length > 0) {
                    result[2].value.forEach((group) => {
                        arr.push(group);
                        group.label = group.name;
                        group.key = group.code;
                        group.value = group.code;
                        group.nameCode = group.name + ' - ' + group.code;
                        if (group.children) {
                            group.children = setChildGroups(group.children);
                            arr = setGroups(group.children, arr);
                        }
                        group.childsCount = group.children.length ? group.children.length : 0;
                    });
                    setDeviceGroupsOptions(result[2].value);
                    setDeviceGroupsOptionsBackup(result[2].value);
                }
            }
            if (result[0].status === 'fulfilled') {
                let devStateObj = deviceStateOptions?.find(
                    (devOptions) => devOptions.code === result?.[0]?.value?.enroll?.status?.toUpperCase()
                );
                let polObj = polOptions?.find((polOption) => polOption.code === result?.[0]?.value?.assignment?.policy);
                let selGroup = arr.find((groupObj) => groupObj.code === result?.[0]?.value?.assignment?.group);
                const inputVal = inputOptions.find(
                    (field) => field.value === polObj?.policy?.config?.INPUTMETHOD?.restrictions?.inputmethod?.trim?.()
                );
                setDefaultGroup(selGroup);
                setVolume(polObj?.policy?.generalsettings?.volume);
                setPolicy(polObj);
                setPolicyBackup(polObj);
                setDeviceStatus(devStateObj);
                setDeviceStatusBackup(devStateObj);
                setPhoneNumber(result?.[0]?.value?.phoneNumber);
                setInputMethod(inputVal || '');
                setBackInputMethod(inputVal || '');

                let obj = {
                    id: recordID,
                    deviceName: result?.[0]?.value?.refs?.find((ref) => ref.type === 'DEVICENAME')?.value,
                    brand: result?.[0]?.value?.hardware?.brand,
                    model: result?.[0]?.value?.hardware?.model,
                    product: result?.[0]?.value?.metrics?.product,
                    showActions: result?.[0]?.value?.enroll?.status === 'ACTIVE',
                    hideFactoryReset: result?.[0]?.value?.enroll?.status !== 'ACTIVE',
                    source: result?.[0]?.value?.hardware?.devicetype ? result?.[0]?.hardware?.devicetype : 'UNRECOGNIZED',
                    deviceSummary: {
                        deviceStatus: result[0]?.value?.enroll?.status,
                        androidId: result?.[0]?.value?.refs?.find((ref) => ref.type === 'ANDROID_ID')?.value,
                        policy: result?.[0]?.value?.assignment?.policy,
                        enrollmentDate: result?.[0]?.value?.enroll?.enrollmentdate
                            ? convertUTCtoIST(result?.[0]?.value?.enroll?.enrollmentdate)
                            : '—',
                        lastModifiedDate: result?.[0]?.value?.modifieddate ? convertUTCtoIST(result?.[0]?.value?.modifieddate) : '—',
                        ip_address: result?.[0]?.value?.metrics?.ip_address,
                        wifi_connection: result?.[0]?.value?.metrics?.wifi_connection,
                        user: result?.[0]?.value?.assignment?.user,
                        group: result?.[0]?.value?.assignment?.group
                    },
                    softwareInfo: {
                        androidVersion: result?.[0]?.value?.metrics?.android_version,
                        bootLoader: result?.[0]?.value?.metrics?.bootloader,
                        buildTime: getDate(result?.[0]?.value?.metrics?.build_time?.seconds),
                        sdkInt: result?.[0]?.value?.metrics?.sdk_int,
                        securityPatch: result?.[0]?.value?.metrics?.security_patch,
                        supportAbs: result?.[0]?.value?.metrics?.support_abs,
                        radioVersion: result?.[0]?.value?.metrics?.radioVersion
                    },
                    hardwareInfo: {
                        availableInternalStorage: result?.[0]?.value?.metrics?.total_available_internal_storage,
                        totalInternalStorage: result?.[0]?.value?.metrics?.total_internal_storage,
                        usedInternalStorage: result?.[0]?.value?.metrics?.total_used_internal_storage
                    }
                };
                let apps = [];
                if (result?.[0]?.value?.metrics && Object.entries(result?.[0]?.value?.metrics?.apps || {})?.length > 0) {
                    result?.[0]?.value?.metrics?.apps?.forEach((app) => {
                        let title = Object.entries(app)?.[0];
                        apps.push({ packagename: title?.[0], code: title?.[1] });
                    });
                }
                setApplications(apps);
                setTVDetails(obj);
            }
            setLoading(false);
        });
    };

    const setChildGroups = (groupArr, groupStatus) => {
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.value = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            group.deviceCount = groupStatus?.[group.code] ? groupStatus?.[group.code] : 0;
            if (group.children) group.children = setChildGroups(group.children, groupStatus);
            group.childsCount = group.children.length ? group.children.length : 0;
        });
        return groupArr;
    };

    const setGroups = (groups, arr) => {
        groups.forEach((group) => {
            arr.push(group);
            if (group.children) arr = setGroups(group.children, arr);
        });
        return arr;
    };

    const getGeoFence = (params) => {
        setLoading(true);
        api.get(url.DEVICE_REPORTS + '/tvgeofence', params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((data) => {
                        data.badgeClass = data.status === 'IN' ? 'success' : 'danger';
                        data.date = data.date ? getDate(data.date, false, true) : '—';
                    });
                    setGeoViolationData({
                        inCount: resp?.data?.[0]?.totalInCount || 0,
                        outCount: resp?.data?.[0]?.totalOutCount || 0,
                        records: resp.data
                    });
                    setGeoTotalRecords(resp.totalRecords);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const returnData = (key, val, edit) => {
        return edit && key === 'Device State' ? (
            <tr>
                <td className="fw-medium w-50">
                    <div className="d-flex align-items-center">{key}</div>
                </td>
                <td className="w-50 word-wrap-break">
                    <div className="d-flex align-items-center">
                        {!showDeviceStateDropdown ? (
                            <>
                                {val ? val : '—'}
                                <IsAuthorized
                                    privRequired={priviliges.DEVICE_EDITOR}
                                    yes={() => (
                                        <i
                                            title="Edit"
                                            className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                            onClick={() => setShowDeviceStateDropdown(true)}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            selectComponent(key)
                        )}
                    </div>
                </td>
            </tr>
        ) : edit && key === 'Device Group' ? (
            <tr>
                <td className="fw-medium w-50">
                    <div className="d-flex align-items-center">
                        {key}
                        {
                            <>
                                <i className="ri-information-line blue-icon ps-1 mt-1" id={'UncontrolledTooltipExample'} />
                                <UncontrolledTooltip placement="bottom" target={'UncontrolledTooltipExample'}>
                                    It will update once the device gets refreshed.
                                </UncontrolledTooltip>
                            </>
                        }
                    </div>
                </td>
                <td className="w-50 word-wrap-break">
                    <div className="d-flex align-items-center">
                        {val ? val : '—'}
                        <IsAuthorized
                            privRequired={priviliges.TVDEVICE_EDITOR}
                            yes={() => (
                                <i
                                    title="Edit"
                                    className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                    onClick={() => setShowDeviceGroup(true)}
                                />
                            )}
                        />
                    </div>
                </td>
            </tr>
        ) : edit && key === 'Policy' ? (
            <tr>
                <td className="fw-medium w-50">
                    <div className="d-flex align-items-center">
                        {key}
                        {showPolicyDropdown && (
                            <>
                                <i className="ri-information-line blue-icon ps-1" id={'UncontrolledTooltipExample'} />
                                <UncontrolledTooltip placement="bottom" target={'UncontrolledTooltipExample'}>
                                    It will update once the device gets refreshed.
                                </UncontrolledTooltip>
                            </>
                        )}
                    </div>
                </td>
                <td className="w-50 word-wrap-break">
                    <div className="d-flex align-items-center">
                        {!showPolicyDropdown ? (
                            <>
                                {val ? val : '—'}
                                <IsAuthorized
                                    privRequired={priviliges.DEVICE_EDITOR}
                                    yes={() => (
                                        <i
                                            title="Edit"
                                            className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                            onClick={() => setShowPolicyDropdown(true)}
                                        />
                                    )}
                                />
                            </>
                        ) : (
                            selectComponent(key)
                        )}
                    </div>
                </td>
            </tr>
        ) : (
            /*
             * : edit && key === 'Phone Number' ? (
             *     <tr>
             *         <td className="fw-medium w-50">
             *             <div className="d-flex align-items-center">
             *                 {key}
             *                 {showPolicyDropdown && (
             *                     <>
             *                         <i className="ri-information-line blue-icon ps-1" id={'UncontrolledTooltipExample'} />
             *                         <UncontrolledTooltip placement="bottom" target={'UncontrolledTooltipExample'}>
             *                             It will update once the device gets refreshed.
             *                         </UncontrolledTooltip>
             *                     </>
             *                 )}
             *             </div>
             *         </td>
             *         <td className="w-50 word-wrap-break">
             *             <div className="d-flex align-items-center">
             *                 {!showPhoneInput ? (
             *                     <>
             *                         {val ? val : '—'}
             *                         <IsAuthorized
             *                             privRequired={priviliges.DEVICE_EDITOR}
             *                             yes={() => (
             *                                 <i
             *                                     title="Edit"
             *                                     className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
             *                                     onClick={() => setShowPhoneInput(true)}
             *                                 />
             *                             )}
             *                         />
             *                     </>
             *                 ) : (
             *                     inputComponent(key)
             *                 )}
             *             </div>
             *         </td>
             *     </tr>
             * ):
             */
            <tr>
                <td className="fw-medium w-50">
                    {key === 'Total internal storage' ? (
                        <div className="d-flex align-items-center h-100">
                            {key + ' '}
                            <i className="ri-information-line cursor-pointer ms-1 link-primary" id={'info-' + key?.replaceAll(' ', '')} />
                            {Tooltip(
                                `info-${key?.replaceAll(' ', '')}`,
                                'This the total internal storage after the systems apps are installed in the factory.'
                            )}
                        </div>
                    ) : (
                        key
                    )}
                </td>
                <td className="text-grey-text fw-medium w-50 word-wrap-break">
                    <div className="d-flex align-items-center">{val ? val : '—'}</div>
                </td>
            </tr>
        );
    };

    /*
     * const handlePhoneChange = (e) => {
     *     let regex = new RegExp(/^[0-9]+$/);
     *     let num = Number(e.target.value);
     *     let valid = regex.test(num);
     *     if (valid && e.target.value?.length <= 10) {
     *         setPhoneNumber(e.target.value);
     *     }
     * };
     */

    /*
     * const onCancelPhone = () => {
     *     setPhoneNumber(tvDetails?.deviceSummary?.phone);
     *     setShowPhoneInput(false);
     * };
     */

    /*
     * const onSubmitPhone = () => {
     *     setLoading(true);
     *     setShowPhoneInput(false);
     *     api.update(url.TV_DEVICES + '/update/' + tvDetails.serial, { phoneNumber: phoneNumber })
     *         .then((resp) => {
     *             resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
     *             if (resp.status === 'success') {
     *                 handlePromise();
     *             }
     *             setLoading(false);
     *         })
     *         .catch((err) => setLoading(false));
     * };
     */

    const handleSubBreadcrumb = (group, ind) => {
        if (ind !== subBreadcrumbItems.length - 1) {
            let crumbs = [...subBreadcrumbItems];
            crumbs.splice(ind + 1);
            setSubBreadcrumbItems(crumbs);
            if (!group.key) {
                setSelectedDeviceGroup({});
                let groupsArr = JSON.parse(JSON.stringify(deviceGroupOptionsBackup));
                groupsArr.forEach((groupObj) => delete groupObj.checked);
                setDeviceGroupsOptions(groupsArr);
            } else {
                setSelectedDeviceGroup(group);
                let groupsArr = JSON.parse(JSON.stringify(group.children));
                groupsArr.forEach((groupObj) => delete groupObj.checked);
                setDeviceGroupsOptions(groupsArr);
            }
        }
    };

    const handleMoveView = (_cell, row) => {
        setSelectedDeviceGroup(row.original);
        setSubBreadcrumbItems([...subBreadcrumbItems, row.original]);
        setDeviceGroupsOptions(row.original.children ? row.original.children : []);
    };

    const deviceGroupBody = () => {
        return (
            <React.Fragment>
                <div className={'mt-5 mb-4 d-flex align-items-center'}>
                    {subBreadcrumbItems?.map((crumbs, ind) => (
                        <React.Fragment key={ind}>
                            {ind !== subBreadcrumbItems?.length - 1 ? (
                                <span
                                    className={`fw-medium font-size-12 text-muted ${
                                        ind !== subBreadcrumbItems?.length - 1 ? ' cursor-pointer' : ''
                                    }`}
                                    onClick={() => handleSubBreadcrumb(crumbs, ind)}
                                >
                                    {crumbs.name}
                                </span>
                            ) : (
                                <span
                                    className={`fw-medium font-size-12 ${ind !== subBreadcrumbItems?.length - 1 ? ' cursor-pointer' : ''}`}
                                    style={{ color: crumbs.avatarColor }}
                                >
                                    {crumbs.name}
                                </span>
                            )}
                            {ind !== subBreadcrumbItems?.length - 1 && <i className="ri-arrow-right-s-line ms-2" />}
                        </React.Fragment>
                    ))}
                </div>
                <TableContainer
                    loading={loading}
                    data={deviceGroupOptions}
                    columns={moveGroupsSchema}
                    handleView={handleMoveView}
                    hideAvatar={true}
                    customPageSize={10}
                    totalRecords={deviceGroupOptions.length ? deviceGroupOptions.length : 0}
                    editor={priviliges.DEVICEGROUP_EDITOR}
                    reader={priviliges.DEVICEGROUP_READER}
                    className="custom-header-css"
                    tableHeader="All Device Groups"
                    divClass="table-responsive table-card"
                    tableClass="table table-nowrap table-border table-centered align-middle"
                    theadClass="bg-light text-muted"
                />
            </React.Fragment>
        );
    };

    /*
     * const inputComponent = (key) => {
     *     return (
     *         <React.Fragment>
     *             <Input
     *                 name={'phoneNumber'}
     *                 id={'phoneNumber'}
     *                 className="form-control"
     *                 placeholder={`Enter ${key}`}
     *                 type={'text'}
     *                 validate={{ required: { value: true } }}
     *                 onChange={(e) => handlePhoneChange(e)}
     *                 value={phoneNumber}
     *             />
     *             <i title="Close" className="ri-close-line ms-2 me-2 fs-18 cursor-pointer link-danger" onClick={() => onCancelPhone()} />
     *             <i title="Submit" className="ri-check-line fs-18 cursor-pointer link-primary" onClick={() => onSubmitPhone()} />
     *         </React.Fragment>
     *     );
     * };
     */

    const onCancelPolicy = () => {
        setPolicy(policyBackup);
        setShowPolicyDropdown(false);
    };

    const onCancelDeviceState = () => {
        setDeviceStatus(deviceStatusBackup);
        setShowDeviceStateDropdown(false);
    };

    const onSubmitPolicy = () => {
        setLoading(true);
        setShowPolicyDropdown(false);
        api.patch(url.DEVICES + '/' + recordID, { 'assignment.policy': policy.code }, false, domains.ANDROID_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    handlePromise();
                } else setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const onSubmitDeviceState = () => {
        setLoading(true);
        const dataObj = {
            device: recordID,
            command: deviceStatus?.code,
            cmdType: 'DeviceActions',
            params: {
                RequestType: deviceStatus?.code,
                Message: ''
            }
        };
        api.create(url.TV_COMMANDS, dataObj, false, domains?.ANDROID_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    setShowDeviceStateDropdown(false);
                    handlePromise();
                } else setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const onChangeGroup = () => {
        setLoading(true);
        setDeviceGroupsOptions(deviceGroupOptionsBackup);
        setSubBreadcrumbItems([{ name: 'Main Group' }]);
        setShowDeviceGroup(false);
        api.patch(url.DEVICES + '/' + recordID, { 'assignment.group': selectedDeviceGroup.code }, false, domains.ANDROID_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    handlePromise();
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) params = { ...searchParams, fromDate: convertDate(val?.[0], '/'), toDate: convertDate(val?.[1], '/') };
        else params = { ...searchParams, fromDate: undefined, toDate: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setGeoViolationPage(1);
        setDateChange(params);
        if (params.fromDate && params.toDate) getGeoFence({ ...params, page: 1, size: 20 });
        else if (val?.length === 0) getGeoFence({ ...params, page: 1, size: 20 });
    };

    const handleGeoPageChange = (pgNum) => {
        setGeoViolationPage(pgNum);
        getGeoFence({ ...dateChange, page: pgNum, size: 20 });
    };

    const togglecol = (key) => {
        setCol({ ...col, [key]: !col?.[key] });
    };

    const toggle1 = useCallback(async () => {
        setDeviceGroupsOptions(deviceGroupOptionsBackup);
        setSubBreadcrumbItems([{ name: 'Main Group' }]);
        setSelectedDeviceGroup({ ...defaultGroup });
        setShowDeviceGroup(!showDeviceGroup);
    }, [showDeviceGroup]);

    const selectComponent = (key) => {
        return (
            <React.Fragment>
                <Select
                    getOptionValue={(option) => option.code}
                    getOptionLabel={(option) => (key === 'Policy' ? <div>{`${option.name} - ${option.code}`}</div> : option?.code)}
                    id={key}
                    className="w-100"
                    name={'name'}
                    options={key === 'Policy' ? policyOptions : deviceStateOptions}
                    value={key === 'Policy' ? policy : deviceStatus}
                    isSearchable={true}
                    placeholder={`Select ${key}`}
                    classNamePrefix={'custom-select'}
                    onChange={(e) => (key === 'Policy' ? setPolicy(e) : setDeviceStatus(e))}
                    noOptionsMessage={() => 'No data found'}
                />
                <i
                    title="Close"
                    className="ri-close-line ms-2 me-2 fs-18 cursor-pointer link-danger"
                    onClick={() => (key === 'Policy' ? onCancelPolicy() : onCancelDeviceState())}
                />
                <i
                    title="Submit"
                    className="ri-check-line fs-18 cursor-pointer link-primary"
                    onClick={() => (key === 'Policy' ? onSubmitPolicy() : onSubmitDeviceState())}
                />
            </React.Fragment>
        );
    };

    const geoViolationBody = () => {
        return (
            <React.Fragment>
                <Row>
                    <div className="col-sm-auto">
                        <div className="form-icon flex-nowrap">
                            <Flatpickr
                                ref={fp}
                                value={dateVal}
                                placeholder={'Search by Date'}
                                className={'form-control form-control-icon w-100 date-filter-picker'}
                                options={{ mode: 'range', dateFormat: 'd M, Y' }}
                                onChange={(e) => {
                                    setDateVal(e);
                                    handleDateSearch(e);
                                }}
                            />
                            <i className="ri-calendar-2-line" />
                        </div>
                    </div>
                </Row>
                <div className={'border-radius-top-left-10 border-radius-top-right-10 padding-y-20 padding-x-15 appusage-background mt-2'}>
                    <Row>
                        <Col sm={4} lg={4} xl={4}>
                            <div className="text-light fw-normal fs-14">In Count</div>
                            <div className="text-light fw-semibold fs-20 margin-top-10">{geoViolationData.inCount}</div>
                        </Col>
                        <Col sm={5} lg={5} xl={5}>
                            <div className="text-light fw-normal fs-14">Out Count</div>
                            <div className="text-light fw-semibold fs-20 margin-top-10">{geoViolationData.outCount}</div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <div>
                        <div className="profile-timeline">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {geoViolationData?.records?.length > 0 ? (
                                    geoViolationData?.records?.map((location, ind) => {
                                        return handleGeoViolationHistoryFunc({ togglecol, col, location, ind });
                                    })
                                ) : (
                                    <div className="fs-13 d-flex align-items-center justify-content-center h-100">
                                        {'No Records Found.'}
                                    </div>
                                )}
                            </div>
                            {geoTotalRecords > 15 && (
                                <Pagination
                                    activePage={geoViolationPage}
                                    itemsCountPerPage={20}
                                    totalItemsCount={geoTotalRecords}
                                    pageRangeDisplayed={3}
                                    marginTopClass={'mt-1'}
                                    onChange={handleGeoPageChange}
                                    hideFirstLastPages={false}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const getLocationHistory = (paramsObj) => {
        setLoading(true);
        let params = {
            ...paramsObj,
            orderby: 'a.eventdate desc',
            filter: { devices: [recordID] }
        };
        api.create(url.LOCATION_HISTORY_REPORT, params, false, domains.REPORTING)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data?.length > 0) {
                    resp.data.forEach((ele) => {
                        if (ele?.eventdate) ele.dateListed = getDate(ele.eventdate);
                    });
                    setLocationsHistory(resp.data);
                    setLocationHistoryTotalRecords(resp.totalRecords);
                }
                setLoading(false);
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const handleMonitoring = (key) => {
        setOffcanvasModal(true);
        setSelectedMonitor(key);
        let date = new Date();
        setFromDate(date);
        setToDate(date);
        if (key.type === 'app_usage') {
            getAppUsage(usageTime, { label: 'Custom Apps', value: 'custom' }, date, date);
        } else if (key.type === 'geo_fence_violation') {
            getGeoFence({ page: 1, size: 20, id: tvDetails.id });
        }
        if (key.type === 'location_history') {
            getLocationHistory({ page: 1, size: 20 });
        }
    };

    const icon = () => {
        return tvDetails.brand?.toLowerCase() === 'acer'
            ? acerIcon
            : tvDetails.brand?.toLowerCase() === 'samsung'
            ? samsungIcon
            : tvDetails.brand?.toLowerCase() === 'nokia'
            ? nokiaIcon
            : tvDetails.brand?.toLowerCase() === 'hp'
            ? hpIcon
            : tvDetails.brand?.toLowerCase() === 'celkon'
            ? celkonIcon
            : tvDetails.brand?.toLowerCase() === 'vu'
            ? vu
            : '';
    };

    const handleSync = () => {
        let timeDiff = new Date() - refreshTime;
        if (refreshTime && timeDiff < 5000) {
            let timeAfterOneMinute = refreshTime.getTime() + 5000;
            var date = new Date(timeAfterOneMinute);
            var hours = date.getHours();
            var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            var formattedTime = hours + ':' + minutes + ':' + seconds;
            toast.error(toastMessages.refreshTime);
            return;
        } else setRefreshTime(new Date());
        setLoading(true);
        handlePromise();
    };

    const monitoringItems = [
        {
            label: 'App Usage',
            icon: 'ri-file-chart-line',
            type: 'app_usage',
            header: 'App Usage - ',
            status: 'active',
            show: !!tenantConfig?.TV_DEVICE_ACTIONS?.includes('APP_USAGE'),
            enable: 'appUsageReport'
        },
        /*
         * {
         *     label: 'Geofence Violation',
         *     icon: 'ri-map-pin-range-line',
         *     type: 'geo_fence_violation',
         *     header: 'Geofence - ',
         *     show: !!tenantConfig?.TV_DEVICE_ACTIONS?.includes('GEOFENCE'),
         *     enable: 'geofence',
         *     disableTooltip: 'Geofence not configured on this device.'
         * },
         */
        {
            label: 'Location History',
            icon: 'ri-map-pin-line',
            type: 'location_history',
            header: 'Location History - ',
            show: !!tenantConfig?.TV_DEVICE_ACTIONS?.includes('LOCATION_HISTORY'),
            enable: 'locationhistory',
            disableTooltip: 'Location history not configured on this device'
        }
    ];

    const toggle = useCallback(async () => {
        let date = new Date();
        let date2 = new Date(date);
        date2.setDate(date.getDate() + 1);
        setFromDate(date);
        setToDate(date2);
        setAppUsage([]);
        setDateVal([]);
        setLocationsHistory([]);
        setGeoViolationPage(1);
        setOffcanvasModal(!offcanvasModal);
    }, [offcanvasModal]);

    const handleAppUsageDaily = (resp) => {
        let totalTime = JSON.parse(JSON.stringify(Number(resp?.data?.total_screen_time ? resp?.data?.total_screen_time : 0) * 1000));
        let totalDetails = {
            mobileData: convertBytesTo(JSON.parse(JSON.stringify(resp?.data?.total_mobile_data ? resp?.data?.total_mobile_data : 0))),
            data: convertBytesTo(JSON.parse(JSON.stringify(resp?.data?.total_data_usage ? resp?.data?.total_data_usage : 0))),
            wifiData: convertBytesTo(JSON.parse(JSON.stringify(resp?.data?.total_wifi_data ? resp?.data?.total_wifi_data : 0)))
        };
        let appUsageArr = [];
        delete resp.data.environment;
        delete resp.data.total_mobile_data;
        delete resp.data.total_data_usage;
        delete resp.data.total_screen_time;
        delete resp.data.total_wifi_data;
        delete resp.data.id;
        delete resp.data.tenant;
        delete resp.data.source;
        delete resp.data.total_ethernet_data;
        Object.entries(resp.data).forEach((data) => {
            let { days, hours, minutes, seconds } = convertSecondsTo(JSON.parse(JSON.stringify(Number(data[1].screenTime) * 1000)));
            appUsageArr.push({
                packageName: data[0],
                title: data[1]?.appName && data[1]?.appName !== '' && data[1]?.appName !== '⁣' ? data[1]?.appName : data[0],
                timeInSeconds: Number(data[1]?.screenTime),
                spentTime: formatDate(days, hours, minutes, seconds),
                datUsage: convertBytesTo(data[1]?.totalData),
                wifiData: convertBytesTo(data[1]?.wifiData),
                mobileData: convertBytesTo(data[1]?.mobileData)
            });
        });
        let { days, hours, minutes, seconds } = convertSecondsTo(totalTime);
        totalDetails.apps = appUsageArr.length;
        totalDetails.time = formatDate(days, hours, minutes, seconds);
        setTotalAppDetails(totalDetails);
        appUsageArr = sortByAsc(appUsageArr, 'timeInSeconds');
        setAppUsage(appUsageArr);
    };

    const handleAppsUsageHourly = (resp) => {
        let appUsageArr = [];
        Object.entries(resp.data).forEach((data) => {
            let dataUsed = data[1].app_usage ? JSON.parse(JSON.stringify(data[1].app_usage)) : 0;
            let obj = {
                title: '',
                spentTime: '',
                time: 0,
                datUsage: convertBytesTo(dataUsed * 1024),
                data: dataUsed * 1024,
                expanded: false,
                expandedData: []
            };
            delete data[1].app_usage;
            Object.entries(data[1]).forEach((timeInterval) => {
                obj.title = timeInterval[1].packageName ? timeInterval[1].packageName : data[0];
                obj.time = Number(obj.time) + Number(timeInterval?.[1]?.appUsageTime);
                let intervalVal = APP_USAGE_INTERVALS[timeInterval?.[0]];
                let { days, hours, minutes, seconds } = convertSecondsTo(Number(timeInterval[1]?.appUsageTime) * 1000);
                let timeVal = formatDate(days, hours, minutes, seconds);
                let intervalObj = { interval: intervalVal, time: timeVal };
                obj.expandedData.push(intervalObj);
            });
            let newObj = JSON.parse(JSON.stringify(obj));
            let { days, hours, minutes, seconds } = convertSecondsTo(Number(newObj.time) * 1000);
            newObj.spentTime = formatDate(days, hours, minutes, seconds);
            appUsageArr.push(newObj);
        });
        let totalDataUsage = 0;
        let totalTimeSpent = 0;
        let totalApps = appUsageArr.length;
        if (appUsageArr.length > 0) {
            appUsageArr.forEach((app) => {
                totalTimeSpent = totalTimeSpent + Number(app.time);
                totalDataUsage = totalDataUsage + Number(app.data);
            });
        }
        let { days, hours, minutes, seconds } = convertSecondsTo(totalTimeSpent * 1000);
        let timeVal = formatDate(days, hours, minutes, seconds);
        appUsageArr = sortByAsc(appUsageArr, 'time');
        setTotalAppDetails({ time: timeVal, data: convertBytesTo(totalDataUsage), apps: totalApps });
        setAppUsage(appUsageArr);
    };

    const handleAppsUsageFunc = ({ resp, setTotalAppDetails, setAppUsage, setLoading, setSidePanelLoader }) => {
        let totalTime = 0;
        let totalDetails = {
            mobileData: 0,
            data: 0,
            wifiData: 0
        };
        let appUsageArr = [];
        resp?.data.forEach((data) => {
            let { days, hours, minutes, seconds } = convertSecondsTo(JSON.parse(JSON.stringify(Number(data.timespent) * 1000)));
            totalTime = totalTime + Number(data?.timespent) * 1000;
            totalDetails.mobileData = totalDetails.mobileData + data?.mobiledata;
            totalDetails.wifiData = totalDetails.wifiData + data?.wifidata;
            totalDetails.data = totalDetails.data + data?.dataused;
            appUsageArr.push({
                packageName: data?.package || '',
                title: data.appname || '',
                timeInSeconds: Number(data?.timespent),
                spentTime: formatDate(days, hours, minutes, seconds),
                datUsage: convertBytesTo(data?.dataused),
                wifiData: convertBytesTo(data?.wifidata),
                mobileData: convertBytesTo(data?.mobiledata)
            });
        });
        let { days, hours, minutes, seconds } = convertSecondsTo(totalTime);
        totalDetails.mobileData = convertBytesTo(totalDetails.mobileData);
        totalDetails.wifiData = convertBytesTo(totalDetails.wifiData);
        totalDetails.data = convertBytesTo(totalDetails.data);
        totalDetails.apps = appUsageArr.length;
        totalDetails.time = formatDate(days, hours, minutes, seconds, 'text-grey-1');
        setTotalAppDetails(totalDetails);
        appUsageArr = sortByAsc(appUsageArr, 'timeInSeconds');
        setAppUsage(appUsageArr);
        setLoading(false);
        setSidePanelLoader(false);
    };

    const getAppUsage = async (duration, usageType, from, to) => {
        setLoading(true);
        setSidePanelLoader(true);
        const getFromDate = async () => {
            if (from) {
                from = JSON.parse(JSON.stringify(add530ToTime(from)));
                from = new Date(from);
                await from.setUTCHours(0, 0, 0, 0);
                return from.toISOString();
            }
            return '';
        };
        const getToDate = async () => {
            if (to) {
                to = JSON.parse(JSON.stringify(add530ToTime(to)));
                to = new Date(to);
                await to.setUTCHours(23, 59, 59, 0);
                return to.toISOString();
            }
            return '';
        };
        let paramsObj = {
            page: 1,
            size: 100,
            table: 'appusage_history',
            groupby: 'appname',
            orderby: 'timespent desc,appname',
            filter: {
                from: await getFromDate(),
                to: await getToDate(),
                devices: [recordID],
                groups: []
            },
            select: 'appname, SUM(timespent) as timespent, SUM(dataused) as dataused, SUM(mobiledata) as mobiledata, SUM(wifidata) as wifidata'
        };

        api.create(url.QUERY_PAGE, paramsObj, false, domains.REPORTING)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    handleAppsUsageFunc({ resp, setTotalAppDetails, setAppUsage, setLoading, setSidePanelLoader });
                }
                setLoading(false);
            })
            .catch((_err) => {
                setLoading(false);
                setSidePanelLoader(false);
            });
    };

    const handleUsageType = (e) => {
        setAppUsageType(e);
        if (fromDate && toDate && fromDate !== '' && toDate !== '') getAppUsage('', e, fromDate, toDate);
        else getAppUsage('', e);
    };

    const handleDateFilter = (e, key) => {
        if (key === 'fromDate') {
            setFromDate(e);
            if (toDate && toDate !== '' && e[0]) getAppUsage('', appUsageType, e[0], toDate);
        } else if (key === 'toDate') {
            setToDate(e);
            if (fromDate && fromDate !== '' && e[0]) getAppUsage('', appUsageType, fromDate, e[0]);
        }
    };

    const checkValue = (value) => {
        return value !== null && value !== undefined;
    };

    const formatLabel = (value, label) => {
        if (checkValue(label)) return value + ' ' + label;
        else return value;
    };

    const actionMessage = () => {
        return type === 'lock' ? (
            <React.Fragment>
                <div className="mt-3">
                    <div className="mb-4">Are you sure you want to lock Device?</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={lockDevice} alt="device" width={60} height={60} />
                    </div>
                    <div className="mb-4">
                        Serial: <span className="fw-semibold">{tvDetails?.deviceSummary?.androidId}</span>
                    </div>
                    <div>
                        <Row>
                            <Col xs={6} md={6} sm={6} lg={5} xl={5} className={'d-flex align-items-center justify-content-between'}>
                                <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                                    Message
                                    <span className="ps-1 text-danger">*</span>
                                </Label>
                            </Col>
                            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                                <div className="input-group">
                                    <Input
                                        name={'message'}
                                        id={'message'}
                                        className="form-control"
                                        placeholder={'Enter Message'}
                                        type={'textarea'}
                                        onChange={(e) => setMessage(e.target.value)}
                                        value={message}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        ) : type === 'reboot' ? (
            <React.Fragment>
                <div className="mt-3">
                    <div className="mb-4">Are you sure you want to reboot Device?</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={rebootDevice} alt="device" width={60} height={60} />
                    </div>
                    <div>
                        Device ID: <span className="fw-semibold">{tvDetails?.deviceSummary?.androidId}</span>
                    </div>
                </div>
            </React.Fragment>
        ) : type === 'volume' ? (
            <React.Fragment>
                <div className="mt-3">
                    <div className="text-primary fw-semibold">Set Volume Control:</div>
                    <div className="mt-2 fs-14">
                        The volume has been set to {policyBackup?.policy?.config?.VOLUME?.restrictions?.value}% at the policy level.
                        However, you can still adjust the volume at the device level as needed.
                    </div>

                    <div className="d-flex align-items-center justify-content-between mt-2">
                        <span>{0}</span>
                        <span>Value: {volume} % </span>
                        <span>{100}</span>
                    </div>
                    <div className="d-flex align-items-center mt-1 gap-3">
                        <i className="ri-volume-up-fill text-muted fs-22"></i>
                        <div className="custom-range-slider-info w-100">
                            <Slider
                                className="my-3 w-100"
                                min={0}
                                max={100}
                                step={1}
                                format={(value) => formatLabel(value, '%')}
                                onChange={(e) => setVolume(e)}
                                value={volume}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        ) : type === 'power_controls' ? (
            <React.Fragment>
                <div className="mt-3 d-flex align-items-center justify-content-center flex-column">
                    <div className="mb-4">Are you sure you want to power {powerStatus?.toLowerCase()} ?</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <div className="p-1 d-flex align-items-center border border-1 border-info rounded-3">
                            <div
                                className={`${
                                    powerStatus !== 'ON' ? 'bg-info text-light' : 'bg-light text-dark'
                                } w-50 px-2 py-1 cursor-pointer`}
                                onClick={() => {
                                    setPowerStatus('OFF');
                                    setActionText('Yes, Power off it!');
                                }}
                            >
                                OFF
                            </div>
                            <div
                                className={`${
                                    powerStatus === 'ON' ? 'bg-info text-light' : 'bg-light text-dark'
                                } w-50 px-2 py-1 cursor-pointer`}
                                onClick={() => {
                                    setPowerStatus('ON');
                                    setActionText('Yes, Power on it!');
                                }}
                            >
                                ON
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        ) : type === 'input_method' ? (
            <React.Fragment>
                <div className="mt-3">
                    <div className="mt-3">
                        <div className="text-primary fw-semibold mb-1">Set Input Source</div>
                        <div className="text-muted fs-12 mb-3">Admin can choose the HDMI port at device level</div>
                        <Select
                            options={inputOptions}
                            onChange={(e) => setInputMethod(e)}
                            value={inputMethod}
                            placeholder={'Select'}
                            noOptionsMessage={() => 'No data found'}
                        />
                    </div>
                </div>
            </React.Fragment>
        ) : type === 'shutdown' ? (
            <React.Fragment>
                <div className="mt-3 text-center">
                    <div className="mb-4">Are you sure you want to turn off this Device?</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={factoryReset} alt="device" width={60} height={60} />
                    </div>
                    <div>
                        Device ID: <span className="fw-semibold">{tvDetails?.deviceSummary?.androidId}</span>
                    </div>
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="mt-3 text-center">
                    <div className="mb-4">Are you sure you want to reset Device?</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={factoryReset} alt="device" width={60} height={60} />
                    </div>
                    <div>
                        Device ID: <span className="fw-semibold">{tvDetails?.deviceSummary?.androidId}</span>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const actionItems = [
        {
            id: 'power_controls',
            label: 'Power Controls',
            show: !!tenantConfig?.TV_DEVICE_ACTIONS?.includes('POWER_CONTROLS'),
            icon: 'ri-wireless-charging-line',
            actionBody: {
                device: recordID,
                command: 'PowerControl',
                cmdType: 'DeviceActions',
                params: {
                    RequestType: 'PowerControl'
                }
            },
            clickFunction: () => {
                setType('power_controls');
                setActionModal(true);
                setActionText('Yes, Power on it!');
            },
            condition: tvDetails.showActions
        },
        {
            id: 'input_method',
            label: 'Input Source',
            show: !!tenantConfig?.TV_DEVICE_ACTIONS?.includes('INPUT_METHOD'),
            icon: 'ri-ram-line',
            actionBody: {
                device: recordID,
                command: 'InputMethod',
                cmdType: 'DeviceActions',
                params: {
                    RequestType: 'InputMethod'
                }
            },
            clickFunction: () => {
                setType('input_method');
                setActionModal(true);
                setActionText('Update');
            },
            condition: tvDetails.showActions
        },
        {
            id: 'reboot',
            label: 'Reboot',
            show: !!tenantConfig?.TV_DEVICE_ACTIONS?.includes('REBOOT'),
            icon: 'ri-creative-commons-sa-line',
            actionBody: {
                device: recordID,
                command: 'RestartDevice',
                cmdType: 'DeviceActions',
                params: {
                    RequestType: 'RestartDevice'
                }
            },
            clickFunction: () => {
                setType('reboot');
                setActionModal(true);
                setActionText('Yes, Reboot it!');
            },
            condition: tvDetails.showActions
        },
        {
            id: 'shutdown',
            label: 'Shutdown',
            show: !!tenantConfig?.TV_DEVICE_ACTIONS?.includes('SHUTDOWN'),
            icon: 'ri-shut-down-line',
            actionBody: {
                device: recordID,
                command: 'ShutdownDevice',
                cmdType: 'DeviceActions',
                params: {
                    RequestType: 'ShutdownDevice'
                }
            },
            clickFunction: () => {
                setType('shutdown');
                setActionModal(true);
                setActionText('Yes, Shut it down!');
            },
            condition: tvDetails.showActions
        },
        {
            id: 'lock',
            label: 'Lock',
            show: !!tenantConfig?.TV_DEVICE_ACTIONS?.includes('LOCK'),
            icon: 'ri-phone-lock-line',
            actionBody: {
                device: recordID,
                command: 'LockDevice',
                cmdType: 'DeviceActions',
                params: {
                    RequestType: 'LockDevice'
                }
            },
            clickFunction: () => {
                setType('lock');
                setActionModal(true);
                setActionText('Yes, Lock it!');
            },
            condition: tvDetails.showActions
        },

        {
            id: 'factoryReset',
            label: 'Factory Reset',
            icon: 'ri-settings-5-line',
            show: !!tenantConfig?.TV_DEVICE_ACTIONS?.includes('FACTORY_RESET'),
            actionBody: {
                device: recordID,
                command: 'EraseDevice',
                cmdType: 'DeviceActions',
                params: {
                    RequestType: 'EraseDevice'
                }
            },
            clickFunction: () => {
                setType('wipe');
                setActionModal(true);
                setActionText('Yes, Reset it!');
            },
            condition: tvDetails.showActions
        },
        {
            id: 'volume',
            label: 'Volume',
            icon: 'ri-volume-up-line',
            show: !!tenantConfig?.TV_DEVICE_ACTIONS?.includes('VOLUME'),
            actionBody: {
                device: recordID,
                command: 'VolumeControl',
                cmdType: 'DeviceActions',
                params: {
                    RequestType: 'VolumeControl'
                }
            },
            clickFunction: () => {
                setType('volume');
                setActionText('Update');
                setActionModal(true);
            },
            condition: tvDetails.showActions && !!policyBackup?.policy?.generalsettings?.volume
        },
        {
            id: 'refresh',
            label: 'Refresh',
            icon: 'ri-refresh-line',
            show: !!tenantConfig?.TV_DEVICE_ACTIONS?.includes('REFRESH'),
            clickFunction: () => {
                handleSync();
            },
            condition: true
        }
    ];

    const actionService = () => {
        setActionModal(false);
        let params = { ...selectedAction?.actionBody };
        if (type === 'lock') params.params.Message = message;
        else if (type === 'input_method') params.params.value = inputMethod?.value;
        else if (type === 'power_controls') params.params.value = powerStatus;
        else if (type === 'volume') params.params.value = volume?.toString();
        tvDeviceActions({
            id: tvDetails.serial,
            type: type?.toUpperCase(),
            actionBody: params,
            toastMsg: toastMessage?.[type?.toUpperCase()],
            setLoading: setLoading,
            setShowModal: setActionModal,
            urlconf: urlconf
        });
        setMessage('');
    };

    const handleExpandApp = (app, ind) => {
        let appUsageArr = [...appUsage];
        appUsageArr?.forEach((appObj, index) => {
            if (index === ind) appObj.expanded = !appObj?.expanded;
        });
        setAppUsage(appUsageArr);
    };

    const handleExportAppUsage = () => {
        setLoading(true);
        let downloadList = [
            ['Device ID', tvDetails?.deviceSummary?.androidId],
            [],
            ['From Date', fromDate, '', 'To Date', toDate],
            [],
            ['Total Apps', 'Total Spent Time', 'Total Data Consumed'],
            [appUsage?.length ? appUsage?.length : '0', totalAppDetails.screenTime, totalAppDetails.dataUsage],
            []
        ];
        if (appUsage?.length > 0)
            downloadList.push(['App Name', 'Consumed Data', 'Consumed Wi-Fi Data', 'Consumed Ethernet Data', 'Spent Time']);
        appUsage.forEach((app) => {
            downloadList.push([app.appName, app.data, app.wifiData, app.ethernetData, app.time]);
        });
        var csvFileData = downloadList;
        var csv = '';
        csvFileData.forEach(function (row) {
            csv += row.join(',');
            csv += '\n';
        });
        var a = document.createElement('a');
        a.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = `App Usage Report ${tvDetails.serial}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    };

    const appUsageBody = () => {
        const getMaxDateForTo = () => {
            let date = new Date();
            date.setHours(23, 59, 59, 0);
            return date;
        };
        return (
            <div>
                {/* <Row className="p-0">
                    <div className="d-flex align-items-center justify-content-end">
                        <Button
                            type="button"
                            id="export"
                            color="primary"
                            className="padding-x-9"
                            disabled={appUsage?.details?.length === 0}
                            onClick={() => handleDownloadAppUsage()}
                        >
                            <span className="d-flex align-items-center">Export Report</span>
                        </Button>
                        {Tooltip('export', 'Export in CSV format.')}
                    </div>
                </Row> */}
                <Row className="p-0 mt-3">
                    {/* <Col sm={3}>
                        <div className="d-flex align-items-center w-100">
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                id={'appUsageType'}
                                name={'appUsageType'}
                                options={[
                                    { label: 'All', value: 'all' },
                                    { label: 'System Apps', value: 'system' },
                                    { label: 'Custom Apps', value: 'custom' }
                                ]}
                                placeholder="Select Usage Type"
                                onChange={(e) => handleUsageType(e)}
                                value={appUsageType}
                                isSearchable={true}
                                className="min-width-150 w-100"
                                classNamePrefix={'custom-select'}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </div>
                    </Col> */}
                    <Col sm={3} className="me-2">
                        <div className="form-icon flex-nowrap">
                            <Flatpickr
                                ref={fp1}
                                value={fromDate}
                                placeholder={'From date and time'}
                                className={'form-control form-control-icon min-width-160 me-2'}
                                options={{
                                    // enableTime: true,
                                    mode: 'single',
                                    maxDate: toDate ? new Date(toDate) : 'today',
                                    dateFormat: 'Y-m-d'
                                }}
                                onChange={(e) => {
                                    handleDateFilter(e, 'fromDate');
                                }}
                            />
                            <i className="ri-calendar-2-line" />
                        </div>
                    </Col>
                    {!tenantConfig.TV_HOURLY_APP_USAGE && (
                        <Col sm={3} className="ms-3">
                            <div className="form-icon flex-nowrap">
                                <Flatpickr
                                    ref={fp1}
                                    value={toDate}
                                    placeholder={'To date and time'}
                                    className={'form-control form-control-icon min-width-160 me-2'}
                                    options={{
                                        enableTime: false,
                                        mode: 'single',
                                        minDate: new Date(fromDate),
                                        maxDate: getMaxDateForTo(),
                                        dateFormat: 'Y-m-d'
                                    }}
                                    onChange={(e) => {
                                        handleDateFilter(e, 'toDate');
                                    }}
                                />
                                <i className="ri-calendar-2-line" />
                            </div>
                        </Col>
                    )}
                </Row>
                <Row className="p-0 mt-3">
                    <div className="border-radius-top-left-10 border-radius-top-right-10 padding-y-20 padding-x-15 appusage-background">
                        <Row>
                            <Col sm={3}>
                                <div className="text-light fw-normal fs-14">TOTAL APPS</div>
                                <div className="text-light fw-semibold fs-20 margin-top-10">{totalAppDetails.apps}</div>
                            </Col>
                            <Col sm={4}>
                                <div className="text-light fw-normal fs-14">TOTAL SPENT TIME</div>
                                <div className="text-light fw-semibold fs-20 margin-top-10">{totalAppDetails.time}</div>
                            </Col>
                            <Col sm={4}>
                                <div className="text-light fw-normal fs-14">TOTAL DATA CONSUMED</div>
                                <div className="d-flex">
                                    <div className="text-light fw-semibold fs-20 margin-top-10">
                                        <div className="br-25 border border-1 border-color-light d-flex align-items-center dataconsume-box padding-x-13 padding-y-7">
                                            <span className="me-2">{totalAppDetails.data}</span>
                                            <i className="ri-information-line cursor-pointer link-light" id={'info'} />
                                            {Tooltip(
                                                'info',
                                                <ol>
                                                    <li className="text-left">Mobile Data: {totalAppDetails.mobileData}</li>
                                                    <li>Wi-Fi Data: {totalAppDetails.wifiData}</li>
                                                </ol>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Card className="border-radius-bottom-right-10 border-radius-bottom-left-10 p-0 shadow-card">
                        <div className="border-radius-bottom-left-10 border-radius-bottom-right-10">
                            <table className="table table-nowrap">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col">App Name</th>
                                        <th scope="col">Spend Time</th>
                                        <th scope="col">Consumed Data</th>
                                        {tenantConfig.HOURLY_APP_USAGE && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {appUsage.length > 0 ? (
                                        appUsage.map((app, ind) => (
                                            <React.Fragment key={ind}>
                                                <tr className={`${ind % 2 !== 0 ? 'table-active' : ''}`}>
                                                    <th scope="row">{app.title}</th>
                                                    <td>{app.spentTime}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <span className="me-2">{app.datUsage}</span>
                                                            <i
                                                                className="ri-information-line cursor-pointer link-info"
                                                                id={`info-${ind}`}
                                                            />
                                                            {Tooltip(
                                                                `info-${ind}`,
                                                                <ol className="text-left">
                                                                    <li>Mobile Data: {app.mobileData}</li>
                                                                    <li>Wi-Fi Data: {app.wifiData}</li>
                                                                </ol>
                                                            )}
                                                        </div>
                                                    </td>
                                                    {tenantConfig.HOURLY_APP_USAGE && (
                                                        <td>
                                                            <div
                                                                className="d-flex align-items-center justify-content-center br-per-50 width-20 height-20 bg-primary cursor-pointer"
                                                                onClick={() => handleExpandApp(app, ind)}
                                                            >
                                                                <i
                                                                    className={`${
                                                                        app.expanded ? 'ri-arrow-up-s-fill' : 'ri-arrow-down-s-fill'
                                                                    }  link-light fs-18`}
                                                                />
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                                {app?.expanded && (
                                                    <tr>
                                                        <td colSpan="5">
                                                            <table className="table table-nowrap mb-0 ms-4">
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th scope="col">Time Interval</th>
                                                                        <th scope="col">Spend Time</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {app?.expandedData?.length > 0 ? (
                                                                        app?.expandedData?.map((expanded, index) => (
                                                                            <tr
                                                                                key={index}
                                                                                className={`${index % 2 !== 0 ? 'table-active' : ''}`}
                                                                            >
                                                                                <th scope="row">{expanded.interval}</th>
                                                                                <td>{expanded.time}</td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td>
                                                                                <span className="p-2">No Records Found.</span>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <tr>
                                            <td>
                                                <span className="p-2">No Records Found.</span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </Row>
            </div>
        );
    };

    const handleLocationsHistoryFunc = ({ togglecol, coll, location, ind }) => {
        return (
            <div className="accordion-item border-0" key={ind}>
                <div className="accordion-header" id="headingOne" onClick={() => togglecol(`col${ind}`)}>
                    <Link
                        to="#"
                        className={classNames('accordion-button', 'p-2', 'shadow-none', {
                            collapsed: coll?.[`col${ind}`]
                        })}
                    >
                        <div className="d-flex align-items-center w-100">
                            <div className="flex-shrink-0 avatar-xxs">
                                <div className={'avatar-title rounded-circle bg-primary'}>
                                    <i className="ri-map-pin-line"></i>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <Row className="fs-13 mb-0 fw-semibold">
                                    <Col sm={6} className="text-truncate" id={`location-${ind}`}>
                                        {location?.address2 || ''}
                                    </Col>
                                    {location?.address2?.length > 20 && Tooltip(`location-${ind}`, location.address2)}
                                    {location.dateListed && (
                                        <Col sm={6} className="text-truncate text-end">
                                            <span className="fw-normal text-end" id={`date-${ind}`}>
                                                {location.dateListed}
                                            </span>
                                            {Tooltip(`date-${ind}`, location.dateListed)}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </div>
                    </Link>
                </div>
                <Collapse id="collapseOne" className="accordion-collapse" isOpen={!col[`col${ind}`]}>
                    <div className="accordion-body ms-10 ps-1 pt-0">
                        <h6 className="mb-2">{location?.address1}</h6>
                    </div>
                </Collapse>
            </div>
        );
    };

    const handleLocationPageChange = (pageNumber) => {
        setActivePage(pageNumber);
        getLocationHistory({ page: pageNumber, size: 20 });
    };

    const handleDisabled = () => {
        let flag = false;
        if (type === 'lock') {
            flag = !message;
        }
        return flag;
    };

    const locationHistoryBody = () => {
        return (
            <div>
                <div>
                    <div className="profile-timeline">
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            {locationsHistory?.length > 0 ? (
                                locationsHistory?.map((location, ind) => {
                                    return handleLocationsHistoryFunc({ togglecol, col, location, ind });
                                })
                            ) : (
                                <div className="fs-13 d-flex align-items-center justify-content-center h-100">{'No Records Found.'}</div>
                            )}
                        </div>
                        {locationsHistoryTotalRecords > 20 && (
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={locationsHistoryTotalRecords}
                                pageRangeDisplayed={3}
                                marginTopClass={'mt-1'}
                                onChange={handleLocationPageChange}
                                hideFirstLastPages={false}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}

            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="View Android TV" history={history} homeLink="Dashboard" showBack={true} backLink="atvdevices" />
                    <Card className="p-3 mb-0">
                        <div className="d-flex align-items-center justify-content-between gap-2">
                            <div>
                                <div className="d-flex align-items-center">
                                    <div className="fs-15 fw-medium">Device ID :</div>
                                    <div className="fs-16 ps-2 text-info">{tvDetails?.deviceSummary?.androidId}</div>
                                </div>
                            </div>
                            <div>
                                <IsAuthorized
                                    privRequired={priviliges.TVDEVICE_EDITOR}
                                    yes={() => (
                                        <div className="d-flex align-items-center justify-content-end gap-1">
                                            {actionItems?.filter((action) => action.show)?.length > 0 && (
                                                <div className="fs-15 d-flex justify-content-center align-items-center fw-medium">
                                                    Actions :
                                                </div>
                                            )}
                                            {actionItems.map((action, ind) => {
                                                return (
                                                    action.show && (
                                                        <div
                                                            key={ind}
                                                            className={`width-35 height-35 d-flex align-items-center justify-content-center ${
                                                                action.condition ? 'bg-447BD107 cursor-pointer' : ''
                                                            }`}
                                                            onClick={() => {
                                                                setSelectedAction(action);
                                                                if (action.condition) action.clickFunction();
                                                            }}
                                                        >
                                                            <i
                                                                id={action.id}
                                                                className={`${action.icon} fs-20 ${
                                                                    action.condition ? 'action-hover link-info' : 'text-muted'
                                                                }`}
                                                            />
                                                            {Tooltip(action.id, action.label)}
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </div>
                                    )}
                                />
                            </div>
                            <Col sm={'auto'} md={3} lg={3} xl={2} className="d-flex align-items-center justify-content-center gap-1">
                                {monitoringItems?.filter((monitor) => monitor.show)?.length > 0 && (
                                    <div className="fs-15 d-flex justify-content-center align-items-center fw-medium">Monitoring :</div>
                                )}
                                {monitoringItems?.map((monitor, ind) => {
                                    return (
                                        monitor.show && (
                                            <div
                                                key={ind}
                                                className="width-35 height-35 d-flex align-items-center justify-content-center bg-447BD107 cursor-pointer"
                                                onClick={() => handleMonitoring(monitor)}
                                            >
                                                <i id={`monitor-${ind}`} className={monitor.icon + ' fs-20 link-info action-hover'} />
                                                {Tooltip(`monitor-${ind}`, monitor.label)}
                                            </div>
                                        )
                                    );
                                })}
                            </Col>
                        </div>
                    </Card>
                    <Row>
                        <Col xs={12} sm={12} md={6} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="card-height-100 mb-0 padding-bottom-50">
                                <CardHeader className="d-flex align-items-center flex-column">
                                    {tvDetails.brand && (
                                        <>
                                            <div className="w-100 mt-3 d-flex justify-content-center">
                                                <img src={icon()} alt="" />
                                            </div>
                                            <div className="w-100 mt-3 d-flex justify-content-center">
                                                <p className="fs-16 mb-2">Brand :</p>
                                                <p className="ps-2 fs-18 fw-bold mb-0">{tvDetails.brand}</p>
                                            </div>
                                            <div className="w-100 d-flex justify-content-center">
                                                <p className="fs-16 mb-2">Model :</p>
                                                <p className="fs-16 ps-2 text-info mb-2">{tvDetails.model}</p>
                                            </div>
                                        </>
                                    )}
                                    <div className="w-100 d-flex justify-content-start">
                                        <h4 className="card-title mb-0 flex-grow-1 text-success">Device Summary</h4>
                                        <div className="flex-shrink-0"></div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData('Device State', tvDetails?.deviceSummary?.deviceStatus, true)}
                                                {returnData('Device ID', tvDetails?.deviceSummary?.androidId)}
                                                {returnData('Device Group', tvDetails?.deviceSummary?.group, true)}
                                                {returnData('Policy', tvDetails?.deviceSummary?.policy, true)}
                                                {/* {returnData('Mobile Number', tvDetails?.deviceSummary?.phone, true)} */}
                                                {returnData('Enrollment Time', convertUTCtoIST(tvDetails?.deviceSummary?.enrollmentDate))}
                                                {returnData(
                                                    'Last Modified Date',
                                                    convertUTCtoIST(tvDetails?.deviceSummary?.lastModifiedDate)
                                                )}
                                                {returnData('Wi-Fi Connection', tvDetails?.deviceSummary?.wifi_connection)}
                                                {returnData('Public IP Address', tvDetails?.deviceSummary?.ip_address)}
                                                {/* {returnData('Policy Compliance', 'NON-COMPLIANT')} */}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="card-height-100 mb-0 padding-bottom-50">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={softwareIcon} alt="Software Icon" height={20}></img>
                                    <h4 className="card-title ps-2 mb-0 flex-grow-1 text-success">Software Information</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData('Android Version', tvDetails?.softwareInfo?.androidVersion)}
                                                {returnData('Boot Loader Version', tvDetails?.softwareInfo?.bootLoader)}
                                                {returnData('Device', tvDetails?.brand)}
                                                {returnData('Model', tvDetails?.model)}
                                                {returnData('Product', tvDetails?.product)}
                                                {returnData('Android Build Time', tvDetails?.softwareInfo?.buildTime)}
                                                {returnData('SDK Int', tvDetails?.softwareInfo?.sdkInt)}
                                                {returnData('Security Patch', tvDetails?.softwareInfo?.securityPatch)}
                                                {returnData('SupportABS', tvDetails?.softwareInfo?.supportAbs?.join(', '))}
                                                {/* {returnData('Radio Version', tvDetails?.softwareInfo?.radioVersion)} */}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="card-height-100 mb-3 padding-bottom-50">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={hardwareIcon} alt="Hardware Icon" height={20}></img>
                                    <h4 className="ps-2 card-title mb-0 flex-grow-1 text-success">Hardware Information</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData(
                                                    'Total available internal storage',
                                                    tvDetails?.hardwareInfo?.availableInternalStorage
                                                )}
                                                {returnData('Total internal storage', tvDetails?.hardwareInfo?.totalInternalStorage)}
                                                {returnData('Total used internal storage', tvDetails?.hardwareInfo?.usedInternalStorage)}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>

                            {/* <Card className="card-height-100 mb-3 padding-bottom-30">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={hardwareIcon} alt="User Icon"></img>
                                    <h4 className="ps-2 card-title mb-0 flex-grow-1 text-success">User Information</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>

                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>{returnData('User Name', tvDetails?.deviceSummary?.user)}</tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card> */}
                        </Col>
                    </Row>
                    <Row>
                        <div className="p-1">
                            <TableContainer
                                loading={loading}
                                searchFilter2={true}
                                showTooltip={true}
                                searchPlaceHolder={'Package'}
                                headerImage={applicationsIcon}
                                tableLabelClass={'text-success'}
                                tableHeader={'Applications'}
                                isGlobalFilter={true}
                                columns={androidTVAppSchema}
                                data={applications}
                                customPageSize={5}
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-wrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                            />
                        </div>
                    </Row>
                </Container>
                <OffcanvasModal
                    direction="end"
                    toggle={toggle1}
                    open={showDeviceGroup}
                    handleCloseClick={toggle1}
                    saveDisabled={!selectedDeviceGroup?._id || selectedDeviceGroup?._id === defaultGroup?._id}
                    loading={loading}
                    OffcanvasModalID="deviceGroup"
                    handleOffcanvasBody={deviceGroupBody}
                    offcanvasHeader="Change Device Group"
                    modalClassName="w-40"
                    handleSaveClick={() => onChangeGroup()}
                />
                <OffcanvasModal
                    direction="end"
                    toggle={toggle}
                    open={offcanvasModal}
                    hideSaveButton={true}
                    handleCloseClick={toggle}
                    loading={loading}
                    OffcanvasModalID="viewtvdetailsmodal"
                    handleOffcanvasBody={
                        selectedMonitor.type === 'app_usage'
                            ? appUsageBody
                            : selectedMonitor.type === 'location_history'
                            ? locationHistoryBody
                            : geoViolationBody
                    }
                    modalClassName={'w-50'}
                    offcanvasHeader={selectedMonitor.header + tvDetails.id}
                />
                <DeleteModal
                    hideIcon={true}
                    hideCenterAlign={type === 'volume' || type === 'input_method'}
                    show={actionModal}
                    modalheader={type === 'volume' ? 'Volume' : type === 'input_method' ? 'Input Source' : ''}
                    hideDeleteIcon={true}
                    confirmation={type === 'volume' || type === 'input_method'}
                    size={type === 'volume' ? 'lg' : 'md'}
                    hideDeleteMessage={true}
                    message={actionMessage()}
                    confirmText={actionText}
                    onDeleteClick={() => actionService({})}
                    disabled={handleDisabled()}
                    onCloseClick={() => {
                        setActionModal(false);
                        setMessage('');
                        if (type === 'volume') setVolume(policyBackup?.policy?.config?.VOLUME?.restrictions?.value);
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default AndroidTVViewDevices;
