export const MESSAGES_V1 = '/messages/v1';
export const IDM = '/idm/v1';
export const REPORTING = '/reporting/v1';
export const PLAY_EMM = '/playemm/v1';
export const IOS_V1 = '/ios/v1';
export const WINDOWS_V1 = '/win/v1';
export const ANTIVIRUS_V1 = '/av/v1';
export const MDM_COMMONS = '/commons/v1';
export const ANDROID_V1 = '/androidtv/v1';
export const FILES_V1 = '/files/v1';
export const ANDROID_MNGT = '/andmgmt/v1';
