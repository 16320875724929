import React from 'react';
import { Col, Input, Label } from 'reactstrap';
import Select from 'react-select';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';

const InputComponent = ({ index, field, validation, formType }) => {
    return (
        <Col xs={12} md={field.colspan} sm={field.colspan} lg={field.colspan} xl={field.colspan} className="mb-2" key={index}>
            <Label className={'mb-0 fw-medium d-flex align-items-center mb-1'}>
                {field.label}
                {field?.mandatory && formType !== 'view' && <span className="red-color ps-1">*</span>}
            </Label>
            {formType !== 'view' ? (
                <div className="input-group position-relative">
                    <Input
                        name={field.value}
                        id={field.value}
                        className="form-control"
                        placeholder={field.placeholder}
                        onChange={validation.handleChange}
                        onBlur={validation?.handleBlur}
                        value={validation?.values[field?.value]}
                        autoComplete="off"
                        disabled={formType === 'view'}
                        invalid={validation.touched[field.value] && validation?.errors?.[field?.value] ? true : false}
                    />
                </div>
            ) : (
                <div className="text-muted d-flex justify-content-start align-items-center">
                    <EllipsisToolTip id={field.value}>{validation?.values[field?.value] || '–'}</EllipsisToolTip>
                </div>
            )}
        </Col>
    );
};

const SelectComponent = ({ index, field, validation, formType }) => {
    return (
        <Col xs={12} md={field.colspan} sm={field.colspan} lg={field.colspan} xl={field.colspan} className="mb-2" key={index}>
            <Label className="mb-0 fw-medium d-flex align-items-center mb-1">
                {field.label}
                {field?.mandatory && formType !== 'view' && <span className="red-color ps-1">*</span>}
            </Label>
            {formType !== 'view' ? (
                <Select
                    getOptionValue={(option) => (field.optionValue ? option[field.optionValue] : option.value)}
                    getOptionLabel={(option) => (field.optionLabel ? option[field.optionLabel] : option.label)}
                    id={field.label}
                    name={field.value}
                    options={field.options}
                    placeholder={field?.placeholder}
                    onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                    onChange={(selectedOption) =>
                        validation.handleChange({
                            target: {
                                name: field.value,
                                value: selectedOption.value
                            }
                        })
                    }
                    value={field.options?.find((option) => option.value === validation.values[field.value]) || ''}
                    isSearchable={true}
                    noOptionsMessage={() => 'No data found'}
                />
            ) : (
                <div className="text-muted d-flex justify-content-start align-items-center">
                    <EllipsisToolTip id={field.value}>
                        {field.options?.find((option) => option.value === validation.values[field.value])?.label || '–'}
                    </EllipsisToolTip>
                </div>
            )}
        </Col>
    );
};

const componentMap = {
    input: InputComponent,
    dropdown: SelectComponent
};

export const WPNDynamicComponents = (props) => {
    const Component =
        componentMap[props.field.inputType] ||
        (() => <div className="pt-1 fw-semibold fs-13 px-0 padding-bottom-5">{props.field.label}</div>);

    return (
        <React.Fragment key={props.index}>
            <Component {...props} />
        </React.Fragment>
    );
};

export const WPNSchema = [
    {
        label: 'Domain',
        value: 'domain',
        inputType: 'input',
        mandatory: true,
        placeholder: 'Enter domain',
        colspan: 12
    },
    /*
     * {
     *     label: 'Auth Mode',
     *     value: 'authmode',
     *     inputType: 'dropdown',
     *     mandatory: true,
     *     placeholder: 'Select Authentication mode',
     *     colspan: 6,
     *     options: [
     *         {
     *             label: 'On-Premise Authentication',
     *             value: 'OnPremise'
     *         },
     *         {
     *             label: 'Federated Authentication',
     *             value: 'Federated'
     *         }
     *     ]
     * },
     */
    {
        label: 'WNS Client ID',
        value: 'wnsclientid',
        inputType: 'input',
        mandatory: true,
        placeholder: 'Enter Client ID (also known as Application ID)',
        colspan: 12
    },
    {
        label: 'WNS Package Family Name',
        value: 'wnspfn',
        inputType: 'input',
        mandatory: true,
        placeholder: 'Enter Package Family Name',
        colspan: 12
    },
    {
        label: 'WNS Client Secret',
        value: 'wnssecret',
        inputType: 'input',
        mandatory: true,
        placeholder: 'Enter Client Secret Key',
        colspan: 12
    }
];
