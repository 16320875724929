import React from 'react';
import { Col, Input, Label } from 'reactstrap';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';

const InputComponent = ({ index, field, validation, formType }) => {
    return (
        <Col xs={12} md={12} sm={12} lg={12} xl={12} className="mb-2" key={index}>
            <Label className={'mb-0 fw-medium d-flex align-items-center mb-1'}>
                {field.label}
                {field?.mandatory && formType !== 'view' && <span className="red-color ps-1">*</span>}
            </Label>
            {formType !== 'view' ? (
                <div className="input-group position-relative">
                    <Input
                        name={field.value}
                        id={field.value}
                        className="form-control"
                        placeholder={field.placeholder}
                        onChange={validation.handleChange}
                        onBlur={validation?.handleBlur}
                        value={validation?.values[field?.value]}
                        autoComplete="off"
                        disabled={formType === 'view'}
                        invalid={validation.touched[field.value] && validation?.errors?.[field?.value] ? true : false}
                    />
                </div>
            ) : (
                <div className="text-muted d-flex justify-content-start align-items-center">
                    <EllipsisToolTip id={field.value}>{validation?.values[field?.value] || '–'}</EllipsisToolTip>
                </div>
            )}
        </Col>
    );
};

const componentMap = {
    input: InputComponent
};

export const LinkADComponents = (props) => {
    const Component =
        componentMap[props.field.inputType] ||
        (() => <div className="pt-1 fw-semibold fs-13 px-0 padding-bottom-5">{props.field.label}</div>);

    return (
        <React.Fragment key={props.index}>
            <Component {...props} />
        </React.Fragment>
    );
};

export const LinkADSchema = [
    {
        label: 'Client ID',
        value: 'clientid',
        inputType: 'input',
        mandatory: true,
        placeholder: 'Enter Client ID (also known as Application ID)'
    },
    {
        label: 'Client Secret',
        value: 'clientsecret',
        inputType: 'input',
        mandatory: true,
        placeholder: 'Enter Client Secret Key'
    },
    {
        label: 'Tenant ID',
        value: 'tenantid',
        inputType: 'input',
        mandatory: true,
        placeholder: 'Enter Tenant ID'
    }
];
