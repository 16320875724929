import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import microsoft from '../../../assets/images/common/png/companies/microsoftImg.png';
import tectoro from '../../../assets/images/common/png/companies/Tectoro.png';
import { useHistory } from 'react-router-dom';
import { integrateSchema } from '../../../Components/constants/constants';
import { useFormik } from 'formik';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { useEnv } from '../../../envContext';
import Loader from '../../../Components/Common/Loader';

const AppProtectionPolicy = () => {
    document.title = 'App Protection';
    const urlconf = useEnv();
    const history = useHistory();
    const api = new APIClient();

    const [state, setState] = useState(true);
    const [showInputs, setShowInputs] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        handlePromise();
    }, []);

    const handlePromise = () => {
        api.get(url.MS_APP_CONFIG, '', domains.MDM_COMMONS)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data !== '' && resp.status === 'success') setState(false);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleConfigure = (type) => {
        setShowInputs(true);
        setState(false);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { clientId: '', clientSecret: '', tenantId: '' },
        onSubmit: (values) => {
            setShowInputs(false);
            api.create(
                url.MS_APP_PROTECT + url.APP_CONFIG,
                { clientid: values.clientId, tenantid: values.tenantId, clientsecret: values.clientSecret },
                false,
                domains.MDM_COMMONS
            )
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') handlePromise();
                    else setLoading(false);
                })
                .catch((err) => setLoading(false));
        }
    });

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="App Protection" history={''} homeLink="Dashboard" />
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Card className="card-height-100">
                                <CardHeader>
                                    <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                                        <div className="fs-14 ">App Protection Policy (Microsoft)</div>
                                        <div
                                            className={`d-flex justify-content-center align-items-center fs-12 ${
                                                !state && !showInputs ? 'text-success' : 'text-danger'
                                            }`}
                                        >
                                            <i
                                                className={`${
                                                    !state && !showInputs ? 'ri-checkbox-circle-fill' : 'ri-error-warning-fill'
                                                }    fs-16 px-1 ${!state && !showInputs ? 'text-success' : 'text-danger'}`}
                                            ></i>

                                            {!state && !showInputs ? 'Configured' : 'Not Configured'}
                                        </div>
                                    </div>
                                    <div className="padding-top-10 font-size-12 fw-medium text-muted">
                                        App protection policies are essential guidelines designed to ensure the security and privacy of
                                        applications. These policies help safeguard against unauthorized access, data breaches, and other
                                        security threats in Applications
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    {showInputs && (
                                        <div>
                                            <div>
                                                <div className="fw-semibold fs-14 mb-2">Connect to Microsoft Entra ID</div>
                                                <div className="fs-12 mb-1">
                                                    You can find this information in your Azure portal after registering your application.
                                                </div>
                                                <div className="fs-12 mb-3">
                                                    Not sure where to start?
                                                    <a
                                                        className="text-decoration-underline"
                                                        target="_blank"
                                                        href="https://learn.microsoft.com/en-us/entra/identity-platform/quickstart-register-app?tabs=certificate"
                                                        rel="noreferrer"
                                                    >
                                                        Learn how to connect Microsoft Entra ID
                                                    </a>
                                                </div>
                                                {integrateSchema.map((field, index) => (
                                                    <Row className="mb-2" key={index}>
                                                        <Col
                                                            xs={12}
                                                            md={12}
                                                            sm={12}
                                                            lg={12}
                                                            xl={12}
                                                            className="d-flex align-items-center justify-content-between"
                                                        >
                                                            <Label className="form-check-label fs-12 fw-medium d-flex align-items-center mb-1">
                                                                {field.label}
                                                                <i
                                                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-13"
                                                                    id={`inputComponent-${index}`}
                                                                />
                                                                <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                                                    {field.helpText ? field.helpText : 'Helper Text'}
                                                                </UncontrolledTooltip>
                                                            </Label>
                                                        </Col>
                                                        <Col xs={12} md={12} sm={12} lg={12} xl={12}>
                                                            <div className={'input-group'}>
                                                                <div className="d-flex align-items-center w-100">
                                                                    <Input
                                                                        name={field.value}
                                                                        id={field.value}
                                                                        className={`mb-1 form-control ${
                                                                            field.class ? field.class : 'w-100'
                                                                        }`}
                                                                        placeholder={`Enter ${field.label}`}
                                                                        type={field.type}
                                                                        maxLength={field.maxLength}
                                                                        validate={{ required: { value: true } }}
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values[field.value] || ''}
                                                                        invalid={
                                                                            validation.touched[field.value] &&
                                                                            validation.errors[field.value]
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {field.label === 'Tenant ID' ? (
                                                            <div className="text-muted fs-11 mb-1">
                                                                The directory tenant that you want to request permission from. This can be
                                                                in GUID or friendly name format.
                                                            </div>
                                                        ) : (
                                                            <div className="text-muted fs-11 mb-1">
                                                                To acquire {field.label}, go to Azure portal, then to App registrations,
                                                                then Select the desired application.{' '}
                                                                <a
                                                                    target="_blank"
                                                                    className="text-decoration-underline fw-medium"
                                                                    href="https://learn.microsoft.com/en-us/entra/identity-platform/quickstart-register-app?tabs=certificate"
                                                                    rel="noreferrer"
                                                                >
                                                                    Click here
                                                                </a>{' '}
                                                                for more information.
                                                            </div>
                                                        )}
                                                    </Row>
                                                ))}
                                                <Row>
                                                    <Col>
                                                        <div className="mt-3">
                                                            <button
                                                                type="reset"
                                                                className="btn btn-light me-2"
                                                                onClick={() => {
                                                                    setShowInputs(false);
                                                                    setState(true);
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-success fw-medium"
                                                                id="add-btn"
                                                                disabled={
                                                                    !validation.values['clientId'] ||
                                                                    validation.values['clientId'] === '' ||
                                                                    !validation.values['clientSecret'] ||
                                                                    validation.values['clientSecret'] === '' ||
                                                                    !validation.values['tenantId'] ||
                                                                    validation.values['tenantId'] === ''
                                                                }
                                                                onClick={() => validation.handleSubmit()}
                                                            >
                                                                Connect
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    )}
                                    {!showInputs && (
                                        <div className="pt-20">
                                            <div className="text-center">
                                                <img height={65} src={microsoft} alt="img"></img>
                                            </div>
                                            <div className="fw-medium fs-14 py-2 text-center">Microsoft</div>
                                            <div className="font-size-12 text-center fw-medium text-muted px-2">
                                                Configure to enforce App Protection Policies on Microsoft apps and Microsoft partner apps by
                                                applying data protection policies directly to the mobile apps
                                            </div>
                                        </div>
                                    )}
                                    {state ? (
                                        <div className="text-center mt-1">
                                            <Button
                                                className="bg-primary px-15 py-3 border-none fw-medium fs-12"
                                                onClick={() => handleConfigure('ms')}
                                            >
                                                Configure
                                            </Button>
                                        </div>
                                    ) : (
                                        !showInputs && (
                                            <div
                                                className="text-secondary fs-15 height-42 d-flex justify-content-center align-items-center cursor-pointer"
                                                onClick={() => history.push('/appprotectionpolicy/microsoft')}
                                            >
                                                <div className="pe-1 fs-13 text-decoration-underline fw-semibold">Click here</div>
                                                <i className="ri-arrow-right-line" />
                                            </div>
                                        )
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Card className="card-height-100">
                                <CardHeader>
                                    <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                                        <div className="fs-14 ">App Protection Policy (Tectoro)</div>
                                    </div>
                                    <div className="padding-top-10 font-size-12 fw-medium text-muted">
                                        App protection policies are essential guidelines designed to ensure the security and privacy of
                                        applications. These policies help safeguard against unauthorized access, data breaches, and other
                                        security threats in Applications
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className="py-20 text-center">
                                        <div>
                                            <img height={65} src={tectoro} alt="img"></img>
                                        </div>
                                        <div className="fw-medium fs-14 py-2">Tectoro</div>
                                        <div className="font-size-12 text-center fw-medium text-muted px-2">
                                            Click here to enforce App Protection Policies on in-house apps by applying data protection
                                            policies. These policies are implemented exclusively on wrapped applications.
                                        </div>
                                        <div>
                                            <div
                                                className="text-secondary height-42 fs-15 d-flex justify-content-center align-items-center cursor-pointer"
                                                onClick={() => history.push('/appprotectionpolicy/tectoro')}
                                            >
                                                <div className="text-decoration-underline fw-semibold pe-1 fs-13">Click here</div>
                                                <i className="ri-arrow-right-line" />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AppProtectionPolicy;
