import React from 'react';
import Select from 'react-select';
import { Card, CardBody, CardFooter, CardHeader, Col, FormFeedback, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { convertUTCtoIST, Tooltip } from '../../../Components/Common/Util';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';

export const addDaystoDate = (days) => {
    const result = new Date();
    result.setDate(result.getDate() + days);
    return result.toISOString();
};

const windowsAuthTypes = {
    NO_AUTH: 'Without Login',
    TECTORO_LOGIN: 'Tectoro Login',
    STATIC_PIN: 'Static PIN'
};

export const EnrollmentConfigCard = ({ index, item, onMakeDefault, onAssignClick, onConfigEdit, onConfigDelete, onConfigView }) => {
    return (
        <Col xxl={4} xl={6} lg={6} md={12} sm={12} xs={12} key={index}>
            <Card className="border card-height-100">
                <CardHeader>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center fs-14">
                            <div className="text-muted">Enrollment Code :</div>
                            <div className="fw-medium ms-2">{item.enrollmentcode}</div>
                        </div>
                        <div className="d-flex align-items-center">
                            {item.isdefault ? (
                                <span className={'lh-lg badge badge-soft-success me-2'}>Default</span>
                            ) : (
                                <span
                                    className={'lh-lg badge badge-soft-dark-gray cursor-pointer me-2'}
                                    onClick={() => onMakeDefault(item)}
                                >
                                    Make Default
                                </span>
                            )}
                            <i
                                id={`assign-${index}`}
                                className="ri-share-forward-line me-2 fs-16 cursor-pointer link-secondary"
                                onClick={() => onAssignClick(item)}
                            />
                            {Tooltip(`assign-${index}`, 'Assign')}
                            <i
                                id={`edit-${index}`}
                                className="ri-edit-box-line me-2 fs-16 cursor-pointer link-secondary"
                                onClick={() => onConfigEdit(item)}
                            />
                            {Tooltip(`edit-${index}`, 'Edit')}
                            {item.isdefault !== true && (
                                <>
                                    <i
                                        id={`delete-${index}`}
                                        className="ri-delete-bin-line me-2 fs-16 cursor-pointer link-secondary"
                                        onClick={() => onConfigDelete(item)}
                                    />
                                    {Tooltip(`delete-${index}`, 'Delete')}
                                </>
                            )}
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm={6}>
                            <div className="fs-12 text-muted ">
                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>Authentication Type</EllipsisToolTip>
                            </div>
                            <div className="d-flex justify-content-start mt-1 mb-2 fw-medium fs-13">
                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                    {windowsAuthTypes[item.authtype]}
                                </EllipsisToolTip>
                            </div>
                        </Col>
                        {item.authtype === 'STATIC_PIN' && (
                            <Col sm={6}>
                                <div className="fs-12 text-muted ">
                                    <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>PIN</EllipsisToolTip>
                                </div>
                                <div className="d-flex justify-content-start mt-1 mb-2 fw-medium fs-13">
                                    <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                        {item?.authconfig?.pin}
                                    </EllipsisToolTip>
                                </div>
                            </Col>
                        )}
                    </Row>
                </CardBody>
                <CardFooter className="d-flex align-items-center justify-content-between">
                    <div className="fs-12">
                        Valid Till : <span className="fs-12 fw-medium"> {convertUTCtoIST(item?.expiration, true)}</span>
                    </div>
                    <div
                        className="text-decoration-underline text-secondary fs-12 fw-medium cursor-pointer"
                        onClick={() => onConfigView(item)}
                    >
                        View Configuration
                    </div>
                </CardFooter>
                <div className={`progress progress-bar animated-progess rounded-bottom rounded-0 height-3 bg-${item?.badgeClass}`}></div>
            </Card>
        </Col>
    );
};

export const getWindowsConfigInitialValues = () => {
    return windowsEnrollConfigSchema?.reduce((defaultValues, field) => {
        defaultValues[field?.value] = field?.defaultValue;
        return defaultValues;
    }, {});
};

export const windowsEnrollConfigSchema = [
    {
        label: 'Authentication Type',
        inputType: 'radios',
        value: 'authtype',
        helpText: 'Specifies Authentication Method used to manage secure enrollment of devices',
        mandatory: true,
        defaultValue: 'NO_AUTH',
        row: true,
        options: [
            {
                label: 'Enrollment without Login',
                value: 'NO_AUTH',
                helpText: 'Upon enabling this option, user can enrol the device without any authentication.'
            },
            {
                label: 'Enrollment with Tectoro Login',
                value: 'TECTORO_LOGIN',
                helpText:
                    'Upon enabling this option, the end user will be able to login with all the possible login options as defined in the authentication mode.'
            },
            {
                label: 'Enrollment with Static PIN',
                value: 'STATIC_PIN',
                helpText:
                    'A static PIN is a fixed numerical code used for secure authentication. During enrollment, the same PIN is used to verify user identity during authentication.'
            }
        ]
    },
    {
        label: '',
        value: 'pin',
        helpText: 'Specifies the minimum number of days a password must be used before it can be changed, ranging from 0 to 998 days',
        inputType: 'input',
        regExp: /^[0-9]+$/,
        maxLength: 4,
        defaultValue: '',
        enableOn: 'authtype',
        enableOnVal: ['STATIC_PIN'],
        placeholder: 'Enter Static PIN'
    },
    {
        label: 'Expiration',
        value: 'expiration',
        helpText: 'Choose the duration for which the token remains constant',
        mandatory: true,
        inputType: 'dropdown',
        placeholder: 'Select',
        defaultValue: 15,
        options: [
            { label: '15 Days', value: 15 },
            { label: '1 Month', value: 30 },
            { label: '3 Months', value: 90 },
            { label: '6 Months', value: 180 },
            { label: '1 Year', value: 365 }
        ]
    }
];

const RadiosButton = ({ index, field, validation, formType }) => {
    return (
        <Row key={index} className={'m-0 p-0'}>
            <Col xs={12} md={12} sm={12} lg={12} xl={12} className="p-0">
                <Label className="form-check-label d-flex align-items-center">
                    <span className="fw-semibold fs-14">{field.label}</span>
                    {field?.mandatory && formType !== 'view' && <span className="red-color ps-1">*</span>}
                </Label>
                {field.helpText && <div className="text-muted fs-13 mb-2">{field.helpText}</div>}
                <div className={'px-0'}>
                    {field?.options?.map((ele, ind) => {
                        return (
                            <div
                                className={`form-check mb-2 ${field?.row ? '' : 'form-check-inline mb-0'}`}
                                key={ind}
                                onClick={() => {
                                    if (formType !== 'view')
                                        validation.handleChange({
                                            target: { name: field.value, value: ele.value }
                                        });
                                }}
                            >
                                <Input
                                    className="form-check-input"
                                    type="radio"
                                    id={field.label}
                                    name={field.value}
                                    disabled={formType === 'view'}
                                    value={ele.value}
                                    onBlur={validation.handleBlur}
                                    onChange={() =>
                                        validation.handleChange({
                                            target: { name: field.value, value: ele.value }
                                        })
                                    }
                                    checked={validation.values[field.value] === ele.value}
                                />
                                <Label className="mb-0">{ele.label}</Label>
                                <div>{ele.helpText && <div className="text-muted fs-13">{ele.helpText}</div>}</div>
                            </div>
                        );
                    })}
                </div>
            </Col>
        </Row>
    );
};

const InputComponent = ({ index, field, validation, formType }) => {
    return (
        <Row key={index} className={'m-0 p-0'}>
            <Col xs={12} md={10} sm={10} lg={10} xl={10} className="m-0 p-0 mb-2">
                <Label className={'mb-0 fw-medium d-flex align-items-center mb-1'}>{field.label}</Label>
                <div className="input-group position-relative">
                    <Input
                        name={field.value}
                        id={field.value}
                        className="form-control"
                        placeholder={field.placeholder}
                        type={field.type}
                        onChange={
                            field.regExp
                                ? (event) => {
                                      if (new RegExp(field.regExp).test(event.target.value) || event.target.value === '') {
                                          validation.handleChange({
                                              target: {
                                                  name: field.value,
                                                  value: event.target.value
                                              }
                                          });
                                      }
                                  }
                                : validation.handleChange
                        }
                        min={field.minValue}
                        max={field.maxValue}
                        maxLength={field.maxLength}
                        onBlur={validation?.handleBlur}
                        value={validation?.values[field?.value]}
                        autoComplete="off"
                        disabled={formType === 'view'}
                        invalid={validation.touched[field.value] && validation?.errors?.[field?.value] ? true : false}
                    />
                    {validation.touched[field.value] && validation.errors[field.value] && (
                        <FormFeedback type="invalid">{validation.errors[field.value]}</FormFeedback>
                    )}
                </div>
            </Col>
        </Row>
    );
};

const SelectComponent = ({ index, field, validation, formType }) => {
    return (
        <>
            {formType === 'add' && (
                <>
                    <div className="my-2 border-bottom"></div>
                    <Row key={index} className={'m-0 p-0'}>
                        <Col xs={12} md={10} sm={10} lg={10} xl={10} className="m-0 p-0 mb-2">
                            <Label className="mb-0 fw-medium d-flex align-items-center">
                                <span className="fw-semibold fs-14">{field.label}</span>
                                {field?.mandatory && formType !== 'view' && <span className="red-color ps-1">*</span>}
                            </Label>
                            <div>{field.helpText && <div className="text-muted fs-13 mb-2">{field.helpText}</div>}</div>
                            <Select
                                getOptionValue={(option) => (field.optionValue ? option[field.optionValue] : option.value)}
                                getOptionLabel={(option) => (field.optionLabel ? option[field.optionLabel] : option.label)}
                                id={field.label}
                                name={field.value}
                                options={field.options?.filter((option) => !option.hidden)}
                                isDisabled={formType === 'view'}
                                placeholder={field?.placeholder ? field?.placeholder : `Select ${field.label}`}
                                onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                                onChange={(selectedOption) =>
                                    validation.handleChange({
                                        target: {
                                            name: field.value,
                                            value: selectedOption.value
                                        }
                                    })
                                }
                                value={field.options?.find((option) => option.value === validation.values[field.value]) || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

const componentMap = {
    radios: RadiosButton,
    input: InputComponent,
    dropdown: SelectComponent
};

export const EnrollConfigComponents = (props) => {
    const Component =
        componentMap[props.field.inputType] ||
        (() => <div className="pt-1 fw-semibold fs-13 px-0 padding-bottom-5">{props.field.label}</div>);

    return (
        <React.Fragment key={props.index}>
            <Component {...props} />
        </React.Fragment>
    );
};

export const configEnableOnCondition = (field, validation) => {
    if (!field.enableOn) return true;
    return field.enableOnVal?.includes(validation.values[field.enableOn]) || false;
};

export const ConfigNotEnabledComponent = ({ field, validation }) => {
    if ('enableOn' in field && !configEnableOnCondition(field, validation)) {
        validation.values[field.value] = field.defaultValue;
    }
    return <></>;
};
