/* eslint-disable multiline-comment-style */
import React from 'react';
import FullyManaged from '../../assets/images/common/svg/common/fullymanaged.svg';
import WorkProfile from '../../assets/images/common/png/common/briefcase.png';
import Dedicated from '../../assets/images/common/png/common/dedicated.png';
import images from '../../assets/images/common/svg/common/images.svg';
import videos from '../../assets/images/common/svg/common/videos.svg';
import music from '../../assets/images/common/svg/common/music.svg';
import documents from '../../assets/images/common/svg/common/documents.svg';
import tectoroLauncher from '../../assets/images/common/png/common/launcher.png';
import multiApp from '../../assets/images/common/png/common/multiapp.png';
import singleApp from '../../assets/images/common/png/common/singleapp.png';
import iosSingleApp from '../../assets/images/common/svg/common/singleapp.svg';
import iosMultiApp from '../../assets/images/common/svg/common/multiapp.svg';
import iphone from '../../assets/images/apple/png/common/iphone.png';
import macbook from '../../assets/images/apple/png/common/macbook.png';
import AndroidMyDevice from '../../assets/images/android/png/AndroidMyDevice.png';
import iOSMyDevice from '../../assets/images/apple/png/common/iOSMyDevice.png';
import windowsFully from '../../assets/images/windows/svg/common/policyFully.svg';
import tvsingleapp from '../../assets/images/tv/png/tvsingleapp.png';
import tvmultiapp from '../../assets/images/tv/png/tvmultiapp.png';
import AeTectoroLauncher from '../../assets/images/common/svg/common/AeTectoroLauncher.svg';
import AeDedicatedDevice from '../../assets/images/common/svg/common/AeDedicatedDevice.svg';
import AeSingleApp from '../../assets/images/common/svg/common/AeSingleApp.svg';
import AeMultiApp from '../../assets/images/common/svg/common/AeAndroid.svg';
import AeFullyManaged from '../../assets/images/common/svg/common/AeFullyManaged.svg';
import AeWorkProfile from '../../assets/images/common/svg/common/AeWorkProfile.svg';

const policiesArr = [
    {
        img: AeSingleApp,
        label: 'Single App',
        id: 'SA',
        desc: 'This solution set allows IT admins to further lock down the dsage of a device to a Single App'
    },
    {
        img: AeMultiApp,
        label: 'Multi App',
        id: 'MA',
        desc: 'You can exercise complete management control over the fully managed company owned devices'
    },
    {
        img: AeFullyManaged,
        label: 'Fully Managed Device',
        id: 'FM',
        desc: 'You Can Exercise Complete Management Control Over The Fully Managed Company Owned Devices'
    },
    {
        img: AeWorkProfile,
        label: 'Work Profile',
        id: 'WP',
        desc: 'The Profile Owner (PO) Deployment Method Is Recommended For Personally Owned Devices (BYOD) That Are Also Used For Work'
    },
    {
        img: AeDedicatedDevice,
        label: 'Dedicated Device',
        id: 'DD',
        desc: 'This Solution Set Allows IT Admins To Further Lock Down The Usage Of A Device To A Single App Or Small Set Of Apps.',
        childPolicies: [
            {
                img: AeSingleApp,
                label: 'Single App',
                id: 'SA',
                desc: 'This Solution Set Allows IT Admins To Further Lock Down The Usage Of A Device To A Single App'
            },
            {
                img: AeMultiApp,
                label: 'Kiosk Launcher',
                id: 'KL',
                desc: 'This Solution Set Allows IT Admins To Further Lock Down The Usage Of A Device To A Small Set Of Apps'
            },
            {
                img: AeTectoroLauncher,
                label: 'Tectoro Launcher',
                id: 'TL',
                desc: 'This Solution Set Allows IT Admins To Further Lock Down The Usage Of A Device To A Small Set Of Apps With More Features'
            }
        ]
    }
];

export const AndroidTVPoliciesArr = [
    {
        img: tvsingleapp,
        label: 'Single App',
        id: 'SA',
        path: 'SA',
        desc: 'Use this section to restrict the device to run only a specific app. This mode is ideal for kiosk mode setups, this ensures users can only access the selected application, improving focus and security.'
    },
    {
        img: tvmultiapp,
        label: 'Multi App',
        id: 'MA',
        path: 'MA',
        desc: 'Use this section to enable access to a selected group of apps, providing users with a customized experience while maintaining control. This mode allows for flexibility and security, ensuring only approved applications are available for use.'
    }
    /*
     * {
     *     img: iosMultiApp,
     *     label: 'Web App',
     *     id: 'WA',
     *     path: 'WA',
     *     desc: 'Enabling Web App Mode allows end users to exclusively access the apps pushed to the device by the admin, thereby restricting access to other apps.'
     * }
     */
];

export const iosPoliciesArr = [
    {
        img: iphone,
        label: 'iOS Policy',
        id: 'ios',
        cards: [
            {
                img: iosSingleApp,
                label: 'Single App',
                id: 'SA',
                path: 'SA-ios',
                desc: 'Enabling Single App Mode restricts device usage to the selected app exclusively, preventing access to other apps.'
            },
            {
                img: iosMultiApp,
                label: 'Multi App',
                id: 'MA',
                path: 'MA-ios',
                desc: 'Enabling Multiple App Mode allows end users to exclusively access the apps pushed to the device by the admin, thereby restricting access to other apps.'
            }
        ],
        desc: 'Create an iOS policy to enforce security settings and restrictions that align with organizational standards for both supervised and unsupervised devices, restrictions, compliance requirements, and app controls'
    },
    {
        img: macbook,
        label: 'macOS Policy',
        id: 'macos',
        path: 'MA-macos',
        desc: 'Create a macOS policy to enforce security settings and restrictions that align with organizational standards for both supervised and unsupervised devices'
    }
];

export const myDeviceCards = [
    {
        img: AndroidMyDevice,
        label: 'Android',
        type: 'android',
        key: 'ANDROID_ENTERPRISE_ENABLED',
        desc: 'Android devices can be managed seamlessly with the Tectoro MDM portal. User can add their device individually by logging into the self-service portal.'
    },
    {
        img: iOSMyDevice,
        label: 'Apple',
        type: 'apple',
        key: 'IOS_ENABLED',
        desc: 'Apple devices are managed through the Tectoro MDM portal, allowing users to perform certain actions independently through self-service portal.',
        steps: (
            <ol className="stepol">
                <li className="stepli">Click on Add devices.</li>
                <li className="stepli">Open Camera app on your iOS device and Scan the QR code.</li>
                <li className="stepli">A Notification will be seen asking you to open Safari. Please click on the notification.</li>
                <li className="stepli">
                    Once Safari opens, it will show you a dialog informing you that the Settings app will be opened. Click on Allow.
                </li>
                <li className="stepli">
                    MDM app will be downloaded and a screen with the option to Install will be seen. Click on Install.
                </li>
                <li className="stepli">
                    The device will ask for a confirmation by asking you to click on install again. Click on Install.
                </li>
                <li className="stepli">This will start enrolling the device.</li>
            </ol>
        )
    }
    // { img: windows, label: 'Windows', type: 'windows', key: 'WINDOWS_ENABLED' }
];
export const windowsPoliciesArr = [
    {
        img: windowsFully,
        label: 'Fully Managed Device',
        id: 'FM',
        desc: 'Management control over the fully managed company owned devices.'
    }
    // {
    //     img: windowsFully,
    //     label: 'Kiosk Mode',
    //     id: 'KL',
    //     desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //     cards: [
    //         {
    //             img: windowsFully,
    //             label: 'Single App',
    //             id: 'SA',
    //             desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    //         },
    //         {
    //             img: windowsFully,
    //             label: 'Multi App',
    //             id: 'MA',
    //             desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    //         }
    //     ]
    // }
];

const checkedData = [];

const contentManagementCards = [
    {
        label: 'Images',
        count: '0',
        size: '0',
        icon: images,
        alt: 'image',
        fileType: 'IMAGE',
        border: 'primary',
        fileFormats: 'image/*'
    },
    { label: 'Videos', count: '0', size: '0', icon: videos, alt: 'video', fileType: 'VIDEO', border: 'danger', fileFormats: 'video/*' },
    { label: 'Audio', count: '0', size: '0', icon: music, alt: 'audio', fileType: 'AUDIO', border: 'warning', fileFormats: 'audio/*' },
    {
        label: 'Documents',
        count: '0',
        size: '0',
        icon: documents,
        alt: 'document',
        fileType: 'DOCUMENT',
        border: 'info',
        fileFormats: 'application/*'
    }
];

export const detailsObj = {
    educationScreens: true,
    systemApps: false,
    allowOffline: false,
    screenOn: false,
    wifiId: undefined,
    securityType: undefined,
    password: undefined,
    securityOptions: 'withAuthentic'
};

export { policiesArr, checkedData, contentManagementCards };
