import React from 'react';

export const tableData = [
    {
        Header: 'License ID',
        accessor: 'licenseId',
        sortable: true,
        customCell: true,
        fieldType: 'clickable',
        width: 50,
        minWidth: 50,
        maxWidth: 50
    },
    {
        Header: 'No.of Devices',
        accessor: 'noOfDevices',
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Remaining Licenses',
        accessor: 'remainingLicense',
        width: 150,
        minWidth: 150,
        maxWidth: 150
    },
    {
        Header: 'Plan Type',
        accessor: 'planType',
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        customCell: true,
        fieldType: 'customcss',
        customClass: 'fw-medium text-capitalize'
        /*
         * customCell: true,
         * fieldType: 'iconText'
         */
    },
    {
        Header: 'License Validity',
        accessor: 'validity',
        customCell: true,
        fieldType: 'coloredText',
        width: 150,
        minWidth: 150,
        maxWidth: 150
    },
    {
        Header: 'License Start Date',
        accessor: 'startDate',
        width: 150,
        minWidth: 150,
        maxWidth: 150
    },
    {
        Header: 'License End Date',
        accessor: 'endDate',
        width: 150,
        minWidth: 150,
        maxWidth: 150
    },
    {
        Header: 'Status',
        accessor: 'status',
        customCell: true,
        fieldType: 'badge',
        width: 100,
        minWidth: 100,
        maxWidth: 100
    }
    /*
     * {
     *     Header: 'Actions',
     *     accessor: 'htnl',
     *     customCell: true,
     *     fieldType: '',
     *     width: 100,
     *     minWidth: 100,
     *     maxWidth: 100
     * }
     */
];

export const details = [
    {
        id: '66e7df6e5530bbbf7c81534a',
        Tenant: 'TT',
        licenseId: 'L001',
        ProductType: 'P123',
        planType: 'STANDARD',
        total: 1000,
        Assigned: 0,
        validity: '2024-09-16T07:33:59.885Z',
        startDate: '2022-09-01T15:04:05Z',
        endDate: '2024-09-01T15:04:05Z',
        Tenure: 731,
        Note: '',
        ModifiedBy: 'kishore',
        ModifiedDate: '2024-09-16T07:34:03.285Z'
    }
];

export const LicenseInfo = [
    { title: 'Total Licenses', accessor: 'totalLicenses' },
    { title: 'Used Licenses', accessor: 'usedLicenses' },
    { title: 'Remaining Licenses', accessor: 'remainingLicenses' }
];

export const DevicesInfo = [
    { title: 'Android Devices', accessor: 'android_OS', icon: 'ri-android-fill' },
    { title: 'Windows Devices', accessor: 'windows_OS', icon: 'ri-windows-fill' },
    { title: 'iOS Devices', accessor: 'iOS_OS', icon: 'ri-tablet-fill' }
];

export const licenseViewSchema = [
    { label: 'License Start Date', accessor: 'startDate', type: 'date' },
    { label: 'License End Date', accessor: 'endDate', type: 'date' },
    { label: 'Remaining Days', accessor: 'remainingDays', dateIcon: 'ri-calendar-2-line pe-2 text-warning' }
];
