import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, Container, Input, Label, Row, Table } from 'reactstrap';
import addProperty from '../../../assets/images/apple/png/common/addProperty.png';
import delConfig from '../../../assets/images/common/png/delete/deleteManagedConfig.png';
import toastMessages from '../../../common/messages/toastMessages';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, convertUTCtoIST, getFormTypeAndRecordId, Tooltip } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { tvConfigsSchema } from '../TableSchema';
import Loader from '../../../Components/Common/Loader';

const IOSConfigurations = () => {
    const [loading, setLoading] = useState(false);
    const [appsData, setAppsData] = useState();
    const [configurations, setConfigurations] = useState([]);
    const [mode, setMode] = useState('add');
    const [propertyMode, setPropertyMode] = useState('add');
    const [selectedRow, setSelectedRow] = useState({});
    const [offCanvasModal, setOffCanvasModal] = useState(false);
    const [canvasLoader, setCanvasLoader] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [apPropertiesArr, setApPropertiesArr] = useState([]);
    const [tableSchema, setTableSchema] = useState([...tvConfigsSchema]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [showProperty, setShowProperty] = useState(false);
    const [selectedFormat, setSelectedFormat] = useState();
    const [configProperty, setConfigProperty] = useState();
    const [formValues, setFormValues] = useState();
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const api = new APIClient();
    const urlconf = useEnv();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    const history = useHistory();
    let user = AuthUser();
    user = user ? JSON.parse(user) : '';

    useEffect(() => {
        let findPriv = user?.data?.privileges?.find((priv) => priv === priviliges.APPLICATION_EDITOR);
        let schema = [...tableSchema];
        if (!findPriv) schema = schema?.filter((obj) => obj.Header !== 'Actions');
        setTableSchema(schema);
        getAppsbyID();
    }, []);

    const getAppsbyID = () => {
        setLoading(true);
        api.get(url.TV_APPS + '/' + recordID, '', domains.ANDROID_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.status === 'success') {
                    setAppsData(resp.data);
                    getAppConfig({ ...searchParams, packagename: resp?.data?.packagename });
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const getAppConfig = (params) => {
        setLoading(true);
        setSearchParams(params);
        const configPromise = new Promise((resolve, reject) => {
            api.get(url.IOS_APPCONFIG, params, domains.ANDROID_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.status === 'success') {
                        resolve(resp);
                    } else reject('Config failed.');
                })
                .catch((err) => reject(err));
        });

        const templatePromise = new Promise((resolve, reject) => {
            api.get(url.IOS_APP_TEMPLATE + '/' + params?.packagename, '', domains.ANDROID_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.status === 'success') {
                        resolve(resp);
                    } else reject('Template failed');
                })
                .catch((err) => reject(err));
        });

        Promise.allSettled([configPromise, templatePromise]).then((result) => {
            if (result[0].status === 'fulfilled') {
                handleConfigBinding(result[0]?.value);
            }
            if (result[1].status === 'fulfilled') {
                const managedArr = [];
                result[1]?.value?.data?.managed?.length > 0 &&
                    result[1]?.value?.data?.managed?.forEach((data) => {
                        const val = {
                            name: data?.property?.name,
                            key: data?.property?.key,
                            description: data?.property?.description,
                            format: data?.property?.format === 'tableInputNumber' ? 'tableInput' : data?.property?.format,
                            type: data?.proprty?.format === 'number' || data?.property?.format === 'tableInputNumber' ? 'number' : '',
                            id: data?._id,
                            hideLabel: true,
                            options: data?.property?.options
                        };

                        managedArr.push(val);
                    });
                result[1]?.value?.data?.default?.forEach((field) => (field.hideLabel = true));
                const propertyData = result[1]?.value?.data?.default?.concat(managedArr);
                setApPropertiesArr(propertyData);
            }
        });
    };

    const getAppConfigurations = (params) => {
        setSearchParams(params);
        api.get(url.IOS_APPCONFIG, params, domains.ANDROID_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.status === 'success') {
                    handleConfigBinding(resp);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleConfigBinding = (resp) => {
        (resp?.data || []).forEach((ele) => {
            ele.title = ele.name ? ele.name : '_';
            ele.createdDate = ele.modifieddate ? convertUTCtoIST(ele.modifieddate) : '_';
        });
        setTotalRecords(resp?.totalRecords);
        setConfigurations(resp?.data);
    };

    const getTemplate = () => {
        setCanvasLoader(true);
        api.get(url.IOS_APP_TEMPLATE + '/' + appsData?.packagename, '', domains.ANDROID_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.status === 'success') {
                    const managedArr = [];
                    resp?.data?.managed?.length > 0 &&
                        resp?.data?.managed?.forEach((data) => {
                            const val = {
                                name: data?.property?.name,
                                key: data?.property?.key,
                                description: data?.property?.description,
                                inputType: data?.property?.format,
                                format: data?.property?.format === 'tableInputNumber' ? 'tableInput' : data?.property?.format,
                                id: data?._id,
                                options: data?.property?.options,
                                config: true
                            };
                            managedArr.push(val);
                        });
                    resp.data?.default?.forEach((data) => {
                        data.config = true;
                        data.inputType = data.format;
                    });
                    const propertyData = resp?.data?.default?.concat(managedArr);
                    setApPropertiesArr([...propertyData]);
                    setCanvasLoader(false);
                }
                setCanvasLoader(false);
            })
            .catch((err) => setCanvasLoader(false));
    };

    const handleAdd = () => {
        setMode('add');
        setOffCanvasModal(true);
    };

    const handleEdit = (row) => {
        setMode('edit');
        setSelectedRow(row.original);
        const values = { ...(row.original.config || {}) };
        apPropertiesArr?.forEach((field) => {
            switch (field.format) {
                case 'checkbox':
                    values[field.key] = !!values[field.key];
                    break;
                case 'select':
                    values[field.key] = field.options?.find((option) => option.value === values[field.key]);
                    break;
                default:
                    break;
            }
        });
        validation.setValues({ ...validation.values, ...(values || {}), configName: row.original?.name });

        let propArr = [...apPropertiesArr];
        propArr?.forEach((ele) => {
            if (ele?.key) {
                ele.val = values?.[ele?.key] ? ele?.key?.slice(0, -1) : undefined;
                ele.enableValue = !values?.[ele?.key] ? false : true;
            }
        });
        setApPropertiesArr(propArr);
        setOffCanvasModal(true);
    };

    const handleCanvasModalClose = useCallback(async () => {
        setSelectedRow({});
        setConfigProperty([]);
        setMode('add');
        setFormValues({});
        validation.resetForm();
        setShowProperty(false);
        setOffCanvasModal(false);
        apPropertiesArr?.forEach((ele) => {
            ele.enableValue = false;
        });
    }, [offCanvasModal]);

    const handleSave = () => {
        setOffCanvasModal(false);
        setLoading(true);

        const dataObj = {
            name: validation.values?.configName,
            packagename: appsData?.packagename,
            adamid: Number(appsData?.adamid),
            config: {}
        };

        apPropertiesArr?.forEach((property) => {
            switch (property.inputType) {
                case 'input':
                case 'number':
                case 'tableInput':
                case 'tableObject':
                case 'textarea':
                    dataObj.config[property?.key] = validation.values[property.key];
                    break;
                case 'checkbox':
                    dataObj.config[property?.key] = !!validation.values[property.key];
                    break;
                case 'select':
                    dataObj.config[property?.key] = validation.values[property.key]?.value;
                    break;
                default:
                    dataObj.config[property?.key] = validation.values[property.key];
            }
        });

        let prepareUrl;
        if (mode === 'add') prepareUrl = api.create(url.IOS_APPCONFIG, dataObj, false, domains.ANDROID_V1);
        else prepareUrl = api.patch(url.IOS_APPCONFIG + '/' + selectedRow?._id, dataObj, false, domains.ANDROID_V1);

        prepareUrl
            .then((resp) => {
                if (resp.status === 'success') {
                    toast.success(mode === 'edit' ? toastMessages.appConfigUpdated : toastMessages.appConfigCreated);
                    getAppConfig({ ...searchParams, packagename: appsData?.packagename });
                }
                setLoading(false);
                setSelectedRow({});
            })
            .catch((err) => setLoading(false));

        validation.resetForm();
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formValues },
        onSubmit: (values) => {
            let status = true;
            if (values?.key?.endsWith('.') || values?.key?.startsWith('.')) {
                toast.error(toastMessages.invalidKey);
                status = false;
                return;
            }
            apPropertiesArr.length > 0 &&
                apPropertiesArr?.forEach((app) => {
                    if (app.key === values?.key) {
                        toast.error(toastMessages.invalidKey);
                        status = false;
                        return;
                    }
                });
            if (status) {
                setCanvasLoader(true);
                const obj = {
                    packagename: appsData?.packagename,
                    property: {
                        name: values?.name,
                        key: values?.key,
                        format: values?.format?.value,
                        description: values?.description,
                        options:
                            values?.format?.value === 'select'
                                ? values?.options
                                : values?.format?.value === 'tableObject'
                                ? values?.keys
                                : []
                    }
                };

                let apiService;
                if (propertyMode === 'edit')
                    apiService = api.patch(url.IOS_APP_TEMPLATE + '/' + values?.id, obj, false, domains.ANDROID_V1);
                else apiService = api.create(url.IOS_APP_TEMPLATE, obj, false, domains.ANDROID_V1);

                apiService
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.status === 'success') {
                            setPropertyMode('add');
                            setShowProperty(false);
                            setCanvasLoader(false);
                            toast.success(
                                propertyMode === 'edit' ? toastMessages?.appConfigPropertyUpdated : toastMessages?.appConfigPropertyCreated
                            );
                            validation.resetForm();
                            getTemplate();
                        } else setCanvasLoader(false);
                    })
                    .catch((err) => setCanvasLoader(false));
            }
        }
    });

    const inputComponent = (field, index) => {
        return (
            <>
                {field?.name && !field.hideLabel && (
                    <>
                        <Col
                            md={field?.customCol ? 6 : 12}
                            xl={field?.customCol ? 5 : 12}
                            lg={field.customCol ? 5 : 12}
                            className="d-flex align-items-center mb-2"
                        >
                            <Label className="form-label d-flex align-items-center fw-medium mb-0 fs-14">
                                {field.name}
                                {field?.mandatory && mode === 'add' ? (
                                    <span className="red-color ps-1">*</span>
                                ) : (
                                    <span className="mx-1"> :</span>
                                )}
                            </Label>
                        </Col>
                        <div>
                            <Col md={field.customCol ? 6 : 12} xl={field.customCol ? 5 : 12} lg={field.customCol ? 5 : 12}>
                                {field.helpText && <div className="text-muted  mb-2 word-wrap-break">{field.helpText}</div>}
                            </Col>
                        </div>
                    </>
                )}

                <Col md={10} sm={10} className={field?.name ? 'mt-2 mb-2' : ''}>
                    <div className={'input-group '}>
                        <div className="d-flex align-items-center w-100">
                            <Input
                                name={field?.key}
                                id={field?.key}
                                className={`form-control ${field.class ? field.class : 'w-100'}`}
                                placeholder={field.placeholder ? field.placeholder : `Enter ${field.name}`}
                                type={field?.type}
                                // disabled={mode === 'view'}
                                maxLength={field?.maxLength}
                                validate={{ required: { value: true } }}
                                onChange={(e) => {
                                    if (field.regex) {
                                        const test = field.regex.test(e.target.value);
                                        if ((!test && e.target.value !== '') || e.target.value?.includes('..')) return;
                                    }
                                    validation.setFieldValue([field.key], e.target.value);
                                }}
                                value={validation.values?.[field?.key] || ''}
                            />
                        </div>
                    </div>
                </Col>
            </>
        );
    };

    const selectComponent = (field) => {
        return (
            <Row className={`pb-3 ${field?.customBorder && 'border-bottom-dashed border-light'}`}>
                {field?.name && !field.hideLabel && (
                    <>
                        <Col
                            md={field?.customCol ? 6 : 12}
                            xl={field?.customCol ? 5 : 12}
                            lg={field.customCol ? 5 : 12}
                            className="d-flex align-items-center mb-2"
                        >
                            <Label className="form-label d-flex align-items-center fw-medium mb-0 fs-14">
                                {field.name}
                                {field?.mandatory && mode === 'add' ? (
                                    <span className="red-color ps-1">*</span>
                                ) : (
                                    <span className="mx-1"> :</span>
                                )}
                            </Label>
                        </Col>
                        <div>
                            <Col md={field.customCol ? 6 : 12} xl={field.customCol ? 5 : 12} lg={field.customCol ? 5 : 12}>
                                {field.helpText && <div className="text-muted  mb-2 word-wrap-break">{field.helpText}</div>}
                            </Col>
                        </div>
                    </>
                )}
                <Col md={field.customCol ? 6 : 10} xl={field.customCol ? 5 : 10} lg={field.customCol ? 5 : 10}>
                    <Select
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.label}
                        isMulti={field.isMulti ? true : false}
                        isClearable={field.isMulti ? true : false}
                        id={field.name ? field.name : field?.key}
                        name={field?.key}
                        isDisabled={mode === 'view'}
                        options={field.options ? field.options : field.dropdownvals}
                        placeholder={`Select ${field.name}`}
                        onBlur={() => validation.handleBlur({ target: { name: field.key } })}
                        onChange={(selectedOption) => {
                            setSelectedFormat(selectedOption);
                            validation.setValues({ ...validation.values, keys: '', options: '' });
                            validation.handleChange({ target: { name: field.key, value: selectedOption } });
                        }}
                        value={validation?.values[field.key] || ''}
                        isSearchable={true}
                        noOptionsMessage={() => 'No data found'}
                    />
                </Col>
            </Row>
        );
    };

    const tableInput = (field) => {
        const handleAddVAl = () => {
            if (validation.values[field.key]?.includes(validation.values[field.val])) {
                toast.error(toastMessages.keyExists);
                return;
            }
            let values = [...(validation.values[field.key] || [])];
            values.push(validation.values[field.val]);
            validation.setValues({ ...validation.values, [field.key]: values, [field.val]: '' });
        };
        const handleDelete = (ind) => {
            let values = [...(validation.values[field.key] || [])];
            values.splice(ind, 1);
            validation.setValues({ ...validation.values, [field.key]: values, [field.val]: '' });
        };

        return (
            <React.Fragment>
                <Row>
                    <Col sm={'auto'}>
                        <Label className="mb-0 fw-medium d-flex align-items-center">
                            {field.name} {field?.mandatory && <span className="red-color ps-1">*</span>}
                        </Label>
                        <div className="text-muted font-size-12 mb-2 word-wrap-break ">{field.helpText && field.helpText}</div>
                        <Table bordered={validation.values?.[field.key]?.length === 1}>
                            <tbody>
                                {(validation.values?.[field.key] || [])?.map((value, ind) => (
                                    <tr key={ind}>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-between">
                                                {value}
                                                {formType !== 'view' && (
                                                    <Button color="danger" outline size="sm" onClick={() => handleDelete(ind)}>
                                                        <i className="ri-delete-bin-6-line fs-16" />
                                                    </Button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {(field.max && validation.values[field.key]?.length
                                    ? validation.values[field.key]?.length < field.max
                                    : true) && formType !== 'view' ? (
                                    <tr>
                                        <td className={`${validation.values?.[field.key]?.length === 1 ? '' : 'border-none'}`}>
                                            <div className="d-flex align-items-center gap-2">
                                                <Input
                                                    name={field?.val}
                                                    id={field?.val}
                                                    className="form-control"
                                                    autoComplete="new-password"
                                                    placeholder={`Enter ${field.name}`}
                                                    type={field.type}
                                                    validate={{ required: { value: true } }}
                                                    onChange={(e) => {
                                                        if (field.regex) {
                                                            const test = field.regex.test(e.target.value);
                                                            if (!test && e.target.value !== '') return;
                                                        }
                                                        validation?.handleChange(e);
                                                    }}
                                                    min={field.minValue}
                                                    max={field.maxValue}
                                                    maxLength={field.maxLength}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values[field?.val] || ''}
                                                    invalid={
                                                        validation?.touched?.[field?.val] && validation?.errors?.[field?.val] ? true : false
                                                    }
                                                />
                                                <Button
                                                    color="success"
                                                    outline
                                                    size="sm"
                                                    disabled={!validation.values[field?.val] || validation.values[field?.val] === ''}
                                                    onClick={handleAddVAl}
                                                >
                                                    <i className="ri-check-line fs-16" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ) : !validation.values[field.key]?.length ? (
                                    '–'
                                ) : (
                                    ''
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const tableObject = (field) => {
        const handleDisable = () => {
            let flag = false;
            flag = field.mandatory?.every((content) => (flag = !validation.values[content] || validation.values[content] === ''));

            return flag;
        };

        const handleAdds = () => {
            let values = [...(validation.values[field.key] || [])];
            let obj = {};
            let clearObj = {};
            field.options.forEach((content) => {
                obj[content.key] = validation.values[content.key] ? validation.values[content.key] : undefined;
                clearObj[content.key] = '';
            });
            if (field.max ? values?.length <= field.max : true) values.push(JSON.parse(JSON.stringify(obj)));
            validation.setValues({ ...validation.values, ...clearObj, [field.key]: values });
        };

        const handleDelete = (ind) => {
            let values = [...(validation.values[field.key] || [])];
            values.splice(ind, 1);
            let clearObj = {};
            field.options.forEach((content) => (clearObj[content.key] = ''));
            validation.setValues({ ...validation.values, ...clearObj, [field.key]: values });
        };

        return (
            <React.Fragment>
                <Row>
                    <Col sm={12}>
                        <Label className="mb-0 fw-medium d-flex align-items-center">{field.name}</Label>
                        <div className="text-muted font-size-12 mb-2 word-wrap-break ">{field.helpText && field.helpText}</div>
                        <Table bordered>
                            {field.showHeader && (
                                <thead className="bg-light text-muted">
                                    <tr>
                                        {field.options?.map((content, index) => (
                                            <td key={index}>
                                                {content.name}
                                                {field.mandatory?.includes(content.ke) && <span className="text-danger ms-1">*</span>}
                                            </td>
                                        ))}
                                        <td>Actions</td>
                                    </tr>
                                </thead>
                            )}
                            <tbody>
                                {(validation.values?.[field.key] || [])?.map((value, ind) => (
                                    <tr key={ind}>
                                        {field.options?.map((content, index) => (
                                            <td className={content.columnClass} key={index}>
                                                {value[content.key]}
                                            </td>
                                        ))}
                                        {formType !== 'view' && (
                                            <td className="d-flex justify-content-center">
                                                <Button color="danger" outline size="sm" onClick={() => handleDelete(ind)}>
                                                    <i className="ri-delete-bin-6-line fs-16" />
                                                </Button>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                                {formType !== 'view' ? (
                                    <tr className={`${validation.values?.[field.key]?.length === 1 ? '' : 'border-none'}`}>
                                        {field.options?.map((content, index) => (
                                            <td
                                                key={index}
                                                className={` ${validation.values?.[field.key]?.length === 1 ? '' : 'border-none px-0'}`}
                                            >
                                                <Row className="p-0 align-items-center">{dynamicComponent(content)}</Row>
                                            </td>
                                        ))}
                                        <td
                                            className={`${
                                                validation.values?.[field.key]?.length === 1 ? '' : 'border-none'
                                            } vertical-align-middle mt-2`}
                                        >
                                            <Button color="success" outline size="sm" disabled={handleDisable()} onClick={handleAdds}>
                                                <i className="ri-check-line fs-16" />
                                            </Button>
                                        </td>
                                    </tr>
                                ) : !validation.values[field.key]?.length ? (
                                    '–'
                                ) : (
                                    ''
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const textareaComponent = (field) => {
        return (
            <>
                {field?.name && !field.hideLabel && (
                    <>
                        <Col
                            md={field?.customCol ? 6 : 12}
                            xl={field?.customCol ? 5 : 12}
                            lg={field.customCol ? 5 : 12}
                            className="d-flex align-items-center mb-2"
                        >
                            <Label className="form-label d-flex align-items-center fw-medium mb-0 fs-14">
                                {field.name}
                                {field?.mandatory && mode === 'add' ? (
                                    <span className="red-color ps-1">*</span>
                                ) : (
                                    <span className="mx-1"> :</span>
                                )}
                            </Label>
                        </Col>
                        <div>
                            <Col md={field.customCol ? 6 : 12} xl={field.customCol ? 5 : 12} lg={field.customCol ? 5 : 12}>
                                {field.helpText && <div className="text-muted  mb-2 word-wrap-break">{field.helpText}</div>}
                            </Col>
                        </div>
                    </>
                )}

                <Col md={10} sm={10} className={field?.name ? 'mt-2 mb-2' : ''}>
                    <div className={'input-group '}>
                        <div className="d-flex align-items-center w-100">
                            <Input
                                name={field?.key}
                                id={field?.key}
                                className={`form-control ${field.class ? field.class : 'w-100'}`}
                                placeholder={`Enter ${field.name}`}
                                type={field?.type}
                                rows={field?.rows}
                                // disabled={mode === 'view'}
                                maxLength={field?.maxLength}
                                validate={{ required: { value: true } }}
                                onChange={validation.handleChange}
                                value={validation.values?.[field?.key] || ''}
                            />
                        </div>
                    </div>
                </Col>
            </>
        );
    };

    const toggleButton = (field) => {
        return (
            <>
                {field.name && !field.hideLabel && (
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                        <Label className="mb-0 fw-medium d-flex align-items-center w-100">{field.name}</Label>
                    </Col>
                )}
                <Col xs={6} md={6} sm={6} lg={7} xl={7} className="d-flex">
                    <div className="form-check form-switch form-switch-lg">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            name={field.key}
                            id={field.key}
                            // disabled={qrDetails}
                            checked={Boolean(validation.values[field.key])}
                            onChange={(e) => validation.setFieldValue([field.key], e.target.checked)}
                        />
                    </div>
                </Col>
            </>
        );
    };

    const dynamicComponent = (field, index) => {
        const fieldType = field?.inputType ? field?.inputType : field?.format;

        switch (fieldType) {
            case 'input':
            case 'number':
                return inputComponent(field);
            case 'select':
                return selectComponent(field);
            case 'tableInput':
                return tableInput(field);
            case 'tableObject':
                return tableObject(field);
            case 'textarea':
                return textareaComponent(field);
            case 'checkbox':
                return toggleButton(field);
        }
    };

    const formatOptions = [
        { label: 'Array of Numbers', value: 'tableInputNumber' },
        { label: 'Array of Objects', value: 'tableObject' },
        { label: 'Array of Strings', value: 'tableInput' },
        { label: 'Boolean', value: 'checkbox' },
        { label: 'Dropdown', value: 'select' },
        { label: 'Number', value: 'number', type: 'number' },
        { label: 'String', value: 'input' },
        { label: 'Text Area', value: 'textarea' }
    ];

    const propertySchema = [
        {
            name: 'Name',
            key: 'name',
            helpText: 'The given name is the label displayed in the interface.',
            inputType: 'input',
            mandatory: true
        },
        {
            name: 'Key',
            key: 'key',
            helpText: 'Key is mandatory for setting up account configurations in the app.',
            mandatory: true,
            regex: new RegExp(/^[a-zA-Z0-9_.]+$/),
            inputType: 'input'
        },
        {
            name: 'Format Type',
            key: 'format',
            helpText: 'The format type refers to the data type used to define the value of a property',
            mandatory: true,
            inputType: 'select',
            options: formatOptions
        },
        {
            name: 'Description',
            key: 'description',
            helpText: 'The description serves as help text, displayed for each property.',
            inputType: 'input',
            type: 'textarea',
            rows: 5
        },
        {
            key: 'options',
            inputType: 'tableObject',
            showOn: ['format'],
            showKey: 'format',
            showVal: 'select',
            mandatory: ['label', 'value'],
            options: [
                {
                    // label: 'label',
                    key: 'label',
                    inputType: 'input',
                    hideLabel: true,
                    columnClass: '',
                    placeholder: 'Enter Label'
                },
                {
                    // label: 'Value',
                    key: 'value',
                    inputType: 'input',
                    hideLabel: true,
                    columnClass: '',
                    placeholder: 'Enter Value'
                }
            ]
        },
        {
            name: 'Keys',
            key: 'keys',
            inputType: 'tableObject',
            showOn: ['format'],
            showKey: 'format',
            showVal: 'tableObject',
            options: [
                { value: 'key', inputType: 'input', hideLabel: true, placeholder: 'Enter Key' },
                {
                    value: 'inputType',
                    inputType: 'select',
                    placeholder: 'Select input type',
                    options: [
                        { label: 'Boolean', value: 'checkbox' },
                        // { label: 'Dropdown', value: 'select' },
                        { label: 'Number', value: 'input', type: 'number' },
                        { label: 'String', value: 'input' },
                        { label: 'Text Area', value: 'textarea' }
                    ]
                }
            ],
            val: 'option',
            regex: new RegExp(/^[a-zA-Z0-9_.]+$/)
        }
    ];

    const handleAddProperty = () => {
        setPropertyMode('add');
        setShowProperty(true);
    };

    const handleProCloseclick = () => {
        setShowProperty(false);
        setFormValues({ configName: validation.values?.configName });
        apPropertiesArr?.forEach((prooo) => {
            if (prooo?.id) prooo.enableValue = false;
        });
        setApPropertiesArr(apPropertiesArr);
        // validation.setValues({ ...validation.values, key: '', format: '' });
    };

    const handleShowOn = (field) => {
        let flag = true;
        field.showOn?.forEach((showOn) => (flag = flag && !!validation.values[showOn]));
        flag = field.showKey ? flag && validation.values[field.showKey]?.value === field.showVal : flag;
        return flag;
    };

    const handleConfigureProperty = (property) => {
        let propArr = [...apPropertiesArr];
        propArr?.forEach((ele) => {
            if (ele?.key === property?.key) {
                ele.val = ele.format === 'tableInput' ? ele?.key?.slice(0, -1) : undefined;
                ele.enableValue = !validation.values?.[ele?.key] ? !ele.enableValue : true;
            }
        });
        setApPropertiesArr(propArr);
    };

    const handleEditProperty = (property) => {
        setPropertyMode('edit');
        setShowProperty(true);
        const dataObj = {
            configName: selectedRow?.name,
            id: property?.id,
            name: property?.name,
            format: formatOptions.find((option) => option.value === property.format),
            key: property?.key,
            description: property?.description,
            [property.format === 'select' ? 'options' : 'keys']: property?.options
        };
        setFormValues({ ...dataObj });
    };

    const handleDeleteProperty = (property) => {
        setCanvasLoader(true);
        api.delete(url.IOS_APP_TEMPLATE + '/' + property?.id, '', domains.ANDROID_V1)
            .then((resp) => {
                if (resp.status === 'success') {
                    setCanvasLoader(false);
                    getTemplate();
                    toast.success(toastMessages.appConfigPropertyDeleted);
                }
            })
            .catch((err) => setCanvasLoader(false));
    };

    const handleOffCanvasBody = () => {
        return !showProperty ? (
            <React.Fragment>
                <div className="mb-2">
                    {inputComponent({ name: 'Configuration Name', key: 'configName', mandatory: true, config: true })}
                </div>
                <div>
                    {apPropertiesArr?.length > 0 && (
                        <Row className="d-flex align-items-center">
                            <Col sm={8} xs={8} md={7} xl={8} lg={8}>
                                <div className="fs-14 fw-medium mt-2">Properties ({apPropertiesArr?.length})</div>
                                <div className="text-muted mt-1">
                                    Here is a list of default properties for configuration, with the option to add custom properties to meet
                                    your specific needs.
                                </div>
                            </Col>
                            <Col m={4} xs={4} md={5} xl={4} lg={4} className="d-flex align-items-center justify-content-end">
                                <Button color="primary" onClick={handleAddProperty}>
                                    + Add Properties
                                </Button>
                            </Col>
                        </Row>
                    )}
                    {apPropertiesArr?.length > 0 ? (
                        apPropertiesArr?.map((property, ind) => {
                            return (
                                <React.Fragment key={ind}>
                                    <div className="mt-2 py-1 border-bottom border-1">
                                        <div className="d-flex p-2 align-items-center justify-content-between">
                                            <div>
                                                <div className="fs-13 fw-medium">{property?.name}</div>
                                                <div className="fs-12 mt-1 text-muted me-2">{property?.description}</div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className={`border p-1 rounded-3 fs-12 me-2 cursor-pointer ${
                                                        validation.values?.[property?.key] ? 'text-primary' : ''
                                                    }`}
                                                    onClick={() => handleConfigureProperty(property, ind)}
                                                >
                                                    {validation.values?.[property?.key] ? 'Configured' : 'Configure'}
                                                </div>
                                                {!property?.id && (
                                                    <div>
                                                        <i
                                                            id={`configure-${ind}`}
                                                            className={`ri-error-warning-line fs-14 text-secondary ${
                                                                property?.id ? 'me-2' : ''
                                                            }`}
                                                        />
                                                        {Tooltip(
                                                            `configure-${ind}`,
                                                            'Default property, you can`t edit or delete this configuration.'
                                                        )}
                                                    </div>
                                                )}
                                                {property?.id && (
                                                    <div className="me-2">
                                                        <i
                                                            className="ri-edit-box-line fs-15 text-secondary cursor-pointer"
                                                            onClick={() => handleEditProperty(property)}
                                                        />
                                                    </div>
                                                )}
                                                {property?.id && (
                                                    <div>
                                                        <i
                                                            className="ri-delete-bin-line fs-15 text-danger cursor-pointer"
                                                            onClick={() => handleDeleteProperty(property)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {property?.enableValue && <div className="m-2">{dynamicComponent(property)}</div>}
                                    </div>
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <div className="text-center mt-20">
                            <div>
                                <img src={addProperty} width={60} alt="no property" />
                            </div>
                            <div className="fs-15 fw-semibold mt-2">Properties not available</div>
                            <div className="mt-2">
                                <Button color="primary" className="p-2 border cursor-pointer" onClick={handleAddProperty}>
                                    + Add Property
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div>
                    {propertySchema?.map((field, ind) => {
                        return (field.showOn ? handleShowOn(field) : true) && dynamicComponent(field, ind);
                    })}
                </div>

                <div className="position-absolute position-bottom-30 end-10">
                    <Button
                        outline
                        size="md"
                        color="primary"
                        className="py-2 px-4 border cursor-pointer me-2"
                        onClick={() => handleProCloseclick()}
                    >
                        Close
                    </Button>
                    <Button
                        size="md"
                        color="primary"
                        className="py-2 px-4 border cursor-pointer"
                        onClick={validation.handleSubmit}
                        disabled={
                            !validation.values?.name ||
                            validation.values.name === '' ||
                            !validation.values?.format ||
                            !validation.values?.key ||
                            validation.values?.key === '' ||
                            (validation.values?.format?.value === 'arrayobj' && validation.values?.keys?.length < 0) ||
                            (validation.values?.format?.value === 'dropdown' && validation.values?.dropdownVals?.length < 0)
                        }
                    >
                        {propertyMode === 'edit' ? 'Update' : 'Save'}
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete Managed Configuration?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={delConfig} alt="deletepolicy" width={60} height={60} />
                </div>
                <div>
                    Configuration Name: <span className="fw-semibold">{selectedRow?.title}</span>
                </div>
            </div>
        );
    };

    const handleDeleteOne = (row) => {
        setSelectedRow(row.original);
        setDeleteModal(true);
    };

    const handleDeleteOneConfirmation = () => {
        setLoading(true);
        api.delete(url.IOS_APPCONFIG + '/' + selectedRow?._id, '', domains.ANDROID_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    getAppConfig({ ...searchParams, packagename: appsData?.packagename });
                    toast.success(toastMessages.appConfigDeleted);
                }
                setDeleteModal(false);
                setSelectedRow({});
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handlePageChange = (pgNum) => {
        setLoading(true);
        getAppConfigurations({ ...searchParams, page: pgNum });
    };

    const handleDisableSave = () => {
        return !showProperty
            ? !validation.values?.configName || validation.values?.configName === ''
            : !validation.values?.name || !validation.values.key || !validation?.values?.format;
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        title={appsData?.name}
                        pageTitle={appsData?.name}
                        showIcon={true}
                        icon={appsData?.icondownloadurl}
                        showBack={true}
                        backLink="atvapps"
                        history={history}
                    />

                    <React.Fragment>
                        <TableContainer
                            loading={loading}
                            data={configurations || []}
                            isGlobalFilter={true}
                            tableHeader={'All Configurations'}
                            addButton={true}
                            handleAddNew={handleAdd}
                            handleEdit={handleEdit}
                            handleDelete={handleDeleteOne}
                            onPageChange={handlePageChange}
                            totalRecords={totalRecords}
                            columns={tableSchema}
                            reader={priviliges.POLICY_READER}
                            editor={priviliges.POLICY_EDITOR}
                            className="custom-header-css"
                            divClass="table-responsive table-card"
                            tableClass="table table-nowrap table-border table-centered align-middle"
                            theadClass="bg-light text-muted"
                        />
                    </React.Fragment>
                    {offCanvasModal && (
                        <OffcanvasModal
                            direction="end"
                            modalClassName="w-40"
                            open={offCanvasModal}
                            hideFooterButtons={showProperty}
                            saveText={'Save'}
                            saveDisabled={handleDisableSave()}
                            toggle={handleCanvasModalClose}
                            handleCloseClick={handleCanvasModalClose}
                            OffcanvasModalID="managedConfigModal"
                            handleOffcanvasBody={handleOffCanvasBody}
                            handleSaveClick={handleSave}
                            offcanvasHeader={
                                showProperty ? (propertyMode === 'edit' ? 'Edit Property' : 'Add Property') : 'Managed Configuration'
                            }
                            loading={canvasLoader}
                            bodyCSSClass={'p-4'}
                        />
                    )}
                    <DeleteModal
                        hideIcon={true}
                        show={deleteModal}
                        hideDeleteMessage={true}
                        message={message()}
                        confirmText={'Delete'}
                        onDeleteClick={handleDeleteOneConfirmation}
                        onCloseClick={() => {
                            setDeleteModal(false);
                            setSelectedRow({});
                        }}
                        deleteMessage={'Are you sure you want to delete this managed configuration ?'}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default IOSConfigurations;
