/* eslint-disable max-nested-callbacks */
/* eslint-disable multiline-comment-style */
import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormFeedback, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import WindowsImage from '../../../assets/images/windows/svg/common/windowslogo.svg';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import { FilePond } from 'react-filepond';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { useEnv } from '../../../envContext';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';
import { Tooltip } from '../../../Components/Common/Util';
import BlockedDomains from '../../../assets/images/common/png/common/blockedDomains.png';

export const getWindowsPolicyInitialValues = (policySchema) => {
    return policySchema?.reduce((defaultValues, cardObj) => {
        cardObj.fields.forEach((obj) => {
            if (obj?.inputType === 'restrictions') {
                obj?.fields?.forEach((restrictionCardObj) => {
                    restrictionCardObj?.fields?.forEach((restrictionObj) => {
                        defaultValues[restrictionObj?.value] = restrictionObj?.defaultValue;
                    });
                });
            } else {
                defaultValues[obj?.value] = obj?.defaultValue;
            }
        });
        return defaultValues;
    }, {});
};

export const InputComponent = (props) => {
    return (
        <Row className="m-0 p-0">
            <Col xs={6} md={6} sm={6} lg={5} xl={5} className={'d-flex align-items-center justify-content-between'}>
                <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                    {props.field.label}
                    {props.field.mandatory && props.formType !== 'view' && props.formType !== 'viewChecker' ? (
                        <span className="red-color ps-1">*</span>
                    ) : props.field.helpText && props.formType !== 'view' && props.formType !== 'viewChecker' ? (
                        <>
                            <i
                                className={'ri-information-line d-flex align-items-center blue-icon ps-1 fs-15'}
                                id={`inputComponent-${props.index}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`inputComponent-${props.index}`}>
                                {props.field.helpText && props.field.helpText}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <span className="ps-1">:</span>
                    )}
                </Label>
            </Col>
            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                <div className="input-group">
                    {props.formType !== 'view' ? (
                        <Input
                            name={props.field.value}
                            id={props.field.value}
                            className="form-control"
                            placeholder={`Enter ${props.field.label}`}
                            type={props.field.type}
                            validate={{ required: { value: true } }}
                            onChange={props.validation?.handleChange}
                            min={props.field.minValue}
                            max={props.field.maxValue}
                            maxLength={props.field.maxLength}
                            onBlur={props.validation?.handleBlur}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                            value={props.validation?.values[props.field?.value] || ''}
                            autoComplete="off"
                            invalid={
                                props.validation?.touched?.[props.field?.value] && props.validation?.errors?.[props.field?.value]
                                    ? true
                                    : false
                            }
                        />
                    ) : props.validation?.values[props.field?.value] || '' ? (
                        props.validation?.values[props.field?.value] || ''
                    ) : (
                        '–'
                    )}
                    {props.validation.touched[props.field.value] && props.validation.errors[props.field.value] ? (
                        <FormFeedback type="invalid">{props.validation.errors[props.field.value]}</FormFeedback>
                    ) : null}
                </div>
            </Col>
        </Row>
    );
};

const PolicyInputComponent = ({ index, field, validation, formType, handleKeyDown }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Row key={index} className={`m-0 p-0 ${enableOnCondition(field, validation) ? '' : 'mask pe-none'}`}>
            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="m-0 p-0 mb-2">
                <Label className={'mb-0 fw-medium d-flex align-items-center mb-1'}>
                    {field.label}
                    {field?.mandatory && formType !== 'view' && <span className="red-color ps-1">*</span>}
                    <i
                        className={'ri-information-line d-flex align-items-center blue-icon ps-1 fs-15'}
                        id={`polInputComponent-${field.value}`}
                    />
                    <UncontrolledTooltip placement="bottom" target={`polInputComponent-${field.value}`}>
                        {field.helpText && field.helpText}
                    </UncontrolledTooltip>
                </Label>
                {/* <div className="text-muted font-size-12 mb-2 word-wrap-break">{field.helpText && field.helpText}</div> */}
                <div className="input-group position-relative">
                    {formType !== 'view' ? (
                        <>
                            <Input
                                name={field.value}
                                id={field.value}
                                className="form-control"
                                placeholder={field.placeholder}
                                type={showPassword ? 'text' : field.type}
                                autoComplete={field.type === 'password' ? 'new-password' : 'off'}
                                onChange={
                                    field.shouldTrim
                                        ? (event) => {
                                              validation.handleChange({
                                                  target: {
                                                      name: field.value,
                                                      value: event.target.value.trim()
                                                  }
                                              });
                                          }
                                        : field.regExp
                                        ? (event) => {
                                              if (new RegExp(field.regExp).test(event.target.value) || event.target.value === '') {
                                                  validation.handleChange({
                                                      target: {
                                                          name: field.value,
                                                          value:
                                                              event.target.value !== ''
                                                                  ? field.valueType === 'number'
                                                                      ? Number(event.target.value)
                                                                      : event.target.value
                                                                  : event.target.value
                                                      }
                                                  });
                                              }
                                          }
                                        : validation.handleChange
                                }
                                min={field.minValue}
                                max={field.maxValue}
                                maxLength={field.maxLength}
                                onBlur={validation?.handleBlur}
                                onKeyDown={(e) => handleKeyDown(e)}
                                value={validation?.values[field?.value]}
                                disabled={field.disabled}
                                invalid={validation.touched[field.value] && validation?.errors?.[field?.value] ? true : false}
                            />
                            {field?.type === 'password' && validation.values[field?.value] && validation.values[field?.value] !== '' && (
                                <button
                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none cursor-auto text-muted"
                                    type="button"
                                    id="password-addon"
                                    onClick={() => togglePassword()}
                                >
                                    <i
                                        id="password-icon"
                                        className={`ri ${showPassword ? 'ri-eye-line' : 'ri-eye-off-line'} align-middle cursor-pointer`}
                                    ></i>
                                </button>
                            )}
                        </>
                    ) : (
                        <span>{validation?.values[field?.value] || '–'}</span>
                    )}
                    {validation.touched[field.value] && validation.errors[field.value] && (
                        <FormFeedback type="invalid">{validation.errors[field.value]}</FormFeedback>
                    )}
                </div>
            </Col>
        </Row>
    );
};

const prepareSendValue = (key, value, dataKey) => {
    const id = dataKey ? dataKey : key;
    if (!value) return '';
    return `<enabled/> <data id='${id}' value='${value}' />`;
};

const getDisplayValue = (preparedValue) => {
    if (!preparedValue) return '';

    // Extract value from the format "<enabled/> <data id='key' value='actual_value' />"
    const match = preparedValue.match(/value='([^']+)'/);
    return match ? match[1] : '';
};

const TempInputComponent = ({ index, field, validation, formType, handleKeyDown }) => {
    return (
        <Row key={index} className={`m-0 p-0 ${enableOnCondition(field, validation) ? '' : 'mask pe-none'}`}>
            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="m-0 p-0 mb-2">
                <Label className={'mb-0 fw-medium d-flex align-items-center mb-1'}>
                    {field.label}
                    {field?.mandatory && formType !== 'view' && <span className="red-color ps-1">*</span>}
                    <i
                        className={'ri-information-line d-flex align-items-center blue-icon ps-1 fs-15'}
                        id={`tempInputComponent-${field.value}`}
                    />
                    <UncontrolledTooltip placement="bottom" target={`tempInputComponent-${field.value}`}>
                        {field.helpText && field.helpText}
                    </UncontrolledTooltip>
                </Label>
                <div className="input-group position-relative">
                    {formType !== 'view' ? (
                        <>
                            <Input
                                name={field.value}
                                id={field.value}
                                className="form-control"
                                placeholder={field.placeholder}
                                type={field.type}
                                autoComplete={'off'}
                                onChange={
                                    field.regExp
                                        ? (event) => {
                                              if (new RegExp(field.regExp).test(event.target.value) || event.target.value === '') {
                                                  validation.handleChange({
                                                      target: {
                                                          name: field.value,
                                                          value: prepareSendValue(field.myId, event.target.value, field?.dataId)
                                                      }
                                                  });
                                              }
                                          }
                                        : (event) => {
                                              validation.handleChange({
                                                  target: {
                                                      name: field.value,
                                                      value: prepareSendValue(field.myId, event.target.value, field?.dataId)
                                                  }
                                              });
                                          }
                                }
                                min={field.minValue}
                                max={field.maxValue}
                                maxLength={field.maxLength}
                                onBlur={validation?.handleBlur}
                                onKeyDown={(e) => handleKeyDown(e)}
                                value={getDisplayValue(validation?.values[field?.value])}
                                disabled={field.disabled}
                                invalid={validation.touched[field.value] && validation?.errors?.[field?.value] ? true : false}
                            />
                        </>
                    ) : (
                        <span>{getDisplayValue(validation?.values[field?.value]) || '–'}</span>
                    )}
                    {validation.touched[field.value] && validation.errors[field.value] && (
                        <FormFeedback type="invalid">{validation.errors[field.value]}</FormFeedback>
                    )}
                </div>
            </Col>
        </Row>
    );
};

const SelectComponent = ({ index, field, validation, formType, wallpapers }) => {
    return (
        <Row key={index} className={`m-0 p-0 ${enableOnCondition(field, validation) ? '' : 'mask pe-none'}`}>
            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="m-0 p-0 mb-2">
                <Label className="mb-0 fw-medium d-flex align-items-center mb-1">
                    {field.label}
                    {field?.mandatory && formType !== 'view' && <span className="red-color ps-1">*</span>}
                    <i
                        className={'ri-information-line d-flex align-items-center blue-icon ps-1 fs-15'}
                        id={`inputComponent-${index}-${field.tooltipId ? field.tooltipId : field.value}`}
                    />
                    <UncontrolledTooltip
                        placement="bottom"
                        target={`inputComponent-${index}-${field.tooltipId ? field.tooltipId : field.value}`}
                    >
                        {field.helpText ? field.helpText : 'Helper Text'}
                    </UncontrolledTooltip>
                </Label>
                {/* <div className="text-muted font-size-12 mb-2 word-wrap-break">{field.helpText && field.helpText}</div> */}
                {formType !== 'view' && formType !== 'viewChecker' ? (
                    <Select
                        getOptionValue={(option) => (field.optionValue ? option[field.optionValue] : option.value)}
                        getOptionLabel={(option) => (field.optionLabel ? option[field.optionLabel] : option.label)}
                        isMulti={field.isMulti ? true : false}
                        isClearable={field.isMulti || field.removable ? true : false}
                        closeMenuOnSelect={field.isMulti ? false : true}
                        id={field.label}
                        name={field.value}
                        options={field.wallpaper ? wallpapers : field.options?.filter((option) => !option.hidden)}
                        placeholder={field?.placeholder ? field?.placeholder : `Select ${field.label}`}
                        onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                        onChange={(selectedOption) =>
                            field.isMulti
                                ? validation.handleChange({
                                      target: {
                                          name: field.value,
                                          value: selectedOption.map((option) => option.value)
                                      }
                                  })
                                : validation.handleChange({
                                      target: {
                                          name: field.value,
                                          value: selectedOption.value
                                      }
                                  })
                        }
                        value={
                            field.isMulti
                                ? field.options?.filter((option) => validation.values[field.value]?.includes(option?.value))
                                : field.wallpaper
                                ? wallpapers?.find((option) => option.value === validation.values[field.value]) || ''
                                : field.options?.find((option) => option.value === validation.values[field.value]) || ''
                        }
                        isSearchable={true}
                        noOptionsMessage={() => 'No data found'}
                    />
                ) : (
                    <span>
                        {field.isMulti
                            ? field.options
                                  ?.filter((option) => validation.values[field.value]?.includes(option?.value))
                                  ?.map((option) => option.label)
                                  .join(', ') || '–'
                            : field.wallpaper
                            ? wallpapers?.find((option) => option.value === validation.values[field.value])?.label || '–'
                            : field.options?.find((option) => option.value === validation.values[field.value])?.label || '–'}
                    </span>
                )}
            </Col>
        </Row>
    );
};

const prepareBitLokerSendValue = (key, value) => {
    return `<data id='${key}' value='${value}' />`;
};

const getBitLockerDisplayValue = (preparedVal) => {
    if (!preparedVal) return '';

    // Extract value from the format "<data id='key' value='actual_value' />"
    const match = preparedVal.match(/value=['"]([^'"]+)['"]/);
    return match ? match[1] : '';
};

const BitLockerDropdowns = ({ index, field, validation, formType }) => {
    const setValueInValidation = (val, eleind) => {
        let originalStr = validation.values[field.value];
        const splitArray = originalStr.split(/(?=<data )/);
        splitArray.splice(eleind + 1, 1, val);
        validation.setValues({ ...validation.values, [field.value]: splitArray.join('') });
    };

    return (
        <Row key={index} className={'m-0 p-0'}>
            <Label className={'mb-0 d-flex align-items-center mb-1 p-0'}>
                <span className="fw-semibold">{field.label}</span>
                <i
                    className={'ri-information-line d-flex align-items-center blue-icon ps-1 fs-15'}
                    id={`bitLoker-${index}-${field.value}`}
                />
                <UncontrolledTooltip placement="bottom" target={`bitLoker-${index}-${field.value}`}>
                    {field.helpText}
                </UncontrolledTooltip>
            </Label>
            <div className="mt-2 p-0">
                {field.fields.map((ele, eleind) => {
                    return (
                        <Row key={eleind} className={'m-0'}>
                            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="m-0 p-0 mb-2">
                                <Label className="mb-0 fw-medium d-flex align-items-center mb-1">{ele.label}</Label>
                                {formType !== 'view' ? (
                                    <Select
                                        id={ele.label}
                                        name={ele.value}
                                        options={ele.options}
                                        onChange={(selectedOption) =>
                                            setValueInValidation(prepareBitLokerSendValue(ele.value, selectedOption.value), eleind)
                                        }
                                        value={
                                            ele.options?.find(
                                                (option) =>
                                                    option.value ===
                                                    getBitLockerDisplayValue(validation.values[field.value].split(/(?=<data )/)[eleind + 1])
                                            ) || ''
                                        }
                                        isSearchable={true}
                                        noOptionsMessage={() => 'No data found'}
                                    />
                                ) : (
                                    <span>
                                        {ele.options?.find(
                                            (option) =>
                                                option.value ===
                                                getBitLockerDisplayValue(validation.values[field.value].split(/(?=<data )/)[eleind + 1])
                                        )?.label || '–'}
                                    </span>
                                )}
                            </Col>
                        </Row>
                    );
                })}
            </div>
        </Row>
    );
};

const BitLockerStartUp = ({ index, field, formType, validation }) => {
    const isFieldVisible = (eleind) => {
        return eleind === 0 || validation.values[field.value].includes(field.fields[0].checkedValue);
    };
    const handleCheckboxChange = (ele, eleind) => {
        const currentValue = validation.values[field.value];
        const isChecked = currentValue.includes(ele.checkedValue);
        if (isChecked) {
            const newValue = eleind === 0 ? '' : currentValue.replace(ele.checkedValue, '');
            validation.setFieldValue(field.value, newValue);
        } else {
            const values = currentValue.split(/(?=<data )/);
            values.splice(eleind, 0, ele.checkedValue);
            validation.setFieldValue(field.value, values.join(''));
        }
    };

    return (
        <Row key={index} className={'m-0 p-0 gap-1'}>
            <Label className={'mb-0 d-flex align-items-center mb-1 p-0'}>
                <span className="fw-semibold">{field.label}</span>
                <i
                    className={'ri-information-line d-flex align-items-center blue-icon ps-1 fs-15'}
                    id={`bitLokerStartup-${index}-${field.value}`}
                />
                <UncontrolledTooltip placement="bottom" target={`bitLokerStartup-${index}-${field.value}`}>
                    {field.helpText}
                </UncontrolledTooltip>
            </Label>
            {field.fields.map((ele, eleind) => {
                return (
                    isFieldVisible(eleind) && (
                        <Row key={eleind} className={'m-0 p-0 gap-1'}>
                            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="p-0 padding-bottom-2">
                                <div className="form-check align-items-center mb-0">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled={formType === 'view'}
                                        onChange={() => handleCheckboxChange(ele, eleind)}
                                        checked={validation.values[field.value].includes(ele.checkedValue)}
                                    />
                                    <Label className="mb-0 d-flex align-items-center cursor-auto">
                                        <span
                                            className={`${formType !== 'view' ? 'cursor-pointer' : ''} `}
                                            onClick={formType !== 'view' ? () => handleCheckboxChange(ele, eleind) : null}
                                        >
                                            {ele.label}
                                        </span>
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                    )
                );
            })}
        </Row>
    );
};

const RecoveryCheckbox = ({ ele, eleind, formType, validation, field, handleCheckboxChange }) => {
    return (
        <Row key={eleind} className={'m-0 p-0 gap-1'}>
            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="p-0 padding-bottom-2">
                <div className="form-check align-items-center mb-0">
                    <Input
                        className="form-check-input"
                        type="checkbox"
                        disabled={formType === 'view'}
                        onChange={() => handleCheckboxChange(ele, eleind)}
                        checked={validation.values[field.value].includes(ele.checkedValue)}
                    />
                    <Label className="mb-0 d-flex align-items-center cursor-auto">
                        <span
                            className={`${formType !== 'view' ? 'cursor-pointer' : ''} `}
                            onClick={formType !== 'view' ? () => handleCheckboxChange(ele, eleind) : null}
                        >
                            {ele.label}
                        </span>
                    </Label>
                </div>
            </Col>
        </Row>
    );
};

const RecoveryDropdown = ({ ele, eleind, formType, validation, field, handleDropdownChange }) => {
    return (
        <Row key={eleind} className={'m-0 p-0'}>
            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="m-0 p-0 mb-2">
                <Label className="mb-0 fw-medium d-flex align-items-center mb-1">{ele.label}</Label>
                {formType !== 'view' ? (
                    <Select
                        id={ele.label}
                        name={ele.value}
                        options={ele.options}
                        onChange={(selectedOption) => handleDropdownChange(selectedOption.value, eleind)}
                        value={
                            ele.options?.find((option) => option.value === validation.values[field.value].split(/(?=<data )/)[eleind]) || ''
                        }
                        isSearchable={true}
                        noOptionsMessage={() => 'No data found'}
                    />
                ) : (
                    <span>
                        {ele.options?.find((option) => option.value === validation.values[field.value].split(/(?=<data )/)[eleind])
                            ?.label || '–'}
                    </span>
                )}
            </Col>
        </Row>
    );
};

const bitLockerComponentMap = {
    checkbox: RecoveryCheckbox,
    dropdown: RecoveryDropdown
};

const BitLockerRecoveryComponents = (props) => {
    const Component = bitLockerComponentMap[props.ele.inputType];

    return (
        <React.Fragment key={props.index}>
            <Component {...props} />
        </React.Fragment>
    );
};

const BitLockerRecovery = ({ index, field, formType, validation }) => {
    const isFieldVisible = (eleind) => {
        return eleind === 0 || validation.values[field.value].includes(field.fields[0].checkedValue);
    };

    const handleCheckboxChange = (ele, eleind) => {
        const currentValue = validation.values[field.value];
        const isChecked = currentValue.includes(ele.checkedValue);
        if (isChecked) {
            const newValue = eleind === 0 ? '' : currentValue.replace(ele.checkedValue, ele.unCheckedValue);
            validation.setFieldValue(field.value, newValue);
        } else {
            const values = currentValue.split(/(?=<data )/);
            values.splice(eleind, 1, eleind === 0 ? ele.initialCheckedValue : ele.checkedValue);
            validation.setFieldValue(field.value, values.join(''));
        }
    };

    const handleDropdownChange = (val, eleind) => {
        let currentValue = validation.values[field.value];
        const values = currentValue.split(/(?=<data )/);
        values.splice(eleind, 1, val);
        validation.setFieldValue(field.value, values.join(''));
    };

    return (
        <Row key={index} className={'m-0 p-0 gap-1'}>
            <Label className={'mb-0 d-flex align-items-center mb-1 p-0'}>
                <span className="fw-semibold">{field.label}</span>
                <i
                    className={'ri-information-line d-flex align-items-center blue-icon ps-1 fs-15'}
                    id={`bitLokerStartup-${index}-${field.value}`}
                />
                <UncontrolledTooltip placement="bottom" target={`bitLokerStartup-${index}-${field.value}`}>
                    {field.helpText}
                </UncontrolledTooltip>
            </Label>
            {field.fields.map((ele, eleind) => {
                return (
                    isFieldVisible(eleind) && (
                        <BitLockerRecoveryComponents
                            ele={ele}
                            eleind={eleind}
                            formType={formType}
                            validation={validation}
                            field={field}
                            handleDropdownChange={handleDropdownChange}
                            handleCheckboxChange={handleCheckboxChange}
                        />
                    )
                );
            })}
        </Row>
    );
};

const Restrictions = ({ index, field, formType, validation, handleKeyDown }) => {
    const [isScroll, setIsScroll] = useState({ 0: true });
    const currentScroll = (label, ind) => {
        let scrollName = document.getElementById(label);
        window.scrollTo({ top: scrollName.offsetTop + 140, behavior: 'smooth', block: 'start', inline: 'nearest' });
        const updatedChecked = { isScroll };
        updatedChecked[ind] = !updatedChecked[ind];
        setIsScroll(updatedChecked);
    };

    return (
        <Row key={index} className="m-0 p-0">
            <Col xs={12} md={12} sm={12} lg={12} xl={12} className="m-0 p-0 position-relative">
                {field.fields.map((subfield, ind) => {
                    return (
                        <Row key={ind} className="m-0 p-0 gap-1 pb-2">
                            <Col xs={6} md={6} sm={6} lg={5} xl={5} id={subfield.label} className="fw-semibold fs-14 px-0 pb-2">
                                {subfield.label}
                            </Col>
                            <RecursiveComponent
                                formType={formType}
                                validation={validation}
                                handleKeyDown={handleKeyDown}
                                fields={subfield.fields}
                                index={ind}
                            />
                        </Row>
                    );
                })}
                <div className="position-absolute">
                    <Card className="bg-light custom-section-card mt-10">
                        <CardHeader className="bg-light fw-semibold fs-14">Sections</CardHeader>
                        <CardBody className="pt-2">
                            {field.fields.map((subfield, ind) => {
                                return (
                                    <Col key={ind}>
                                        <div
                                            key={ind}
                                            className={`fw-medium py-1 cursor-pointer d-flex justify-content-start align-items-center ${
                                                isScroll[ind] ? 'text-primary' : ''
                                            }`}
                                            onClick={() => currentScroll(subfield.label, ind)}
                                        >
                                            <i className={`${isScroll[ind] ? 'ri-arrow-right-s-line fs-16' : 'ps-5'}`}></i>
                                            <span className="fw-medium"> {subfield.label}</span>
                                        </div>
                                    </Col>
                                );
                            })}
                        </CardBody>
                    </Card>
                </div>
            </Col>
        </Row>
    );
};

const RecursiveComponent = ({ fields, validation, formType, handleKeyDown }) => {
    return (
        <>
            {fields.map((ele, ind) => {
                return enableOnCondition(ele, validation) ? (
                    <React.Fragment key={ind}>
                        <DynamicComponents
                            field={ele}
                            index={ind}
                            formType={formType}
                            validation={validation}
                            handleKeyDown={handleKeyDown}
                        />
                        {ele.fields && (
                            <div className="px-4">
                                <RecursiveComponent
                                    formType={formType}
                                    validation={validation}
                                    handleKeyDown={handleKeyDown}
                                    fields={ele.fields}
                                ></RecursiveComponent>
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <NotEnabledComponent field={ele} validation={validation} />
                );
            })}
        </>
    );
};

const RadiosButton = ({ index, field, validation, formType }) => {
    if ('enableOn' in field && !enableOnCondition(field, validation) && 'notEnabledValue' in field) {
        validation.values[field.value] = field.notEnabledValue;
    }
    return (
        <Row key={index} className={`m-0 p-0 ${enableOnCondition(field, validation) ? '' : 'mask pe-none'}`}>
            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="p-0">
                <Label className="form-check-label fw-medium d-flex align-items-center">
                    {field.label}
                    {field?.mandatory && formType !== 'view' && <span className="red-color ps-1">*</span>}
                    <i
                        className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                        id={`radio-${index}-${field.tooltipId ? field.tooltipId : field.value}`}
                    />
                    <UncontrolledTooltip placement="bottom" target={`radio-${index}-${field.tooltipId ? field.tooltipId : field.value}`}>
                        {field.helpText ? field.helpText : 'Helper Text'}
                    </UncontrolledTooltip>
                </Label>
                {/* <div className="text-muted font-size-12 mb-2 word-wrap-break">{field.helpText && field.helpText}</div> */}
                {formType !== 'view' ? (
                    <div className={'px-0 padding-top-2'}>
                        {field?.options?.map((ele, ind) => {
                            return (
                                <div
                                    className={`form-check ${field?.row ? '' : 'form-check-inline mb-0'}`}
                                    key={ind}
                                    onClick={() =>
                                        validation.handleChange({
                                            target: { name: field.value, value: ele.value }
                                        })
                                    }
                                >
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id={field.label}
                                        name={field.value}
                                        disabled={formType === 'view'}
                                        value={ele.value}
                                        onBlur={validation.handleBlur}
                                        onChange={() =>
                                            validation.handleChange({
                                                target: { name: field.value, value: ele.value }
                                            })
                                        }
                                        checked={validation.values[field.value] === ele.value}
                                    />
                                    <Label className="mb-0">{ele.label}</Label>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <span>{field.options?.find((option) => option.value === validation.values[field.value])?.label || '–'}</span>
                )}
            </Col>
        </Row>
    );
};

const Checkbox = ({ index, field, validation, formType }) => {
    if ('enableOn' in field && !enableOnCondition(field, validation) && 'notEnabledValue' in field) {
        validation.values[field.value] = field.notEnabledValue;
    }
    return (
        <Row key={index} className={`m-0 p-0 ${enableOnCondition(field, validation) ? '' : 'mask pe-none'}`}>
            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="p-0 padding-bottom-2">
                <div className="form-check align-items-center mb-0">
                    <Input
                        className="form-check-input"
                        type="checkbox"
                        id={field.value}
                        name={field.value}
                        disabled={formType === 'view'}
                        onChange={() =>
                            validation.handleChange({
                                target: {
                                    name: field.value,
                                    value: field.options?.find((option) => option.value !== validation.values[field.value])?.value
                                }
                            })
                        }
                        checked={validation.values[field.value] === field?.checkedValue}
                    />
                    <Label className="mb-0 d-flex align-items-center cursor-auto">
                        <span
                            className={`${formType !== 'view' ? 'cursor-pointer' : ''} `}
                            onClick={
                                formType !== 'view'
                                    ? () =>
                                          validation.handleChange({
                                              target: {
                                                  name: field.value,
                                                  value: field.options?.find((option) => option?.value !== validation.values[field.value])
                                                      ?.value
                                              }
                                          })
                                    : null
                            }
                        >
                            {field.label}
                        </span>
                        <i
                            className="ri-information-line d-flex blue-icon ps-1 fs-15"
                            id={`inputComponent-${field.tooltipId ? field.tooltipId : field.value}`}
                        />
                        <UncontrolledTooltip
                            placement="bottom"
                            target={`inputComponent-${field.tooltipId ? field.tooltipId : field.value}`}
                        >
                            {field.helpText}
                        </UncontrolledTooltip>
                    </Label>
                </div>
            </Col>
        </Row>
    );
};

const Applications = ({ apps, formType, onAppCheck }) => {
    return (
        <React.Fragment>
            <Row>
                {apps.length > 0 &&
                    [...apps].map((data, index) => (
                        <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                            <Card className="shadow-card border">
                                <CardHeader className="position-relative p-0 border-bottom-0">
                                    <div className="mb-4">
                                        <div className="position-absolute top-0 end-0 px-2 py-1">
                                            <Input
                                                className="border border-primary fs-12 cursor-pointer"
                                                type="checkbox"
                                                id={data.name}
                                                name={data.name}
                                                disabled={formType === 'view' || data.name === AGENT_APP_NAME}
                                                onClick={() => onAppCheck(data.isChecked, index)}
                                                checked={data.isChecked}
                                            />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row className="d-flex justify-content-center align-items-center">
                                        <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2}>
                                            <img
                                                // className="border rounded"
                                                src={data.icon ? data.icon : WindowsImage}
                                                alt="img"
                                                height={40}
                                                width={40}
                                            />
                                        </Col>
                                        <Col xs={9} sm={9} md={9} lg={9} xl={10} xxl={10} className="px-3">
                                            <div className="fs-13 fw-semibold">
                                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                    {data.name}
                                                </EllipsisToolTip>
                                            </div>
                                            <div className="fs-11 text-muted">Version {data.version ? data.version : '1.0.0'}</div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    {data.assignedlicenses || data.totallicenses ? (
                                        <div className="mt-auto">
                                            <div className="d-flex mb-1">
                                                <div className="flex-grow-1">
                                                    <div className="fw-medium fs-11">Licenses</div>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <div className="d-flex align-items-center">
                                                        <i className="ri-list-check align-bottom me-1 text-muted fs-11"></i>
                                                        <span className="fw-medium fs-11">
                                                            {data.assignedlicenses}/{data.totallicenses}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="progress progress-sm animated-progess">
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{
                                                        width: (Number(data.assignedlicenses) / Number(data.totallicenses)) * 100 + '%'
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center">
                                            <i className="ri-information-line fs-14 pe-1" />
                                            <i className="text-grey fw-medium fs-11 word-ellipsis">No License is required.</i>
                                        </div>
                                    )}
                                </CardFooter>
                            </Card>
                        </Col>
                    ))}
            </Row>
        </React.Fragment>
    );
};

const ArrayComponent = ({ index, field, validation, formType, handleKeyDown }) => {
    const [showArray, setShowArray] = useState(true);
    const [arrayMode, setArrayMode] = useState('add');
    const [arrayIndex, setArrayIndex] = useState(-1);

    const getEmptyObj = () => {
        let obj = {};
        field?.fields?.forEach((subfield) => {
            obj[subfield?.value] = subfield?.defaultValue;
        });
        return obj;
    };

    const handleAddClick = () => {
        let obj = getEmptyObj();
        validation.setValues({ ...validation.values, ...obj });
        setShowArray(false);
        setArrayMode('add');
        setArrayIndex(-1);
    };

    const handleCancel = () => {
        let obj = getEmptyObj();
        validation.setValues({ ...validation.values, ...obj });
        setShowArray(true);
        setArrayMode('add');
        setArrayIndex(-1);
    };

    const checkDisable = () => {
        let flag = false;
        field?.fields?.forEach((subfield) => {
            if (enableOnCondition(subfield, validation)) {
                if (subfield.mandatory && validation.values[subfield.value] === '') {
                    flag = true;
                }
            }
        });
        return flag;
    };

    const handleEdit = (value, ind) => {
        validation.setValues({ ...validation.values, ...value });
        setArrayMode('edit');
        setArrayIndex(ind);
        setShowArray(false);
    };

    const handleView = (value) => {
        validation.setValues({ ...validation.values, ...value });
        setArrayMode('view');
        setShowArray(false);
    };

    const handleDelete = (ind) => {
        let values = JSON.parse(JSON.stringify(validation.values[field.value]));
        values.splice(ind, 1);
        validation.setValues({ ...validation.values, [field.value]: values });
        setArrayMode('add');
        setArrayIndex(-1);
    };

    const handleSave = () => {
        let obj = {};
        field?.fields?.forEach((subfield) => {
            obj[subfield?.value] = validation.values[subfield?.value];
        });
        let values = { ...validation.values };
        if (values[field.value] && values[field.value]?.length > 0) {
            if (arrayMode === 'edit') values[field.value]?.splice(arrayIndex, 1, obj);
            else values[field.value]?.push(obj);
        } else values[field.value] = [obj];
        let emptyObj = getEmptyObj();
        validation.setValues({ ...values, ...emptyObj });
        setShowArray(true);
        setArrayMode('add');
        setArrayIndex(-1);
    };

    const renderCardValue = (card, value) => {
        return card.inputType === 'dropdown' ? card.options?.find((obj) => obj.value === value[card.value])?.label : value[card.value];
    };

    return showArray ? (
        <React.Fragment key={index}>
            {formType !== 'view' && (
                <div className="d-flex align-items-center justify-content-end pe-3">
                    <Button color="primary" sime="md" onClick={handleAddClick}>
                        + Add New {field?.label}
                    </Button>
                </div>
            )}
            <Row className="bvinwen">
                {validation.values?.[field.value]?.length > 0 ? (
                    validation.values?.[field.value]?.map((value, indexs) => (
                        <Col sm="auto" className="p-2" key={indexs}>
                            <Card className="shadow-card">
                                <CardBody
                                    className={`d-flex align-items-center justify-content-center position-relative ${
                                        formType === 'view' ? 'cursor-pointer' : ''
                                    }`}
                                    onClick={formType === 'view' ? () => handleView(value) : null}
                                >
                                    <div className="position-absolute top-5 end-5">
                                        <div className="d-flex align-items-center gap-2">
                                            {formType !== 'view' && (
                                                <>
                                                    <i
                                                        className="ri-edit-line link-info cursor-pointer"
                                                        onClick={() => handleEdit(value, indexs)}
                                                    />
                                                    <i
                                                        className="ri-delete-bin-line link-danger cursor-pointer"
                                                        onClick={() => handleDelete(indexs)}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <table>
                                            {field?.fields
                                                ?.filter((obj) => obj?.showInCard)
                                                ?.map((card, ind) => (
                                                    <tr key={ind}>
                                                        <td className="fw-semibold px-3 py-2">{card.label}</td>
                                                        <td className="fw-semibold px-2 py-2">:</td>
                                                        <td className="px-3 py-2 ">
                                                            <div className="width-150">
                                                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                    {renderCardValue(card, value)}
                                                                </EllipsisToolTip>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))
                ) : (
                    <NoRecordsFound />
                )}
            </Row>
        </React.Fragment>
    ) : (
        <React.Fragment key={index}>
            {field?.fields?.map((subfield, ind) => {
                return enableOnCondition(subfield, validation) ? (
                    <DynamicComponents
                        field={subfield}
                        index={ind}
                        formType={formType}
                        validation={validation}
                        handleKeyDown={handleKeyDown}
                    />
                ) : (
                    <NotEnabledComponent field={subfield} validation={validation} />
                );
            })}
            <div className="d-flex align-items-center justify-content-center gap-2 pt-3">
                <Button size="md" className="btn btn-light" onClick={handleCancel}>
                    Cancel
                </Button>
                {formType !== 'view' && (
                    <Button size="md" color="primary" onClick={handleSave} disabled={checkDisable()}>
                        Save
                    </Button>
                )}
            </div>
        </React.Fragment>
    );
};

const UploadComponent = ({ index, field, formType, validation, setLoading }) => {
    const api = new APIClient();
    const urlconf = useEnv();
    const handleFileUpload = (files) => {
        if (field.accept.some((ext) => files.filename?.endsWith(ext))) {
            setLoading(true);
            api.get(url.WINDOWS_FILES, { filename: files?.file.name }, domains.WINDOWS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        fetch(resp?.data?.url, {
                            method: 'PUT',
                            body: files?.file
                        }).then((response) => {
                            if (response?.status === 200) {
                                api.get(
                                    url.WINDOWS_FILES_DOWNLOAD,
                                    { serverFileName: resp?.data?.serverfilename },
                                    domains.WINDOWS_V1
                                ).then((finalResp) => {
                                    finalResp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(finalResp) : finalResp;
                                    if (finalResp.status === 'success') {
                                        validation.setFieldValue(field.value, finalResp.data);
                                    }
                                });
                            }
                        });
                    }
                })
                .finally(() => setLoading(false));
        } else {
            toast.error(toastMessages.invalidFileFormat);
        }
    };

    return (
        <Row key={index} className={`m-0 p-0 ${enableOnCondition(field, validation) ? '' : 'mask pe-none'}`}>
            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="m-0 p-0 mb-2">
                <Label className="mb-0 fw-medium d-flex align-items-center mb-1">
                    {field.label}
                    {field?.mandatory && formType !== 'view' && <span className="red-color ps-1">*</span>}
                </Label>
                <div className="text-muted font-size-12 mb-2 word-wrap-break">{field.helpText}</div>
                {formType !== 'view' ? (
                    !validation.values[field.value] ? (
                        <FilePond
                            name={field.value}
                            acceptedFileTypes={field.acceptedFileTypes}
                            minFileSize={field.minFileSize || '5KB'}
                            maxFileSize={field.maxFileSize}
                            maxFiles={field.maxFiles}
                            allowMultiple={true}
                            allowFileSizeValidation={true}
                            onaddfile={(_, fileItems) => handleFileUpload(fileItems)}
                            className="filepond filepond-input-multiple"
                        />
                    ) : (
                        <div className="d-flex align-items-center gap-2">
                            <img
                                src={validation.values[field.value]}
                                alt={field.value}
                                height={80}
                                className="p-2 rounded border border-2"
                            />
                            <i
                                className="ri-delete-bin-5-line link-danger cursor-pointer fs-18"
                                onClick={() => validation.setFieldValue(field.value, '')}
                            />
                        </div>
                    )
                ) : (
                    <img src={validation.values[field.value]} alt={field.value} height={80} className="p-2 rounded border" />
                )}
            </Col>
        </Row>
    );
};

const FireWallComponent = ({ index, field, validation, formType }) => {
    const [blockedUrl, setBlockedUrl] = useState('');

    const handleAddDomain = () => {
        let values = { ...validation.values };
        if (values[field.value] && values[field.value]?.length > 0) values[field.value].push(blockedUrl);
        else values[field.value] = [blockedUrl];
        validation.setValues({ ...values });
        setBlockedUrl('');
    };

    const handleRemoveDomain = (ele) => {
        let arr = JSON.parse(JSON.stringify(validation.values[field.value]));
        arr = arr.filter((val) => val !== ele);
        validation.setValues({ ...validation.values, [field.value]: arr });
    };

    const handleClearAll = () => {
        validation.setValues({ ...validation.values, [field.value]: [] });
    };

    return (
        <Row key={index} className={`m-0 p-0 ${enableOnCondition(field, validation) ? '' : 'mask pe-none'}`}>
            <Col xs={12} md={12} sm={12} lg={6} xl={6}>
                <Card className="card-height-100 mb-0">
                    <CardBody className="badge-soft-danger">
                        <Row>
                            <Col>
                                <div className="fs-14 fw-semibold">Blocked Domains</div>
                            </Col>
                        </Row>
                        <Row>
                            <div className="d-flex align-items-center fs-13 py-2">
                                <i className="ri-error-warning-line"></i>
                                <div className="px-1">The below added domains will be inaccessible</div>
                            </div>
                        </Row>
                        <Row className="align-items-start">
                            {formType !== 'view' && (
                                <>
                                    <Col>
                                        <Input
                                            className="form-control"
                                            placeholder="Enter Domain"
                                            onChange={(e) => setBlockedUrl(e.target.value)}
                                            value={blockedUrl}
                                        />
                                    </Col>
                                    <Col sm="auto">
                                        <Button
                                            size="sm"
                                            type="button"
                                            disabled={!blockedUrl}
                                            color={'primary'}
                                            onClick={() => handleAddDomain()}
                                        >
                                            <span className="d-flex align-items-center">
                                                <i className="ri-add-line fs-16"></i> Add New
                                            </span>
                                        </Button>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </CardBody>
                    <Card className="p-0">
                        <CardHeader className="p-3 border-bottom-dashed border-2">
                            <Row className="align-items-center">
                                <Col className="fs-14 fw-semibold">All Domains ({validation.values?.[field.value]?.length})</Col>
                                {formType !== 'view' && validation.values?.[field.value]?.length > 0 && (
                                    <Col sm="auto">
                                        <div className="w-100">
                                            <Button
                                                size="md"
                                                className="bg-light border-light text-primary"
                                                onClick={() => handleClearAll()}
                                            >
                                                Clear All Domains
                                            </Button>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <div className="filter-choices-input height-205 overflow-auto">
                                    <div className="choices" data-type="text">
                                        <div className="choices__inner min-height-1">
                                            <div className="choices__list choices__list--multiple">
                                                {validation.values?.[field.value]?.length > 0 ? (
                                                    validation.values?.[field.value]?.map((ele, indexItem) => {
                                                        return (
                                                            <div
                                                                key={indexItem}
                                                                className="choices__item choices__item--selectable bg-danger border-white"
                                                            >
                                                                <div id={`domain-${indexItem}-blocked`}>
                                                                    {ele}
                                                                    {formType !== 'view' && (
                                                                        <button
                                                                            type="button"
                                                                            className="choices__button"
                                                                            onClick={() => handleRemoveDomain(ele)}
                                                                        >
                                                                            Remove item
                                                                        </button>
                                                                    )}
                                                                </div>
                                                                {Tooltip(`domain-${indexItem}-blocked`, ele)}
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="d-flex height-180 align-items-center justify-content-center">
                                                        <div className="d-flex align-items-center justify-content-center flex-column">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <img alt="" height={40} className="opacity-50" src={BlockedDomains} />
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-center fs-13 fw-normal mt-2">
                                                                No Domains Found
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Card>
            </Col>
        </Row>
    );
};

const DelimiterArrayComponent = ({ index, field, validation, formType }) => {
    const [blockedUrl, setBlockedUrl] = useState('');

    const handleAddDomain = () => {
        let values = { ...validation.values };
        if (values[field.value]?.length > 0) values[field.value] = values[field.value] + field.delimiter + blockedUrl;
        else values[field.value] = blockedUrl;
        validation.setValues({ ...values });
        setBlockedUrl('');
    };

    const handleRemoveDomain = (ele) => {
        let existingString = JSON.parse(JSON.stringify(validation.values[field.value]));
        let arr = existingString.length > 0 ? existingString.split(field.delimiter) : [];
        arr = arr.filter((val) => val !== ele);
        existingString = arr.join(field.delimiter);
        validation.setValues({ ...validation.values, [field.value]: existingString });
    };

    return (
        <Row key={index} className={`m-0 p-0 ${enableOnCondition(field, validation) ? '' : 'mask pe-none'}`}>
            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="m-0 p-0">
                <Label className={'mb-0 fw-medium d-flex align-items-center mb-1'}>
                    {field.label}
                    <i
                        className={'ri-information-line d-flex align-items-center blue-icon ps-1 fs-15'}
                        id={`delimiterInputComponent-${field.value}`}
                    />
                    <UncontrolledTooltip placement="bottom" target={`delimiterInputComponent-${field.value}`}>
                        {field.helpText && field.helpText}
                    </UncontrolledTooltip>
                </Label>
                <Row className="align-items-start">
                    {formType !== 'view' && (
                        <>
                            <Col>
                                <Input
                                    className="form-control"
                                    placeholder={`Enter ${field.placeholder}`}
                                    onChange={(e) => setBlockedUrl(e.target.value)}
                                    value={blockedUrl}
                                />
                            </Col>
                            <Col sm="auto">
                                <Button size="sm" type="button" disabled={!blockedUrl} color={'primary'} onClick={() => handleAddDomain()}>
                                    <span className="d-flex align-items-center">
                                        <i className="ri-add-line fs-16"></i> Add New
                                    </span>
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
                <CardBody className={`p-0 ${formType !== 'view' ? 'pt-2' : ''}`}>
                    <Row>
                        <div className="filter-choices-input">
                            <div className="choices" data-type="text">
                                <div className="choices__inner min-height-1">
                                    <div className="choices__list choices__list--multiple">
                                        {validation.values?.[field.value]?.length > 0 ? (
                                            validation.values?.[field.value].split(field.delimiter)?.map((ele, indexItem) => {
                                                return (
                                                    <div key={indexItem} className="choices__item choices__item--selectable border-white">
                                                        <div id={`domain-${indexItem}-blocked`}>
                                                            {ele}
                                                            {formType !== 'view' && (
                                                                <button
                                                                    type="button"
                                                                    className="choices__button"
                                                                    onClick={() => handleRemoveDomain(ele)}
                                                                >
                                                                    Remove item
                                                                </button>
                                                            )}
                                                        </div>
                                                        {Tooltip(`domain-${indexItem}-blocked`, ele)}
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="d-flex align-items-center justify-content-center flex-column">
                                                    <div className="d-flex align-items-center justify-content-center fs-13 fw-normal">
                                                        No {field.label}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </CardBody>
            </Col>
        </Row>
    );
};

// Data Sample
// <data id="sample" value="1.youtbue.com2.whatsapp.com">
const TempDelimiterArrayComponent = ({ index, field, validation, formType }) => {
    const [blockedUrl, setBlockedUrl] = useState('');

    const handleAddDomain = () => {
        let values = { ...validation.values };
        if (getDisplayValue(values[field.value])?.length > 0) {
            values[field.value] = prepareSendValue(
                field.myId,
                getDisplayValue(values[field.value]) +
                    `${field.delimiter}${Math.floor(getDisplayValue(values[field.value]).split(field.delimiter).length / 2) + 1}${
                        field.delimiter
                    }${blockedUrl}`,
                field?.dataId
            );
        } else values[field.value] = prepareSendValue(field.myId, `1${field.delimiter}${blockedUrl}`, field?.dataId);
        validation.setValues({ ...values });
        setBlockedUrl('');
    };

    const handleRemoveDomain = (ele) => {
        let existingString = JSON.parse(JSON.stringify(getDisplayValue(validation.values[field.value])));
        let parts = existingString.split(field.delimiter);
        let filteredParts = [];
        for (let i = 0; i < parts.length; i += 2) {
            if (parts[i + 1] !== ele) {
                filteredParts.push(parts[i + 1]);
            }
        }
        let updatedString = filteredParts.map((ourl, ind) => `${ind + 1}${field.delimiter}${ourl}`).join(field.delimiter);
        validation.setValues({ ...validation.values, [field.value]: prepareSendValue(field.myId, updatedString, field?.dataId) });
    };

    return (
        <Row key={index} className={`m-0 p-0 ${enableOnCondition(field, validation) ? '' : 'mask pe-none'}`}>
            <Col xs={12} md={6} sm={6} lg={5} xl={5} className="m-0 p-0">
                <Label className={'mb-0 fw-medium d-flex align-items-center mb-1'}>
                    {field.label}
                    <i
                        className={'ri-information-line d-flex align-items-center blue-icon ps-1 fs-15'}
                        id={`tempDelimiterInputComponent-${field.value}`}
                    />
                    <UncontrolledTooltip placement="bottom" target={`tempDelimiterInputComponent-${field.value}`}>
                        {field.helpText && field.helpText}
                    </UncontrolledTooltip>
                </Label>
                <Row className="align-items-start">
                    {formType !== 'view' && (
                        <>
                            <Col>
                                <Input
                                    className="form-control"
                                    placeholder={`Enter ${field.placeholder}`}
                                    onChange={(e) => setBlockedUrl(e.target.value)}
                                    value={blockedUrl}
                                />
                            </Col>
                            <Col sm="auto">
                                <Button size="sm" type="button" disabled={!blockedUrl} color={'primary'} onClick={() => handleAddDomain()}>
                                    <span className="d-flex align-items-center">
                                        <i className="ri-add-line fs-16"></i> Add New
                                    </span>
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
                <CardBody className={`p-0 ${formType !== 'view' ? 'pt-2' : ''}`}>
                    <Row>
                        <div className="filter-choices-input">
                            <div className="choices" data-type="text">
                                <div className="choices__inner min-height-1">
                                    <div className="choices__list choices__list--multiple">
                                        {getDisplayValue(validation.values?.[field.value])?.length > 0 ? (
                                            getDisplayValue(validation.values?.[field.value])
                                                .split(field.delimiter)
                                                ?.filter((_, ind) => ind % 2 !== 0)
                                                ?.map((ele, indexItem) => {
                                                    return (
                                                        <div
                                                            key={indexItem}
                                                            className="choices__item choices__item--selectable border-white"
                                                        >
                                                            <div id={`${field.value}-${indexItem}-blocked`}>
                                                                {ele}
                                                                {formType !== 'view' && (
                                                                    <button
                                                                        type="button"
                                                                        className="choices__button"
                                                                        onClick={() => handleRemoveDomain(ele)}
                                                                    >
                                                                        Remove item
                                                                    </button>
                                                                )}
                                                            </div>
                                                            {Tooltip(`${field.value}-${indexItem}-blocked`, ele)}
                                                        </div>
                                                    );
                                                })
                                        ) : (
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="d-flex align-items-center justify-content-center flex-column">
                                                    <div className="d-flex align-items-center justify-content-center fs-13 fw-normal">
                                                        No {field.label}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </CardBody>
            </Col>
        </Row>
    );
};

const componentMap = {
    policyInput: PolicyInputComponent,
    tempInput: TempInputComponent,
    dropdown: SelectComponent,
    bitLockerDropdowns: BitLockerDropdowns,
    bitLockerStartup: BitLockerStartUp,
    bitLockerRecovery: BitLockerRecovery,
    radios: RadiosButton,
    restrictions: Restrictions,
    applications: Applications,
    checkbox: Checkbox,
    array: ArrayComponent,
    upload: UploadComponent,
    firewall: FireWallComponent,
    delimiterArray: DelimiterArrayComponent,
    tempDelimiterArray: TempDelimiterArrayComponent
};

export const DynamicComponents = (props) => {
    const Component =
        componentMap[props.field.inputType] ||
        (() => <div className="pt-1 fw-semibold fs-13 px-0 padding-bottom-5">{props.field.label}</div>);

    return (
        <React.Fragment key={props.index}>
            <Component {...props} />
        </React.Fragment>
    );
};

export const enableOnCondition = (field, validation) => {
    if (!field.enableOn) return true;
    if (field.subStringCheck)
        return (
            (validation.values[field.enableOn] && field.enableOnVal?.some((val) => validation.values[field.enableOn].includes(val))) ||
            false
        );
    return field.enableOnVal?.includes(validation.values[field.enableOn]) || false;
};

export const customConfig = (field) => {
    return field.isCustomConfig || false;
};

export const NotEnabledComponent = ({ field, validation }) => {
    if ('enableOn' in field && !enableOnCondition(field, validation)) {
        validation.values[field.value] = field.defaultValue;
    }
    return <></>;
};

export const AGENT_APP_NAME = 'Tectoro MDM';
