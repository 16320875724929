import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Input, Label } from 'reactstrap';

import { FilePond } from 'react-filepond';
import { applePushData, bussinessData, priviliges } from '../../../Components/constants/constants';
import AppleBussinessImage from '../../../assets/images/apple/png/common/apple_bussiness.png';
import { APIClient } from '../../../helpers/api_helper';

import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import ABMCard from './ABMCard';
import { IsAuthorized } from '../../../Components/Common/Util';

const AppleBusinessManager = ({ setLoading, handleDelete, getABMData, abm }) => {
    const api = new APIClient();
    const urlconf = useEnv();
    const [serverName, setServerName] = useState('');
    const [apkFilesBussiness, setAPKFilesBussiness] = useState([]);
    const [showSteps, setShowSteps] = useState(false);
    const [showInitialScreen, setShowInitialScreen] = useState(true);
    const [abmData, setAbmData] = useState([...abm]);
    const [recordId, setRecordId] = useState('');

    useEffect(() => {
        setAbmData([...abm]);
    }, [abm]);

    const onEnterName = (e) => {
        setServerName(e);
    };

    const handleAPKFileUploadBussiness = (fileItems) => {
        setAPKFilesBussiness(fileItems);
    };
    const onAddServer = () => {
        setShowSteps(true);
        setShowInitialScreen(false);
    };

    const handleUploadFile = (resp) => {
        if (resp.status?.toLowerCase() === 'success') {
            const formData = new FormData();
            formData.append('file', apkFilesBussiness?.[0]?.file);
            api.patch(url.MDMSERVER + `/${resp?.id}/token`, formData, false, domains.IOS_V1)
                .then((newRep) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (newRep.status === 'success') {
                        setShowSteps(false);
                        getABMData();
                        setShowInitialScreen(true);
                        toast.success(toastMessages.ABMSuccess);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };
    const createServerAndUpload = () => {
        setLoading(true);
        let serverDetails = { name: serverName, status: 'A', config: {} };
        api.create(url.MDMSERVER, serverDetails, false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                handleUploadFile(resp);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const updateServer = () => {
        setLoading(true);
        let serverDetails = { name: serverName };
        api.patch(url.MDMSERVER + '/' + recordId, serverDetails, false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                setRecordId('');
                setShowSteps(false);
                setShowInitialScreen(true);
                handleUploadFile(resp);
            })
            .catch((err) => setLoading(false));
    };

    const onDownloadClick = (ele) => {
        if (ele === 'Download File.csv') {
            setLoading(true);
            api.get(url.ABM_CERT, {}, domains.IOS_V1)
                .then((resp) => {
                    let csv = resp;
                    let a = document.createElement('a');
                    a.href = 'data:text/pem;charset=utf-8,' + encodeURI(csv);
                    a.target = '_blank';
                    a.download = 'ABMCertificate.pem';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        }
        if (ele === 'Sign In to Apple Business Manager') {
            let a = document.createElement('a');
            a.href = 'https://business.apple.com/#/main/preferences/mdmserver-new';
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        if (ele === 'Sign In to Apple School Manager') {
            let a = document.createElement('a');
            a.href = 'https://school.apple.com/';
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    const handleAction = (type, id) => {
        if (type === 'change') {
            setShowSteps(true);
            setRecordId(id);
        } else if (type === 'delete') {
            handleDelete({ enabled: true, id: id });
        } else if (type === 'sync') {
            setLoading(true);
            api.patch(url.MDMSERVER + '/' + id + '/sync', '', false, domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    getABMData();
                    setLoading(false);
                    toast.success(toastMessages.ABMSync);
                })
                .catch((err) => setLoading(false));
        }
    };

    return (
        <Card className="card-height-100">
            <CardHeader className="card-height-100 max-height-150">
                <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                    <div className="fs-14">Apple Business/School Manager</div>
                    <div
                        className={`d-flex justify-content-center align-items-center fs-12 ${
                            abmData.length === 0 ? 'text-danger' : 'text-success'
                        }`}
                    >
                        {abmData.length === 0 ? (
                            <i className="ri-error-warning-fill fs-16 px-1 text-danger"></i>
                        ) : (
                            <i className="ri-checkbox-circle-fill fs-16 px-1 text-success"></i>
                        )}
                        {abmData.length === 0 ? 'Not Configured' : 'Configured'}
                    </div>
                </div>
                <div className="padding-top-10 font-size-12 fw-medium text-muted">
                    The Apple Business/School manager, formerly known as ADE/DEP, empowers organizations to preconfigure the initial setup
                    process for newly purchased or freshly reset devices. By automating device enrollment and setup processes, it simplifies
                    device deployment and seamlessly integrates with our MDM solutions.
                </div>
            </CardHeader>
            {abmData.length === 0 && showInitialScreen ? (
                <div>
                    <CardBody>
                        <div className="text-center  py-20">
                            <div>
                                <img height={65} src={AppleBussinessImage} alt="img"></img>
                            </div>
                            <div className="fw-medium fs-14 py-2">Apple Business/School Manager</div>
                            <div className="font-size-12 text-muted text-start fw-medium px-2">
                                Please click "Configure" and follow the steps provided to set up. For best practice, it's recommended to use
                                a generic corporate email address rather than a personal one, since the certificate needs to be renewed
                                annually.
                            </div>

                            <IsAuthorized
                                privReq={priviliges.ENTERPRISE_EDITOR}
                                yes={() => (
                                    <div className="py-6">
                                        <Button className="bg-primary px-15 py-3 border-none fw-medium fs-12" onClick={() => onAddServer()}>
                                            Configure
                                        </Button>
                                    </div>
                                )}
                            />
                        </div>
                    </CardBody>
                </div>
            ) : !showSteps ? (
                <div>
                    <CardBody>
                        <div className="text-center  py-2">
                            <div>
                                <img height={65} src={AppleBussinessImage} alt="img"></img>
                            </div>
                            <div className="fw-medium fs-14 py-2">Apple Business/School Manager</div>
                        </div>
                        <ABMCard data={abmData} handleAction={handleAction} />
                        {/* <Card className="border border-2 border-dashed pt-4 py-2 " onClick={() => setShowSteps(true)}>
                            <div className="text-center">
                                <img height={50} src={AddIcon} alt="img"></img>
                                <div className="font-size-12 text-muted fw-medium py-2">
                                    Click and add more than one MDM server in Apple Business/School Manager account
                                </div>
                            </div>
                        </Card> */}
                    </CardBody>
                </div>
            ) : (
                <CardBody>
                    <div>
                        {bussinessData.map((ele, ind) => {
                            return (
                                <div
                                    className={` py-4 ${
                                        ind !== applePushData.length - 1 ? 'border-1 border-bottom-dashed border-grey' : ''
                                    }`}
                                    key={ind}
                                >
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img height={32} src={ele.image} alt="img"></img>
                                        </div>
                                        <div className="px-2">
                                            <div className="text-primary fw-medium">{ele.step}</div>
                                            <div className="fw-semibold fs-13">{ele.title}</div>
                                        </div>
                                    </div>
                                    <div className="py-2 fw-medium fs-12 text-muted">{ele.text}</div>
                                    <div className="d-flex justify-content-between align-items-center text-secondary cursor-pointer">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div>
                                                {ind !== applePushData.length - 1 ? (
                                                    <img height={ind === 0 ? 16 : 14} src={ele.icon} alt="img"></img>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div
                                                className="text-decoration-underline px-1 fs-13 fw-semibold"
                                                onClick={() => onDownloadClick(ele.linkText)}
                                            >
                                                {ele.linkText}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div>{ind === 1 ? <img height={ind === 0 ? 16 : 14} src={ele.icon2} alt="img"></img> : ''}</div>
                                            <div
                                                className="text-decoration-underline px-1 fs-13 fw-semibold "
                                                onClick={() => onDownloadClick(ele.linkText2)}
                                            >
                                                {ele?.linkText2}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <Label htmlFor="name">
                            Name
                            <span className="red-color ps-1"> *</span>
                        </Label>
                        <div className="text-muted fs-12">Enter the name of your Organization.</div>
                        <Input
                            className="my-2"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Enter Name"
                            value={serverName}
                            onChange={(e) => onEnterName(e.target.value)}
                        ></Input>
                        <div className={`${serverName === '' ? 'mask pe-none' : ''}`}>
                            {/* <div className="pt-2 fw-semibold fs-13">Upload Apple Signed Certificate</div>
                            <div className="py-2 fw-medium fs-12 text-muted">
                                The Apple Business Manager signed certificate must be uploaded below to complete the DEP process. This
                                certificate expires in a year, and must be renewed. The reminder will be sent to the Apple ID email that is
                                used for this process.
                            </div> */}
                            <div className="filepondmarginremoval w-95">
                                <FilePond
                                    name="files"
                                    minFileSize="1KB"
                                    maxFileSize="300MB"
                                    maxFiles={1}
                                    allowMultiple={true}
                                    files={apkFilesBussiness}
                                    className="filepond filepond-input-multiple"
                                    onupdatefiles={(fileItems) => handleAPKFileUploadBussiness(fileItems)}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center margin-top-10 px-3">
                            <Button
                                className="bg-light border-primary text-primary mx-3 px-5 fs-12 fw-semibold"
                                onClick={() => {
                                    setShowInitialScreen(true);
                                    setShowSteps(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="bg-primary border-none px-8 fs-12 fw-semibold"
                                disabled={apkFilesBussiness.length === 0 || serverName === ''}
                                onClick={() => (recordId ? updateServer() : createServerAndUpload())}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </CardBody>
            )}
        </Card>
    );
};

export default AppleBusinessManager;
