const SIM_SETTINGS_SCHEMA = [
    {
        label: 'Allow SIM',
        value: 'allowSim',
        helpText: 'If you enable the Allow SIM, you can configure the Mobile SIM slots & features.',
        inputType: 'toggle'
    },
    {
        label: 'Network Binding :',
        helpText:
            'Network binding is a feature that binds a device to a specific network by restricting access to authorized connections and improving protection against unauthorized access. The device will be blocked if the user violates the network binding settings.',
        inputType: 'toggle',
        value: 'enableNetworkBinding',
        isHeading: true,
        showOn: ['allowSim'],
        customBorder: true,
        fields: [
            {
                label: 'Primary Slot',
                value: 'slot1',
                inputType: 'select',
                helpText: 'The selected network service will be configured with the primary SIM slot.',
                options: [
                    { label: 'Any', value: 'any' },
                    { label: 'None', value: 'none' },
                    { label: 'Airtel', value: 'airtel' },
                    { label: 'BSNL', value: 'bsnl' },
                    { label: 'Jio', value: 'jio' },
                    { label: 'VI', value: 'Vodafone IN' }
                ]
            },
            {
                label: 'Secondary Slot',
                value: 'slot2',
                inputType: 'select',
                helpText: 'The selected network service will be configured with the secondary SIM slot.',
                options: [
                    { label: 'Any', value: 'any' },
                    { label: 'None', value: 'none' },
                    { label: 'Airtel', value: 'airtel' },
                    { label: 'BSNL', value: 'bsnl' },
                    { label: 'Jio', value: 'jio' },
                    { label: 'VI', value: 'Vodafone IN' }
                ]
            }
        ]
    },
    {
        label: 'SIM Binding :',
        helpText:
            'SIM binding is similar to pairing a SIM card with a particular device. To ensure that only that particular SIM card associated with the device will work.',
        inputType: 'toggle',
        value: 'enableSimBinding',
        isHeading: true,
        showOn: ['allowSim']
    },
    {
        label: 'Bind First detected SIM',
        value: 'simBinding',
        val: 'first_detected',
        inputType: 'radio',
        customCol: true,
        helpText:
            'Selecting this option will automatically pair the first SIM card detected by the device, establishing a seamless connection.',
        showOn: ['allowSim', 'enableSimBinding']
    },
    {
        label: 'Binding SIM with slot 1',
        value: 'simBinding',
        val: 'slot1',
        inputType: 'radio',
        customCol: true,
        helpText:
            'Binding the SIM card with slot 1 means establishing a connection between the SIM card placed in the first slot of the device and the device itself. This ensures that the services associated with the SIM card are accessible only through slot 1.',
        showOn: ['allowSim', 'enableSimBinding']
    },
    {
        label: 'Binding SIM with slot 2',
        value: 'simBinding',
        val: 'slot2',
        inputType: 'radio',
        customCol: true,
        helpText:
            'Binding the SIM card with slot 2 means establishing a connection between the SIM card placed in the second slot of the device and the device itself. This ensures that the services associated with the SIM card are accessible only through slot 2.',
        showOn: ['allowSim', 'enableSimBinding']
    },
    {
        label: 'Binding both SIM1 and SIM2',
        value: 'simBinding',
        val: 'both',
        inputType: 'radio',
        customCol: true,
        helpText:
            'Binding the SIM card with slot 1 & 2 means establishing a connection between the SIM 1 with slot 1 and SIM 2 with slot 2 placed in both the slots of the device.',
        showOn: ['allowSim', 'enableSimBinding']
    },
    {
        label: 'Actions to be Performed on SIM Binding :',
        inputType: 'heading',
        alert: true,
        noteCondition: 'simBinding',
        noteVal: ['slot1', 'slot2'],
        showOn: ['allowSim', 'enableSimBinding']
    },
    {
        label: 'Device Actions',
        value: 'deviceAction',
        inputType: 'radios',
        helpText: 'You can choose a default action on the device when the user violates the SIM binding settings.',
        showOn: ['allowSim', 'enableSimBinding'],
        radios: [
            {
                label: 'No Action Required',
                value: 'deviceAction',
                val: 'noAction'
            },
            {
                label: 'Block Device',
                value: 'deviceAction',
                val: 'actionReq'
            }
        ]
    },
    /*
     * {
     *     inputType: 'select',
     *     customCol: true,
     *     customBorder: true,
     *     isMulti: true,
     *     options: [
     *         { label: 'Block device on SIM removal', value: 'block_device_on_SIM_removal' },
     *         { label: 'Block device on inserting incorrect SIM', value: 'block_device_on_incorrect_SIM' },
     *         { label: 'Block device on SIM inserted in wrong slot', value: 'block_device_on_SIM_inserted_in_wrong_slot' }
     *     ],
     *     value: 'action',
     *     showKey: 'deviceAction',
     *     showVal: 'actionReq',
     *     showOn: ['allowSim', 'deviceAction']
     * },
     */
    {
        label: 'SIM PIN configuration',
        heading: true,
        helpText:
            'Enable SIM PIN is a security feature that locks the SIM card with a Personal Identification Number. This ensures that only users who know the PIN can access the network services, adding an extra layer of security to mobile devices.',
        value: 'simPinEnabled',
        inputType: 'toggle',
        showOn: ['allowSim']
    },
    {
        label: 'SIM PIN',
        inputType: 'radios',
        value: 'simPin',
        showOn: ['allowSim', 'simPinEnabled'],
        helpText:
            'We can choose either SIM 1 or SIM 2, on which a PIN is required. This PIN must be entered each time the device is restarted, or SIM card is inserted into a device.',
        radios: [
            { label: 'SIM1', value: 'simPin', val: 'sim1' },
            { label: 'SIM2', value: 'simPin', val: 'sim2' }
        ]
    },
    {
        label: 'PIN Generation',
        inputType: 'radios',
        value: 'pinGen',
        showOn: ['allowSim', 'simPinEnabled'],
        helpText:
            'On selection of Random PIN generation, a random PIN will be generated by the system. On selection of Custom PIN, Admin will have a provision to set a customized PIN. This PIN will be applicable to all the devices to which the configuration is associated.',
        radios: [
            { label: 'Random PIN Generator', value: 'pinGen', val: 'random' },
            { label: 'Custom PIN', value: 'pinGen', val: 'custom' }
        ]
    },
    {
        inputType: 'input',
        smallLabel: true,
        maxLength: 4,
        showKey: 'pinGen',
        type: 'number',
        showVal: 'custom',
        customText:
            'Custom PINs can be a maximum of 4 digits and can consist of any combination except for 0000 and IIII, which are default PINs for most mobile devices.',
        placeholder: 'Custom PIN',
        value: 'pin',
        showOn: ['allowSim', 'simPinEnabled', 'pinGen']
    }
];

export default SIM_SETTINGS_SCHEMA;
