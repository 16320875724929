import axios from 'axios';
import { toast } from 'react-toastify';
import { decrypt, encrypt } from '../Components/Common/Util';
import { tenantConfig as tenant } from '../Components/constants/TenantConfig';
import { authUser, enterpriseAccount } from '../store/Commons/action';
import { tenantConfig } from '../store/actions';
import { Store } from '../store/store';

let urlconf = {};
let baseURL = '';
let originVal = '';

// Function to initialize axios settings
const initializeAxios = () => {
    // Update baseURL and originVal
    baseURL = window.location.origin?.includes('localhost')
        ? urlconf?.REACT_APP_API_URL || 'https://portal.mdmdev.tectoro.com/api'
        : window.location.origin + '/api';
    originVal = window.location.origin?.includes('localhost')
        ? (urlconf?.REACT_APP_API_URL || 'https://portal.mdmdev.tectoro.com/api')?.slice(0, -4)
        : window.location.origin;
};

// Configure axios defaults
axios.defaults.baseURL = baseURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

// axios interceptor intercepts the response and decrypts the data if encryption is enabled also handles the error status codes.
axios.interceptors.response.use(
    function (response) {
        let resp = response?.data ? response?.data : response;
        return urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? (response?.headers?.['x-encrypted'] === 'Y' ? decrypt(resp) : resp) : resp;
    },
    function (error) {
        /*
         * Any status codes that falls outside the range of 2xx cause this function to trigger
         */
        if (!navigator.onLine) window.location.href = '/#/auth-offline';
        let message;
        error.response.data =
            urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true'
                ? error.response?.status?.toString() === '400'
                    ? JSON.parse(decrypt(error.response.data))
                    : error.response.data
                : error.response.data;
        switch (error.response.status) {
            case 500:
                // message = 'Internal Server Error';
                window.location.href = '/#/auth-500';
                break;
            case 502:
                message = 'Bad Gateway';
                break;
            case 503:
                message = 'Server maintenance';
                window.location.href = '/#/maintenance';
                break;
            case 401:
                // message = 'Invalid Credentials';
                // eslint-disable-next-line no-case-declarations
                const route401 = localStorage.getItem('route401');
                // eslint-disable-next-line no-case-declarations
                const logout = localStorage.getItem('logout');
                if (!route401 || logout) localStorage.setItem('route401', window.location.hash?.replace('#', ''));
                else if (route401 === 'auth-401') {
                    sessionStorage.clear();
                    localStorage.clear();
                    Store.dispatch(authUser(null));
                    Store.dispatch(enterpriseAccount(null));
                    Store.dispatch(tenantConfig(tenant?.[urlconf.REACT_APP_COMPANY_ID]));
                    window.location.reload();
                }
                window.location.href = '/#/auth-401';
                break;
            case 404:
                // message = 'Sorry! the data you are looking for could not be found';
                window.location.href = '/#/auth-404';
                break;
            case 403:
                return Promise.reject(error.response.status);
            default:
                message = error?.response?.data?.errors?.[0] || error.response.data.error || error;
                toast.error(message);
                return Promise.reject(message);
        }
    }
);

// Subscribe to store updates and initialize axios when urlconf is available
Store.subscribe(() => {
    const envVals = Store.getState()?.Commons?.envVals;
    const user = Store.getState()?.Commons?.authUser;
    const tokenVal = user ? JSON.parse(user)?.data?.token : null;
    if (tokenVal) axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenVal;
    if (envVals && Object.keys(envVals).length > 0) {
        urlconf = JSON.parse(envVals);
        initializeAxios(); // Reinitialize axios with new urlconf
    }
});

// Initial axios setup
initializeAxios();

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

const customTransformRequest = (data) => {
    return data;
};

class APIClient {
    get = (url, params, customizedBaseURL, usestaticurl) => {
        let response;
        let paramKeys = [];
        if (usestaticurl) delete axios.defaults.headers.common.Authorization;
        if (params) {
            Object.keys(params).map((key) => {
                if (params[key]) paramKeys.push(key + '=' + params[key]);
                return paramKeys;
            });
            const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : '';
            response = axios.get(
                usestaticurl ? url : `${customizedBaseURL ? originVal + customizedBaseURL + url : baseURL + url}?${queryString}`,
                params
            );
        } else {
            response = axios.get(usestaticurl ? url : `${customizedBaseURL ? originVal + customizedBaseURL + url : baseURL + url}`, params);
        }
        return response;
    };

    create = (url, data, noEnc, customizedBaseURL) => {
        let param = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? (noEnc ? data : encrypt(data)) : data;
        return axios.post(customizedBaseURL ? originVal + customizedBaseURL + url : baseURL + url, param);
    };

    update = (url, data, noEnc, customizedBaseURL) => {
        let param = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? (noEnc ? data : encrypt(data)) : data;
        let requestConfig = {
            url: customizedBaseURL ? originVal + customizedBaseURL + url : baseURL + url,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            data: param,
            transformRequest: customTransformRequest
        };
        return urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? axios(requestConfig) : axios.put(baseURL + url, data);
    };

    delete = (url, config, customizedBaseURL) => {
        return axios.delete(customizedBaseURL ? originVal + customizedBaseURL + url : baseURL + url, { ...config });
    };

    patch = (url, data, noEnc, customizedBaseURL) => {
        let param = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? (noEnc ? data : encrypt(data)) : data;
        return axios.patch(customizedBaseURL ? originVal + customizedBaseURL + url : baseURL + url, param);
    };

    getFileDownload = (url, customizedBaseURL, usestaticurl) => {
        return axios({
            method: 'GET',
            url: usestaticurl ? url : customizedBaseURL ? originVal + customizedBaseURL + url : baseURL + url,
            responseType: 'blob'
        });
    };
}

const getLoggedinUser = () => {
    let userString = Store.getState()?.Commons?.authUser;
    const user = JSON.parse(userString);
    if (!user) return null;
    return user;
};

export { APIClient, getLoggedinUser, setAuthorization };
